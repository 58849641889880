import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WarehouseService } from 'app/services/warehouse.service';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-dialog-create-or-edit-storage-level',
  templateUrl: './dialog-create-or-edit-storage-level.component.html',
  styleUrls: ['./dialog-create-or-edit-storage-level.component.scss']
})
export class DialogCreateOrEditStorageLevelComponent implements OnInit {

  storageLevelForm: UntypedFormGroup;
  warehouses: any[] = [];
  warehousesFiltered: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder,
    private _dialogRef: MatDialogRef<DialogCreateOrEditStorageLevelComponent>,
    private _warehouseService: WarehouseService,
  ) { 
    this.storageLevelForm = this._fb.group({
      // appToken: [''],
      // language: [''],
      // codPsServices: [''],
      uuidWarehouse : ['', [Validators.required]],
      minLevel      : ['', [Validators.maxLength(10), Validators.required,Validators.pattern('^[0-9]*$')]],
      okLevel       : ['', [Validators.maxLength(10), Validators.required,Validators.pattern('^[0-9]*$')]],
      maxLevel      : ['', [Validators.maxLength(10), Validators.required,Validators.pattern('^[0-9]*$')]],
      flagAlert     : ['Y'],
    });
  }

  ngOnInit(): void {
    this.warehouses = this.data.warehouse.warehouse;
    this.warehousesFiltered = this.warehouses.filter((item) =>  item.FLAG_STOCK === 'Y');

    if(this.data.element){
      this.storageLevelForm.patchValue({
        uuidWarehouse: this.data.element.UUID_WAREHOUSE,
        minLevel: this.data.element.MIN_LEVEL,
        okLevel: this.data.element.OK_LEVEL,
        maxLevel: this.data.element.MAX_LEVEL,
        flagAlert: this.data.element.FLAG_ALERT,
      });
    }
  }

  async create(){
    const json = {
      ...this.storageLevelForm.value,
      uuidPsServices: this.data.uuidPsServices
    };
    const resp = await this._warehouseService.createStorageLevel(json, this.data.update);
    // console.log(resp);
    this._dialogRef.close(true);
  }

}
