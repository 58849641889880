<div class="w-full  sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">


<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<div *ngIf="type == 'NEW'" class="bg-white rounded-lg p-4">

    <div class="w-full">
        <!-- ##########################################################  --> 
        <div class="grid grid-cols-10">   
            <div class="col-span-8">
                <!-- ## -->
                    <h2 class="prose prose-xl"><b> Asignar EasyPrice </b></h2>                       
                   <!--  <h2 class="prose "> Editar Guia Entrada </h2>   -->
                <!-- ## -->
            </div>
            <div class="col-span-1">
                <!-- ## -->
                <button class="close " mat-button (click)="closeBtn()"> <mat-icon>close</mat-icon></button>    
                <!-- ## -->
            </div>
        </div>
        <!-- ##########################################################  -->    

    </div>
            <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
            <mat-divider class="py-2"></mat-divider>
            <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->




            <!-- ##########################################################  -->
            <div >
                <button *ngIf="flagModalPS==true" mat-raised-button color="primary" (click)="showPS()" > <mat-icon>add</mat-icon>Selecionar Producto</button>
            </div>    
            <!-- ##########################################################  -->    
    <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
        <form [formGroup]="eslPSForm">
            <div>                    
                <!--//-->                        
                <div class="grid grid-cols-3 gap-4">
                            <mat-form-field class="w-full">
                                <mat-label>Nombre del producto</mat-label>
                                <input matInput formControlName="NAME_PS_SERVICES"  readonly/>
                            </mat-form-field>
                            <mat-form-field class="w-full">
                                <mat-label>SKU</mat-label>
                                <input matInput formControlName="COD_PS_SERVICES"  readonly/>
                            </mat-form-field>
                            <mat-form-field class="w-full">
                                <mat-label>Codigo Barras</mat-label>
                                <input matInput formControlName="BARCODE"  readonly/>
                            </mat-form-field>
                </div>
                <!--//-->
                <div class="grid grid-cols-3 gap-4">
                            <mat-form-field class="w-full">
                                <mat-label>Categoria</mat-label>
                                <input matInput  placeholder="Nombre del producto"   formControlName="NAME_PS_CATEGORY"  readonly/>
                            </mat-form-field>
                            <mat-form-field class="w-full">
                                <mat-label>Sub Categoria</mat-label>
                                <input matInput  placeholder="Nombre del producto"   formControlName="NAME_PS_GROUP"  readonly/>
                            </mat-form-field>
                            <mat-form-field class="w-full">
                                <mat-label>Tipo</mat-label>
                                <input matInput  placeholder="Nombre del producto"   formControlName="NAME_PS_TYPE"  readonly/>
                            </mat-form-field>                                                        
                </div>
                <!--//-->                
                <div class="grid grid-cols-3 gap-4">
                            <mat-form-field class="w-full">
                                <mat-label>Codigo EasyTag</mat-label>
                                <input matInput  placeholder="Nombre del producto"   formControlName="COD_ESL_TAG"  readonly/>
                            </mat-form-field>
                            <mat-form-field class="w-full">
                                <mat-label>Paleta de Colores</mat-label>
                                <input matInput  placeholder="Nombre del producto"   formControlName="COLOR_ESL"  readonly/>
                            </mat-form-field>
                            <mat-form-field class="w-full">
                                <mat-label>Tamaño</mat-label>
                                <input matInput  placeholder="Nombre del producto"   formControlName="SIZE_ESL"  readonly/>
                            </mat-form-field>                                                        
                </div>
                <!--//-->
                <div class="grid grid-cols-3 gap-4">
                            <mat-form-field>
                                <mat-label>Selecionar Formato</mat-label>
                                <mat-select formControlName="idEpcEslTemplate"  >
                                    <mat-option *ngFor="let a of eslTemplateSize" (click)="showTemplate(a.SAMPLE_B64)" [value]="a.ID_EPC_ESL_TEMPLATE">{{ a.NAME_TEMPLATE }}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="eslPSForm.get('idEpcEslTemplate').hasError('required')">El Formato es requerido </mat-error>
                            </mat-form-field>
                        <div class="modal-body" *ngIf="showB64 == true">
                            <img  [src]="imgB64"  style= "height:150px;width:350px;cursor:pointer" class="modal-hover-opacity"/>
                        </div>
                </div>
                <!--//-->  
                <div class="grid grid-cols-3 gap-4">
                            <mat-form-field>
                                <mat-label>Selecionar Accesorio</mat-label>
                                <mat-select formControlName="idEpcEslAccessories">
                                    <mat-option *ngFor="let a of eslAccessories" [value]="a.ID_EPC_ESL_ACCESSORIES" >{{a.NAME_ESL_ACCESSORIES}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="eslPSForm.get('idEpcEslAccessories').hasError('required')">El Accesorio es requerido </mat-error>
                            </mat-form-field>
                </div>                                                                    
                <!--//-->                                                                                              
            </div>

        </form>
    <!-- ##########################################################  -->
    <!-- ##########################################################  -->   
        <!-- ##########################################################  -->
        <div >
            <button mat-raised-button color="primary" (click)="addPsTag()" [disabled]="eslPSForm.invalid"> <mat-icon>add</mat-icon>Agregar EasyPrice</button>
        </div>    
        <!-- ##########################################################  -->    
</div>
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<div *ngIf="type == 'DETAIL'" class="bg-white rounded-lg p-4">

    <div class="w-full">
        <!-- ##########################################################  --> 
        <div class="grid grid-cols-10">   
            <div class="col-span-8">
                <!-- ## -->
                    <h2 class="prose prose-xl">Producto: <b>{{this.dataEsl.NAME_PS_SERVICES}}</b></h2>                       
                    <h2 class="prose ">SKU:<b>{{this.dataEsl.COD_PS_SERVICES}}</b> Codigo EasyPrice: <b>{{this.dataEsl.COD_ESL_TAG}}</b></h2>   
                <!-- ## -->
            </div>
            <div class="col-span-1">
                <!-- ## -->
                <button class="close " mat-button (click)="closeBtn()"> <mat-icon>close</mat-icon></button>    
                <!-- ## -->
            </div>
        </div>
        <!-- ##########################################################  -->    

    </div>
            <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
            <mat-divider class="py-2"></mat-divider>
            <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->

<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
    <div>
        <mat-table [dataSource]="tableEslPsDetail" class="mat-elevation-z8">
            <!--//-->                    
            <ng-container matColumnDef="B64PRICE">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-sku">img</mat-header-cell>
                <mat-cell *matCellDef="let o">
                                                <!-- #############  -->                
                                                <div class="flex items-center" *ngIf="o.ACTION_QUEUE == 'SET_URL'">
                                                    <div class="relative flex flex-0 items-center justify-center w-12 h-12 mr-6 rounded overflow-hidden border">

                                                        <img *ngIf="o.B64PRICE != 'FALSE'" style="height:50px;width:50px" class="w-8" [src]="o.B64PRICE" (click)="openImg('URL',o.B64PRICE,null,nulL )" >
                                                        <div *ngIf="o.B64PRICE == 'FALSE'" class="flex items-center justify-center w-full h-full text-xs font-semibold leading-none text-center uppercase" > NO IMG </div>
                                                            
                                                    </div>
                                                </div> 
                                                <!-- #############  -->
                                                <div class="flex items-center" *ngIf="o.ACTION_QUEUE == 'SET_B64'">
                                                    <div class="relative flex flex-0 items-center justify-center w-12 h-12 mr-6 rounded overflow-hidden border">

                                                        <img *ngIf="o.B64PRICE != 'FALSE'"  style="height:50px;width:50px" class="w-8" [src]="o.B64PRICE" (click)="openImg('URL',o.B64PRICE,null,nulL )" >
                                                        <div *ngIf="o.B64PRICE == 'FALSE'"  class="flex items-center justify-center w-full h-full text-xs font-semibold leading-none text-center uppercase" > NO IMG </div>
                                                            
                                                    </div>
                                                </div>                                             
                                                <!-- #############  -->                                            
                                                <div class="flex items-center" *ngIf="o.ACTION_QUEUE == 'INFO' || o.ACTION_QUEUE == 'PROPERTIES' || o.ACTION_QUEUE == 'RESET'">
                                                    <div class="flex items-center justify-center w-full h-full text-xs font-semibold leading-none text-center uppercase" > NO IMG </div>
                                                </div>                                            
                </mat-cell>
            </ng-container>
            <!--//-->                        
            <ng-container matColumnDef="ACTION_QUEUE">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">Acción</mat-header-cell>
                <mat-cell *matCellDef="let o">
                                                <div *ngIf="o.ACTION_QUEUE == 'INFO'"    style="color: rgb(55, 9, 107)"><b>Información</b></div>
                                                <div *ngIf="o.ACTION_QUEUE == 'PROPERTIES'"    style="color: rgb(55, 9, 107)"><b>Información</b></div>
                                                <div *ngIf="o.ACTION_QUEUE == 'RESET'"    style="color: rgb(55, 9, 107)"><b>Reinicio</b></div>
                                                <div *ngIf="o.ACTION_QUEUE == 'SET_B64'" style="color: rgb(0, 0, 0)"><b>Actualiza Precio</b></div>
                                                <div *ngIf="o.ACTION_QUEUE == 'SET_URL'" style="color: rgb(0, 0, 0)"><b>Actualiza Precio</b></div>
                </mat-cell>
            </ng-container>                    
            <!--//-->         
            <ng-container matColumnDef="COUNT_SEND">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Reintentos</mat-header-cell>
                <mat-cell *matCellDef="let o"><b>{{o.COUNT_SEND | number}}</b></mat-cell>
            </ng-container>                        
            <!--//-->                        
            <ng-container matColumnDef="DATE_PROCESS">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Procesado</mat-header-cell>
                <mat-cell *matCellDef="let o"><small>{{o.DATE_PROCESS |date:'dd-MM-yyyy HH:mm:ss'}}</small></mat-cell>
            </ng-container>
            <!--//-->   
            <ng-container matColumnDef="DATE_REQUEST">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Solicitado</mat-header-cell>
                <mat-cell *matCellDef="let o"><small>{{o.DATE_REQUEST | date:'dd-MM-yyyy HH:mm:ss'}}</small></mat-cell>
            </ng-container>
            <!--//-->   
            <ng-container matColumnDef="DATE_SCHEDULE">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Programado</mat-header-cell>
                <mat-cell *matCellDef="let o"><small>{{o.DATE_SCHEDULE | date:'dd-MM-yyyy HH:mm:ss'}}</small></mat-cell>
            </ng-container>               
            <!--//-->   
            <ng-container matColumnDef="RESULT_EPC_ESL_VENDOR">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Estado Actualización</mat-header-cell>
                <mat-cell *matCellDef="let o">
                                        <div *ngIf="o.RESULT_EPC_ESL_VENDOR== 'ERR'" style="color: rgb(252, 2, 2)"><b>{{o.GLS_STATE}}</b></div>
                                        <div *ngIf="o.RESULT_EPC_ESL_VENDOR != 'ERR'" style="color: rgb(14, 116, 5)"><b>{{o.GLS_STATE}}</b></div>
                </mat-cell>
            </ng-container>
            <!--//-->  
            <ng-container matColumnDef="MESSAGE">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Precio</mat-header-cell>
                <mat-cell *matCellDef="let o">
                                                    <div *ngIf="o.RESULT_EPC_ESL_VENDOR == 'ERR'" style="color: rgb(252, 2, 2)"><b>{{o.MESSAGE?.price  | currency:'CLP':'symbol-narrow'}}</b></div>
                                                    <div *ngIf="o.RESULT_EPC_ESL_VENDOR != 'ERR'" style="color: rgb(14, 116, 5)"><b>{{o.MESSAGE?.price  | currency:'CLP':'symbol-narrow'}}</b></div>                                                                        
                </mat-cell>
            </ng-container>
            <!--//-->                                                                                                                                                                                                                                                  
            <mat-header-row *matHeaderRowDef="displayedColumnEslPsDetail" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
            <mat-row *matRowDef="let row;columns: displayedColumnEslPsDetail" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
            <!--//-->                    
        </mat-table>
            <!--//-->      
            <mat-spinner *ngIf="isLoadingEslPsDetail" style="margin:0 auto"></mat-spinner>         
            <mat-paginator
            #pagEslPsDetail
            [pageSize]="pag"
            showFirstLastButtons
            [pageSizeOptions]="pageSizeOptions" 
            aria-label="Select page of periodic elements">
        </mat-paginator>               
        <!--//-->  
    </div>
</div>
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->