<div class="w-full  sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<div class="bg-white rounded-lg p-4">
    <!-- ##########################################################  -->
    <!-- ##########################################################  -->
    <div class="w-full">
        <!-- ##########################################################  --> 
        <div class="grid grid-cols-10">   
            <div class="col-span-8">
                <!-- ## -->
                    <h2 class="prose prose-xl"><b> Selecione Producto </b></h2>                       
                   <!--  <h2 class="prose "> Editar Guia Entrada </h2>   -->
                <!-- ## -->
            </div>
            <div class="col-span-1">
                <!-- ## -->
                <button class="close " mat-button (click)="closeBtn()"> <mat-icon>close</mat-icon></button>    
                <!-- ## -->
            </div>
        </div>
        <!-- ##########################################################  -->    

    </div>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<mat-divider class="py-2"></mat-divider>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->




<div class="w-full py-2">
        <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/4">
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Buscar..." #input>
            <mat-icon matPrefix> find_in_page </mat-icon> 
        </mat-form-field>
    </div>

<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!-- ///////////////////////////////////////////////////////////////////////////-->       
<!-- ///////////////////////////////////////////////////////////////////////////--> 
<div>       
        <!-- ================================================================== -->
            <mat-table [dataSource]="tableCatalogo" class="mat-elevation-z8">
                <!--//-->                    
                <ng-container matColumnDef="img">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-sku">img</mat-header-cell>
                    <mat-cell *matCellDef="let o">
                                                    <div class="flex items-center">
                                                        <div class="relative flex flex-0 items-center justify-center w-12 h-12 mr-6 rounded overflow-hidden border">
                                                            <img
                                                                class="w-8"
                                                                *ngIf="o.IMG_D !== '0'"
                                                                [alt]="'Product thumbnail image'"
                                                                [src]="urlImg + '/' + o.IMG_D + '/OIUG/null/null/null'">
                                                              
                                                            <div
                                                                class="flex items-center justify-center w-full h-full text-xs font-semibold leading-none text-center uppercase"
                                                                *ngIf="o.IMG_D === '0'">
                                                                NO IMG
                                                            </div>
                                                        </div>
                                                    </div>
                    </mat-cell>
                </ng-container>
                <!--//-->
                <ng-container matColumnDef="SKU">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">SKU</mat-header-cell>
                    <mat-cell *matCellDef="let o"><b>{{o.COD_PS_SERVICES}}</b></mat-cell>
                </ng-container>                        
                <!--//-->                        
                <ng-container matColumnDef="nombre">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">nombre</mat-header-cell>
                    <mat-cell *matCellDef="let o"><small>{{o.NAME_PS_SERVICES}}</small></mat-cell>
                </ng-container>
                <!--//-->   
                <ng-container matColumnDef="group">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Sub Categoría</mat-header-cell>
                    <mat-cell *matCellDef="let o"><small>{{o.NAME_PS_GROUP}}</small></mat-cell>
                </ng-container>
                <!--//-->   
                <ng-container matColumnDef="category">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Categoria</mat-header-cell>
                    <mat-cell *matCellDef="let o"><small>{{o.NAME_PS_CATEGORY}}</small></mat-cell>
                </ng-container>
                <!--//-->             
                <ng-container matColumnDef="precio">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Neto</mat-header-cell>
                    <mat-cell *matCellDef="let o">
                                <div style="color: rgb(9, 9, 132)"><b>{{o.PRICE.SYMBOL_CURRENCY}} {{o.PRICE.PRICE | number}}</b></div>
                        </mat-cell>
                </ng-container>
                <!--//-->                        
                <ng-container matColumnDef="stock">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">stock</mat-header-cell>
                    <mat-cell *matCellDef="let o">
                                                    <div *ngIf="o.FLAG_STOCK == 'Y'" style="color: rgb(55, 9, 107)"><b>{{o.QUANTITY_AVAILABLE}}</b></div>
                                                    <div *ngIf="o.FLAG_STOCK == 'N'" style="color: rgb(0, 0, 0)"><b>-</b></div>
                    </mat-cell>
                </ng-container>                    
                <!--//-->                                                                      
                <ng-container matColumnDef="activo">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-quantity">activo</mat-header-cell>
                    <mat-cell *matCellDef="let o">
                                                    <div *ngIf="o.ENABLED_PS_SERVICES === 'Y' ? true : false"><mat-icon class="text-green-400 icon-size-5" [svgIcon]="'heroicons_solid:check'"></mat-icon></div>
                                                    <div *ngIf="o.ENABLED_PS_SERVICES === 'N' ? true : false"><mat-icon class="text-red-400 icon-size-5"   [svgIcon]="'heroicons_solid:x'"></mat-icon></div>
                    </mat-cell>
                </ng-container>
                <!--// 081123-->                                                                      
                <ng-container matColumnDef="tag">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-quantity"  style="color: rgb(9, 4, 102)">EasyPrice</mat-header-cell>
                    <mat-cell *matCellDef="let o">
                                                    <div *ngIf="o.Q_TAG > 0 "><mat-icon class="text-green-400 icon-size-5" [svgIcon]="'heroicons_solid:check'"></mat-icon></div>
                                                    <div *ngIf="o.Q_TAG == 0"><mat-icon class="text-red-400 icon-size-5"   [svgIcon]="'heroicons_solid:x'"></mat-icon></div>
                    </mat-cell>
                </ng-container>
                <!--//-->                                                                                                                                                             
                <ng-container matColumnDef="tipo">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-quantity">tipo</mat-header-cell>
                    <mat-cell *matCellDef="let o">
                                                    <div *ngIf="o.COD_WAREHOUSE_TYPE === 'W'"><mat-icon  class="text-green-700 icon-size-6"> warehouse </mat-icon>   <small><b>&nbsp;{{o.NAME_PS_TYPE}}&nbsp;</b></small></div> 
                                                    <div *ngIf="o.COD_WAREHOUSE_TYPE === 'V'"><mat-icon  class="text-blue-700 icon-size-6"> cloud </mat-icon>        <small><b>&nbsp;{{o.NAME_PS_TYPE}}&nbsp;</b></small></div> 
                                                    <div *ngIf="o.COD_WAREHOUSE_TYPE === 'U'"><mat-icon  class="text-blue-700 icon-size-6"> all_inclusive </mat-icon><small><b>&nbsp;{{o.NAME_PS_TYPE}}&nbsp;</b></small></div> 
                    </mat-cell>
                </ng-container>
                <!--//-->                       
                <ng-container matColumnDef="acc">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-add"></mat-header-cell>
                    <mat-cell *matCellDef="let o">
                        <!-- //////////////////////////////////////////////////  -->
                            <div >
                                <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button (click)="selectProduct(o)"> <mat-icon class="icon-size-5" color="primary" [svgIcon]="'search'"></mat-icon> </button>
                            </div>
                        <!-- //////////////////////////////////////////////////  -->                                    
                    </mat-cell>
                </ng-container>
                <!--//-->               
                <mat-header-row *matHeaderRowDef="displayedColumnCatalogo" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                <mat-row *matRowDef="let row;columns: displayedColumnCatalogo" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                <!--//-->                    
            </mat-table>
                <!--//-->      
                <mat-spinner *ngIf="isLoadingCatalogo" style="margin:0 auto"></mat-spinner>         
                <mat-paginator
                #pagCatalogo
                [pageSize]="pag"
                showFirstLastButtons
                [pageSizeOptions]="pageSizeOptions" 
                aria-label="Select page of periodic elements">
            </mat-paginator>               
            <!--//-->     
        <!-- ================================================================== -->
</div>        
<!-- ///////////////////////////////////////////////////////////////////////////-->
<!-- ///////////////////////////////////////////////////////////////////////////-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
</div>
</div>