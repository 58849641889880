<div class="w-full  sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">


<!--############################################################-->
<div class="bg-white rounded-lg p-4">
    <!-- ##########################################################  -->
    <!-- ##########################################################  -->
    <div class="w-full">
        <!-- ##########################################################  --> 
        <div class="grid grid-cols-10">   
            <div class="col-span-8">
                <!-- ## -->
                    <h2 class="prose prose-xl"><b> Crear Costo</b></h2>                       
                   <!--  <h2 class="prose "> Editar Guia Entrada </h2>   -->
                <!-- ## -->
            </div>
            <div class="col-span-1">
                <!-- ## -->
                <button class="close " mat-button (click)="closeBtn()"> <mat-icon>close</mat-icon></button>    
                <!-- ## -->
            </div>
        </div>
        <!-- ##########################################################  -->    

    </div>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<mat-divider class="py-2"></mat-divider>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->


    <form [formGroup]="costosForm" onsubmit="return false;">


<!--//-->                                                 
<div class="grid grid-cols-2 gap-1">
    <!-- ## -->
        <div>
                <mat-form-field class="w-3/4">
                    <mat-label> Tipo de costos </mat-label>
                    <mat-select  formControlName="idPsServicesCostItem">
                        <ng-container *ngFor="let item of data.costsItems">
                            <mat-option [(value)]="item.ID_PS_SERVICES_COST_ITEM" (click)="setNameCost(item)">
                                {{ item.NAME_SERVICES_COST_ITEM }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
        </div>
        <div>
                    <mat-form-field class="w-1/2">
                        <mat-label> Nombre del costo</mat-label>
                        <!-- <span matPrefix>$</span> -->
                        <input matInput formControlName="name" />
                    </mat-form-field>
        </div>
    <!-- ## -->    
</div>
<!--//-->                                                     
<!--//-->                                                 
<div class="grid grid-cols-2 gap-1">
    <!-- ## -->
        <div class="col-span-2">
                        <div>
                            <mat-form-field class="w-1/3">
                            <mat-label> Selecione Proveedor</mat-label>
                                <input matInput type="text"   formControlName="rutCustomer" readonly>
                                <mat-icon matPrefix> person </mat-icon>
                                <button  class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button (click)="showCustomer()"> <mat-icon class="icon-size-5" color="primary" [svgIcon]="'search'"></mat-icon> </button>
                            </mat-form-field>
                            
                        </div>
                        <div>
                            <mat-form-field class="w-3/4">
                                <input matInput type="text"   formControlName="nameCustores" readonly>
                                <mat-icon matPrefix> person </mat-icon>
                            </mat-form-field>                          
                        </div>
        </div>
    <!-- ## -->    
</div>
<!--//-->   
<!--//-->                                                 
<div class="grid grid-cols-2 gap-1">
    <!-- ## -->
        <div class="col-span-2">
                    <!--//-->
                    <mat-form-field class="w-1/3">
                        <mat-label> Valor </mat-label>
                        <span matPrefix>{{
                            data.SYMBOL_CURRENCY
                        }}</span>
                        <input
                            matInput
                            type="number"
                            formControlName="price"
                        />
                    </mat-form-field>
        </div>
    <!-- ## -->    
</div>
<!--//-->   
<div> 
    <p style="color: rgb(0, 32, 96);background-color:rgb(255, 242, 204);border-style: dotted;"><b>Solo se puede ingresar un Costo Principal, este se actualiza<small>(*)</small> al realizar una guia de entrada</b><br><small>(*) Opcional</small> </p>
</div> 
<!--//-->                                                 
<div class="grid grid-cols-2 gap-1">
    <!-- ## -->
        <div class="col-span-2">
            <button
            mat-raised-button
            color="primary"
            (click)="addCost()"
            
        >
            Agregar
        </button>
        </div>
    <!-- ## -->    
</div>
<!--//-->  

    </form>  

</div>
<!--############################################################-->
</div>