import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EmisorService } from 'app/services/emisor.service'; 
import { ChangeDetectorRef } from '@angular/core';
import { CatalogService } from 'app/services/catalog.service';
import { FormBuilder, UntypedFormGroup, Validators,FormControl } from '@angular/forms';
import { FuseConfirmationService } from '@fuse/services/confirmation';
@Component({
  selector: 'app-dialog-external',
  templateUrl: './dialog-external.component.html',
  styleUrls: ['./dialog-external.component.scss']
})
export class DialogExternalComponent implements OnInit {

externalForm    : UntypedFormGroup;
//############################################################
//############################################################
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<DialogExternalComponent>,
    private _fb: FormBuilder,
    private _emisorService: EmisorService,
    private ref: ChangeDetectorRef,
    private _catalogService: CatalogService,
    private _fuseConfirmationService: FuseConfirmationService,
  ) { 

    this.externalForm = this._fb.group({
      uuidBrands: [null,[Validators.required]]
     ,link: [null,[Validators.required]]


    })

  }
//############################################################
//############################################################
  ngOnInit(): void {

  }
//############################################################
//############################################################
async closeBtn(): Promise<any> {
  this._dialogRef.close();
}
//############################################################
//############################################################
async addExternalPS(){
  var rs,tmp
  tmp = this.data.brands.filter(i => i.UUID_PS_BRANDS == this.externalForm.value.uuidBrands)[0]
    this._fuseConfirmationService.open({
      title: `Agregar Link ${tmp.NAME_PS_BRANDS}`,
      message: `Se agregara la redirección externa`,
      icon: {
        name: 'delete',
        color: 'primary'
      },
      actions: {
        confirm: {
          label: 'Agregar',
        },
        cancel: {
          label: 'Cancelar',
        }
      }
    }).afterClosed().subscribe(
      async (res: any) => {
        // //(res)
        if(res === 'confirmed'){
          var json = {
                         appToken               : null
                        ,language               : 'es'     
                        ,uuidPsServices         : this.data.uuidPsServices
                        ,idPsServicesExternal   : 0
                        ,uuidPsBrands           : this.externalForm.value.uuidBrands
                        ,linkPsServicesExternal : this.externalForm.value.link
                        ,acc                    : 'A'
                      
                      }
        rs =  await this._catalogService.setPsServicesExternal(json)
        if(rs.success ==  true){
          this._dialogRef.close({refresh:true});
        }
        }
      }
    );
  
    }
//############################################################
//############################################################    
}
