<div class="w-full  sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
<!--############################################################-->
<div class="bg-white rounded-lg p-4">

    <!-- ##########################################################  -->
    <!-- ##########################################################  -->
    <div class="w-full">
        <!-- ##########################################################  --> 
        <div class="grid grid-cols-10">   
            <div class="col-span-8">
                <!-- ## -->
                    <h2 class="prose prose-xl">Duplicar Producto  <b *ngIf="product?.data?.FLAG_EXTERNAL == 'Y'" style="color:rgb(245, 170, 9)">Externo</b>  </h2>                       
                        <!-- #### -->
                        <!-- #### -->                    
                <!-- ## -->
            </div>
            <div class="col-span-1">
                <!-- ## -->
                <button class="close " mat-button (click)="closeBtn()"> <mat-icon>close</mat-icon></button>    
                <!-- ## -->
            </div>
        </div>
        <!-- ##########################################################  -->    
            <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
            <mat-divider class="py-2"></mat-divider>
            <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
            <!-- ##############################################################################################-->


                <mat-stepper [linear]="false">
                    <mat-step label="Informacion General" [stepControl]="infoForm">
                        <form [formGroup]="infoForm">
                            <!-- //////////////////////////////////////////////////////////////////////////////// -->
                            <!-- //////////////////////////////////////////////////////////////////////////////// -->                             
                            <div class="grid grid-cols-7">
                                <div class="col-span-5">
                                    <mat-form-field class="w-3/4 ">
                                        <mat-label>Nombre del producto</mat-label>
                                        <input 
                                            matInput 
                                            placeholder="Nombre del producto"
                                            (input)="countCharacter('name')" 
                                            formControlName="name" />
                                        <mat-hint> {{countNameCharacter}} / 70 </mat-hint>
                                        <mat-error *ngIf="infoForm.get('name').hasError('required')"> El nombre es requerido </mat-error>
                                        <mat-error *ngIf="infoForm.get('name').hasError('maxlength')"> El nombre no puede ser mas de 70 caracteres </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-span-2 flex items-center justify-center">
                                    <mat-slide-toggle
                                        color="primary"
                                        formControlName="visible"
                                        > <b>Comercializable</b> </mat-slide-toggle
                                        >
                                </div>
                            </div>
                            <div class="w-full mt-4">
                                <div class="grid grid-cols-2 gap-4">
                                    <div>
                                        <mat-form-field class="w-3/4">
                                            <mat-label>SKU</mat-label>
                                            <input
                                                matInput
                                                readonly
                                                type="text"
                                                formControlName="SKU"
                                                />
                                            <!-- <mat-error *ngIf="infoForm.get('SKU').hasError('required')"> El SKU es requerido </mat-error> -->
                                        </mat-form-field>
                                    </div>
                                    <div>
                                        <div class="flex justify-center">
                                            <ngx-barcode
                                            [bc-format]="formatBarcode"
                                            [bc-value]="barcode"
                                            [bc-display-value]="true"
                                            ></ngx-barcode>
                                            
                                        </div>
                                    </div>
                                </div>  
                             </div>   
                             <div *ngIf="flagExternal == false"><mat-slide-toggle color="primary" formControlName="flagOutStock">Comercializar Fuera Stock</mat-slide-toggle></div>
                             <!-- %%% -->  
                                <div class="grid grid-cols-2 gap-4 mt-4">
                                    <div>
                                        <div class="flex items-center">
                                            <mat-form-field
                                                class="w-full"
                                                appearance="fill"
                                                >
                                                <mat-label>Tipo de código</mat-label>
            
                                                <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$-->
                                                
                                                <mat-select formControlName="typeCod">
                                                    <mat-option
                                                    *ngFor="let opt of typeBarcode"
                                                    [value]="opt.COD_TYPE_BARCODE"
                                                    (click)="changeTypeCod(opt.FORMAT_TYPE_BARCODE,opt.COD_TYPE_BARCODE)"
                                                    [matTooltip]="opt.MASK_SKU"
                                                    matTooltipPosition="left"
                                                    >
                                                    {{ opt.GLS_TYPE_BARCODE }}
                                                    </mat-option>
                                                </mat-select>
                                                
                                                <!-- 
                                                <mat-select formControlName="typeCod" (selectionChange)="changetypeCod($event.value)" >
                                                    <ng-container *ngFor="let opt of options">
                                                        <mat-option [value]="opt.value">
                                                            {{opt.value}}
                                                        </mat-option>
                                                    </ng-container>
                                                </mat-select>  
                                                -->                                  
                                                <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$-->
            
            
                                            </mat-form-field>
                                        </div>
                                        <div class="flex items-center mt-4">
                                            <mat-form-field class="w-full" appearance="fill">
                                                <mat-label>Código de barras</mat-label>
                                                <mat-checkbox matPrefix (change)="toggleEditModeBarcode()"></mat-checkbox>
                                                <input matInput [readOnly]="!editModeBarcode" formControlName="barcode" />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex mt-4" *ngIf="flagExternal == false">
                                    <mat-form-field class="w-1/4 pr-2">
                                        <mat-label>Nombre en la boleta</mat-label>
                                        <input
                                            (input)="countCharacter('nameInvoice')"
                                            matInput
                                            type="text"
                                            formControlName="nameInvoice"
                                            />
                                        <mat-hint aling="end"> {{countNameInvoiceCharacter}} / 40 </mat-hint>
                                        <mat-error *ngIf="infoForm.get('nameInvoice').hasError('maxlength')"> El nombre en boleta no puede superar los 40 caracteres </mat-error>
                                        <mat-error *ngIf="infoForm.get('nameInvoice').hasError('required')"> El nombre en boleta es requerida </mat-error>
                                    </mat-form-field>
            
                                    <mat-form-field class="w-1/4">
                                        <mat-label>Descripción en la boleta</mat-label>
                                        <textarea matInput (input)="countCharacter('descInvoice')" type="text" formControlName="descInvoice"></textarea>
                                        <mat-hint aling="end"> {{countDescInvoiceCharacter}} / 80 </mat-hint>
                                        <mat-error *ngIf="infoForm.get('descInvoice').hasError('maxlength')"> La descripción en la boleta no puede superar los 80 caracteres </mat-error>
                                    </mat-form-field>
                                </div>
                                <!-- ////////////  -->
                                <div class="flex mt-4">
                                    <mat-form-field class="w-3/4 pr-2">
                                        <mat-label>Descripcion Corta (Vista Rapida Ecommerce)</mat-label>
                                        <input
                                            (input)="countCharacter('psServicesDescriptionShort')"
                                            matInput
                                            type="text"
                                            formControlName="psServicesDescriptionShort"
                                            />
                                        <mat-hint aling="end"> {{countpsServicesDescriptionShort}} / 100 </mat-hint>
                                        <mat-error *ngIf="infoForm.get('psServicesDescriptionShort').hasError('maxlength')"> La descripción Corta no puede superar los  100 caracteres </mat-error>
                                        <mat-error *ngIf="infoForm.get('psServicesDescriptionShort').hasError('required')"> La descripción Corta es requerida </mat-error>
                                    </mat-form-field>
                                </div>                    
                                <!-- ////////////  -->
                                <div class="mt-4">
                                    <mat-label> Descripcion del producto</mat-label>
                                    <mat-error *ngIf="infoForm.get('description').hasError('required')"> La descripcion es requerida </mat-error>
                                    <quill-editor format="html" formControlName="description"></quill-editor>
                                </div>
                                                     
                            <!-- //////////////////////////////////////////////////////////////////////////////// -->
                            <!-- //////////////////////////////////////////////////////////////////////////////// -->  
                            <button  *ngIf="flagExternal == false" mat-raised-button color="primary" matStepperNext >Siguiente </button>
                            <button  *ngIf="flagExternal == true" mat-raised-button color="primary"  (click)="assingResume()"  [disabled]="infoForm.invalid" matStepperNext>Resumen</button>
                            <!-- // -->

                        </form>
                    </mat-step>
                    <mat-step label="Precio" [stepControl]="preciosForm"  *ngIf="flagExternal == false">
                        <form [formGroup]="preciosForm" class="flex flex-col w-1/2 sm:pl-8">
                            <div class="w-full">
            <!--&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->                    
            <div>
                <div class="text-2xl  tracking-tight"> <p style="color: rgb(4, 5, 93)">Margen Mínimo: <b>{{this.data.percentProfitsMargin  | percent}}</b></p></div> 
            <div>
                <mat-form-field class="w-1/2">
            
                    <mat-label>Costo </mat-label>

                    <span matPrefix>
                        {{ data.chargeCodeSelectDetail[0]?.SYMBOL_CURRENCY }}
                        </span>
                    <input matInput formControlName="cost" class="w-full" (change)="setFirstPriceSales($event,$event.target.value)"/>
                </mat-form-field>
                <!-- ######################## -->
                <!-- ######################## -->                 
                    <div class="mb-2">
                        <mat-slide-toggle
                            color="primary"
                            formControlName="flagClonePrice" (change)="setCopyCost($event)"
                            >Copiar Estructura de Costos</mat-slide-toggle
                            >
                    </div>
                    <div class="mb-2">
                        <mat-slide-toggle
                            color="primary"
                            formControlName="flagCopyDiscount" 
                            >Copiar Descuentos</mat-slide-toggle
                            >
                    </div>                    
                <!-- ######################## -->
                <!-- ######################## -->                 
                <mat-form-field class="w-1/2">
                    <mat-label>Precio de Venta (Precio Bruto) </mat-label>
                    <span matPrefix>
                        {{ data.chargeCodeSelectDetail[0]?.SYMBOL_CURRENCY }}
                        </span>
                    <input matInput type="number" [min]="minBruto" formControlName="psPriceTAX" (change)="setPrecioNeto($event,$event.target.value)" class="w-1/2"/>
                    <mat-error *ngIf="preciosForm.get('cost').value > preciosForm.get('psPrice').value"> El Precio de venta no puede ser menor que el costo </mat-error>
                </mat-form-field>
            
                                <mat-form-field class="w-1/2">
                                    <mat-label>Precio Neto</mat-label>
                                    <span matPrefix>
                                    {{ data.chargeCodeSelectDetail[0]?.SYMBOL_CURRENCY }}
                                    </span>
                                    <input
                                        matInput
                                        type="number"
                                        formControlName="psPrice"
                                        class="w-full"
                                        readonly
                                        />
                                </mat-form-field>
            
                            </div>                    
            </div>                    
            <!--&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->                                        
                            </div>
                            <!--
                            <div *ngIf="!!product" class="mb-4">
                                <mat-card>
                                    <mat-card-subtitle>Margen</mat-card-subtitle>
                                    <mat-card-content>
                                        <div ng-if="{{this.margenPrice.txt}} !=null">
                                            <p class="text-red-500">
                                                {{ this.margenPrice.txt }}
                                            </p>
                                        </div>
                                        <mat-divider inset></mat-divider>
                                        <p>
                                            Porcentaje Margen:<b
                                                >{{ this.margenPrice.percent }}%</b
                                                >
                                        </p>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                            -->
                            <h1 class="prose"></h1>
                            <div class="w-full mb-4">
                                <div class="mb-2">
                                    <mat-slide-toggle color="primary" formControlName="flagConsignado"  (change)="setCustomerDefault($event)">Consignado</mat-slide-toggle>
                                                    <!-- //-->
                                                    <div *ngIf="preciosForm.get('flagConsignado').value == true">
                                                        <mat-form-field class="w-1/2">
                                                            <input matInput type="text"   formControlName="rutCustomerConsignado" readonly>
                                                            <mat-icon matPrefix> person </mat-icon>
                                                        </mat-form-field>
                                                        <mat-form-field class="w-3/4">
                                                            <input matInput type="text"   formControlName="nameCustoreConsignado" readonly>
                                                            <mat-icon matPrefix> person </mat-icon>
                                                        </mat-form-field>  
                                                        <button  class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button (click)="showCustomer()"> <mat-icon class="icon-size-5" color="primary" [svgIcon]="'search'"></mat-icon> </button>
                                                    </div> 
                                                    <!--//-->                        
                                </div>   
                                <!-- ################### -->

                                <!-- ################### -->
                                <div class="mb-2">
                                    <mat-slide-toggle
                                        color="primary"
                                        formControlName="overwrite"
                                        >Sobrescribible</mat-slide-toggle
                                        >
                                </div>
                                <div class="mb-2">
                                    <mat-slide-toggle
                                        color="primary" 
                                        formControlName="activeDteTax"
                                        >Afecto</mat-slide-toggle
                                        >
                                </div>
                                <div class="mb-2">
                                    <mat-slide-toggle
                                        color="primary"
                                        formControlName="activeRetenidoTax"
                                        #toggleRetTax
                                        >Impuesto Retenido</mat-slide-toggle
                                        >
                                    <mat-form-field class="w-60">
                                        <mat-select
                                            formControlName="codRetenidoTax"
                                            [disabled]="
                                            !preciosForm.get('activeRetenidoTax').value
                                            "
                                            class="w-full"
                                            >
                                            <ng-container
                                                *ngFor="let tax of impuestosRetenidos"
                                                >
                                                <mat-option [value]="tax.value">{{
                                                    tax.viewValue
                                                    }}
                                                </mat-option>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="mb-2">
                                    <mat-slide-toggle
                                        color="primary"
                                        formControlName="activeAnticipadoTax"
                                        >Impuesto Especial</mat-slide-toggle
                                        >
                                    <mat-form-field class="w-60">
                                        <mat-select
                                            formControlName="codAnticipadoTax"
                                            [disabled]="
                                            !preciosForm.get('activeAnticipadoTax')
                                            .value
                                            "
                                            class="w-full"
                                            >
                                            <ng-container
                                                *ngFor="let tax of impuestosEspeciales"
                                                >
                                                <mat-option [value]="tax.value">{{
                                                    tax.viewValue
                                                    }}
                                                </mat-option>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </form>
                <!-- ///////////////////////////////////  -->
                <div class="flex justify-end">
                    <button mat-button matStepperPrevious>Volver</button>
                    <button mat-raised-button color="primary"  (click)="assingResume()"  [disabled]="infoForm.invalid || preciosForm.invalid" matStepperNext>Resumen</button>
                </div>
                <!-- ///////////////////////////////////  -->                        
                    </mat-step>


                    <mat-step label="Resumen">
                        <form [formGroup]="resumeForm">
                            <div class="w-full">
                                <h2 class="text-xl font-bold mb-4">Resumen General</h2>
                                <h2 *ngIf=" infoForm.get('visible').value == true" class="text-xl font-bold mb-4">Producto <b>Comercializable</b></h2>
                                <h2 *ngIf=" infoForm.get('visible').value == false" class="text-xl font-bold mb-4"><p style="color: red">¡¡ALERTA!!: Producto<b> No Comercializable</b>, para modificar en Paso 1(Información General) </p> </h2>
                                <div class="flex mb-2">
                                    <div class="w-1/2 pr-2">
                                        <mat-form-field class="w-full">
                                            <mat-label>Nombre</mat-label>
                                            <input matInput formControlName="name">
                                        </mat-form-field>
                                        
                                    </div>
                                    <div class="w-1/2 pl-2">
                                        <!-- <mat-form-field class="w-full">
                                            <mat-label> Descripcion</mat-label>
                                            <textarea matInput formControlName="desc" class="w-full h-50" [value]="resumeForm.value.desc">
                                            </textarea>
                                            </mat-form-field> -->
                                        <!-- <mat-form-field class="w-full">
                                            <mat-label>Descripción</mat-label>
                                            <input
                                                matInput
                                                formControlName="desc"
                                            />
                                            </mat-form-field> -->
                                        <!-- <quill-editor
                                            formControlName="desc">
                                            <div quill-editor-toolbar></div>
                                            <quill-view></quill-view>
                                            </quill-editor> -->
                                        <!-- <quill-view
                                            [content]="resumeForm.value.desc"
                                            formControlName="desc"
                                            ></quill-view> -->
                                    </div>
                                </div>
                                <div class="grid grid-cols-4 gap-2">
                                    <div>
                                        <mat-form-field class="w-full">
                                            <mat-label>SKU</mat-label>
                                            <input matInput formControlName="SKU">
                                        </mat-form-field>
                                    </div>
                        <!--/////////////////////////--> 
                        <div *ngIf="flagExternal == false">
                            <mat-form-field class="w-full">
                                <mat-label> Unidad de Medida</mat-label>
                                <input matInput formControlName="unitMeasurement">
                            </mat-form-field>
                        </div>        
                        <div class="w-full" *ngIf="flagExternal == false"> 
                            <mat-form-field class="w-full">
                                <mat-label> Lote </mat-label>
                                <input matInput type="text" formControlName="entryPsServices">
                            </mat-form-field>
                        </div>
                        <div class="w-full" *ngIf="flagExternal == false">
                            <mat-form-field class="w-full">
                                <mat-label> Vencimiento </mat-label>
                                <input matInput type="text" formControlName="dueDate">
                            </mat-form-field>
                        </div>
                        <div class="w-full" >
                            <mat-form-field class="w-full">
                                <mat-label> Externo </mat-label>
                                <input matInput type="text" formControlName="external">
                            </mat-form-field>
                        </div>            
                        
                        <!--/////////////////////////-->                           
                                    <div>
                                        <mat-form-field class="w-full">
                                            <mat-label> Codigo EAN </mat-label>
                                            <input matInput formControlName="codigoInt">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-4 gap-2">
                                    <div class="w-full">
                                        <mat-form-field class="w-full">
                                            <mat-label> Categoria </mat-label>
                                            <input matInput type="text" formControlName="categoria">
                                        </mat-form-field>
                                    </div>
                                    <div class="w-full">
                                        <mat-form-field class="w-full">
                                            <mat-label> Tipo </mat-label>
                                            <input matInput type="text" formControlName="tipo">
                                        </mat-form-field>
                                    </div>
                                    <div class="w-full">
                                        <mat-form-field class="w-full">
                                            <mat-label> Grupo </mat-label>
                                            <input matInput type="text" formControlName="grupo">
                                        </mat-form-field>
                                    </div>
                                    <div class="w-full">
                                        <mat-form-field class="w-full">
                                            <mat-label> Marca </mat-label>
                                            <input matInput type="text" formControlName="marca">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full mt-4" *ngIf="flagExternal == false">
                                <h2 class="text-xl font-bold mb-4">
                                    Resumen de Facturación
                                </h2>
                                <!-- <div class="flex mb-2">
                                    <div class="w-1/2 pr-2">
                                        <mat-form-field class="w-full">
                                            <mat-label>Fecha inicio</mat-label>
                                            <input
                                                matInput
                                                formControlName="fechaInicio"
                                            />
                                        </mat-form-field>
                                    </div>
                                    <div class="w-1/2 pl-2">
                                        <mat-form-field class="w-full">
                                            <mat-label>Fecha fin</mat-label>
                                            <input
                                                matInput
                                                formControlName="fechaFin"
                                            />
                                        </mat-form-field>
                                    </div>
                                    </div> -->
                                <div class="flex mb-2">
                                    <div class="w-1/2 pl-2">
                                        <mat-form-field class="w-full">
                                            <mat-label>Codigo de Cargo</mat-label>
                                            <input
                                                matInput
                                                formControlName="charCode"
                                                />
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div>
                                    <h4 class="font-medium"> Cuentas Contables </h4>
                                    <div>
                                        <mat-form-field
                                            *ngFor="
                                            let cuentas of cuentasContablesArray
                                            "
                                            class="pr-2"
                                            >
                                            <mat-label>{{ cuentas.name }}</mat-label>
                                            <input
                                                matInput
                                                type="text"
                                                disabled
                                                [value]="cuentas.cta"
                                                />
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full mt-4" *ngIf="flagExternal == false">
                                <h2 class="text-xl font-bold mb-4">Precio</h2>   
                                <div class="grid grid-cols-4 gap-2">
                                <!--#######################################################################-->
                                
                                    <div class="">
                                        <mat-form-field class="w-full">
                                            <mat-label>Precio Neto</mat-label>
                                            <span matPrefix>
                                                {{ data.chargeCodeSelectDetail[0]?.NAME_CURRENCY }} $
                                                </span>
                                            <input
                                                matInput
                                                formControlName="psPrice"
                                                />
                                        </mat-form-field>
                                    </div>
            
                                    <div class="">
                                        <mat-form-field class="w-full">
                                            <mat-label>Precio de Venta (Precio Bruto)</mat-label>
                                            <span matPrefix>
                                                {{ data.chargeCodeSelectDetail[0]?.NAME_CURRENCY }} $
                                                </span>
                                            <input
                                                matInput
                                                formControlName="psPriceTAX"
                                                />
                                        </mat-form-field>
                                    </div>
            
                                    <div class="">
                                        <mat-form-field class="w-full">
                                            <mat-label>Costo</mat-label>
                                            <span matPrefix>
                                                {{ data.chargeCodeSelectDetail[0]?.NAME_CURRENCY }} $
                                                </span>
                                            <input matInput disabled formControlName="cost"/>
                                        </mat-form-field>
                                    </div>
                                <!--#######################################################################-->
                                    <div>
                                        <mat-form-field class="w-full">
                                            <mat-label> Consignado </mat-label>
                                            <input matInput type="text" disabled [value]="preciosForm.value.flagConsignado? 'Si' : 'No'">
                                        </mat-form-field>
                                    </div>   
                                    
                                
            
                                    <div>
                                        <mat-form-field class="w-full">
                                            <mat-label> Sobrescribible </mat-label>
                                            <input matInput type="text" disabled [value]="preciosForm.value.overwrite? 'Si' : 'No'">
                                        </mat-form-field>
                                    </div>
                                    <div>
                                        <mat-form-field class="w-full">
                                            <mat-label> Afecto </mat-label>
                                            <input matInput type="text" disabled [value]="preciosForm.value.activeDteTax? 'Afecto Inpuestos' : 'Exento Impuestos'">
                                        </mat-form-field>
                                    </div>
                                    <div class="">
                                        <mat-form-field class="w-full">
                                            <mat-label>Impuesto Retenido</mat-label>
                                            <input
                                                matInput
                                                formControlName="codRetenidoTax"
                                                />
                                        </mat-form-field>
                                    </div>
                                    <div class="">
                                        <mat-form-field class="w-full">
                                            <mat-label>Impuesto Especial</mat-label>
                                            <input
                                                matInput
                                                formControlName="codAnticipadoTax"
                                                />
                                        </mat-form-field>
                                    </div>
                                </div>
                                <!-- <div class="flex mb-2">
                                    </div> -->
                            </div>
                        </form>
                        <div class="flex justify-end">
                            <button mat-button matStepperPrevious>Volver</button>
                            <!-- ## -->                
                                <button *ngIf="flagExternal == false "
                                mat-raised-button
                                [color]="'primary'"
                                (click)="cloneProduct()"
                                [disabled]="infoForm.invalid || preciosForm.invalid"
                                >
                                Añadir
                                </button>
                            <!-- ##  *ngIf="uuidPsServices === 'add'" -->
                                <button *ngIf="flagExternal == true "
                                mat-raised-button
                                [color]="'primary'"
                                (click)="cloneProduct()"
                                [disabled]="infoForm.invalid"
                                >
                                Añadir
                                </button>
                            <!-- ## -->                    
                        </div>
                    </mat-step>                    
                </mat-stepper>
    </div>
</div>    





</div>