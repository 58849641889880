import { Component, Inject,OnInit,ChangeDetectorRef, HostListener,ViewChild,LOCALE_ID } from '@angular/core';

import { FormBuilder, UntypedFormGroup, Validators,FormControl } from '@angular/forms';
import { CatalogService } from 'app/services/catalog.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import {COMMA, ENTER, W} from '@angular/cdk/keycodes';
// import {MatChipInputEvent} from '@angular/material/chips';
import { Observable, of } from 'rxjs';
// import {map, startWith} from 'rxjs/operators';
import * as rut from '@fdograph/rut-utilities';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { ActivatedRoute, Router } from '@angular/router';
import { DocsService } from 'app/services/docs.service';
import { fuseAnimations } from '@fuse/animations';


//import { DialogSeeAllMovementsComponent } from './dialog-See-All-Movements/dialog-See-All-Movements.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';

import { WarehouseService } from 'app/services/warehouse.service';
import { Buffer } from "buffer";
import { DialogCreateOrEditStorageLevelComponent } from './dialog-create-or-edit-storage-level/dialog-create-or-edit-storage-level.component';
import { DialogSeeAllMovementsComponent } from './dialog-see-all-movements/dialog-See-all-Movements.component';     
import {ModalImgComponent} from '../modal-img/modal-img.component'
import { EslDetailComponent } from '../esl-detail/esl-detail.component';
import { DialogCostComponent } from './dialog-cost/dialog-cost.component'
import { SelectClientesComponent } from '../../clientes/select-clientes/select-clientes.component'
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// import * as barcode from 'jsbarcode'
// import JsBarcode from 'jsbarcode';

import { DialogExternalComponent } from './dialog-external/dialog-external.component'
import { DialogCloneComponent } from './dialog-clone/dialog-clone.component'

import { DiscountsComponent } from '../discounts/discounts.component'

//import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, NumberFormatStyle } from '@angular/common';
import { DatePipe } from '@angular/common';
//
@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  animations     : fuseAnimations
})
export class DetailComponent implements OnInit {
  @ViewChild('pagPrice' ) pagPrice : MatPaginator;
  ///////////////////////// VARIABLES GLOBALES /////////////////////////

  appTokenImg = environment.appTokenImg;
  appImgURL   = environment.appImgURL;
  appImgSizeA = environment.appImgSizeA;
  appImgSizeB = environment.appImgSizeB;

  resumeForm: UntypedFormGroup;

  prefixEAN = '000000';
  ean13Generated: boolean = false;
  ean8Generated: boolean = false;

  product: any = {}
  isProduct: boolean = false;
  allDataLoaded: boolean = false;

  priceActive= [];


  selectedColor: string = '';
   
  dateCreation     : any = null 
  dateUpdate       : any = null 
  employeeCreation : any = null 
  employeeUpdate   : any = null 
  //
  countOrders        : number =0;
  countStock         : number =0;
  countOrdersPending : number =0;
  flagEditProperties : boolean = false;

  ///////////////////////// Informacion Basica /////////////////////////
  imgs: any[] = [];
  infoForm: UntypedFormGroup;
  //codProd: any;
  uuidPsServices :string = '';
  percentProfitsMargin = 0;
  minBruto =0


  formatBarcode: any;
  categories: any[] = [];
  types: any[] = [];
  groups: any[] = [];
  groupsSelected: any[] = [];
  providers: any[] // = [{value: 0,RUT:'11111111-1', RAZON_SOCIAL: 'Generico'}];
  brands: any[]
  barcode: any;

  countNameCharacter: number = 0;
  countNameInvoiceCharacter: number = 0;
  countDescInvoiceCharacter: number = 0;
  countpsServicesDescriptionShort: number = 0;

  editModeBarcode: boolean = false;

  selectedSalesChannel: any[] = [];

  // config = {
  //   toolbar: [
  //     ['bold', 'italic', 'underline', 'strike'],
  //     ['blockquote', 'code-block'],
  //     [{ 'header': 1 }, { 'header': 2 }],
  //     [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  //     [{ 'script': 'sub' }, { 'script': 'super' }],
  //     [{ 'indent': '-1' }, { 'indent': '+1' }],
  //     [{ 'direction': 'rtl' }],
  //     [{ 'size': ['small', false, 'large', 'huge'] }],
  //     [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  //     [{ 'color': [] }, { 'background': [] }],
  //     [{ 'font': [] }],
  //     [{ 'align': [] }],
  //     ['clean']
  //   ]
  // };
  minDateInfo = new Date(2000, 0, 1);
  today = new Date();
  charCodes: any[] = [];
  charCodeSelected: any;
  currentImg: any;
  imgSrc: any;
  ///////////////////////// DETALLES DE FACTURACION /////////////////////////

  cuentasContablesArray: any[] = [];

  ///////////////////////// IMGS /////////////////////////
  imgToUpload: File;

  ///////////////////////// PRECIOS /////////////////////////
  preciosForm: UntypedFormGroup;
  impuestosRetenidos: any[] = [];
  impuestosEspeciales: any[] = [];
//#########################################################
pag                 : number = 10;
pageSizeOptions     : any    = [ 10,50, 100];
//#########################################################
  @ViewChild('pagTag' ) pagTag : MatPaginator;
  // ['COD_ESL_TAG','STATUS','LAST_UDP','STATUS_UDP','PRICE_UDP','SIZE_ESL','BATTERY_ESL_TAG','TEMPERATURE','COLOR_ESL','NAME_TEMPLATE','acc']; 
  displayedColumnTag  : string[] = ['COD_ESL_TAG','STATUS','LAST_UDP','STATUS_UDP','PRICE_UDP','SIZE_ESL','BATTERY_ESL_TAG','NAME_TEMPLATE','NAME_ESL_ACCESSORIES','acc']; 
  isLoadingTag        : boolean = false;
  dataTag             : any = [];
  tableTag            : any = [];
//#########################################################
  ///////////////////////// COSTOS /////////////////////////
@ViewChild('pagCost' ) pagCost : MatPaginator;
displayedColumnCost  : string[] = ['TIPO','NOMBRE','COSTO','RUT','SUPPLIER','DATE_UPDATE','acc']; 
isLoadingCost        : boolean = false;
dataCost             : any = [];
tableCost            : any = [];
@ViewChild('pagCostOld' ) pagCostOld : MatPaginator;
displayedColumnCostOld  : string[] = ['TIPO','NOMBRE','COSTO','RUT','DATE_UPDATE','SUPPLIER']; 
isLoadingCostOld        : boolean = false;
dataCostOld             : any = [];
tableCostOld            : any = [];
//#########################################################

displayedColumnPrice  : string[] = ['ACTIVE_PRICE','SALES_PRICE','COST_PRICE','DATE_START','DATE_END','EMPLOYEE_CREATION','DATE_CREATION']; 
isLoadingPrice        : boolean = false;
dataPrice             : any = [];
tablePrice            : any = [];
pricepag              : number = 5;
priceSizeOptions      : any    = [ 5, 10,20,50];
//#########################################################
@ViewChild('pagExternal' ) pagExternal : MatPaginator;
displayedColumnExternal  : string[] = ['BRAND','LINK','DATE_CREATION','acc']; 
isLoadingExternal        : boolean = false;
dataExternal             : any = [];
tableExternal            : any = [];
externalpag              : number = 5;
externalSizeOptions      : any    = [ 5, 10,20,50];
//#########################################################
@ViewChild('pagReview' ) pagReview : MatPaginator;
displayedColumnReview  : string[] = ['REVIEW_RATING','EMAIL','NAME','DATE_REVIEW','REVIEW_SUBJECT','REVIEW_COMMENTS','acc']; 
isLoadingReview        : boolean = false;
dataReview             : any = [];
tableReview            : any = [];
reviewpag              : number = 5;
reviewSizeOptions      : any    = [ 5, 10,20,50];
//#########################################################
//#########################################################
  ///////////////////////// DESCUENTOS /////////////////////////
  @ViewChild('pagDiscount' ) pagDiscount : MatPaginator;
  displayedColumnDiscount  : string[] = ['NAME','SALESCHANEL','DATE_START_END','DISCOUNT','RULE','ORDER_CATERORY','PERIODOAPPLY','HOURAPPLY','ESTADO','EMPLOYEE','DATE','acc']; 
  isLoadingDiscount       : boolean = false;
  dataDiscount            : any = [];
  tableDiscount           : any = [];
  @ViewChild('pagDiscountOld' ) pagDiscountOld : MatPaginator;
  displayedColumnDiscountOld  : string[] = ['NAME','SALESCHANEL','DATE_START_END','DISCOUNT','RULE','ORDER_CATERORY','PERIODOAPPLY','HOURAPPLY','ESTADO','EMPLOYEE','DATE']; 
  isLoadingDiscountOld        : boolean = false;
  dataDiscountOld             : any = [];
  tableDiscountOld            : any = [];
  //#########################################################
  costos: any[] = [];
  costosAgregados: any[] = [];
  costosHistoricosAll: any[] = [];
  costosHistoricos: any[] = [];
  costsItems: any;
  //IC
  costosProducto : any = {};
  unitMeasurement: any ;
  unitMeasurements: any = [];
  ///////////////////////// PROPIEDADES /////////////////////////
  propiedadesForm: UntypedFormGroup;
  dimMedidas: any[] = [];

  ///////////////////////// BODEGAS /////////////////////////
  // dataSourceBodegas: any[] = [];
  // displayedColumnsBodegas: string[] = ['item', 'name', 'stock', 'actions'];
  warehouseSelected: any = {};
  warehouseJSON: any;
  warehouse: any;
  warehouse$: Observable<any>;
  storageTotal: any;
  //displayedColumnsBodegas: string[] = ['typeDoc','codMovimineto' ,'codBox', 'nameReasonCode','dte', 'dateUpdate','qty'];
  displayedColumnsBodegas: string[] = ['typeDoc','codMovimineto' , 'nameReasonCode','dte', 'dateUpdate','qty'];

  ///////////////////////// STOCK /////////////////////////
  stockForm: UntypedFormGroup;
  stocks: any[] = [];
  stockSelected: any = {};
  displayedColumnsStock: string[] = ['codWarehouse', 'type', 'nameWarehouse', 'stockMin', 'stockOK', 'stockMax','alerta','actions'];

  /*
    {
      "ID_PS_WAREHOUSE_STORAGE_LEVEL": 7,
      "ID_PS_SERVICES": 1075,
      "UUID_WAREHOUSE": "32636202-3130-3443-2140-F6C81F9FEE7D",
      "MIN_LEVEL": 20,
      "OK_LEVEL": 20,
      "MAX_LEVEL": 100,
      "FLAG_ALERT": "Y",
      "DATE_UPDATE": "03/03/2023 00:00:00",
      "COD_WAREHOUSE": "W69",
      "NAME_WAREHOUSE": "DESDE FRONT",
      "COD_WAREHOUSE_TYPE": "S",
      "NAME_WAREHOUSE_TYPE": "Tienda"
    }
  */

  // IC
  preciosHistoricos: any[] = [];
  lastIdPriceOld: number = 0;
  margenPrice = { amount:0,percent:0,txt:null,style:'',amountSalesRecomented:0,amountSalesRecomentedTax:0};
  chargeCode =[];
  chargeCodeSelect ={};
  chargeCodeSelectDetail = [];
    ////////////////////////////
    regularTax  : number =0;
    precioBruto : number =0;
    precioNetoNew : number =0;
    matTabPriceDetail : number =0;
  // costItemSelected: any;
/////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
  flexibleAttributesForm    : UntypedFormGroup;
  flexibleAttributes        : any[];
  flexibleAttributesValue   : any[]=[];
  flexibleAttributesToCreate: any[] = [];
  flexibleAttributesValidate: false;
  categoryFlexibleAttributes: any = [];
  flexibleAttributesValuePS : any = [];
/////////////////////////////////////////////////////////////////////////////////////////
reviewRating    : any = 0;
reviewCount     : any = 0;
arrReviewRating : any = []
//
flagButtonEcommerce = false;
//
psServicesCombinationProperty         : any = []
psServicesCombinationPropertyValueAll : any = []

psServicesCombinationPropertyFilter_A : any = []
psServicesCombinationPropertyFilter_B : any = []

psServicesCombinationPropertyType_A : any =''
psServicesCombinationPropertyType_B : any =''

psServicesCombinationPropertyValueFilter_A : any = []
psServicesCombinationPropertyValueFilter_B : any = []

psServicesCombinationPropertyValueColor_A : any = ''
psServicesCombinationPropertyValueColor_B : any = ''
/////////////////////////////////////////////////////////////////////////////////////////

  ///////////////////////// ETC /////////////////////////

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  saleschannels: any[] = [];

  typeBarcode: any[] = []
  filteredOptions: Observable<any[]>;
  showButtonUpload : boolean = false
//////////////////////////////////////////////////////////////////////////////////////
bcWidth   ="2"
bcHeight ="60"
arrExternal : any =[
    {val:true,gls:'Producto Externo'}
   ,{val:false,gls:'Producto Interno'}
]
flagExternal : boolean = false;
flagExternalDisable : boolean = false;
//
typesGlobal = []
//
uuidBrandsDefault :any = {}
flaguuidBrandsDefault :boolean = false;
//
dataTypeMeasurement  : any =''
//////////////////////////////////////////////////////////////////////////////////////
//############################################################
codeByKeyboard: any = '';
isCodeByMachine: boolean = false;
focusInputCode: boolean = false;
flagRedLight:Boolean = false;
//############################################################
setExternal(v){
  console.log(v)
  this.flagExternal = v
  if(this.flagExternal == true){

    console.log(this.types)
    this.infoForm.get('nameInvoice').setValue('N/A');
    this.infoForm.get('descInvoice').setValue('N/A');
    this.infoForm.get('idUnitMeasurement').setValue(0);
    this.infoForm.get('idUnitMeasurement').setValue(0);
    this.infoForm.get('charCode').setValue('DUMMY');
    this.types = this.types.filter(i => i.COD_PS_TYPE == 'LINK')
  }
  else{
    this.infoForm.get('nameInvoice').setValue('');
    this.infoForm.get('descInvoice').setValue('');
    this.infoForm.get('idUnitMeasurement').setValue(null);
    this.infoForm.get('charCode').setValue(null);
    this.types = JSON.parse(JSON.stringify(this.typesGlobal))
  }
}
setFlagExterno(){
  this.flagExternalDisable = true
}
//////////////////////////////////////////////////////////////////////////////////////
  constructor(
    private _catalogService: CatalogService,
    private _fb: FormBuilder,
    private _toast: ToastrService,
    private _fuseConfirmationService: FuseConfirmationService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _docsService: DocsService,
    private _warehouseService: WarehouseService,
    private _dialog: MatDialog,
    private ref: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<DetailComponent>, 
    private _currencyPipe:CurrencyPipe,
    private _datePipe : DatePipe,
  ) {

    this.product = {};
    this.infoForm = this._fb.group({
      name: ['', [Validators.required, Validators.maxLength(70)]],
      category: [null, [Validators.required]],
      group: [null, [Validators.required]],
      type: [null, [Validators.required]],
      SKU:   [null,[Validators.required]],
      barcode: [{ value: '', disabled: true }],
      price: [0, [Validators.required]],
      visible: [false, [Validators.required]],
      typeCod: [null,[Validators.required]],
      description: [null, [Validators.required, Validators.maxLength(5000)]],
      fechaInicio: [new Date()],
      fechaFin: [new Date(9999, 11, 31)],
      uuidBrands: [null,[Validators.required]],
      salesChannel: [Array],
      currentImg: [0],
      charCode:   [null,[Validators.required]],
      nameInvoice: ['', [Validators.maxLength(40), Validators.required]],
      descInvoice: ['', Validators.maxLength(80)],
      idUnitMeasurement :  [null,[Validators.required]],
      flagEntryPsServices: [false],
      flagOutStock:        [false],
      flagDueDate:         [false],
      flagExternal:        [false, Validators.required],
      //
      uuidPsCombinationProperty_a:        [null],
      uuidPsCombinationProperty_b:        [null],      
      uuidPsCombinationPropertyValue_a:   [null],
      uuidPsCombinationPropertyValue_b:   [null],
      psServicesDescriptionShort:         ['', Validators.maxLength(100)]
    });


    this.preciosForm = this._fb.group({
      codRetenidoTax: [0],
      activeRetenidoTax: [false],
      codAnticipadoTax: [0],
      activeAnticipadoTax: [false,],
      codTypeService: ['', ],
      codChargeCode: [''],
      psPrice: [null, [Validators.required]],   
      psPriceRound : [null, [Validators.required]],   
      activeDteTax: [true],
      cost: [{value: 0, disabled: false}, [Validators.required]],
      overwrite: [false],
      flagConsignado: [false],
      flagOutStock: [false],     
      
      uuidCrmCustomerConsignado   : ['',[Validators.required]],
      rutCustomerConsignado       : ['',[Validators.required]],
      nameCustoreConsignado       : ['',[Validators.required]],

      psPriceTAX: [null, [Validators.required]],
    });

   
    this.propiedadesForm = this._fb.group({
      color: 0,
      //
      productLength: [0],
      productWidth: [0],
      productHeight: [0],
      productWeight: [0],
      //
      height: "",
      width: "",
      length: "",
      weight: "",
      text: ""
    });

    this.resumeForm = this._fb.group({
      name: [''],
      categoria: [''],
      tipo: [''],
      grupo: [''],
      marca: [''],
      SKU: [''],
      unitMeasurement: [0],      
      codigoInt: [''],
      charCode: [''],
      psPrice: [''],
      psPriceRound :[''],
      cost: [''],
      psPriceTAX: [''],
      sobrescribible: [''],
      afecto: [''],
      codRetenidoTax: [''],
      codAnticipadoTax: [''],
      //
      entryPsServices: [''],
      outStock: [''],
      dueDate: [''],
      external: [''],      
    });
  }
  //////////////////////////////////////////////
  // FORMULARIO DE ATRIBUTOS FLEXIBLES


  //////////////////////////////////////////////////////////////////////
  ///////////////////////// CICLO DE VIDA /////////////////////////////
  /////////////////////////////////////////////////////////////////////

  async ngOnInit(): Promise<void> {
    //let uuidPsServices: any = window.location.pathname;
    //uuidPsServices = uuidPsServices.split('catalogos')[1].split('/')[1];

    this.uuidPsServices = this.data.uuidPsServices
    // (this.codProd);
    if(this.uuidPsServices == 'add'){
      this.loadIfIsNotProduct();
      this.infoForm.get('group').disable()
    }else{
      this.isProduct = true;
      this.loadIfIsProduct();
       //  this.infoForm.get('SKU').setValidators( [Validators.required]);
       //  this.preciosForm.get('codChargeCode').setValidators( [Validators.required]);
        // this.preciosForm.get('psPrice').setValidators( [Validators.required]);
      // this.filterChargeCode();
    }
    //////////////////////////////////////////////
    let tmp2 = (JSON.parse(localStorage.getItem('emisorData')))
    this.regularTax = tmp2.emisorData[0].IVA;
    //////////////////////////////////////////////
  };


  async ngAfterViewInit(): Promise<void> {
    // if(this.allDataLoaded){
    //   await this.makeTableCostosActuales(this.codProd);
    // }
  };

  //////////////////////////////////////////////////////////////////////
  ///////////////////// LLAMADAS A LA API //////////////////////////////
  /////////////////////////////////////////////////////////////////////
  
  async getAllTypes(): Promise<any> {
    const $this = this;
    return await new Promise(async (resolve, reject) => {
      try {
        const resp = await $this._catalogService.getTypes();
        resolve({status: true, data: resp.data});
      } catch (err) {
        reject({status: false, error: err});
      }
    });
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  async getUnitMeasurement(): Promise<any> {
    const $this = this;
    return await new Promise(async (resolve, reject) => {
      try {
        const resp = await $this._catalogService.getUnitMeasurement();
        resolve({status: true, data: resp.data});
      } catch (err) {
        reject({status: false, error: err});
      }
    });
  }
  
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  async getAllCategories(): Promise<any>{
    const $this = this;
    return await new Promise(async (resolve, reject) => {
      try {
        const resp = await $this._catalogService.getCategorias();
        resolve({status: true, data: resp.data});
      } catch (err) {
        reject({status: false, error: err});
      }
    });
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  async getPropiedadesProducto(): Promise<any>{
    const $this = this;
    return await new Promise(async (resolve, reject) => {
      try {
        const { codigos } = await $this._docsService.getDataDteMake();
        const dimMedidas = codigos.filter((item: any) => item.codAgpcn === "03");
        resolve({status: true, data: dimMedidas});
      } catch (err) {
        reject({status: false, error: err});
      }
    });
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  async getAllGroups(): Promise<any>{
    const $this = this;
    return await new Promise(async (resolve, reject) => {
      try {
        const resp = await $this._catalogService.getFamilys();
        resolve({status: true, data: resp.data});
      } catch (err) {
        reject({status: false, error: err});
      }
    });
  };

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  async getSalesChannels(): Promise<any>{
    const $this = this;
    let saleschannels: any = [];
    return await new Promise(async (resolve, reject) => {
      try {
        const resp = await $this._catalogService.getAllSalesChannels();
        await resp.data.forEach((item: any) => {
          // // (item);
          let isChecked = false;
          if($this.isProduct){
            // ('Esta intendando entrar al producto');
            // //($this.product);
            $this.product.salesChannel.forEach((sc: any) => {
              if(item.ID_EPC_SALES_CHANNEL === sc.ID_EPC_SALES_CHANNEL){
                // //('entro');
                isChecked = true;
              }
            });
          }
          saleschannels.push({
            ...item,
            checked: isChecked,
          });
        });
        this.selectedSalesChannel = saleschannels.filter((item: any) => item.checked);
        resolve({status: true, data: saleschannels});
      } catch (err) {
        reject({status: false, error: err});
      }
    });
  };

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/*
  async getCharCodes(): Promise<any>{
    const $this = this;
    return await new Promise(async (resolve, reject) => {
      try {
        const resp = await $this._catalogService.getAllChargeCodes();
        // //(resp);
        if(this.isProduct){
          $this.chargeCodeSelectDetail = resp.filter(item => item.COD_CHARGE_CODE === $this.product.data.COD_CHARGE_CODE);

        if($this.product.data.COD_CHARGE_CODE == 'DUMMY'){
          resolve({status: true, data: []});
        }else{
            if($this.chargeCodeSelectDetail[0]?.ATTRIBUTE_CHARGE_CODE?.ps == undefined ){
              $this._toast.error('Cuentas contables vacias', 'Error');
              reject({status: false, error: "ChargeCode sin cuentas contable!!"}); 
            }
            else{
                $this.cuentasContablesArray = Object.values(this.chargeCodeSelectDetail[0]?.ATTRIBUTE_CHARGE_CODE?.ps);
                resolve({status: true, data: resp});
            }
             
        }
       
        }
        
      } catch (err) {
        console.log(err)
        reject({status: false, error: err});
      }
    });
  };*/
  
  async getCharCodes(): Promise<any>{
    const $this = this;
    return await new Promise(async (resolve, reject) => {
      try {
        const resp = await $this._catalogService.getAllChargeCodes();
        // //(resp);
        if(this.isProduct){
          //############################################
          $this.chargeCodeSelectDetail = resp.filter(item => item.COD_CHARGE_CODE === $this.product.data.COD_CHARGE_CODE);

          if($this.chargeCodeSelectDetail[0]?.ATTRIBUTE_CHARGE_CODE?.ps == undefined){
            if($this.product.data.COD_CHARGE_CODE == 'DUMMY'){
              resolve({status: true, data: []});
            }else{
                  $this._toast.error('Cuentas contables vacias', 'Error');
                  reject({status: false, error: "ChargeCode sin cuentas contable!!"}); 
            }

          }
          else{
              $this.cuentasContablesArray = Object.values(this.chargeCodeSelectDetail[0]?.ATTRIBUTE_CHARGE_CODE?.ps);
          }
          //############################################
        }
        // //(resp)
        resolve({status: true, data: resp});
      } catch (err) {
        console.log(err)
        reject({status: false, error: err});
      }
    });
  };
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
async getBrands(): Promise<any>{
  const $this = this;
  return await new Promise(async (resolve, reject) => {
    try {
      const resp = await $this._catalogService.getBrands();
      resolve({status: true, data: resp.data});
    } catch (err) {
      reject({status: false, error: err});
    }
  });
}
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
async getPsServicesTypeBarcode(): Promise<any>{
  const $this = this;
  return await new Promise(async (resolve, reject) => {
    try {
      const resp = await $this._catalogService.getPsServicesTypeBarcode();
      resolve({status: true, data: resp.typeBarcode});
    } catch (err) {
      reject({status: false, error: err});
    }
  });
}
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  async  getCostProduct(){
    const $this = this;
    return await new Promise(
    async function (resolve, reject) {
        try{
          const res = await $this._catalogService.getCostsByProduct($this.uuidPsServices);
          // //(res)
          resolve(res)       
        }
        catch(e){
            reject({status:false,data:null})
        }
    }); 
  };

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  async getAllCostItems(): Promise<any>{
    const $this = this;
    return await new Promise( async (resolve, reject) => {
      try {
        const { data } = await this._catalogService.getCostItemsByProduct($this.uuidPsServices);
        resolve({status:true, data: data});
      } catch (err) {
        //(err);
        reject({status: false, data: null})
      }
    });
    // const {data, success} = await this._catalogService.getCostItemsByProduct(this.codProd);
    // if(success){
    //   this.costsItems = data;
    //   // this.makeTableCostosActuales();
    //   // //(this.costsItems);
    // }
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  async getPsServicesDiscount(): Promise<any>{
    const $this = this;
    return await new Promise( async (resolve, reject) => {
      try {
        const { discount,discountOld } = await this._catalogService.getPsServicesDiscount($this.uuidPsServices);
        resolve({status:true, discount:discount,discountOld:discountOld});
      } catch (err) {
        //(err);
        reject({status: false, data: null})
      }
    });
  }
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  async getTaxes(): Promise<any>{
    const $this = this;
    return await new Promise( async (resolve, reject) => {
      try {
        const resp = await $this._catalogService.getAllTaxes();
        const impuestoRetenido = resp.data.filter((item: any) => item.TIPO === 'R').map((item: any) => {
            return {
              viewValue: `${item.GLOSA} (${item.TASA}%)`,
              value: item.COD_TAX,
            };
          });

        const impuestoEspecial = resp.data.filter((item: any) => item.TIPO === 'A').map((item: any) => {
            return {
              viewValue: `${item.GLOSA} (${item.TASA}%)`,
              value: item.COD_TAX,
              };
          });

        resolve({status: true, data: {
          impuestoRetenido,
          impuestoEspecial
        }});
      } catch (err) {
        console.log(err);
        reject({status: false, data: null});
      }
    });
  }
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
async getPsServicesCombinationProperty(): Promise<any>{
  const $this = this;
  return await new Promise(async (resolve, reject) => {
    try {
      let tmp = await this._catalogService.getPsServicesCombinationProperty();
      resolve({status: true, data: tmp.psCombinationProperty});
    } catch (err) {
      reject({status: false, error: err});
    }
  });
}
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
async getPsServicesCombinationPropertyValueAll(): Promise<any>{
  const $this = this;
  return await new Promise(async (resolve, reject) => {
    try {
      let tmp = await this._catalogService.getPsServicesCombinationPropertyValueAll();
      resolve({status: true, data: tmp.psCombinationPropertyValue});
    } catch (err) {
      reject({status: false, error: err});
    }
  });
}
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //////////////////////////////////////////////////////////////////////
  ///////////////////////// FUNCiONES GLOBALES /////////////////////////
  //////////////////////////////////////////////////////////////////////
  async loadIfIsProduct(){
    const task1 = [];
    
    await this.getProductByCode(this.uuidPsServices);
    this.warehouse$ = of(this.warehouseJSON.storageTotal);
    if(this.product.img.length > 0){
      this.imgSrc = `${this.appImgURL}${this.appTokenImg}/${this.imgs[0].UUID_IMG}${this.appImgSizeA}`;
    }

    task1.push(this.getSalesChannels());          // 0
    task1.push(this.getCharCodes());              // 1
    task1.push(this.getPropiedadesProducto());    // 2
    task1.push(this.getCostProduct())             // 3
    task1.push(this.getAllCostItems());           // 4
    task1.push(this.getAllCategories());          // 5
    task1.push(this.getAllGroups());              // 6
    task1.push(this.getAllTypes());               // 7
    task1.push(this.getTaxes());                  // 8
    task1.push(this.getUnitMeasurement());        // 9
    task1.push(this.getBrands());                 // 10
    //
    task1.push(this.getPsServicesCombinationProperty());                 // 11
    task1.push(this.getPsServicesCombinationPropertyValueAll());                 // 12
    task1.push(this.getPsServicesTypeBarcode());  //13
    task1.push(this.getPsServicesDiscount());     //14
    Promise.all(task1).then(async (result) => {
      var tmp;
      this.saleschannels = result[0].data;
      this.chargeCode = result[1].data;
      this.dimMedidas = result[2].data;
      this.costsItems = result[4].data
      this.categories = result[5].data;
      this.groups = result[6].data;
      this.types = result[7].data;
      this.impuestosEspeciales = result[8].data.impuestoEspecial;
      this.impuestosRetenidos = result[8].data.impuestoRetenido;
     
      this.costosProducto = result[3]
      this.unitMeasurements = result[9].data

      this.setGroup(this.product.data.ID_PS_CATEGORY)
      if(this.product?.data?.REMOVE == 'Y') this.infoForm.get('group').disable();
      this.brands= result[10].data;
      //
      this.psServicesCombinationProperty         = result[11].data
      this.psServicesCombinationPropertyValueAll = result[12].data
      ///////////////////////////////////////////////////
      this.typeBarcode=result[13].data
      this.formatBarcode =  this.typeBarcode.filter(i => i.COD_TYPE_BARCODE == this.product.data.COD_TYPE_BARCODE)[0].FORMAT_TYPE_BARCODE;
      ///////////////////////////////////////////////////
      this.dataDiscount    =result[14].discount
      this.dataDiscountOld =result[14].discountOld
      ///////////////////////////////////////////////////
      this.psServicesCombinationPropertyFilter_A = this.psServicesCombinationProperty;
      ///////////////////////////////////////////////////
      tmp = await this.psServicesCombinationPropertyFilter_A.filter(i => i.UUID_COMBINATION_PROPERTY == this.product.data.UUID_COMBINATION_PROPERTY_A)
      if(tmp.length> 0){
        await this.setPsAttribGroup('A',tmp[0])
        tmp = await this.psServicesCombinationPropertyValueFilter_A.filter(i => i.UUID_PS_COMBINATION_PROPERTY_VALUE == this.product.data.UUID_PS_COMBINATION_PROPERTY_VALUE_A)[0]
        await this.setPsAttrib('A',tmp)
      }
      tmp = await this.psServicesCombinationPropertyFilter_B.filter(i => i.UUID_COMBINATION_PROPERTY == this.product.data.UUID_COMBINATION_PROPERTY_B)
      if(tmp.length> 0){
        await this.setPsAttribGroup('B',tmp[0])
        tmp = await this.psServicesCombinationPropertyValueFilter_B.filter(i => i.UUID_PS_COMBINATION_PROPERTY_VALUE == this.product.data.UUID_PS_COMBINATION_PROPERTY_VALUE_B)[0]
        await this.setPsAttrib('B',tmp) 
      }
    ///////////////////////////////////////////////////
    this.typesGlobal = JSON.parse(JSON.stringify(this.types))
    ///////////////////////////////////////////////////
    tmp = await this.groups.filter(item => item.ID_PS_GROUP == this.product.data.ID_PS_GROUP)[0]
    this.percentProfitsMargin = tmp.PERCENT_PROFITS_MARGIN
    ///////////////////////////////////////////////////  
    tmp = this.saleschannels.filter(i => i.COD_SALES_CHANNEL == 'ECOMMCERCE' &&  i.checked == true)
    if(tmp.length > 0) this.flagButtonEcommerce = true; else this.flagButtonEcommerce = false;
    /////////////////////////////////////////////////     
      await this.loadFormPrecios();
      await this.makeTableCostosActuales(this.costosProducto);
    ///////////////////////////////////////////////////        
      this.allDataLoaded = true;
      await this.loadPrice(this.product.price)
      await this.loadTag()
      await this.loadCost(this.dataCost)
      await this.loadCostOld(this.dataCostOld)
      //
      await this.loadDiscount(this.dataDiscount)
     // await this.loadDiscountOld(this.dataDiscountOld)
    //////////////////////////////////////////////////
    if(this.product.data.FLAG_EXTERNAL == 'Y'){
      //this.types = this.types.filter(i => i.COD_PS_TYPE == 'LINK')
      this.infoForm.get('type').disable();

    }
    //////////////////////////////////////////////////
    this.dataTypeMeasurement = this.product.data.DATA_TYPE

    //this.types = this.types.filter(i => i.COD_PS_TYPE == 'LINK')
    //console.log(this.typesGlobal)
    //console.log(this.product.data.ID_PS_TYPE)
    //console.log(this.product.data.FLAG_EXTERNAL)
    //////////////////////////////////////////////////
    }, (err) => { console.log(err); });

  };
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
setGroup(idPsCategory){
  var chk
    this.groupsSelected =  this.groups.filter(item => item.ID_PS_CATEGORY == idPsCategory)
    if( this.groupsSelected.length == 0){
      this.infoForm.get('group').setValue(null);
      this.infoForm.get('group').disable();
    }
    else{
     //this.infoForm.get('group').setValue(null);
      this.infoForm.get('group').enable();
      chk = this.groupsSelected.filter(item => item.ID_PS_GROUP == this.infoForm.value.group)
      if(chk.length > 0){}
      else{
        this.infoForm.get('group').setValue(null);
      }
    }
  //this.infoForm.get('category').disable();
  
  }
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  async loadIfIsNotProduct(){
    const task1 = [];
    var tmp
    task1.push(this.getSalesChannels());          // 0
    task1.push(this.getCharCodes());              // 1
  
    task1.push(this.getAllCategories());          // 2
    task1.push(this.getAllGroups());              // 3
    task1.push(this.getAllTypes());               // 4
    task1.push(this.getTaxes());                  // 5
    task1.push(this.getUnitMeasurement());        // 6
    task1.push(this.getBrands());                 // 7
    task1.push(this.getPsServicesTypeBarcode());  // 8
    Promise.all(task1).then((result) => {
      //(result);
      this.saleschannels = result[0].data;
      this.chargeCode = result[1].data;
     
      this.categories = result[2].data;
      this.groups = result[3].data;
      this.types = result[4].data;
      this.typesGlobal = JSON.parse(JSON.stringify(this.types))
      this.impuestosEspeciales = result[5].data.impuestoEspecial;
      this.impuestosRetenidos = result[5].data.impuestoRetenido;
      this.unitMeasurements = result[6].data
      this.brands= result[7].data;
      this.typeBarcode=result[8].data
      ////
      this.uuidBrandsDefault = this.brands.filter(i => i.DEFAULT_PS_BRANDS == 'Y')[0]
    ///////////////////////////////////////////////////
    //console.log(this.groups)
    //let tmp = this.groups.filter(item => item.ID_PS_GROUP == this.product.data.ID_PS_GROUP)[0]
    //this.percentProfitsMargin = tmp.PERCENT_PROFITS_MARGIN
    //console.log(this.percentProfitsMargin)
    this.preciosForm.get('uuidCrmCustomerConsignado').setValue('*');
    this.preciosForm.get('rutCustomerConsignado').setValue('00000000-0');
    this.preciosForm.get('nameCustoreConsignado').setValue('dummy');    
    tmp = this.unitMeasurements.filter(i => i.DEFAULT_UNIT_MEASUREMENT == 'Y')[0]
    this.infoForm.get('idUnitMeasurement').setValue(tmp.ID_UNIT_MEASUREMENT); 
    ///////////////////////////////////////////////////  

    //////////////////////////////////////////////////
      this.allDataLoaded = true;
    }, (err) => { console.log(err); });
  }
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//###########################################################
setDefaultBrand(type){
  if(type.COD_PS_TYPE == 'LINK'){
    this.infoForm.get('uuidBrands').setValue(this.uuidBrandsDefault.UUID_PS_BRANDS     );
    this.flaguuidBrandsDefault = true
    }else{
      this.infoForm.get('uuidBrands').setValue(null    );
    this.flaguuidBrandsDefault = false
    }
}
//###########################################################
//Carga Atributos Flexibles
addToFormControl() {
var valueFormPS : any =[]
    this.categoryFlexibleAttributes.forEach((fa) => {
      fa.LIST_VALUES_PARAM = JSON.parse(fa.LIST_VALUES_PARAM);
    });
    this.flexibleAttributesForm = this._fb.group({});
    this.categoryFlexibleAttributes.forEach((fa) => {
      this.flexibleAttributesForm.addControl(fa.NAME_PARAM_FORM, new FormControl(''));
      if(fa.FLAG_MANDATORY_PARAM === 'Y'){
         this.flexibleAttributesForm.get(fa.NAME_PARAM_FORM).setValidators([Validators.required]);
      }        
      //completar valor
      valueFormPS =this.flexibleAttributesValuePS.filter(i => i.ID_PS_CATEGORY_FLEXIBLE_ATTRIBUTES == fa.ID_PS_CATEGORY_FLEXIBLE_ATTRIBUTES)
      if(valueFormPS.length > 0){
        this.flexibleAttributesForm.get(fa.NAME_PARAM_FORM).setValue(valueFormPS[0].VALUE_FLEXIBLE_ATTRIBUTES);
      }
    });
  }
  //transforma Atributos Flexibles
  async makeFlexibleForm(form,master): Promise<void>{
    const $this = this;
    var flex : any = [];
      return await new Promise (async function(resolve, reject) {
        try {
          //##################################
           for(let i =0; i< master.length; i++){
              //
              if(form.value[master[i].NAME_PARAM_FORM]){
              flex.push(
                          { 
                             idPsCategoryFlexibleAttributes  : master[i].ID_PS_CATEGORY_FLEXIBLE_ATTRIBUTES
                            ,valueFlexibleAttributes         : form.value[master[i].NAME_PARAM_FORM].toString()
                            ,acc                             : 'ADD'
                          }
                      );
              }
              else{
                flex.push(
                  { 
                     idPsCategoryFlexibleAttributes  : master[i].ID_PS_CATEGORY_FLEXIBLE_ATTRIBUTES
                    ,valueFlexibleAttributes         : null
                    ,acc                             : 'DEL'
                  }
              );                
              }
             //
           }
           resolve(flex)
          //##################################
        } catch (err) {
          console.log(err);
          reject(err);
        }
      });
    }
//###########################################################
//###########################################################
removeSpecialCharacters(event) {
  let newVal = event.target.value.replace('[^A-Za-z,]', '');
  return newVal;
}
//###########################################################
//###########################################################
  async getProductByCode(uuidPsServices: string): Promise<any>{
    this.product = await this._catalogService.getProductByCode(uuidPsServices);  

    //////////////////////////////////////////////////
    if(this.product.data.FLAG_EXTERNAL == 'Y'){
        await this.loadExternal(this.product.external)
    }
    //////////////////////////////////////////////////
    await this.loadReview(this.product.review)
    //////////////////////////////////////////////////    
    this.dateCreation     = this.product.data.DATE_CREATE
    this.dateUpdate       = this.product.data.DATE_UPDATE
    this.employeeCreation = this.product.data.EMPLOYEE_CREATION
    this.employeeUpdate   = this.product.data.EMPLOYEE_UPDATE

    /////////////////////////////////////////////////
    this.reviewRating   = this.product.data.REVIEW_RATING
    this.reviewCount    = this.product.data.REVIEW_COUNT
    this.arrReviewRating = [];
    for(let i=1; i<=5;i++){
      if(i <= this.reviewRating)
        this.arrReviewRating.push({color:'Y', tag:'&#9733;'});
      else
        this.arrReviewRating.push({color:'N', tag:'&#9733;'})
    }

    /////////////////////////////////////////////////
    this.dataTag          = this.product.eslTag
    this.loadTag()
    /////////////////////////////////////////////////
    //Carga Atributos Flexibles
    this.categoryFlexibleAttributes   = this.product.categoryFlexibleAttributes 
    this.flexibleAttributesValuePS    = this.product.flexibleAttributesValue 
    this.addToFormControl()
    /////////////////////////////////////////////////    
    this.barcode = this.product.data.BARCODE;

    this.warehouseJSON = {
      storageDetail: this.product.storageDetail,
      storageLevel: this.product.storageLevel,
      storageTotal: this.product.storageTotal,
      warehouse: this.product.warehouse,
      storageMovements: this.product.storageMovements
    };
    ////////////////////////////////
    try{
        this.warehouseJSON.storageTotal.forEach((element: any) => {
          // console.log(element)
          element.DATA_BOX_STORAGE = JSON.parse(element.DATA_BOX_STORAGE);
        });
    }
    catch{
      this.warehouseJSON.storageTotal.DATA_BOX_STORAGE = [{"name":"Pasillo","value":"0","type":"string"},{"name":"Fila","value":"0","type":"number"},{"name":"Numero","value":"0","type":"string"}]
    }
    ///////////////////////////////
    // this.categorySelected = this.product.data.COD_PS_CATEGORY;
    // this.typeSelected = this.product.data.COD_PS_TYPE;
    // this.groupSelected = this.product.data.COD_PS_GROUP;
    let fechaInicio = this.product.data.DATE_START.split('-');
    let fechaFin = this.product.data.DATE_END.split('-');
    this.charCodeSelected = this.product.data.COD_CHARGE_CODE;
    this.imgs = this.product.img;

    ////////////////////////////////////////////////
    // Filtro por el precio activo
    var priceOK = this.product.price.filter(item => item.ACTIVE_PRICE == 'Y')
    ////////////////////////////////////////////////
    this.infoForm.patchValue({
      name: this.product.data.NAME_PS_SERVICES,
      barcode: this.product.data.BARCODE,
      SKU: this.product.data.COD_PS_SERVICES,
      uuidPsServices: this.product.data.UUID_PS_SERVICES,
      ////////////////////////////////////////////////
      price: priceOK[0].SALES_PRICE,
      ////////////////////////////////////////////////
      visible: this.product.data.VISIBLE == 'Y' ? true : false,
      category: this.product.data.ID_PS_CATEGORY,
      group: this.product.data.ID_PS_GROUP,
      charCode : this.product.data.COD_CHARGE_CODE,
      type: this.product.data.ID_PS_TYPE,
      typeCod: this.product.data.COD_TYPE_BARCODE,
      fechaInicio: new Date(fechaInicio[0], fechaInicio[1] - 1, fechaInicio[2]),
      fechaFin: new Date(fechaFin[0], fechaFin[1] - 1, fechaFin[2]),
      salesChannel: this.product.salesChannel.map((x: any) => x.ID_EPC_SALES_CHANNEL),
      description:  Buffer.from(this.product.data.PS_SERVICES_DESCRIPTION, 'base64').toString(),
      uuidBrands: this.product.data.UUID_PS_BRANDS,
      nameInvoice: this.product.data.NAME_PS_SERVICES_INVOICE,
      descInvoice: this.product.data.PS_SERVICES_DESCRIPTION_INVOICE,
      //
      idUnitMeasurement: this.product.data.ID_UNIT_MEASUREMENT,
      flagEntryPsServices: this.product.data.FLAG_ENTRY_PS_SERVICES  === 'Y' ? true : false,
      flagOutStock:        this.product.data.FLAG_OUT_STOCK  === 'Y' ? true : false,
      flagDueDate:         this.product.data.FLAG_DUE_DATE  === 'Y' ? true : false,
      flagExternal:        this.product.data.FLAG_EXTERNAL  === 'Y' ? true : false,    
      //
      uuidPsCombinationPropertyValue_a:   this.product.data.UUID_PS_COMBINATION_PROPERTY_VALUE_A,
      uuidPsCombinationPropertyValue_b:   this.product.data.UUID_PS_COMBINATION_PROPERTY_VALUE_B,
      psServicesDescriptionShort:         this.product.data.PS_SERVICES_DESCRIPTION_SHORT,
      uuidPsCombinationProperty_a:        this.product.data.UUID_COMBINATION_PROPERTY_A,
      uuidPsCombinationProperty_b:        this.product.data.UUID_COMBINATION_PROPERTY_B,
    }); 
    ///////////////////////////////////////////////////
    this.countCharacter('nameInvoice')
    this.countCharacter('descInvoice')
    this.countCharacter('psServicesDescriptionShort')
    this.preciosForm.get('cost').disable();
    ///////////////////////////////////////////////////    
    this.countOrders        = this.product.data.COUNT_ORDERS
    this.countStock         = this.product.data.COUNT_STOCK
    this.countOrdersPending = this.product.data.COUNT_ORDERS_PENDING
    if(this.countOrders == 0 && this.countStock == 0 ){
      this.flagEditProperties = true
    }
    else{
      this.flagEditProperties = false
      //this.infoForm.get('category').disable();
      //this.infoForm.get('group').disable();
      this.infoForm.get('type').disable();
      this.infoForm.get('SKU').disable();
      this.infoForm.get('charCode').disable();
      this.infoForm.get('typeCod').disable();

    

    }
    ///////////////////////////////////////////////////
    ///////////////////////////////////////////////////
    //console.log(this.product.data.FLAG_OVERWRITE)

    this.preciosForm.patchValue({
      activeRetenidoTax           : this.product.data.FLAG_RETENIDO_TAX === 'Y' ? true : false,
      activeAnticipadoTax         : this.product.data.FLAG_ANTICIPADO_TAX === 'Y' ? true : false,
      codRetenidoTax              : this.product.data.COD_RETENIDO_TAX,
      codAnticipadoTax            : this.product.data.COD_ANTICIPADO_TAX,
      overwrite                   : this.product.data.FLAG_OVERWRITE  == 'Y' ? true : false,
      activeDteTax                : this.product.data.COD_DTE_TAX  === '0' ? true : false,
      flagConsignado              : this.product.data.FLAG_CONSIGNADO  === 'Y' ? true : false,
      //
      uuidCrmCustomerConsignado   : this.product.data.UUID_CRM_CUSTOMER_CONSIGNADO,
      rutCustomerConsignado       : this.product.data.RUT_CONSIGNADO,
      nameCustoreConsignado       : this.product.data.CUSTOMER_FANCY_NAME_CONSIGNADO,
    });





    this.propiedadesForm.patchValue({
      height: this.product.data.PS_SHIPPING_MEASURE?.height ?? '',
      width: this.product.data.PS_SHIPPING_MEASURE?.width ?? '',
      length: this.product.data.PS_SHIPPING_MEASURE?.length ?? '',
      weight: this.product.data.PS_SHIPPING_MEASURE?.weight ?? '',
      
      text: this.product.data.PS_ATTRIBUTES.text[0] ?? '',
      //
      productHeight: this.product.data.PS_ATTRIBUTES?.measures[0]?.height ?? 0,
      productWidth: this.product.data.PS_ATTRIBUTES?.measures[0]?.width ?? 0,
      productLength: this.product.data.PS_ATTRIBUTES?.measures[0]?.length ?? 0,
      productWeight: this.product.data.PS_ATTRIBUTES?.measures[0]?.weight ?? 0,
      // 
      color: this.product.data.PS_ATTRIBUTES?.color[0] ?? 0,
    });
    //####################################################
    //####################################################
    if(this.product?.data?.REMOVE == 'Y'){
      this.infoForm.disable();
      this.preciosForm.disable();
      this.propiedadesForm.disable();
    }
    //####################################################
    //####################################################
  };

//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
showCustomer(){
  const dialogRef = this._dialog.open(SelectClientesComponent, {
      
    height: '100%',
    width: '100%',
    data: {
       flagCustomer     : false
      ,flagSupplier     : true
      ,flagEmployee     : false
      ,flagAssociate    : false
      ,flagDistributor  : false

    }
});
dialogRef.afterClosed().subscribe(async (result) => {
    this.preciosForm.get('uuidCrmCustomerConsignado').setValue(result.UUID_CRM_CUSTOMER      );
    this.preciosForm.get('rutCustomerConsignado').setValue(result.RUT     );
    this.preciosForm.get('nameCustoreConsignado').setValue(result.CUSTOMER_FANCY_NAME     );

});
}
setCustomerDefault(e){
if(e.checked == false){
  this.preciosForm.get('uuidCrmCustomerConsignado').setValue('*');
  this.preciosForm.get('rutCustomerConsignado').setValue('00000000-0');
  this.preciosForm.get('nameCustoreConsignado').setValue('dummy');
}
if(e.checked == true){
  this.preciosForm.get('uuidCrmCustomerConsignado').setValue(null);
  this.preciosForm.get('rutCustomerConsignado').setValue(null);
  this.preciosForm.get('nameCustoreConsignado').setValue(null);
}
}

//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
setPrecioBruto(e,priceF){
  var p1,p2
  var price  :number= Number(priceF)
  p2 = Math.round( price * (1 + (this.regularTax/100))) 
  p1=p2/100 
  ////////////////
  let x = Math.floor(p1)*100
  let y = Math.round(p1) *100
  this.precioNetoNew = Math.round( y / (1 + (this.regularTax/100)))
  this.precioBruto =Math.round( this.precioNetoNew * (1 + (this.regularTax/100))) 
}
//
setPrecioNeto(e,priceF){
  var tmp,tmp2
  var price  :number= Number(priceF)
  //tmp = Math.round( price / (1 + (this.regularTax/100))) 
  tmp = ( price / (1 + (this.regularTax/100))).toFixed(5) 
  tmp2 = ( price / (1 + (this.regularTax/100))).toFixed(2) 
  this.preciosForm.get('psPrice').setValue(tmp);
  this.preciosForm.get('psPriceRound').setValue(tmp2);
  this.setPrecioBruto(e,tmp)

}
//
setFirstPriceSales(e,costo){
  var neto=0,bruto=0;
  var cost :number= Number(costo)


  if(this.percentProfitsMargin >= 1){
    //neto  =  Math.trunc( (cost * this.percentProfitsMargin)+cost)
    neto  =  Number(( (cost * this.percentProfitsMargin)+cost).toFixed(5))
  }else{
    //neto  = Math.trunc((cost)/(1-this.percentProfitsMargin)+1)
    neto  = Number(((cost)/(1-this.percentProfitsMargin)).toFixed(5) )
  }
  bruto = Math.round( neto  * (1 + (this.regularTax/100))) 
  this.minBruto =bruto

  this.preciosForm.get('psPriceTAX').setValue(bruto);
  this.preciosForm.get('psPrice').setValue(neto);
  this.preciosForm.get('psPriceRound').setValue(neto.toFixed(2));

}
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
async loadTag():Promise<any>{
  this.isLoadingTag=true; 
  this.tableTag =  new MatTableDataSource(this.dataTag);
  this.ref.detectChanges();
  this.tableTag.paginator = this.pagTag;
  this.isLoadingTag=false; 
  //this.tableTag.renderRows();
}  
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
async loadExternal(data):Promise<any>{
  this.isLoadingExternal=true; 
  this.tableExternal =  new MatTableDataSource(data);
  this.ref.detectChanges();
  this.tableExternal.paginator = this.pagExternal;
  this.isLoadingExternal=false; 
}  
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
async loadReview(data):Promise<any>{
  this.isLoadingReview=true; 
  this.tableReview =  new MatTableDataSource(data);
  this.ref.detectChanges();
  this.tableReview.paginator = this.pagReview;
  this.isLoadingReview=false; 
} 
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// checkDigitEAN13(barcode)
chkEAN13(barcode){
  try{
    var barcode12
  var dv 
  var barcodeOK
  barcode12 =  barcode.substring(0, 12)
  dv = this.checkDigitEAN13(barcode12)
  barcodeOK = barcode12 + dv
  if(barcodeOK == barcode)
  return true;
  else
  return false;
  
  }
  catch(e){
    return false;
  }
  
  }  
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  async createNewProduct(): Promise<any>{
    const date = new Date();
    const today = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    var glsVisible="";
    if(this.infoForm.value.visible == false) glsVisible=`<b>¡¡Atencion!!<br><b>Producto Creado como no visible</b></b>`; else glsVisible="";

    
    const confirmCreateDialog = this._fuseConfirmationService.open({
      title: 'Crear Producto',
      message: `¿Está seguro de crear el producto ${this.infoForm.value.name}?<br>${glsVisible}`,
      icon: {
        name: 'shopping_cart',
        color: 'success'
      },
      actions: {
        confirm: {
          label: 'Confirmar',
          color: 'primary'
        },
        cancel: {
          label: 'Seguir editando',
        }
      }
    });
 
    confirmCreateDialog.afterClosed().subscribe(async (result) => {
      // //(result); 
      if(result == 'confirmed'){
        this.infoForm.enable();
        //###############################
        let chk = this.chkEAN13(this.infoForm.value.barcode)
        if(chk == false) {
          this._toast.error('Codigo EAN13 Incorrecto:'+this.infoForm.value.barcode,'Error');
          return false;
        }
      //###############################   
        let tmpA = this.infoForm.value.name.replaceAll('"',"'")
        let tmpB = this.infoForm.value.nameInvoice.replaceAll('"',"'")   
        let tmpC = this.infoForm.value.descInvoice.replaceAll('"',"'")   
      //== 
        const data = {
          "codPsServices": this.infoForm.value.SKU,
          "uuidPsServices": this.infoForm.value.UUID_PS_SERVICES,
          "idPsGroup": this.infoForm.value.group,
          "idPsCategory": this.infoForm.value.category,
          "idPsType": this.infoForm.value.type,
          "codDteTax": this.preciosForm.value.activeDteTax ? "0":"1",
          "idCodType": 900,
          "flagBundle": "N",
          "namePsServices": tmpA,
          "psServicesDescription": await Buffer.from(this.infoForm.value.description).toString('base64') ,
          "orderPsService": 0,
          "dateStart": this.formatDate(this.infoForm.value.fechaInicio),
          "dateEnd": this.formatDate(this.infoForm.value.fechaFin),
          "flagRetenidoTax": this.preciosForm.value.activeRetenidoTax ? 'Y' : 'N',
          "codRetenidoTax": this.preciosForm.value.activeRetenidoTax ? this.preciosForm.value.codRetenidoTax : -99,
          "flagAnticipadoTax": this.preciosForm.value.activeAnticipadoTax ? 'Y' : 'N',
          "codAnticipadoTax": this.preciosForm.value.activeAnticipadoTax ? this.preciosForm.value.codAnticipadoTax : -99,
          "codTypeService": "0",
          // "codChargeCode": this.getCharCodeValue(this.charCodeSelected),
//REVUSAR DEBEN IR TODFOS""!!!!"          
          "codChargeCode": this.chargeCodeSelectDetail[0]?.COD_CHARGE_CODE || 'DUMMY',
          "enabledPsServices":'Y', 
          "codTypePsServices": this.infoForm.value.typeCod,//"EAN13",
          "flagOverwrite": this.preciosForm.value.overwrite ? 'Y' : 'N',
          "uuidBrands": this.infoForm.value.uuidBrands,
          "flagRoot": "Y",
          "visible": this.infoForm.value.visible ? 'Y' : 'N',
          "remove": "N",
          //////////////////////////////////////////////////////////
          //////////////////////////////////////////////////////////
          "psPrice": this.preciosForm.value.psPrice,
          "cost"   : this.preciosForm.value.cost,
          //////////////////////////////////////////////////////////
          //////////////////////////////////////////////////////////          
          "servicesSalesChannel": [],
     //     "flagDueDate"                   : "N",
          "psServicesDescriptionInvoice"  : tmpC || 'Sin Descripción',
          "namePsServicesInvoice"         : tmpB
          ,"psAttributes"                  :  {"color":[],"measures":[{"height":"0","width":"0","length":0,"weight":0}],"size":[],"text":[""]}

        // ,"flagEntryPsServices"           : "N"
          ,"flagShippingMeasure"           : "Y"
          ,"psShippingMeasure"             : {"measureSize":"Cm","measureWeight":"Kg","width":0,"height":0,"length":0,"weight":0}
         // ,"flagExternal"                  : "N",
          ,"barcode": this.infoForm.value.barcode
          //
          ,"flagConsignado"             : this.preciosForm.value.flagConsignado ? 'Y' : 'N'
          ,"uuidCrmCustomerConsignado"  : this.preciosForm.value.uuidCrmCustomerConsignado



          ,"idUnitMeasurement"  : this.infoForm.value.idUnitMeasurement
          ,"flagEntryPsServices": this.infoForm.value.flagEntryPsServices ? 'Y' : 'N'
          ,"flagOutStock"       : this.infoForm.value.flagOutStock ? 'Y' : 'N'
          ,"flagDueDate"        : this.infoForm.value.flagDueDate ? 'Y' : 'N'
          ,"flagExternal"       : this.infoForm.value.flagExternal ? 'Y' : 'N'
          //
          ,"psServicesDescriptionShort":         this.infoForm.value.psServicesDescriptionShort
          //     
          ,"parentUUID"  :'0'    
        };
        if(this.flagExternal == false){
            this.selectedSalesChannel.forEach((item: any) => {
              // //(item)
              data.servicesSalesChannel.push(item.ID_EPC_SALES_CHANNEL);
            });
        }else{
          
          data.servicesSalesChannel.push( this.saleschannels.filter(i => i.COD_SALES_CHANNEL == 'ECOMMCERCE')[0].ID_EPC_SALES_CHANNEL)
          data.idUnitMeasurement = 1
          data.psPrice = 999999
          data.cost    = 999999

        }


   const x = await this._catalogService.updateCreateDeleteProductos(data, true);
   if(!!x){
     this._toast.success('Producto creado correctamente');
     //this._router.navigate(['/catalogos']);
     this.closeBtn()
   }
        // //(x);
      }
    });
    
  }
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
   formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('/');
}
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  async updateProduct(): Promise<any>{
  //== 
    let tmpA = this.infoForm.value.name.replaceAll('"',"'")
    let tmpB = this.infoForm.value.nameInvoice.replaceAll('"',"'")   
    let tmpC = this.infoForm.value.descInvoice.replaceAll('"',"'")   
  //== 
  let arrFlex = await this.makeFlexibleForm(this.flexibleAttributesForm,this.categoryFlexibleAttributes)
   this.infoForm.get('typeCod').enable();
   // this.infoForm.get('typeCod').disable();
  //==
    const data = {
      "codPsServices": this.infoForm.get('SKU').value,
      "uuidPsServices":this.uuidPsServices,
      "idPsGroup": this.infoForm.get('group').value,
      "idPsCategory": this.infoForm.get('category').value,
      "idPsType": this.infoForm.get('type').value,
      "idCodType":900,
      "codTypePsServices": this.infoForm.value.typeCod,
      "codChargeCode": this.chargeCodeSelectDetail[0].COD_CHARGE_CODE,
      "namePsServices": tmpA,
      "psServicesDescription": await Buffer.from(this.infoForm.value.description ).toString('base64') ,
      "enabledPsServices": this.infoForm.get('visible').value ? 'Y' : 'N',
      "visible": this.infoForm.value.visible ? 'Y' : 'N',
      "flagRetenidoTax": this.preciosForm.value.activeRetenidoTax ? 'Y' : 'N',
      "codRetenidoTax": this.preciosForm.value.activeRetenidoTax ? this.preciosForm.value.codRetenidoTax : -99,
      "flagAnticipadoTax": this.preciosForm.value.activeAnticipadoTax ? 'Y' : 'N',
      "codAnticipadoTax": this.preciosForm.value.activeAnticipadoTax ? this.preciosForm.value.codAnticipadoTax : -99,
      "uuidBrands": this.infoForm.value.uuidBrands,
      "servicesSalesChannel": []
     // ,"flagDueDate"                   : "N"
      ,"psServicesDescriptionInvoice"  : tmpC
      ,"namePsServicesInvoice"         : tmpB
      ,"psAttributes"                  : {
        "color": [
          this.selectedColor
        ],
        "measures":[{ // 
          height: this.propiedadesForm.get('productHeight').value,
          width: this.propiedadesForm.get('productWidth').value,
          length: this.propiedadesForm.get('productLength').value,
          weight: this.propiedadesForm.get('productWeight').value
        }],
        "size":[],
        "text": [this.propiedadesForm.get('text').value]
      }
    //  ,"flagEntryPsServices"           : "N"
      ,"flagShippingMeasure"           : "N"
      ,"psShippingMeasure"             : {
        "measureSize":"Cm",
        "measureWeight":"Kg",
        "width": this.propiedadesForm.get('width').value,
        "height": this.propiedadesForm.get('height').value,
        "length": this.propiedadesForm.get('length').value,
        "weight": this.propiedadesForm.get('weight').value
      }
     // ,"flagExternal"                  : "N"
      ,"barcode": this.infoForm.get('barcode').value
      ,"flagOverwrite" : this.preciosForm.value.overwrite ? 'Y' : 'N'
      ,"codDteTax"     : this.preciosForm.value.activeDteTax ? '0' : '1'
      ,"dateStart": this.formatDate(this.infoForm.value.fechaInicio)
      ,"dateEnd": this.formatDate(this.infoForm.value.fechaFin)
          //
          ,"flagConsignado"     : this.preciosForm.value.flagConsignado ? 'Y' : 'N'
          ,"uuidCrmCustomerConsignado"  : this.preciosForm.value.uuidCrmCustomerConsignado
          ,"idUnitMeasurement"  : this.infoForm.value.idUnitMeasurement
          ,"flagEntryPsServices": this.infoForm.value.flagEntryPsServices ? 'Y' : 'N'
          ,"flagOutStock"       : this.infoForm.value.flagOutStock ? 'Y' : 'N'     
          ,"flagDueDate"        : this.infoForm.value.flagDueDate ? 'Y' : 'N'
          ,"flagExternal"       : this.infoForm.value.flagExternal ? 'Y' : 'N'
          // 
          ,"flexibleAttributesForm":arrFlex      
          //
          ,"uuidPsCombinationPropertyValue_a":   this.infoForm.value.uuidPsCombinationPropertyValue_a
          ,"uuidPsCombinationPropertyValue_b":   this.infoForm.value.uuidPsCombinationPropertyValue_b
          ,"psServicesDescriptionShort":         this.infoForm.value.psServicesDescriptionShort
          //
          ,"parentUUID"  :'0'    

    };
    this.barcode = this.infoForm.get('barcode').value;
    // //(data);
    //this.infoForm.get('typeCod').disable();
    ////////////////////////////////////////////////
    var priceOK = this.product.price.filter(item => item.ACTIVE_PRICE == 'Y')
    //price: priceOK[0].SALES_PRICE,
    ////////////////////////////////////////////////
      

    if(priceOK[0].SALES_PRICE !== this.preciosForm.get('psPrice').value){
      const newPrice = this.preciosForm.get('psPrice').value;
      const uuidPsServices = this.product.data.UUID_PS_SERVICES
      await this._catalogService.updatePrice(newPrice, uuidPsServices);
    }

    this.selectedSalesChannel.forEach((item: any) => {
      // //(item)
      data.servicesSalesChannel.push(item.ID_EPC_SALES_CHANNEL);
    });

/////////////////////////////////////////////////////////////////////////////////////////////

let chk = this.chkEAN13(this.barcode)
if(chk == false) {
  this._toast.error('Codigo EAN13 Incorrecto:'+this.barcode,'Error');
  return false;
}

    const res = await this._catalogService.updateCreateDeleteProductos(data);
    if(res.success){
      this._toast.success('Producto actualizado correctamente').onHidden.subscribe(async () => {
       
        await this.loadIfIsProduct()
      });
    }else{
      await this.ngOnInit();
    }
//////////////////////////////////////////////////////////////////////////////////////////////    
}

  //////////////////////////////////////////////////////////////////////
  ///////////////////////// INFORMACION BASICA /////////////////////////
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  /////////////////////// DETALLES FATCURACION /////////////////////////
  //////////////////////////////////////////////////////////////////////
  toggleSelection(item: any): void {
    const index = this.selectedSalesChannel.indexOf(item);
    if (index === -1) {
      this.selectedSalesChannel.push(item);
    } else {
      this.selectedSalesChannel.splice(index, 1);
    }
  };

  // add(event: MatChipInputEvent): void {
  //   const value = (event.value || '').trim();

  //   // Add our fruit
  //   if (value) {
  //     this.saleschannels.push({name: value});
  //   }

  //   // Clear the input value
  //   event.chipInput!.clear();
  // }

  // remove(fruit: any): void {
  //   const index = this.saleschannels.indexOf(fruit);

  //   if (index >= 0) {
  //     this.saleschannels.splice(index, 1);
  //   }
  // }

  // displayFn(user: any): string {
  //   // //(user);
  //   // return user && user.name ? user.name : '';
  //   return user;
  // }

  //////////////////////////////////////////////////////////////////////
  ///////////////////////////// IMAGENES ///////////////////////////////
  //////////////////////////////////////////////////////////////////////
  async loadPrice(data):Promise<any>{
    this.isLoadingPrice=true; 
    this.tablePrice =  new MatTableDataSource(data);
    this.ref.detectChanges();
    this.tablePrice.paginator = this.pagPrice; 
    this.isLoadingPrice=false; 
    this.ref.detectChanges();
    
  } 
  //////////////////////////////////////////////////////////////////////
  ////////////////////////////// PRECIOS ///////////////////////////////
  //////////////////////////////////////////////////////////////////////
  async loadFormPrecios(): Promise<any>{
    // //(this.product);
   //margenPrice = { amount:0,percent:0,txt:null}
    let amount = 0;
    let percent=0;
    let margenTxt=null;
    let priceRecommended=0;
    ////
    await  this.loadPrice(this.product.price)
    ////
    this.priceActive = await this.product.price.filter(item => item.ACTIVE_PRICE == 'Y');
    if( this.priceActive[0].COST_PRICE === 0 || this.priceActive[0].COST_PRICE == null){
      margenTxt="Margen No Determinado";
      this.percentProfitsMargin = 0
    }
    else{ 
      /////////////////////////////////////////////
      if(this.percentProfitsMargin >= 1){

        //priceRecommended  = Math.trunc((this.priceActive[0].COST_PRICE)/(1+this.percentProfitsMargin)+1)
        priceRecommended  =  Math.trunc( (this.priceActive[0].COST_PRICE * this.percentProfitsMargin)+this.priceActive[0].COST_PRICE)

      }else{
        priceRecommended  = Math.trunc((this.priceActive[0].COST_PRICE)/(1-this.percentProfitsMargin)+1)
        //priceRecommended  =  Math.trunc( (this.priceActive[0].COST_PRICE * this.percentProfitsMargin)+this.priceActive[0].COST_PRICE)
      }
      /////////////////////////////////////////////      
      //percent           = (this.priceActive[0].SALES_PRICE/this.priceActive[0].COST_PRICE)-1
      percent           = (this.priceActive[0].SALES_PRICE-this.priceActive[0].COST_PRICE)/(this.priceActive[0].SALES_PRICE)
      amount            = this.priceActive[0].SALES_PRICE - this.priceActive[0].COST_PRICE;
      /////////////////////////////////////////////
      if( amount < 0) {
          percent = percent *-1;
          margenTxt="Magen Negativo";
      }
    }
    this.margenPrice.amount                   = amount;
    this.margenPrice.percent                  = percent;
    this.margenPrice.txt                      = margenTxt;
    this.margenPrice.style                    = margenTxt;
    this.margenPrice.amountSalesRecomented    = priceRecommended
    this.margenPrice.amountSalesRecomentedTax =  Math.round( priceRecommended * (1 + (this.regularTax/100))) 
    ////////////////////////////////
    this.preciosForm.patchValue({
      psPrice: this.priceActive[0].SALES_PRICE,
      psPriceRound: (this.priceActive[0].SALES_PRICE).toFixed(2),
      cost: this.priceActive[0].COST_PRICE,

      overwrite: this.product.data.FLAG_OVERWRITE === 'Y' ? true : false,
      activeDteTax: this.product.data.COD_DTE_TAX === "0" ? true : false,
    });
    ////////////////////////////////
    this.setPrecioBruto(null,this.priceActive[0].SALES_PRICE)

    
    let tmpP = Math.round( this.priceActive[0].SALES_PRICE * (1 + (this.regularTax/100))) 
    this.preciosForm.get('psPriceTAX').setValue(tmpP);
    ////////////////////////////////    
  }
  //////////////////////////////////////////////////////////////////////
// agregar funcion para crear precio marketero
//=SI(H5<100;REDONDEAR.MAS((H5-10)/10;2)*10;SI(H5<1000;REDONDEAR.MAS((H5-100)/100;2)*100;SI(H5<10000;REDONDEAR.MAS((H5-1000)/1000;2)*1000;SI(F5<100000;REDONDEAR.MAS((H5-10000)/10000;2)*10000;SI(F5<1000000;REDONDEAR.MAS((H5-100000)/100000;2)*100000;"")))))
//=SI(H5<100;H5-10;SI(H5<1000;H5-100;SI(H5<10000;H5-1000;SI(H5<100000;H5-10000;SI(H5<1000000;H5-100000;"")))))
//=H5-O5+N5
  /////////////////////////////////////////////////////////////////////
  ////////////////////////////// COSTOS ////////////////////////////////
  //////////////////////////////////////////////////////////////////////
  /*
  async addCost(): Promise<any>{
     let cost: any = {
      // "codPsServices": this.product.data.COD_PS_SERVICES,
       "uuidPsServices": this.product.data.UUID_PS_SERVICES,
       "uuidCrmSupplier": this.costosForm.get('provider').value,
       "idPsServicesCostItem": this.costoItemSelected.ID_PS_SERVICES_COST_ITEM,
       "uuidPsServicesPrice": this.priceActive[0].UUID_PS_SERVICES_PRICE,
       "nameServicesCost": this.costosForm.get('name').value,
       "costServicesCost": this.costosForm.get('price').value,
       "activeServicesCost": 'Y',//this.costosForm.get('active').value? 'Y': 'N',
       "extraDataServicesCost": {},
       "deleteCostType": this.costoItemSelected.NAME_SERVICES_COST_ITEM
     };
    // let tmp: any = this.costosTable.dataSource;
    // this.costosTable.dataSource = [];
    // tmp.push(cost);
    // this.costosTable.dataSource = tmp;
    // // //(this.costosTable.dataSource);
    // delete cost.deleteCostType;
     let { success } = await this._catalogService.crearCostos(cost);

     if(success){
       //this.costosForm.reset();
       this.costoItemSelected = {};
       //
        await  this.loadIfIsProduct();
        await this.makeTableCostosActuales(this.costosProducto);
       this._toast.success('Costo agregado correctamente');
       this.costosForm.reset();
     }
     // this.costosTable.dataSource = tmp;

  };
  */
  //////////////////////////////////////////////////////////////////////
  /*
  async deleteCosto(cost: any): Promise<any>{
    let tmp: any = this.costosTable.dataSource;

    tmp = tmp.filter((item: any) => item.ID_PS_SERVICES_COST !== cost.ID_PS_SERVICES_COST);
    const { success } = await this._catalogService.deleteCosto(cost);
    // //(x)
    if(success){
      this.costosTable.dataSource = tmp;
      await this.loadIfIsProduct();
      this._toast.info('Costo eliminado');
    }
  }*/
  //////////////////////////////////////////////////////////////////////
  createCost(): void {
    const $this = this;
    var arrCost :any = {}
    const dialogRef = this._dialog.open(DialogCostComponent, {
      width: '1000px',
      height: '600px',
      data: {
       costsItems  : this.costsItems
      ,currentCosts : this.costosProducto.cost
      ,SYMBOL_CURRENCY : this.product.data.SYMBOL_CURRENCY
  
      }
    });
  
    dialogRef.afterClosed().subscribe(async (result) =>  {
      if (result) {
        //############################################
        //Opciones
          arrCost = {
                      // "codPsServices": this.product.data.COD_PS_SERVICES,
                      "uuidPsServices": this.product.data.UUID_PS_SERVICES,
                      "uuidCrmSupplier": result.costosForm.uuidCrmCustomer,
                      "idPsServicesCostItem": result.costosForm.idPsServicesCostItem,
                      "uuidPsServicesPrice": this.priceActive[0].UUID_PS_SERVICES_PRICE,
                      "nameServicesCost": result.costosForm.name,
                      "costServicesCost": result.costosForm.price,
                      "activeServicesCost": 'Y',//this.costosForm.get('active').value? 'Y': 'N',
                      "extraDataServicesCost": {},
                      "deleteCostType": result.costosForm.nameServicesCostItem
                    };
          let { success } = await this._catalogService.crearCostos(arrCost);
          if(success){
            //
             await  this.loadIfIsProduct();
             await this.makeTableCostosActuales(this.costosProducto);
             this._toast.success('Costo agregado correctamente');
          }          
        //############################################       
      }
    });
  };
 //////////////////////////////////////////////////////////////////////
  async deleteCost(cost: any): Promise<any>{

    this._fuseConfirmationService.open({
      title: `Eliminar Producto <b>${cost.NAME_SERVICES_COST}</b>`,
      message: `¿Está seguro de eliminar Costo?`,
      icon: {
        name: 'delete',
        color: 'warn'
      },
      actions: {
        confirm: {
          label: 'Eliminar',
        },
        cancel: {
          label: 'Cancelar',
        }
      }
    }).afterClosed().subscribe(
      async (res: any) => {
        // //(res)
        if(res === 'confirmed'){
          //#########################################################################
            let tmp: any = this.dataCost;
            tmp = tmp.filter((item: any) => item.ID_PS_SERVICES_COST !== cost.ID_PS_SERVICES_COST);
            const { success } = await this._catalogService.deleteCosto(cost);
            // //(x)
            if(success){
              this.dataCost = tmp;
              await this.loadIfIsProduct();
              this._toast.success('Costo eliminado');
            }
          //#########################################################################
        }
      }
    );
  }
  //////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////// costosHistoricos
  async makeTableCostosActuales(costosOK): Promise<any>{
    //Costos Actuales
    this.dataCost=costosOK.cost 
    await this.loadCost(this.dataCost)
    //Costos Historicos
    if(costosOK.priceOld.length > 0)
      this.lastIdPriceOld   = costosOK.priceOld[0].UUID_PS_SERVICES_PRICE;
    else  
    this.lastIdPriceOld = 0;
    //
    this.costosHistoricosAll = costosOK.costOld 
    this.preciosHistoricos= costosOK.priceOld 

    this.dataCostOld = this.costosHistoricosAll.filter(item => item.UUID_PS_SERVICES_PRICE == this.lastIdPriceOld);
    await this.loadCostOld(this.dataCostOld)
  }
  //////////////////////////////////////////////////////////////////////
  async filterCostOld(): Promise<any>{
    this.dataCostOld = this.costosHistoricosAll.filter(item => item.UUID_PS_SERVICES_PRICE == this.lastIdPriceOld);
    await this.loadCostOld(this.dataCostOld)
  }
  //////////////////////////////////////////////////////////////////////
  async loadCost(data):Promise<any>{
    this.isLoadingCost=true; 
    this.tableCost =  new MatTableDataSource(data);
    this.ref.detectChanges();
    this.tableCost.paginator = this.pagCost;
    this.isLoadingCost=false; 
  }  
  //////////////////////////////////////////////////////////////////////
  async loadCostOld(data):Promise<any>{
    this.isLoadingCostOld=true; 
    this.tableCostOld =  new MatTableDataSource(data);
    this.ref.detectChanges();
    this.tableCostOld.paginator = this.pagCostOld;
    this.isLoadingCostOld=false; 
    //
    await this.filterDiscountOld()
  }  

   //////////////////////////////////////////////////////////////////////

   async loadDiscount(data):Promise<any>{
    this.isLoadingDiscount=true; 
    this.tableDiscount =  new MatTableDataSource(data);
    this.ref.detectChanges();
    this.tableDiscount.paginator = this.pagDiscount;
    this.isLoadingDiscount=false; 

  }  
  //////////////////////////////////////////////////////////////////////
  async loadDiscountOld(data):Promise<any>{
    this.isLoadingDiscountOld=true; 
    this.tableDiscountOld =  new MatTableDataSource(data);
    this.ref.detectChanges();
    this.tableDiscountOld.paginator = this.pagDiscountOld;
    this.isLoadingDiscountOld=false; 
  }  
  
  //////////////////////////////////////////////////////////////////////
  async filterDiscountOld(): Promise<any>{
    let tmp  = this.dataDiscountOld.filter(item => item.UUID_PS_SERVICES_PRICE == this.lastIdPriceOld);
    await this.loadDiscountOld(tmp)
  }
  //////////////////////////////////////////////////////////////////////  
  //////////////////////////////////////////////////////////////////////
  //////////////////////////// PROPIEDADES /////////////////////////////
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  ///////////////////////////// BODEGAS ////////////////////////////////
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  ////////////////////////// ALERTAS DE STOCK //////////////////////////
  //////////////////////////////////////////////////////////////////////



  ///############################################################################################################
  ///############################################################################################################
  ///############################################################################################################
  ///############################################################################################################
/*

  private _filter(name: any): any[] {
    // //(name);
    const filterValue = name.toLowerCase();

    return this.typeBarcode.filter(option => option.COD_TYPE_BARCODE.toLowerCase().includes(filterValue));
  }
*/

  // generateBarcode(codProd: string){
    //   //(JsBarcode('', '123456789012', {
  //     format: 'EAN13'
  //   }));
  // }


  //////////////////////////////////////////////////////////////////////
  ///////////////////////// Informacion Basica /////////////////////////
  //////////////////////////////////////////////////////////////////////

  filterChargeCode(): void{
    this.chargeCodeSelectDetail=this.chargeCode.filter(item => item.COD_CHARGE_CODE === this.chargeCodeSelect);
    //console.log(this.chargeCodeSelectDetail)
    //(this.chargeCodeSelectDetail);
    this.cuentasContablesArray = Object.values(this.chargeCodeSelectDetail[0]?.ATTRIBUTE_CHARGE_CODE?.ps);
  }

  getCharCodeValue(charCodeJson: any): string{
    // //(charCodeJson);
    this.charCodeSelected = charCodeJson;
    return charCodeJson.value;
  }
////###################################
changeTypeCod(cod: any,type:any,flagRegularSKU:string): void{
  if(flagRegularSKU == 'Y'){
    // //(cod);
    if(cod == 'EAN13'){

      this.infoForm.patchValue({barcode: this.generarCodigoEAN13()})
    }else if(cod == 'EAN8'){
      this.infoForm.patchValue({barcode: this.generarCodigoEAN8()})

    }
    this.formatBarcode = cod;
}else{
     this.infoForm.patchValue({barcode: '0200001000003'})
     this.formatBarcode = '0200001000003'
}
  }
//###################################
  changeCheckedSalesChannel(sc: any, index: number): void{
    if(sc.checked){
      this.saleschannels[index].checked = false;
    }else{
      this.saleschannels[index].checked = true;
    };
  };

  /////////////////////////////////////////////////////////
  ///////////////////////// PRECIOS /////////////////////////
  /////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////
  ///////////////////////// COSTOS /////////////////////////
  /////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////
  ///////////////////////// UTILS /////////////////////////
  /////////////////////////////////////////////////////////
  

/////////////////////////////////////////////////////////////////////////////
  async deleteProduct(): Promise<any>{
    const $this = this;
    var txt = '';

    
    //this.countOrders        = this.product.data.COUNT_ORDERS
    //this.countStock         = this.product.data.COUNT_STOCK
    //this.countOrdersPending = this.product.data.COUNT_ORDERS_PENDING

    if(this.countStock > 0 || this.countOrdersPending > 0 ){
      //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        if(this.product.data.COD_WAREHOUSE_TYPE == 'W' ) txt = `1)Ordenes pendientes Actuales: <b>${this.countOrdersPending}</b><br>2)Stock Actual en bodegas: <b>${this.countStock}</b>`;
        if(this.product.data.COD_WAREHOUSE_TYPE == 'V' ) txt = `1)Ordenes pendientes Actuales: <b>${this.countOrdersPending}</b><br>2)Stock Actual en bodegas: <b>${this.countStock}</b>`;
        if(this.product.data.COD_WAREHOUSE_TYPE == 'U' ) txt = `1)Ordenes pendientes Actuales: <b>${this.countOrdersPending}</b>`;      
        const dialogRef = this._fuseConfirmationService.open({
              title: `No es posible eliminar: <b>${this.product.data.NAME_PS_SERVICES}</b>?`,
              message: `<b><b>¡¡Atención!!</b></b><br>Para eliminar no debe tener:<br>${txt}<br>`,
              icon: {
                  show: true,
                  name: 'delete',
                  color: 'primary'
              },
              actions: {
                  confirm: {
                      label: 'Aceptar',
                      color: 'primary'
                  },
                  cancel: {
                      show :  false,
                      label: 'Cancelar',
                  }
              }
          });  
        return 0;      
      //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%      
    } 
    else{
      //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        if(this.product.data.COD_WAREHOUSE_TYPE == 'W' ) txt = `<b>1)No tiene Ordenes pendientes</b><br><b>2)Sin Stock en bodegas</b>`;
        if(this.product.data.COD_WAREHOUSE_TYPE == 'V' ) txt = `<b>1)No tiene Ordenes pendientes</b><br><b>2)Sin Stock en bodegas</b>`;
        if(this.product.data.COD_WAREHOUSE_TYPE == 'U' ) txt = `<b>1)No tiene Ordenes pendientes</b>`;
        //
            const dialogRef = this._fuseConfirmationService.open({
              title: `¿Eliminar producto <b>${this.product.data.NAME_PS_SERVICES}</b>?`,
              message: `<b><b>¡¡Atención!!</b></b><br>El producto solo puede ser eliminado si:<br>${txt}<br><br><b>Importante: Una vez eliminado no es posible recuperar el producto</b>`,
              icon: {
                  show: true,
                  name: 'delete',
                  color: 'warn'
              },
              actions: {
                  confirm: {
                      label: 'Eliminar',
                      color: 'warn'
                  },
                  cancel: {
                      
                      label: 'Cancelar',
                  }
              }
          });
        
            dialogRef.afterClosed().subscribe(async (result) => {
              // //(result);
              if(result === 'confirmed'){
                const res = await  this._catalogService.delpsServices($this.uuidPsServices);  
                console.log(res)
                if(res.success){
                  this._toast.success(res.dataSet.glsStatus, '',{
                    timeOut: 1000,
                  }).onHidden.subscribe(() => {
                    this.closeBtn()
                  });
                }
              }
          });      
      //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%      
    }

  }
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
  cycleImages(forward: boolean = true): void {
      // Get the image count and current image index
      const count = this.imgs.length;
      const currentIndex = this.infoForm.get('currentImg').value;

      // Calculate the next and previous index
      const nextIndex = currentIndex + 1 === count ? 0 : currentIndex + 1;
      const prevIndex = currentIndex - 1 < 0 ? count - 1 : currentIndex - 1;

      // If cycling forward...
      if ( forward )
      {
          this.infoForm.get('currentImg').setValue(nextIndex);
          this.imgSrc = `${this.appImgURL}${this.appTokenImg}/${this.imgs[nextIndex].UUID_IMG}${this.appImgSizeA}`;
          //console.log(this.imgSrc)
          // //(this.imgSrc);
      }
      // If cycling backwards...
      else
      {
          this.infoForm.get('currentImg').setValue(prevIndex);
          this.imgSrc = `${this.appImgURL}${this.appTokenImg}/${this.imgs[prevIndex].UUID_IMG}${this.appImgSizeA}`;
         // console.log(this.imgSrc)
          // //(this.imgSrc);

      }
  }
/////////////////////////////////////////////////////////////////////////////
  onFileChange(event: any): void {
    // //(event.target.files[0]);
    this.imgToUpload = event.target.files[0];
    this.showButtonUpload = true

  }
/////////////////////////////////////////////////////////////////////////////
  async uploadFile(): Promise<any> {
    if(!!this.imgToUpload){

      let extraData = {alt:this.product.data.NAME_PS_SERVICES,sku:this.product.data.COD_PS_SERVICES}
      const res = await this._catalogService.uploadImg(this.imgToUpload,this.uuidPsServices,extraData);
      //(res);
      if(res.codStatus === "200"){
        const res2 = await this._catalogService.attacheImgToProduct(res.data.uuidImg, this.uuidPsServices);
        if(res2.success){
          this._toast.success('Imagen subida correctamente');
          this.ngOnInit();
          this.showButtonUpload = false
        }
        // location.reload();
      }
    }else{
      this._toast.warning('No se ha seleccionado ninguna imagen');
    }
  }
/////////////////////////////////////////////////////////////////////////////
  async drop(event: CdkDragDrop<string[]>): Promise<any> {
    moveItemInArray(this.imgs, event.previousIndex, event.currentIndex);
    // //('Posicion previa: ', event.previousIndex);
    // //('Posicion actual: ', event.currentIndex);
    // //("id", event.item.element.nativeElement.id)

    const uuidImg = event.item.element.nativeElement.id;
    let position = event.currentIndex;
    let typeImg = "";
    if(position === 0){
      // //("por defecto");
      typeImg = "D";
    }else{
      // //("no por defecto");
      typeImg = "R";
    }
    // //(this.imgs.length);
    // //(position);
    if(this.imgs.length === position +1){
      // //("Es la ultima imagen");
      position = this.imgs.length + 1;
    }
    await this._catalogService.updateOrRemoveImgProduct(uuidImg,  this.product.data.UUID_PS_SERVICES, position, typeImg);
    // //(x);
    // //(this.imgs);
  }
  /////////////////////////////////////////////////////////////////////////////
  async deleteImg(uuidImg: string): Promise<any>{
    await this._catalogService.deattachImgToProduct(uuidImg,  this.product.data.UUID_PS_SERVICES).then(
      (res: any) => {
        if(res.success){
          this._toast.success('Imagen eliminada correctamente');
          this.ngOnInit();
        }
      }
    );
  }
  /////////////////////////////////////////////////////////////////////////////
  async openImg(type:string,urlImg:string, uuidImg: string,imgB64 : string): Promise<any>{
    const dialogRef = this._dialog.open(ModalImgComponent, {
              height:'100%'
              ,width: '100%'
              ,data: {
                         type      : type
                        ,urlImg    : urlImg
                        ,uuidImg   : uuidImg
                        ,imgB64    : imgB64
                        ,imgHeight : '800px'
                        ,imgWidth  : '600px'
                        ,rotate    : null
                        ,title     : this.product.data.NAME_PS_SERVICES
              }
          });
          dialogRef.afterClosed().subscribe(async (result) => {
             // this.ngOnInit();
          });
  }  
/////////////////////////////////////////////////////////////////////////////
  toggleDetails(warehouseuuid: string,warehouse: any): void {
        // //(test);
        // If the product is already selected
        if ( this.warehouseSelected && this.warehouseSelected[0]?.UUID_WAREHOUSE === warehouseuuid) {
            // Close the details
            
            this.closeDetails();
            return;
        }
        this.warehouseSelected = this.warehouseJSON.storageMovements.filter((x: any) => x.UUID_WAREHOUSE === warehouseuuid);
        if(this.warehouseSelected.length == 0){
          this._toast.warning('Bodega: '+warehouse.COD_WAREHOUSE +'-'+ warehouse.NAME_WAREHOUSE+' sin Movimientos', 'Atención');
        }
        // //(this.warehouseSelected);
    }
/////////////////////////////////////////////////////////////////////////////
  closeDetails(): void {
    this.warehouseSelected = [];

  }
/////////////////////////////////////////////////////////////////////////////
  openDialogSeeAllMovements(warehouse: any):void{
    // //(warehouse)
    const detail = this.warehouseJSON.storageDetail.filter((x: any) => x.UUID_WAREHOUSE === warehouse.UUID_WAREHOUSE);
    this._dialog.open(DialogSeeAllMovementsComponent, {
      data: {
         nameWareHouse  : warehouse.NAME_WAREHOUSE
        ,codWarehouse   : warehouse.COD_WAREHOUSE
        ,warehouseDetail: detail
        ,nameProduct    : this.product.data.NAME_PS_SERVICES
        ,codProduct     : this.product.data.COD_PS_SERVICES
        ,x :this.product

      },
      width: '1000px'
    });
  }
/////////////////////////////////////////////////////////////////////////////
  async createStorageLevel(): Promise<any>{
    this._dialog.open(DialogCreateOrEditStorageLevelComponent, {
      data: {
        warehouse: this.warehouseJSON,
        //product: this.product
        uuidPsServices :this.uuidPsServices,
        update: false
      }
    }).afterClosed().subscribe(
      (res: any) => {
        // //(res)
        if(res){
          this.ngOnInit();
        }
      }
    );
  };
/////////////////////////////////////////////////////////////////////////////
  async updateStorageLevel(element: any): Promise<any>{
    this._dialog.open(DialogCreateOrEditStorageLevelComponent, {
      data: {
        warehouse: this.warehouseJSON,
        //product: this.product,
        uuidPsServices :this.uuidPsServices,
       element: element,
        update: true
      }
    }).afterClosed().subscribe(
      (res: any) => {
        if(res){
          this.ngOnInit();
        }
      }
    );
  }
/////////////////////////////////////////////////////////////////////////////
  async deleteStorageLevel(element: any): Promise<any>{
    this._fuseConfirmationService.open({
      title: 'Eliminar nivel de almacenamiento',
      message: '¿Está seguro de eliminar este nivel de almacenamiento?',
      icon: {
        name: 'delete',
        color: 'warn'
      },
      actions: {
        confirm: {
          label: 'Eliminar',
        },
        cancel: {
          label: 'Cancelar',
        }
      }
    }).afterClosed().subscribe(
      (res: any) => {
        // //(res)
        if(res === 'confirmed'){
          this._warehouseService.deleteWarehouseStorageLevel(this.uuidPsServices, element.UUID_WAREHOUSE);
          this.ngOnInit();
        }
      }
    );
  };
/////////////////////////////////////////////////////////////////////////////
  assingResume(): void{
    // //(this.infoForm.value)
    // //(this.preciosForm.value);
    var unitMeasurements
try{    
    this.resumeForm.enable();
    this.infoForm.get('barcode').enable();
    if(this.flagExternal == false){
      //=====================================      
      unitMeasurements = this.unitMeasurements.find((x: any) => x.ID_UNIT_MEASUREMENT === this.infoForm.value.idUnitMeasurement)    
      this.resumeForm.patchValue({
        name: this.infoForm.value.name,
        categoria: this.categories.find((x: any) => x.ID_PS_CATEGORY === this.infoForm.value.category).NAME_PS_CATEGORY,
        tipo: this.types.find((x: any) => x.ID_PS_TYPE === this.infoForm.value.type).NAME_PS_TYPE,
        grupo: this.groups.find((x: any) => x.ID_PS_GROUP === this.infoForm.value.group).NAME_PS_GROUP,
        marca: this.brands.find((x: any) => x.UUID_PS_BRANDS === this.infoForm.value.uuidBrands).NAME_PS_BRANDS,
        SKU: this.infoForm.value.SKU,
        codigoInt: this.infoForm.value.barcode,
        charCode: this.infoForm.value.charCode,
        psPrice: this.preciosForm.value.psPriceRound,
        psPriceTAX:this.preciosForm.value.psPriceTAX,
        cost:this.preciosForm.value.cost,
        sobrescribible: this.preciosForm.value.overwrite ? 'Si' : 'No',
        afecto: this.preciosForm.value.overwrite ? 'Si' : 'No',
        codRetenidoTax: this.preciosForm.value.codRetenidoTax === 0 ? 'No' : 'Si',
        codAnticipadoTax: this.preciosForm.value.codAnticipadoTax === 0 ? 'No' : 'Si',
        //
        unitMeasurement : unitMeasurements.NAME_UNIT_MEASUREMENT +' '+ unitMeasurements.DTE ,
        consignado      : this.preciosForm.value.flagConsignado   == false ? 'No' : 'Si',
        entryPsServices : this.infoForm.value.flagEntryPsServices == false ? 'No' : 'Si',
        outStock        : this.infoForm.value.flagOutStock        == false ? 'No' : 'Si',
        dueDate         : this.infoForm.value.flagDueDate         == false ? 'No' : 'Si',
        external        : this.infoForm.value.flagExternal        == false ? 'No' : 'Si',      
      });
      this.resumeForm.disable();
      this.infoForm.get('barcode').disable();
      //=====================================      
    }
    else{
      //=====================================
      this.resumeForm.enable();
      this.infoForm.get('barcode').enable();
        //************************************************ */
          this.resumeForm.patchValue({
                name: this.infoForm.value.name,
                categoria: this.categories.find((x: any) => x.ID_PS_CATEGORY === this.infoForm.value.category).NAME_PS_CATEGORY,
                tipo: this.types.find((x: any) => x.ID_PS_TYPE === this.infoForm.value.type).NAME_PS_TYPE,
                grupo: this.groups.find((x: any) => x.ID_PS_GROUP === this.infoForm.value.group).NAME_PS_GROUP,
                marca: this.brands.find((x: any) => x.UUID_PS_BRANDS === this.infoForm.value.uuidBrands).NAME_PS_BRANDS,
                SKU: this.infoForm.value.SKU,
                codigoInt: this.infoForm.value.barcode,
                charCode: this.infoForm.value.charCode,
                psPrice: 0,
                psPriceTAX:0,
                cost:0,
                //
                external        : 'Si',      
              });
        //************************************************ */        
      this.resumeForm.disable();
      this.infoForm.get('barcode').disable();
      //=====================================      
    }
    

  }catch(e){
    console.log(e)
  }    
  }
/////////////////////////////////////////////////////////////////////////////
  assingSKUAndPrefixEANByGroup(group: any): void{
    // //(group);
    this.infoForm.patchValue({
      SKU: group.NEW_COD_PS_SERVICES
    });
    if(this.prefixEAN != group.PREFIX_BARCODE && this.ean13Generated){
      this.prefixEAN = group.PREFIX_BARCODE;
      this.changeTypeCod('EAN13','EAN13','Y');
    }else if(this.prefixEAN != group.PREFIX_BARCODE && this.ean8Generated){
      this.prefixEAN = group.PREFIX_BARCODE;
      this.changeTypeCod('EAN8','EAN8','Y');
    }else{
      if(this.prefixEAN != group.PREFIX_BARCODE){
        // //('grupo diferente')
        this.prefixEAN = group.PREFIX_BARCODE;
      }
    }
    ///////////////////////////////////////////////////////////////
    this.percentProfitsMargin = group.PERCENT_PROFITS_MARGIN
    //////////////////////////////////////////////////////////////
  };
/////////////////////////////////////////////////////////////////////////////
  generarCodigoEAN13(): string {
    var  prefix
    var idGroup
    var codigoEAN13

    this.ean13Generated = true;
    if(this.prefixEAN == '000000'){
      idGroup = this.infoForm.get('group').value
      prefix = this.groups.filter(item => item.ID_PS_GROUP == idGroup)[0].PREFIX_BARCODE
    }
    else{
       prefix = this.prefixEAN;
    }

    const randomNumber = Math.floor(Math.random() * 100000); // Generar un número aleatorio de 12 dígitos
    let codigo = randomNumber.toString().padStart(6, '0'); // Rellenar con ceros a la izquierda si es necesario
    codigo = codigo.slice(0, 6);
    codigo = prefix + codigo;
    codigo = codigo.substring(0, 12)

    let digit = this.checkDigitEAN13(codigo)
    // //(codigo.slice(0, 6));
    codigoEAN13 =codigo + digit
    return codigoEAN13;
  };
/////////////////////////////////////////////////////////////////////////////
 checkDigitEAN13(barcode) {
  const sum = barcode.split('')
    .map((n, i) => n * (i % 2 ? 3 : 1)) // alternate between multiplying with 3 and 1
    .reduce((sum, n) => sum + n, 0) // sum all values

  const roundedUp = Math.ceil(sum / 10) * 10; // round sum to nearest 10

  const checkDigit = roundedUp - sum; // subtract round to sum = check digit
  
  return checkDigit;
}
/////////////////////////////////////////////////////////////////////////////
  generarCodigoEAN8(): string {
    this.ean8Generated = true;
    let prefix = this.prefixEAN;
    prefix = prefix.slice(0, 2);
    const randomNumber = Math.floor(Math.random() * 100000); // Generar un número aleatorio de 7 dígitos
    let codigo = randomNumber.toString().padStart(5, '0'); // Rellenar con ceros a la izquierda si es necesario
    // //(codigo);
    codigo = codigo.slice(0, 5);
    codigo = prefix + codigo;
    
    let sumaPares = 0;
    let sumaImpares = 0;
  
    for (let i = 0; i < 7; i++) {
      const digito = Number(codigo.charAt(i));
      if (i % 2 === 0) {
        sumaImpares += digito;
      } else {
        sumaPares += digito;
      }
    }
  
    const sumaTotal = sumaPares * 3 + sumaImpares;
    const digitoVerificador = 10 - (sumaTotal % 10);
  
    const codigoEAN8 = codigo + digitoVerificador.toString();
  
    return codigoEAN8;
  }
/////////////////////////////////////////////////////////////////////////////
  countCharacter(field: string){
    // //(field)
    if(field == 'nameInvoice'){
      // //(this.infoForm.value.nameInvoice)
      this.countNameInvoiceCharacter = this.infoForm.value.nameInvoice.length;
    }else if(field == 'descInvoice'){
      this.countDescInvoiceCharacter = this.infoForm.value.descInvoice.length;
    }else if(field == 'name'){
      this.countNameCharacter = this.infoForm.value.name.length;
    }else if(field == 'psServicesDescriptionShort'){
      this.countpsServicesDescriptionShort = this.infoForm.value.psServicesDescriptionShort.length;
    }
  }
/////////////////////////////////////////////////////////////////////////////
//###########################################################
async editOrCreateTag(type,o): Promise<void> {
  const dialogRef = this._dialog.open(EslDetailComponent, {
    
      height:'100%',
      width: '100%',
      data: {
         type : type
        ,uuidPsServicesEslTag: o.UUID_PS_SERVICES_ESL_TAG
        ,dataTag             : o
        ,uuidPsServices      : null
      }
  });
  dialogRef.afterClosed().subscribe(async (result) => {
      this.ngOnInit();
  });
}
//###########################################################
async deleteTag(o){
  this._fuseConfirmationService.open({
    title: `Eliminar EasyPrice <b>${o.COD_ESL_TAG}</b>`,
    message: `Seran Eliminados todos los moviemientos asociados al EasyPrice`,
    icon: {
      name: 'delete',
      color: 'warn'
    },
    actions: {
      confirm: {
        label: 'Eliminar',
      },
      cancel: {
        label: 'Cancelar',
      }
    }
  }).afterClosed().subscribe(
    async (res: any) => {
      // //(res)
      if(res === 'confirmed'){
        let setTag = await this._catalogService.epcDelTag(o.UUID_PS_SERVICES_ESL_TAG)
        await  this.ngOnInit()
      }
    }
  );
}
//###########################################################
openEcommerceProduct(){

var url=this.product.data.URL_ECOMMERCE+'/'+this.product.data.UUID_PS_SERVICES
console.log(url)
window.open(url, "_blank");
}
//###########################################################
async setPsAttribGroup(type,c){
  if(type == 'A'){
    if( this.infoForm.value.uuidPsCombinationProperty_a == this.infoForm.value.uuidPsCombinationProperty_b ){
      console.log("resert")
      this.psServicesCombinationPropertyValueFilter_B = []
    }   

    this.psServicesCombinationPropertyValueFilter_A = await this.psServicesCombinationPropertyValueAll.filter(i => i.UUID_COMBINATION_PROPERTY == c.UUID_COMBINATION_PROPERTY)
    this.psServicesCombinationPropertyFilter_B      = await this.psServicesCombinationPropertyFilter_A.filter(i => i.UUID_COMBINATION_PROPERTY != c.UUID_COMBINATION_PROPERTY )    
    this.psServicesCombinationPropertyType_A = c.TYPE_COMBINATION_PROPERTY    
  
  }
  if(type == 'B'){ 
    this.psServicesCombinationPropertyValueFilter_B = this.psServicesCombinationPropertyValueAll.filter(i => i.UUID_COMBINATION_PROPERTY == c.UUID_COMBINATION_PROPERTY)
    this.psServicesCombinationPropertyType_B = c.TYPE_COMBINATION_PROPERTY    
  }
  }
//###########################################################
async setPsAttrib(type,c){
  if(type == 'A'){
    if(this.psServicesCombinationPropertyType_A == 'COLOR') this.psServicesCombinationPropertyValueColor_A = c.VALUE_COMBINATION_PROPERTY
    this.infoForm.get('uuidPsCombinationPropertyValue_a').setValue(c.UUID_PS_COMBINATION_PROPERTY_VALUE);
  }
  if(type == 'B'){
    if(this.psServicesCombinationPropertyType_B == 'COLOR') this.psServicesCombinationPropertyValueColor_B= c.VALUE_COMBINATION_PROPERTY 
    this.infoForm.get('uuidPsCombinationPropertyValue_b').setValue(c.UUID_PS_COMBINATION_PROPERTY_VALUE);
  }
}
//###########################################################
async closeBtn(): Promise<any> {
  this._dialogRef.close();
}
//###########################################################
addExternalPS(){
  //DialogExternalComponent
  this._dialog.open(DialogExternalComponent, {
    data: {
      nameProduct: this.product.data.NAME_PS_SERVICES,
      skuProduct: this.product.data.COD_PS_SERVICES,
      uuidPsServices :this.uuidPsServices,
      brands: this.brands,
      update: false
    }
  }).afterClosed().subscribe(
    (res: any) => {
      if(res){
        this.ngOnInit();
      }
    }
  );

}
//###########################################################
async deleteExternalPS(o){
var rs,tmp
  this._fuseConfirmationService.open({
    title: `Eliminar Link <b>${o.NAME_PS_BRANDS}</b>`,
    message: `Se eliminara la redirección externa`,
    icon: {
      name: 'delete',
      color: 'warn'
    },
    actions: {
      confirm: {
        label: 'Eliminar',
      },
      cancel: {
        label: 'Cancelar',
      }
    }
  }).afterClosed().subscribe(
    async (res: any) => {
      // //(res)
      if(res === 'confirmed'){
        var json = {
                       appToken               : null
                      ,language               : 'es'     
                      ,uuidPsServices         : this.product.data.UUID_PS_SERVICES
                      ,idPsServicesExternal   : o.ID_PS_SERVICES_EXTERNAL
                      ,uuidPsBrands           : '0'
                      ,linkPsServicesExternal : null
                      ,acc                    : 'D'
                    
                    }
      rs =  await this._catalogService.setPsServicesExternal(json)
      if(rs.success ==  true){
        tmp =  this.product.external.filter(a => a.ID_PS_SERVICES_EXTERNAL !=o.ID_PS_SERVICES_EXTERNAL)
        await this.loadExternal(tmp)
      }
      }
    }
  );

  }
//###########################################################
openExternal(url){
  window.open(url, "_blank");
  }
 //########################################################### 
  deleteReview(o){
    var rs,tmp
    this._fuseConfirmationService.open({
      title: `Eliminar Review <b>${o.REVIEW_RATING}</b>`,
      message: ` ${o.REVIEW_SUBJECT} ${o.REVIEW_COMMENTS} por ${o.mail}`,
      icon: {
        name: 'delete',
        color: 'warn'
      },
      actions: {
        confirm: {
          label: 'Eliminar',
        },
        cancel: {
          label: 'Cancelar',
        }
      }
    }).afterClosed().subscribe(
      async (res: any) => {
        // //(res)
        if(res === 'confirmed'){
          var json = {
                        appToken                   : null
                       ,language                   : 'es'
                       ,uuidSSO                    : ''
                       ,uuidPsServicesReview       : o.UUID_PS_SERVICES_REVIEW                                 
                       ,uuidPsServices             : this.product.data.UUID_PS_SERVICES                                                                         
                       ,action                     : 'DEL'
                       ,extraDataPsServicesReview  : {}                
                   }                    
       rs =  await this._catalogService.setPsReview(json)
       if(rs.success ==  true){
        await this.ngOnInit();
        await this.loadReview(this.product.review)

       }
       }
      }
    );

  }
//###########################################################  
clonePS(){

  if(this.editModeBarcode){return true}
  var chkCat,chkGrp,chkBrand

  
  chkCat   = this.categories.filter(i => i.ID_PS_CATEGORY == this.product.data.ID_PS_CATEGORY)
  chkGrp   = this.groups.filter(i => i.ID_PS_GROUP == this.product.data.ID_PS_GROUP)
  chkBrand = this.brands.filter(i => i.UUID_PS_BRANDS == this.product.data.UUID_PS_BRANDS)
 //============================================================
  if(chkCat.length == 0 || chkGrp.length == 0){
    this._fuseConfirmationService.open({
      title: `No es posible duplicar producto<b>${ this.product.data.NAME_PS_SERVICES}</b>`,
      message: ` Categoria/SubCaterogia en estado inactivo`,
      icon: {
        name:  'shopping_cart',
        color: 'warn'
      },
      actions: {
        confirm: {
          label: 'Aceptar',
        },
        cancel: {
          show :  false,
          label: 'Cancelar',
        }
      }
    })
    return false
  }
  //===========================================================
  if(chkBrand.length == 0){
    this._fuseConfirmationService.open({
      title: `No es posible duplicar producto<b>${ this.product.data.NAME_PS_SERVICES}</b>`,
      message: ` Marca no especificada`,
      icon: {
        name:  'shopping_cart',
        color: 'warn'
      },
      actions: {
        confirm: {
          label: 'Aceptar',
        },
        cancel: {
          show :  false,
          label: 'Cancelar',
        }
      }
    })
    return false
  }  
  //===========================================================  
  let group = this.groupsSelected.filter(i => i.ID_PS_GROUP == this.product.data.ID_PS_GROUP)[0]
  this._dialog.open(DialogCloneComponent, {
    width: '100%',
    height: '100%',
    data: {
       groupsSelected         : group
      ,EAN13                  : this.generarCodigoEAN13()
      ,typeBarcode            : this.typeBarcode
      ,uuidPsServices         : this.uuidPsServices
      ,impuestosEspeciales    : this.impuestosEspeciales
      ,impuestosRetenidos     : this.impuestosRetenidos
      ,percentProfitsMargin   : this.percentProfitsMargin
      ,chargeCodeSelectDetail : this.chargeCodeSelectDetail
      //
      ,types                  : this.types
      ,groups                 : this.groups
      ,brands                 : this.brands
      ,categories             : this.categories
      ,unitMeasurements       : this.unitMeasurements
      ,cuentasContablesArray  : this.cuentasContablesArray
      ,saleschannels          : this.saleschannels 
    }
  }).afterClosed().subscribe(
    (res: any) => {
      if(res.status == true){
        this._dialogRef.close();
      }
    }
  );

}
//###########################################################  
//###########################################################  
createDiscount(){
var rs : any
  this._dialog.open(DiscountsComponent, {
    width: '100%',
    height: '100%',
    data: {
             chargeCodeSelectDetail : this.chargeCodeSelectDetail
            ,uuidPsServices         : this.product.data.UUID_PS_SERVICES
            ,uuidPsServicesPrice    : this.priceActive[0].UUID_PS_SERVICES_PRICE
            ,idUnitMeasurement      : this.product.data.ID_UNIT_MEASUREMENT
            ,unitMeasurements       : this.unitMeasurements 
          }
  }).afterClosed().subscribe(
    async (res: any) => {
      if(res.status == true){
         rs = await this.getPsServicesDiscount()
         this.dataDiscount    =rs.discount
         this.dataDiscountOld =rs.discountOld
         await this.loadDiscount(this.dataDiscount)
         await this.loadDiscountOld(this.dataDiscountOld)
      }
    }
  );
}
//###########################################################  
//###########################################################  
deleteDiscount(o){
console.log(o)
var rs,tmp
if(o.TYPE_PS_SERVICES_DISCOUNT == 'A')
{
  if(o.COD_CURRENCY == 'CLP'  ){
    tmp= `Monto  ${this._currencyPipe.transform(o.DISCOUNT, 'CLP', 'symbol-narrow')}`
  }else{
    tmp= `Monto ${o.SYMBOL_CURRENCY} ${this._currencyPipe.transform(o.DISCOUNT, o.SYMBOL_CURRENCY)}`
  }
   
}else{
   tmp= `Porcentaje  ${Math.round(o.DISCOUNT*100)}%`
}

this._fuseConfirmationService.open({
  title: `Eliminar Descuento canal <b>${o.NAME_SALES_CHANNEL}</b>`,
  message: ` ${tmp}`,
  icon: {
    name: 'delete',
    color: 'warn'
  },
  actions: {
    confirm: {
      label: 'Eliminar',
    },
    cancel: {
      show: true,
      label: 'Cancelar',
    }
  }
}).afterClosed().subscribe(
  async (res: any) => {
    // //(res)
    if(res === 'confirmed'){
      var json = {
                   uuidPsServicesDiscount  : o.UUID_PS_SERVICES_DISCOUNT 
                  ,acction                 : 'DEL'              
               }    
      var add = await this._catalogService.setPsServicesDiscount(json)
        if(add.success == true){
          rs = await this.getPsServicesDiscount()
          this.dataDiscount    =rs.discount
          this.dataDiscountOld =rs.discountOld
          await this.loadDiscount(this.dataDiscount)
          await this.loadDiscountOld(this.dataDiscountOld)
        }
   }
  }
);
}
//###########################################################  
@HostListener('window:keydown', ['$event'])
async keyEvent(event: KeyboardEvent) {
 // let $this = this;

    var  charTmp : string  =null ,uuidPsServices : string = ''
        if(!this.focusInputCode){
            if(event.code.includes('Digit') && !this.isCodeByMachine){
                this.flagRedLight = true;
                this.isCodeByMachine = true;
                this.codeByKeyboard = '';
            }
            if(this.isCodeByMachine){
                if(event.code === 'Enter'){
                    //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
                        console.log(this.codeByKeyboard)
                        if(this.editModeBarcode){
                          this.infoForm.get('barcode').setValue(this.codeByKeyboard);
                        }
                        this.isCodeByMachine = false;
                        this.codeByKeyboard = '';
                        //valido EAN
                        //if(uuidPsServices != '-1')
                        //  this.editOrCreateProduct(uuidPsServices)
                        //else
                        //  this._toast.warning('Producto no Encontrado', 'Alerta');
                    //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%                
                }else{
                    this.flagRedLight   = true;
                    charTmp = event.key.replace('Shift','')
                    this.codeByKeyboard = this.codeByKeyboard + charTmp;
                }
            }
        }
  //===========================          
  }
//###########################################################    
toggleEditModeBarcode() {
  this.editModeBarcode = !this.editModeBarcode;
  console.log(this.editModeBarcode)
  if (this.editModeBarcode) {
    this.infoForm.get('barcode')?.enable();
  } else {
    this.infoForm.get('barcode')?.disable();
  }
}
//###########################################################   
}
