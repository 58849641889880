
<div class="w-full  sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- Header -->

<!-- ////////////////////////////////////////////////////////////////////////////////////////////////-->
<div *ngIf="data.type == 'add'" class="bg-white rounded-lg p-4">
    <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
    <div class="w-full">
        <!-- ##########################################################  --> 
        <div class="grid grid-cols-10">   
            <div class="col-span-8">
                <!-- ## -->
                    <h2 class="prose prose-xl"><b> Crear OOFF </b></h2>                       
                   <!--  <h2 class="prose "> Editar Guia Entrada </h2>   -->
                <!-- ## -->
            </div>
            <div class="col-span-1">
                <!-- ## -->
                <button class="close " mat-button (click)="closeBtn()"> <mat-icon>close</mat-icon></button>    
                <!-- ## -->
            </div>
        </div>
        <!-- ##########################################################  -->    

    </div>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<mat-divider class="py-2"></mat-divider>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->


    <form [formGroup]="rcForm">
                <!-- ##########################################################  -->
                <mat-card class="col-span-1" >
                    <mat-card-content class="h-full">
                        <!--#################################--> 
                            <!--//-->
                            <div class="flex">
                                <mat-form-field class="w-1/3">
                                        <mat-label><b>Tipo:</b></mat-label>   
                                    <mat-select formControlName="flagInstallments" (selectionChange)="setFlagInstallments($event.value,null)">
                                        <mat-option value="Y">Cuotas</mat-option>
                                        <mat-option value="N">Rango Fechas</mat-option>
                                    </mat-select>  
                                </mat-form-field>   
                            </div>
                            <!-- ////////////////////////////  -->
                            <div class="flex" >
                                 <div *ngIf="flagInstallments == false" class="w-full">
                                    <!-- // -->
                                        <div>
                                            <section >
                                                <mat-checkbox class="example-margin" (change)="setIndefinido($event.checked)">Indefinido</mat-checkbox>
                                            </section>
                                        </div>  
                                        <div class="flex">
                                            <mat-form-field class="w-1/3">
                                                    <mat-label>Fecha Inicio:</mat-label>   
                                                    <input matInput formControlName="dateStart" [matDatepicker]="emision" [min]="today" [value]="today" />
                                                    <mat-hint>MM/DD/YYYY</mat-hint>
                                                    <mat-datepicker-toggle matSuffix [for]="emision" ></mat-datepicker-toggle>
                                                    <mat-datepicker #emision ></mat-datepicker>
                                            </mat-form-field>   
                                            <mat-form-field class="w-1/3">
                                                    <mat-label>Fecha Fin:</mat-label>   
                                                    <input matInput formControlName="dateEnd" [matDatepicker]="fin" [min]="today" [value]="today" />
                                                    <mat-hint>MM/DD/YYYY</mat-hint>
                                                    <mat-datepicker-toggle matSuffix [for]="fin" ></mat-datepicker-toggle>
                                                    <mat-datepicker #fin ></mat-datepicker>
                                            </mat-form-field>                                                 
                                        </div>
                                    <!-- // -->                                        
                                 </div>
                                 <div *ngIf="flagInstallments == true" class="w-full">
                                        <mat-form-field class="w-1/2">
                                            <mat-label ><b>Cantidad de Cuotas:</b></mat-label>
                                            <input matInput number formControlName="totalInstallments"  (input)="numericOnly($event)"  (keypress)="numericOnly($event)"  (paste)="onPaste($event)" /> 
                                            
                                        </mat-form-field>
                                 </div>                                 
                            <!-- ////////////////////////////  -->                                
                            </div>
                            <!--//-->  
                            <!--//-->
                                    <div class="flex">
                                        <mat-form-field class="w-1/2">
                                            <mat-label>Dias de Vencimiento:</mat-label>
                                            <mat-select  formControlName="dueDate" >
                                                <ng-container *ngFor="let p of arrDueDate">
                                                    <mat-option [value]="p.cod"> {{p.gls}} </mat-option>
                                                </ng-container>
                                            </mat-select>  
                                            </mat-form-field>  
                                    </div>
                            <!--//-->  
                            <!--//-->      
                            <div class="flex">
                                    <mat-form-field class="w-1/2">
                                        <mat-label>Dia Emisión:</mat-label>
                                        <mat-select  formControlName="recurringDay" >
                                            <ng-container *ngFor="let p of arrmake">
                                                <mat-option [value]="p.cod"> {{p.gls}} </mat-option>
                                            </ng-container>
                                        </mat-select>  
                                        </mat-form-field>  
                                </div>
                        <!--//-->  
                        <!--//-->      
                        <div class="flex">
                                    <mat-form-field class="w-1/2">
                                        <mat-label> DTE:</mat-label>
                                        <mat-select  formControlName="codDoc" >
                                            <ng-container *ngFor="let p of arrDte">
                                                <mat-option [value]="p.cod"> {{p.gls}} </mat-option>
                                            </ng-container>
                                        </mat-select>  
                                        </mat-form-field>  
                                </div>
                        <!--//-->  
                        <!--//-->      
                        <div class="flex">
                                    <mat-form-field class="w-1/2">
                                        <mat-label>Renovación Automatica :</mat-label>
                                        <mat-select  formControlName="flagAtRenew" >
                                            <ng-container *ngFor="let p of arrOp">
                                                <mat-option [value]="p.cod"> {{p.gls}} </mat-option>
                                            </ng-container>
                                        </mat-select>  
                                        </mat-form-field>  
                                </div>
                        <!--//-->  
                        <!--//-->
                        <div class="">
                                <mat-form-field appearance="fill" class="w-1/2  fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet form-gen">
                                    <mat-label>Contacto</mat-label>
                                    <mat-select  formControlName="uuidContactoReceptor" (selectionChange)="setContactoReceptorMail($event.value)">
                                        <ng-container *ngFor="let f of customerRelatedContact">
                                            <mat-option [value]="f.UUID_CRM_CUSTOMER_CONTACT">{{f.FIRST_NAME}} {{f.LAST_NAME}}</mat-option>
                                        </ng-container> 
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="fill" class="w-1/3  fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet form-gen">
                                    <mat-label> Correo Contacto</mat-label>
                                    <input matInput  formControlName="contactoReceptorMail" readonly>
                                    <mat-icon matSuffix> alternate_email </mat-icon>
                                </mat-form-field>     
                                <div class="buttons" >
                                    <button mat-button color="primary" (click)="createContact()" > Crear Contacto </button>
                                </div>                               
                        </div>
                        <!--//-->                        
                        <!--//-->                                                                                              
                        <!--&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->          
                        <!--&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->                                                       
                    </mat-card-content>
                </mat-card>            
            <!-- ##########################################################  -->
    </form>            
    <div >
        <button mat-raised-button color="primary" (click)="addOOFF()" [disabled]="rcForm.invalid"> <mat-icon>add</mat-icon>Crear OOFF</button>
    </div>  
    <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->    
</div>
<!-- ///////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////////-->
<div *ngIf="data.type == 'edit'" class="bg-white rounded-lg p-4">
    <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
    <div class="w-full">
        <!-- ##########################################################  --> 
        <div class="grid grid-cols-10">   
            <div class="col-span-8">
                <!-- ## -->
                    <h2 class="prose prose-xl">
                            OOFF: <b>{{dataRC.COD_OOFF}}-{{dataRC.VERSION_COD_OOFF}} </b>
                            <mat-icon *ngIf="rcForm.value.statusRc == 'I'" class="text-yellow-400 icon-size-25" [svgIcon]="'heroicons_solid:minus-circle'"></mat-icon>
                            <mat-icon *ngIf="rcForm.value.statusRc == 'A'" class="text-green-400  icon-size-25" [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>   
                    </h2>                       
                    <h2 class="prose "> {{data.rut || '1-9' | rut:'DOTS_DASH'}} {{data.name}}</h2>   
                <!-- ## -->
            </div>
            <div class="col-span-1">
                <!-- ## -->
                <button class="close " mat-button (click)="closeBtn()"> <mat-icon>close</mat-icon></button>    
                <!-- ## -->
            </div>
        </div>
        <!-- ##########################################################  -->    

    </div>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<mat-divider class="py-2"></mat-divider>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->


<mat-tab-group #tabsrC  [(selectedIndex)]="matTabOOFF"  (selectedTabChange)="refreshOOFF($event,null)" >
 <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->      
 <mat-tab label="OOFF" >
 <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
        <form [formGroup]="rcForm">
                <!-- ##########################################################  -->
                <mat-card class="col-span-1" >
                    <mat-card-content class="h-full">
                        <!--#################################--> 
                        <mat-card-header>
                            <mat-card-title> Detalle OOFF </mat-card-title>
                        </mat-card-header>
                            <!--//-->
                            <div class="flex" *ngIf="dataPs.length > 0">
                                <mat-form-field class="w-1/2">
                                    <mat-label>Estado OOFF :</mat-label>
                                    <mat-select  formControlName="statusRc" >
                                        <ng-container *ngFor="let p of arrType">
                                            <mat-option [value]="p.cod"> {{p.gls}} </mat-option>
                                        </ng-container>
                                    </mat-select>  
                                    </mat-form-field>  
                            </div>                        
                            <!--//-->
                            <!--//-->                                                        
                            <div class="flex">
                                <mat-form-field class="w-1/3">
                                        <mat-label><b>Tipo:</b></mat-label>   
                                    <mat-select formControlName="flagInstallments" (selectionChange)="setFlagInstallments($event.value,null)">
                                        <mat-option value="Y">Cuotas</mat-option>
                                        <mat-option value="N">Rango Fechas</mat-option>
                                    </mat-select>  
                                </mat-form-field>   
                            </div>
                            <!-- ////////////////////////////  -->
                            <div class="flex" >
                                <div *ngIf="flagInstallments == false" class="w-full">
                                    <!-- // -->
                                        <div>
                                            <section >
                                                <mat-checkbox class="example-margin" (change)="setIndefinido($event.checked)">Indefinido</mat-checkbox>
                                            </section>
                                        </div>  
                                        <div class="flex">
                                            <mat-form-field class="w-1/3">
                                                    <mat-label>Fecha Inicio:</mat-label>   
                                                    <input matInput formControlName="dateStart" [matDatepicker]="emision" [min]="today" [value]="today" />
                                                    <mat-hint>MM/DD/YYYY</mat-hint>
                                                    <mat-datepicker-toggle matSuffix [for]="emision" ></mat-datepicker-toggle>
                                                    <mat-datepicker #emision ></mat-datepicker>
                                            </mat-form-field>   
                                            <mat-form-field class="w-1/3">
                                                    <mat-label>Fecha Fin:</mat-label>   
                                                    <input matInput formControlName="dateEnd" [matDatepicker]="fin" [min]="today" [value]="today" />
                                                    <mat-hint>MM/DD/YYYY</mat-hint>
                                                    <mat-datepicker-toggle matSuffix [for]="fin" ></mat-datepicker-toggle>
                                                    <mat-datepicker #fin ></mat-datepicker>
                                            </mat-form-field>                                                 
                                        </div>
                                    <!-- // -->                                        
                                </div>
                                <div *ngIf="flagInstallments == true" class="w-full">
                                        <mat-form-field class="w-1/2">
                                            <mat-label ><b>Cantidad de Cuotas:</b></mat-label>
                                            <input matInput number formControlName="numberInstallments"  readonly /> /
                                            <input matInput number formControlName="totalInstallments"  (input)="numericOnly($event)"  (keypress)="numericOnly($event)"  (paste)="onPaste($event)" /> 
                                            
                                        </mat-form-field>
                                </div>                                 
                            <!-- ////////////////////////////  -->                                
                            </div>
                            <!--//-->  
                            <div class="grid grid-cols-2">   
                                <div >
                                    <!-- ## -->
                                        <mat-form-field class="w-3/4">
                                            <mat-label>Dias de Vencimiento:</mat-label>
                                            <mat-select  formControlName="dueDate" >
                                                <ng-container *ngFor="let p of arrDueDate">
                                                    <mat-option [value]="p.cod"> {{p.gls}} </mat-option>
                                                </ng-container>
                                            </mat-select>  
                                            </mat-form-field>  
                                    <!-- ## -->
                                </div>
                                <div class="col-span-1">
                                    <!-- ## -->
                                    <mat-form-field class="w-3/4">
                                        <mat-label>Dia Emisión:</mat-label>
                                        <mat-select  formControlName="recurringDay" >
                                            <ng-container *ngFor="let p of arrmake">
                                                <mat-option [value]="p.cod"> {{p.gls}} </mat-option>
                                            </ng-container>
                                        </mat-select>  
                                        </mat-form-field>  
                                    <!-- ## -->
                                </div>
                            </div>                            
                            <!--//-->

                        <!--//-->  
                        <div class="grid grid-cols-2">   
                            <div >
                                <!-- ## -->
                                    <mat-form-field class="w-3/4">
                                        <mat-label>DTE:</mat-label>
                                        <mat-select  formControlName="codDoc" >
                                            <ng-container *ngFor="let p of arrDte">
                                                <mat-option [value]="p.cod"> {{p.gls}} </mat-option>
                                            </ng-container>
                                        </mat-select>  
                                        </mat-form-field>  
                                <!-- ## -->
                            </div>
                            <div class="col-span-1">
                                <!-- ## -->
                                    <mat-form-field class="w-3/4">
                                        <mat-label>Renovación Automatica :</mat-label>
                                        <mat-select  formControlName="flagAtRenew" >
                                            <ng-container *ngFor="let p of arrOp">
                                                <mat-option [value]="p.cod"> {{p.gls}} </mat-option>
                                            </ng-container>
                                        </mat-select>  
                                        </mat-form-field>  
                                <!-- ## -->
                            </div>
                        </div>                           
                        <!--//-->                                                                                               
                        <!--&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->          
                        <!--&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->                                                       
                    </mat-card-content>
                </mat-card>            
            <!-- ##########################################################  -->
        </form>            
        <div >
        <button mat-raised-button color="primary" (click)="udpOOFF()" [disabled]="rcForm.invalid"> <mat-icon>add</mat-icon>Actualizar OOFF</button>
        </div>  
        <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->   
        <br><br>
    <!-- ========================================================================================= -->
    <!-- ========================================================================================= -->
    <mat-tab-group #tabsrC  [(selectedIndex)]="matTabDteSelected"  (selectedTabChange)="refreshRc($event,null)" >
        <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->
        <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ --> 
        <mat-tab label="Productos" >
            <mat-tab-group>    
                    <mat-tab label="Vigentes" >
                            <!-- ========================================= -->
                            <!-- ========================================= -->            
                                    <div class="flex"> 
                                        <button mat-raised-button  color="primary" (click)="addPs()" > <mat-icon>add</mat-icon> Productos</button>
                                    </div>
                            <!-- ========================================= -->
                            <!-- ========================================= -->     
                            <mat-table [dataSource]="tablePs" class="mat-elevation-z8">
                                <!--//-->                    
                                <ng-container matColumnDef="COD_PS_SERVICES">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">SKU</mat-header-cell>
                                    <mat-cell *matCellDef="let o">
                                        <!-- <small><b>{{o.COD_PS_SERVICES}}</b></small> -->
                                        <button  style=" white-space: pre-wrap !important;" mat-button color="primary" (click)="editOrCreateProduct(o.UUID_PS_SERVICES)" > 
                                            <small><b>{{o.NAME_PS_SERVICES}}</b><br><b style="color: rgb(9, 9, 132)">{{o.COD_PS_SERVICES}}</b></small>
                                        </button> 
                                    </mat-cell>
                                </ng-container>                        
                                <!--//-->                       
                                <ng-container matColumnDef="NAME_CURRENCY">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Moneda</mat-header-cell>
                                    <mat-cell *matCellDef="let o"><b>{{o.NAME_CURRENCY}}</b></mat-cell>
                                </ng-container>
                                <!--//-->                       
                                <ng-container matColumnDef="PRICE">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Neto</mat-header-cell>
                                    <mat-cell *matCellDef="let o">
                                                                        <div *ngIf="o.FLAG_OVERWRITE_PRICE == 'N'"> {{o.SYMBOL_CURRENCY}} {{o.SALES_PRICE | number}}</div>
                                                                        <div *ngIf="o.FLAG_OVERWRITE_PRICE == 'Y'"> {{o.SYMBOL_CURRENCY}} {{o.OVERWRITE_PRICE | number}}(*)</div>
                                    </mat-cell>
                                </ng-container>
                                <!--//-->                      
                                <ng-container matColumnDef="DISCOUNT">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Descuento %</mat-header-cell>
                                    <mat-cell *matCellDef="let o">
                                                                        <div>{{o.OVERWRITE_DISCOUNT | percent}}</div>
                                    </mat-cell>
                                </ng-container>
                                <!--//-->                              
                                <ng-container matColumnDef="DATE_DISCOUNT">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Vigencia Descuento</mat-header-cell>
                                    <mat-cell *matCellDef="let o">{{o.DATE_START_DISCOUNT | date:'dd/MM/yy'}} {{o.DATE_END_DISCOUNT | date:'dd/MM/yy'}}</mat-cell>
                                </ng-container>
                                <!--//-->                              
                                <ng-container matColumnDef="GLS">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Descripción</mat-header-cell>
                                    <mat-cell *matCellDef="let o">
                                                                        <div > {{o.OVERWRITE_DESCRIPTION}}</div>
                                    </mat-cell>
                                </ng-container>
                                <!--//-->
                                <ng-container matColumnDef="QUANTITY_PS_SERVICES">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Cantidad</mat-header-cell>
                                    <mat-cell *matCellDef="let o"><small>{{o.QUANTITY_PS_SERVICES}}</small></mat-cell>
                                </ng-container>
                                <!--//-->   
                                <ng-container matColumnDef="TOTAL">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Total Neto</mat-header-cell>
                                    <mat-cell *matCellDef="let o">
                                                                    <!-- <div *ngIf="o.FLAG_OVERWRITE_PRICE == 'N'"> <b>{{o.SYMBOL_CURRENCY}} {{o.SALES_PRICE     * o.QUANTITY_PS_SERVICES | number}}</b>   </div>
                                                                    <div *ngIf="o.FLAG_OVERWRITE_PRICE == 'Y'"> <b>{{o.SYMBOL_CURRENCY}} {{o.OVERWRITE_PRICE * o.QUANTITY_PS_SERVICES | number}}</b>(*)</div>   -->
                                                                
                                                                    <span *ngIf="o.COD_CURRENCY == 'CLP'; else currencyTotal ">
                                                                        <!-- $$ -->  
                                                                        <b>{{o.SUB_TOTAL | currency:'CLP':'symbol-narrow'}}</b>  
                                                                    </span>
                                                                    <ng-template #currencyTotal>
                                                                        <!-- ## -->
                                                                        <span>
                                                                        <b> {{o.SYMBOL_CURRENCY}} {{ o.SUB_TOTAL | number : '1.0-2'}}</b> 
                                                                        </span>
                                                                        <!-- //-->                          
                                                                    </ng-template>                                                         
                                    </mat-cell>
                                </ng-container>
                                <!--//-->                       
                                <ng-container matColumnDef="WAREHOUSE">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Bodega</mat-header-cell>
                                    <mat-cell *matCellDef="let o"><small>{{o.COD_WAREHOUSE}} {{o.NAME_WAREHOUSE}}</small></mat-cell>
                                </ng-container>
                                <!--//-->   
                                <ng-container matColumnDef="EMPLOYEE_CREATE">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Creado</mat-header-cell>
                                    <mat-cell *matCellDef="let o"><small>{{o.EMPLOYEE_CREATE.FIRST_NAME}} {{o.EMPLOYEE_CREATE.LAST_NAME}}</small></mat-cell>
                                </ng-container>
                                <!--//-->   
                                <ng-container matColumnDef="EMPLOYEE_UPDATE">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Actualizado</mat-header-cell>
                                    <mat-cell *matCellDef="let o"><small>{{o.EMPLOYEE_UPDATE.FIRST_NAME}} {{o.EMPLOYEE_UPDATE.LAST_NAME}}</small></mat-cell>
                                </ng-container>
                                <!--//-->                                
                                <ng-container matColumnDef="DATE_CREATION">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Fecha Creación</mat-header-cell>
                                    <mat-cell *matCellDef="let o">{{o.DATE_CREATION | date:'dd/MM/yy'}}</mat-cell>
                                </ng-container>
                                <!--//-->                        
                                <ng-container matColumnDef="DATE_UPDATE">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">Fecha Actualizacion</mat-header-cell>
                                    <mat-cell *matCellDef="let o">{{o.DATE_UPDATE | date:'dd/MM/yy'}}</mat-cell>
                                </ng-container>                    
                                <!--//-->                     
                                <ng-container matColumnDef="acc">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-add"></mat-header-cell>
                                    <mat-cell *matCellDef="let o">
                                        <!-- //////////////////////////////////////////////////  -->
                                            <div ><button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button (click)="delPs(o)"> <mat-icon class="icon-size-5" color="warn" [svgIcon]="'delete'"></mat-icon> </button></div>
                                        <!-- //////////////////////////////////////////////////  -->                                    
                                    </mat-cell>
                                </ng-container>
                                <!--//-->               
                                <mat-header-row *matHeaderRowDef="displayedPs" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                                <mat-row *matRowDef="let row;columns: displayedPs" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                                <!--//-->                    
                            </mat-table>
                                <!--//-->      
                                <mat-spinner *ngIf="isLoadingPs" style="margin:0 auto"></mat-spinner>         
                                <mat-paginator
                                #pagPs
                                [pageSize]="pagSelect"
                                showFirstLastButtons
                                [pageSizeOptions]="pageSizeOptions" 
                                aria-label="Select page of periodic elements">
                            </mat-paginator>               
                            <!--//-->  
                            <small><b>(*) Precio Sobrescrito</b></small>
                    </mat-tab>
                    <!-- $$ -->
                    <mat-tab label="No Vigentes" >
                        <mat-table [dataSource]="tablePsDel" class="mat-elevation-z8">
                            <!--//-->                    
                            <ng-container matColumnDef="COD_PS_SERVICES">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">SKU</mat-header-cell>
                                <mat-cell *matCellDef="let o">
                                        
                                        <button  style=" white-space: pre-wrap !important;" mat-button color="primary" (click)="editOrCreateProduct(o.UUID_PS_SERVICES)" > 
                                            <small><b>{{o.NAME_PS_SERVICES}}</b><br><b style="color: rgb(9, 9, 132)">{{o.COD_PS_SERVICES}}</b></small>
                                        </button> 
                                    </mat-cell>
                            </ng-container>                        
                            <!--//-->                        
                            <ng-container matColumnDef="NAME_PS_SERVICES">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Nombre</mat-header-cell>
                                <mat-cell *matCellDef="let o"><small>{{o.NAME_PS_SERVICES}}</small></mat-cell>
                            </ng-container>
                            <!--//-->                       
                            <ng-container matColumnDef="NAME_CURRENCY">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Moneda</mat-header-cell>
                                <mat-cell *matCellDef="let o"><b>{{o.NAME_CURRENCY}}</b></mat-cell>
                            </ng-container>
                            <!--//-->                       
                            <ng-container matColumnDef="PRICE">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Neto</mat-header-cell>
                                <mat-cell *matCellDef="let o">
                                                                    <div *ngIf="o.FLAG_OVERWRITE_PRICE == 'N'"> {{o.SYMBOL_CURRENCY}} {{o.SALES_PRICE | number}}</div>
                                                                    <div *ngIf="o.FLAG_OVERWRITE_PRICE == 'Y'"> {{o.SYMBOL_CURRENCY}} {{o.OVERWRITE_PRICE | number}}(*)</div>
                                </mat-cell>
                            </ng-container>
                                <!--//-->                      
                                <ng-container matColumnDef="DISCOUNT">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Descuento %</mat-header-cell>
                                    <mat-cell *matCellDef="let o">
                                                                        <div>{{o.OVERWRITE_DISCOUNT | percent}}</div>
                                    </mat-cell>
                                </ng-container>
                                <!--//-->                              
                                <ng-container matColumnDef="DATE_DISCOUNT">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Vigencia Descuento</mat-header-cell>
                                    <mat-cell *matCellDef="let o">{{o.DATE_START_DISCOUNT | date:'dd/MM/yy'}} {{o.DATE_END_DISCOUNT | date:'dd/MM/yy'}}</mat-cell>
                                </ng-container>
                                <!--//-->                      
                                <ng-container matColumnDef="GLS">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Descripción</mat-header-cell>
                                    <mat-cell *matCellDef="let o">
                                                                        <div > {{o.OVERWRITE_DESCRIPTION}}</div>
                                    </mat-cell>
                                </ng-container>
                                <!--//-->  
                            <ng-container matColumnDef="QUANTITY_PS_SERVICES">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Cantidad</mat-header-cell>
                                <mat-cell *matCellDef="let o"><small>{{o.QUANTITY_PS_SERVICES}}</small></mat-cell>
                            </ng-container>
                            <!--//-->   
                            <ng-container matColumnDef="TOTAL">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Total Neto</mat-header-cell>
                                <mat-cell *matCellDef="let o">
                                                                    <!-- <div *ngIf="o.FLAG_OVERWRITE_PRICE == 'N'"> <b>{{o.SYMBOL_CURRENCY}} {{o.SALES_PRICE     * o.QUANTITY_PS_SERVICES | number}}</b>   </div>
                                                                    <div *ngIf="o.FLAG_OVERWRITE_PRICE == 'Y'"> <b>{{o.SYMBOL_CURRENCY}} {{o.OVERWRITE_PRICE * o.QUANTITY_PS_SERVICES | number}}</b>(*)</div>   -->
                                                                
                                                                    <span *ngIf="o.COD_CURRENCY == 'CLP'; else currencyTotal ">
                                                                        <!-- $$ -->  
                                                                        <b>{{o.SUB_TOTAL | currency:'CLP':'symbol-narrow'}}</b>  
                                                                    </span>
                                                                    <ng-template #currencyTotal>
                                                                        <!-- ## -->
                                                                        <span>
                                                                        <b> {{o.SYMBOL_CURRENCY}} {{ o.SUB_TOTAL | number : '1.0-2'}}</b> 
                                                                        </span>
                                                                        <!-- //-->                          
                                                                    </ng-template>    
                                </mat-cell>
                            </ng-container>
                            <!--//-->                       
                            <ng-container matColumnDef="WAREHOUSE">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Bodega</mat-header-cell>
                                <mat-cell *matCellDef="let o"><small>{{o.COD_WAREHOUSE}} {{o.NAME_WAREHOUSE}}</small></mat-cell>
                            </ng-container>
                            <!--//-->   
                            <ng-container matColumnDef="EMPLOYEE_CREATE">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Creado</mat-header-cell>
                                <mat-cell *matCellDef="let o"><small>{{o.EMPLOYEE_CREATE.FIRST_NAME}} {{o.EMPLOYEE_CREATE.LAST_NAME}}</small></mat-cell>
                            </ng-container>
                            <!--//-->   
                            <ng-container matColumnDef="EMPLOYEE_UPDATE">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Actualizado</mat-header-cell>
                                <mat-cell *matCellDef="let o"><small>{{o.EMPLOYEE_UPDATE.FIRST_NAME}} {{o.EMPLOYEE_UPDATE.LAST_NAME}}</small></mat-cell>
                            </ng-container>
                            <!--//-->                                
                            <ng-container matColumnDef="DATE_CREATION">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Fecha Creación</mat-header-cell>
                                <mat-cell *matCellDef="let o">{{o.DATE_CREATION | date:'dd/MM/yy'}}</mat-cell>
                            </ng-container>
                            <!--//-->                        
                            <ng-container matColumnDef="DATE_UPDATE">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">Fecha Actualizacion</mat-header-cell>
                                <mat-cell *matCellDef="let o">{{o.DATE_UPDATE | date:'dd/MM/yy'}}</mat-cell>
                            </ng-container>                    
                            <!--//-->                     
                            <mat-header-row *matHeaderRowDef="displayedPsDel" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                            <mat-row *matRowDef="let row;columns: displayedPsDel" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                            <!--//-->                    
                        </mat-table>
                            <!--//-->      
                            <mat-spinner *ngIf="isLoadingPsDel" style="margin:0 auto"></mat-spinner>         
                            <mat-paginator
                            #pagPsDel
                            [pageSize]="pagSelect"
                            showFirstLastButtons
                            [pageSizeOptions]="pageSizeOptions" 
                            aria-label="Select page of periodic elements">
                        </mat-paginator>               
                        <!--//-->  
                        <small><b>(*) Precio Sobrescrito</b></small>
                    </mat-tab>
            </mat-tab-group>
        </mat-tab>
        <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->
        <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->   
        <mat-tab label="Descuentos Globales" >
            <mat-tab-group>             
                <mat-tab label="Vigentes" >
            <!-- ========================================= -->
            <!-- ========================================= -->       
                  <!--
                    agregar vrear descuento!!! 
                    <div class="flex"> 
                            <button mat-raised-button  color="primary" (click)="addContact()" > <mat-icon>add</mat-icon> Contacto</button>
                            <button mat-button color="primary" (click)="createContact()" > Crear Contacto </button>
                    </div>
                    -->
            <!-- ========================================= -->
            <!-- ========================================= -->
                    <mat-table [dataSource]="tableDiscount" class="mat-elevation-z8">
                        <!--//-->                    
                        <ng-container matColumnDef="DISCOUNT">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Tipo</mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                            <div *ngIf="o.TYPE_RC_DISCOUNT == 'P'"><b>{{o.DISCOUNT | percent: '1.2' }}</b></div>
                                            <div *ngIf="o.TYPE_RC_DISCOUNT == 'A'"><b>{{o.DISCOUNT | currency:'CLP':'symbol-narrow'}}</b></div>
                            </mat-cell>
                        </ng-container>                        
                        <!--//-->   
                        <ng-container matColumnDef="GLS_RC_DISCOUNT">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Glosa</mat-header-cell>
                            <mat-cell *matCellDef="let o"><small>{{o.GLS_RC_DISCOUNT}}</small></mat-cell>
                        </ng-container>
                        <!--//-->                       
                        <ng-container matColumnDef="DATE_START">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Inicio</mat-header-cell>
                            <mat-cell *matCellDef="let o"><small>{{o.DATE_START  | date:'dd/MM/yy'}}</small></mat-cell>
                        </ng-container>
                        <!--//-->   
                        <ng-container matColumnDef="DATE_END">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Fin</mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                                <small *ngIf="o.DATE_END == '9999-12-31'">Indefinido</small>
                                                <small *ngIf="o.DATE_END != '9999-12-31'">{{o.DATE_END  | date:'dd/MM/yy'}}</small>
                                    </mat-cell>
                        </ng-container>
                        <!--//-->   
                        <ng-container matColumnDef="DATE_CREATION">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Creado</mat-header-cell>
                            <mat-cell *matCellDef="let o"><small>{{o.DATE_CREATION  | date:'dd/MM/yy'}}</small></mat-cell>
                        </ng-container>
                        <!--//-->   
                        <ng-container matColumnDef="EMPLOYEE_CREATE">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Colaborador</mat-header-cell>
                            <mat-cell *matCellDef="let o"><small>{{o.EMPLOYEE_CREATE.FIRST_NAME}} {{o.EMPLOYEE_CREATE.LAST_NAME}}</small></mat-cell>
                        </ng-container>
                        <!--//-->                                    
                        <ng-container matColumnDef="acc">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-add"></mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                <!-- //////////////////////////////////////////////////  -->
                                    <div><button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button (click)="deleteDiscount(o)"> <mat-icon class="icon-size-5" color="warn" [svgIcon]="'delete'"></mat-icon> </button></div>
                                <!-- //////////////////////////////////////////////////  -->                                    
                            </mat-cell>
                        </ng-container>
                        <!--//-->               
                        <mat-header-row *matHeaderRowDef="displayedDiscount" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                        <mat-row *matRowDef="let row;columns: displayedDiscount" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                        <!--//-->                    
                    </mat-table>
                        <!--//-->      
                        <mat-spinner *ngIf="isLoadingDiscount" style="margin:0 auto"></mat-spinner>         
                        <mat-paginator
                        #pagDiscount
                        [pageSize]="pagSelect"
                        showFirstLastButtons
                        [pageSizeOptions]="pageSizeOptions" 
                        aria-label="Select page of periodic elements">
                    </mat-paginator>               
                    <!--//-->                 
                </mat-tab>         
                <!-- $$ -->   
                <mat-tab label="No Vigentes" >
            <!-- ========================================= -->
            <!-- ========================================= -->       

                    <mat-table [dataSource]="tableDiscountDelete" class="mat-elevation-z8">
                        <!--//-->                    
                        <ng-container matColumnDef="DISCOUNT">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Tipo</mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                            <div *ngIf="o.TYPE_RC_DISCOUNT == 'P'"><b>{{o.DISCOUNT | percent: '1.2' }}</b></div>
                                            <div *ngIf="o.TYPE_RC_DISCOUNT == 'A'"><b>{{o.DISCOUNT | currency:'CLP':'symbol-narrow'}}</b></div>
                            </mat-cell>
                        </ng-container>                        
                        <!--//-->   
                        <ng-container matColumnDef="GLS_RC_DISCOUNT">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Glosa</mat-header-cell>
                            <mat-cell *matCellDef="let o"><small>{{o.GLS_RC_DISCOUNT}}</small></mat-cell>
                        </ng-container>
                        <!--//-->                       
                        <ng-container matColumnDef="DATE_START">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Inicio</mat-header-cell>
                            <mat-cell *matCellDef="let o"><small>{{o.DATE_START  | date:'dd/MM/yy'}}</small></mat-cell>
                        </ng-container>
                        <!--//-->   
                        <ng-container matColumnDef="DATE_END">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Fin</mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                        <small *ngIf="o.DATE_END == '9999-12-31'">Indefinido</small>
                                        <small *ngIf="o.DATE_END != '9999-12-31'">{{o.DATE_END  | date:'dd/MM/yy'}}</small>
                            </mat-cell>
                        </ng-container>
                        <!--//-->   
                        <ng-container matColumnDef="DATE_CREATION">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Creado</mat-header-cell>
                            <mat-cell *matCellDef="let o"><small>{{o.DATE_CREATION  | date:'dd/MM/yy'}}</small></mat-cell>
                        </ng-container>
                        <!--//-->   
                        <ng-container matColumnDef="EMPLOYEE_CREATE">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Colaborador</mat-header-cell>
                            <mat-cell *matCellDef="let o"><small>{{o.EMPLOYEE_CREATE.FIRST_NAME}} {{o.EMPLOYEE_CREATE.LAST_NAME}}</small></mat-cell>
                        </ng-container>
                        <!--//-->                                    
                        <ng-container matColumnDef="acc">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-add"></mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                <!-- //////////////////////////////////////////////////  -->
                                    <div><button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button (click)="deleteDiscount(o)"> <mat-icon class="icon-size-5" color="warn" [svgIcon]="'delete'"></mat-icon> </button></div>
                                <!-- //////////////////////////////////////////////////  -->                                    
                            </mat-cell>
                        </ng-container>
                        <!--//-->               
                        <mat-header-row *matHeaderRowDef="displayeDiscountDelete" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                        <mat-row *matRowDef="let row;columns: displayeDiscountDelete" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                        <!--//-->                    
                    </mat-table>
                        <!--//-->      
                        <mat-spinner *ngIf="isLoadingDiscount" style="margin:0 auto"></mat-spinner>         
                        <mat-paginator
                        #pagDiscountDelete
                        [pageSize]="pagSelect"
                        showFirstLastButtons
                        [pageSizeOptions]="pageSizeOptions" 
                        aria-label="Select page of periodic elements">
                    </mat-paginator>               
                    <!--//-->                 
                </mat-tab> 
            </mat-tab-group>
        </mat-tab>                 
        <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->
        <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->                       
        <mat-tab label="Contactos" >
                <!-- ========================================= -->
                <!-- ========================================= -->            
                        <div class="flex"> 
                                <button mat-raised-button  color="primary" (click)="addContact()" > <mat-icon>add</mat-icon> Contacto</button>
                                <button mat-button color="primary" (click)="createContact()" > Crear Contacto </button>
                        </div>
                <!-- ========================================= -->
                <!-- ========================================= -->
                        <mat-table [dataSource]="tableContact" class="mat-elevation-z8">
                            <!--//-->                    
                            <ng-container matColumnDef="type">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Tipo</mat-header-cell>
                                <mat-cell *matCellDef="let o"><b>{{o.GLS_CONTACT_TYPE}}</b></mat-cell>
                            </ng-container>                        
                            <!--//-->                        
                            <ng-container matColumnDef="legalNumber">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Rut</mat-header-cell>
                                <mat-cell *matCellDef="let o"><small>{{o.LEGAL_NUMBER || '1-9' | rut:'DOTS_DASH'}}</small></mat-cell>
                            </ng-container>
                            <!--//-->   
                            <ng-container matColumnDef="firstName">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Nombre</mat-header-cell>
                                <mat-cell *matCellDef="let o"><small>{{o.FIRST_NAME}} {{o.FIRST_NAME2}}</small></mat-cell>
                            </ng-container>
                            <!--//-->   
                            <ng-container matColumnDef="lastName">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Apellido</mat-header-cell>
                                <mat-cell *matCellDef="let o"><small>{{o.LAST_NAME}} {{o.LAST_NAME2}}</small></mat-cell>
                            </ng-container>
                            <!--//-->   
                            <ng-container matColumnDef="phone">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Telefono</mat-header-cell>
                                <mat-cell *matCellDef="let o"><small>{{o.PHONE}}</small></mat-cell>
                            </ng-container>
                            <!--//-->             
                            <ng-container matColumnDef="mail">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Email</mat-header-cell>
                                <mat-cell *matCellDef="let o">
                                            <div style="color: rgb(9, 9, 132)"><b>{{o.MAIL}}</b></div>
                                    </mat-cell>
                            </ng-container>                 
                            <!--//-->                        
                            <ng-container matColumnDef="acc">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-add"></mat-header-cell>
                                <mat-cell *matCellDef="let o">
                                    <!-- //////////////////////////////////////////////////  -->
                                        <div><button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button (click)="deleteContact(o)"> <mat-icon class="icon-size-5" color="warn" [svgIcon]="'delete'"></mat-icon> </button></div>
                                    <!-- //////////////////////////////////////////////////  -->                                    
                                </mat-cell>
                            </ng-container>
                            <!--//-->               
                            <mat-header-row *matHeaderRowDef="displayedContact" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                            <mat-row *matRowDef="let row;columns: displayedContact" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                            <!--//-->                    
                        </mat-table>
                            <!--//-->      
                            <mat-spinner *ngIf="isLoadingContact" style="margin:0 auto"></mat-spinner>         
                            <mat-paginator
                            #pagContact
                            [pageSize]="pagSelect"
                            showFirstLastButtons
                            [pageSizeOptions]="pageSizeOptions" 
                            aria-label="Select page of periodic elements">
                        </mat-paginator>               
                        <!--//-->                 
        </mat-tab>
        <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->
        <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->        
        <mat-tab label="Envio" >
            <form [formGroup]="rcForm">
                <!-- ##########################################################  -->
                    <!--//-->      
                    <div class="flex">
                        <mat-form-field class="w-1/2">
                            <mat-label>Glosa Adicional en Factura :</mat-label>
                            <mat-select  formControlName="flagAdicional"  (selectionChange)="enableDisable('flagAdicional',$event.value)">
                                <ng-container *ngFor="let p of arrOp">
                                    <mat-option [value]="p.cod"> {{p.gls}} </mat-option>
                                </ng-container>
                            </mat-select>  
                            </mat-form-field>  
                            <mat-form-field class="w-1/5">
                                <mat-label>Glosa Adicional:</mat-label>                               
                                <input matInput formControlName="glosaAdicional"  [disabled]="(rcForm.get('flagAdicional').value)=='N'"  minlength="3" maxlength="80"   />    
                            </mat-form-field>                              
                    </div>
                    <br>
                    <!--//-->  
                    <div class="grid grid-cols-2">   
                        <div>
                            <mat-form-field style="width: 450px;">
                                <mat-label>Correo :</mat-label>                               
                                                <textarea matInput
                                                formControlName="txtToSend" 
                                                cdkTextareaAutosize
                                                #autosize="cdkTextareaAutosize"
                                                cdkAutosizeMinRows="5"
                                                cdkAutosizeMaxRows="15"
                                                rows="5" cols="40"
                                                ></textarea>
                            </mat-form-field> 
                        </div>
                        <div>
                           
                                <h2 style="color: rgb(0, 32, 96);"><b>Puede Utilizar las variales:</b></h2>
                                <ul>
                                    <li  style="color: rgb(0, 32, 96);background-color:rgb(255, 242, 204);border-style: dotted;">%_TXT_CONTACTOS%</li>
                                    <li  style="color: rgb(0, 32, 96);background-color:rgb(255, 242, 204);border-style: dotted;">%_TXT_FOLIO%</li>
                                    <li  style="color: rgb(0, 32, 96);background-color:rgb(255, 242, 204);border-style: dotted;">%_TXT_TOTAL%</li>
                                    <li  style="color: rgb(0, 32, 96);background-color:rgb(255, 242, 204);border-style: dotted;">%_TXT_DETALLE%</li>
                                    <li  style="color: rgb(0, 32, 96);background-color:rgb(255, 242, 204);border-style: dotted;">%_TXT_FECHA_EMISION%</li>
                                    <li  style="color: rgb(0, 32, 96);background-color:rgb(255, 242, 204);border-style: dotted;">%_TXT_FECHA_VENCIMIENTO%</li>
                                </ul>
                           
                        </div>
                    </div>
                    <!--//-->                      
                <!-- ##########################################################  -->
            </form>   
            <div >
                <button mat-raised-button color="primary" (click)="udpOOFF()" [disabled]="rcForm.invalid"> <mat-icon>add</mat-icon>Actualizar Propiedades</button>
                </div>               
        </mat-tab>
        <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->
        <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->        
        <mat-tab label="Atributos" >
            <form [formGroup]="rcAttributesTypeForm">
                <!-- ========================================= -->
                <!-- ========================================= -->        
                    <div class="flex">
                        <mat-form-field class="w-1/2">
                            <mat-label>Tipo Factura:</mat-label>
                            <mat-select  formControlName="idRcAttributesType" (selectionChange)="setRcAttributesType($event.value)">
                                <ng-container *ngFor="let p of rcAttributesType">
                                    <mat-option [value]="p.ID_RC_ATTRIBUTES_TYPE">{{p.COD_RC_ATTRIBUTES_TYPE}}-{{p.NAME_RC_ATTRIBUTES_TYPE}} </mat-option>
                                </ng-container>
                            </mat-select>  
                        </mat-form-field>  
                            <button mat-raised-button [disabled]="rcAttributesTypeSelected.length > 0" color="primary" (click)="addAttributes()" > <mat-icon>add</mat-icon> Attributo</button>
                    </div>
                <!-- ========================================= -->
                <!-- ========================================= -->
                            <!-- *************************************** -->
                                <mat-table [dataSource]="tableAttributes" class="mat-elevation-z8">
                                    <!--//-->                    
                                    <ng-container matColumnDef="FLAG_RECURRENT">
                                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Tipo</mat-header-cell>
                                        <mat-cell *matCellDef="let o">
                                                                <div *ngIf="o.FLAG_RECURRENT == 'Y'"><b>{{o.DATE_RC_ATTRIBUTES_TYPE | date}}</b></div>
                                                                <div *ngIf="o.FLAG_RECURRENT == 'N'"><b>Fijo</b></div>
                                        </mat-cell>
                                    </ng-container>                        
                                    <!--//-->                        
                                    <ng-container matColumnDef="VALUE_RC_ATTRIBUTES_TYPE">
                                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Rut</mat-header-cell>
                                        <mat-cell *matCellDef="let o"><small>{{o.VALUE_RC_ATTRIBUTES_TYPE}}</small></mat-cell>
                                    </ng-container>               
                                    <!--//-->                                    
                                    <ng-container matColumnDef="DATE_CREATE">
                                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Fecha Creación</mat-header-cell>
                                        <mat-cell *matCellDef="let o">{{o.DATE_CREATE | date:'dd/MM/yy'}}</mat-cell>
                                    </ng-container>
                                    <!--//-->                                                        
                                    <ng-container matColumnDef="acc">
                                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-add"></mat-header-cell>
                                        <mat-cell *matCellDef="let o">
                                            <!-- //////////////////////////////////////////////////  -->
                                                <div ><button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button (click)="deleteAttributes(o)"> <mat-icon class="icon-size-5" color="warn" [svgIcon]="'delete'"></mat-icon> </button></div>
                                            <!-- //////////////////////////////////////////////////  -->                                    
                                        </mat-cell>
                                    </ng-container>
                                    <!--//-->               
                                    <mat-header-row *matHeaderRowDef="displayedAttributes" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                                    <mat-row *matRowDef="let row;columns: displayedAttributes" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                                    <!--//-->                    
                                </mat-table>
                                    <!--//-->      
                                    <mat-spinner *ngIf="isLoadingAttributes" style="margin:0 auto"></mat-spinner>         
                                    <mat-paginator
                                    #pagAttributes
                                    [pageSize]="pagSelect"
                                    showFirstLastButtons
                                    [pageSizeOptions]="pageSizeOptions" 
                                    aria-label="Select page of periodic elements">
                                </mat-paginator>               
                                <!--//-->                                      
                            <!-- *************************************** --> 
                <!-- ========================================= -->
                <!-- ========================================= -->                               
            </form>
        </mat-tab>
        <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->
        <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->          
        <mat-tab label="Historial" >
            <!-- ================================================================== -->
            <div>
                <h2 class="mat-h2">Historial</h2>
                    <mat-table [dataSource]="tableMessage" class="mat-elevation-z8">
                    <!--//-->  
                        <ng-container matColumnDef="employeeName">
                        <mat-header-cell *matHeaderCellDef  class="font-bold uppercase text-sm text-gray-600"> Colaborador </mat-header-cell>
                        <mat-cell *matCellDef="let s"> {{s.EMPLOYEE_DATA.FIRST_NAME}} {{s.EMPLOYEE_DATA.LAST_NAME}} </mat-cell>
                        </ng-container>
                    <!--//-->                      
                        <ng-container matColumnDef="message">
                        <mat-header-cell *matHeaderCellDef  class="font-bold uppercase text-sm text-gray-600"> Mensaje </mat-header-cell>
                        <mat-cell *matCellDef="let s"> 
                                                        <div  [innerHTML]="s.TXT_RC_MESSAGE"></div>
                        </mat-cell>
                        </ng-container>
                    <!--//-->                          
                        <ng-container matColumnDef="date">
                        <mat-header-cell *matHeaderCellDef  class="font-bold uppercase text-sm text-gray-600"> Fecha </mat-header-cell>
                        <mat-cell *matCellDef="let s"> {{s.DATE_CREATION |  date:'dd-MM-yyyy HH:mm:ss'}} </mat-cell>
                        </ng-container>
                    <!--//-->  
                    <ng-container matColumnDef="tipo">
                        <mat-header-cell *matHeaderCellDef  class="font-bold uppercase text-sm text-gray-600"> Tipo </mat-header-cell>
                        <mat-cell *matCellDef="let s"> 
                            <div *ngIf=" s.FLAG_RC_MESSAGE_PUBLIC == 'Y' ">Publica</div>
                            <div *ngIf=" s.FLAG_RC_MESSAGE_PUBLIC == 'N' ">Privada</div>
                        </mat-cell>
                        </ng-container>                                    
                    <!--//-->                                                                 
                        <!-- Columnas de la tabla -->
                        <mat-header-row *matHeaderRowDef="displayedMessage" class="bg-gray-100 border-b border-gray-200" ></mat-header-row>
                        <mat-row *matRowDef="let row;columns: displayedMessage" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>                            
                    </mat-table>
                    <!--//-->      
                    <mat-spinner *ngIf="isLoadingMessage" style="margin:0 auto"></mat-spinner>         
                    <mat-paginator
                    #pagMessage
                    [pageSize]="pagSelect"
                    showFirstLastButtons
                    [pageSizeOptions]="pageSizeOptions" 
                    aria-label="Select page of periodic elements">
                </mat-paginator>  
            </div>             
            <!-- ================================================================== -->            
        </mat-tab> 
    <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->
    <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->                      
    </mat-tab-group>
    <!-- ========================================================================================= -->
    <!-- ========================================================================================= -->        
</mat-tab>
 <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
 <mat-tab label="Ordenes" >
<!-- ================================================================== -->
        <!--########################################################################-->
        <div class="flex">
            <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/4">
                <mat-label>Mes a mostrar</mat-label>
                <mat-select #monthSelect [(value)]="monthSelected">
                    <mat-option *ngFor="let month of months" [value]="month.value" (click)="ngOnInit()">
                        {{month.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field> 

            <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/4">
                <mat-label>Año a mostrar</mat-label>
                <mat-select #monthSelect [(value)]="yearSelected">
                    <mat-option *ngFor="let year of last5Years" [value]="year" (click)="ngOnInit()">
                        {{year}}
                    </mat-option>
                </mat-select>
            </mat-form-field> 

            <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/4">
                <mat-label>Buscar</mat-label>
                <input matInput (keyup)="applyFilter($event,'order')" placeholder="Buscar..." #input>
                <mat-icon matPrefix> find_in_page </mat-icon> 
            </mat-form-field> 
        </div>        
    <!--########################################################################-->
            <div>
                <h2 class="mat-h2">Ordenes</h2>
                <mat-table [dataSource]="tableOrder" class="mat-elevation-z8">
                    <!--//-->                    
                    <ng-container matColumnDef="FRIENLY_EOM_ORDER">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-sku">Orden</mat-header-cell>
                        <mat-cell *matCellDef="let s"><b>{{s.FRIENLY_EOM_ORDER}}</b></mat-cell>
                    </ng-container>
                    <!--//-->
                    <ng-container matColumnDef="TOTAL">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Total</mat-header-cell>
                        <mat-cell *matCellDef="let s">
                                        <!-- ////////////////////////////////////////////////-->
                                            <small *ngIf="s.FLAG_PAYMENT == 'Y'">
                                                <div *ngIf="s.COD_REASON_CODE == 'AO'">
                                                    <mat-icon class="text-yellow-400 icon-size-5" [svgIcon]="'heroicons_solid:minus-circle'"></mat-icon>
                                                    <b>{{s.TOTAL | currency:'CLP':'symbol-narrow'}}</b>
                                                </div>  
                                                <div *ngIf="s.COD_REASON_CODE != 'AO'">
                                                    <mat-icon class="text-green-400 icon-size-5" [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
                                                    <b>{{s.TOTAL | currency:'CLP':'symbol-narrow'}}</b>
                                                </div>                                                                     
                                            </small>
                                            <small *ngIf="s.FLAG_PAYMENT != 'Y'">
                                                <mat-icon class="text-red-400 icon-size-5" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
                                                {{s.TOTAL | currency:'CLP':'symbol-narrow'}} 
                                            </small>
                                        <!-- ////////////////////////////////////////////////-->                                            
                        </mat-cell>
                    </ng-container>                        
                    <!--//-->     
                    <ng-container matColumnDef="NAME_COD_REASON_CODE">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-quantity">Estado</mat-header-cell>
                        <mat-cell *matCellDef="let s">{{s.NAME_COD_REASON_CODE | titlecase}}</mat-cell>
                    </ng-container>
                    <!--//-->                       
                    <ng-container matColumnDef="COD_SP">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Punto Venta</mat-header-cell>
                        <mat-cell *matCellDef="let s"><b>{{s.COD_SP}} {{s.NAME_SP | titlecase}}</b></mat-cell>
                    </ng-container>
                    <!--//-->             
                    <ng-container matColumnDef="DATE_CREATION">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Fecha Creación</mat-header-cell>
                        <mat-cell *matCellDef="let s">{{s.DATE_CREATION | date:'dd/MM/yy hh:mm'}}</mat-cell>
                    </ng-container>
                    <!--//-->                        
                    <ng-container matColumnDef="DATE_UPDATE">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">Fecha Actualizacion</mat-header-cell>
                        <mat-cell *matCellDef="let s">{{s.DATE_UPDATE | date:'dd/MM/yy hh:mm'}}</mat-cell>
                    </ng-container>                    
                    <!--//-->  
                    <!--                      
                    <ng-container matColumnDef="RUT">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">Cliente</mat-header-cell>
                        <mat-cell *matCellDef="let s">{{s.RUT | rut:'DOTS_DASH' }}</mat-cell>
                    </ng-container>   
                    -->                 
                    <!--//-->    
                    <!--
                    <ng-container matColumnDef="EMPLOYEE">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">Empleado</mat-header-cell>
                        <mat-cell *matCellDef="let s">{{s.EMPLOYEE_FIRST_NAME }} {{s.EMPLOYEE_LAST_NAME }} {{s.EMPLOYEE_LAST_NAME2 }}</mat-cell>
                    </ng-container>                    
                    -->                    
                    <!--//-->  
                    <ng-container matColumnDef="UUID_EOM_ORDER">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label"></mat-header-cell>
                        <mat-cell *matCellDef="let s">
                                        <!-- ////////////////////////////////////////////////--> 
                                            <div *ngIf="s.COD_REASON_CODE !='AO'">
                                                <!-- 
                                                <button mat-raised-button color="warn"    [disabled]="s.COD_REASON_CODE !='FN'" (click)="returnOrder(s.UUID_EOM_ORDER)" > <mat-icon  svgIcon="heroicons_solid:emoji-sad" ></mat-icon></button>
                                                <button mat-raised-button color="primary" (click)="orderDetail(s.UUID_EOM_ORDER)" > <mat-icon  svgIcon="heroicons_solid:clipboard-list" ></mat-icon></button>
                                                -->
                                                <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button  [disabled]="s.COD_REASON_CODE !='FN'" (click)="returnOrder(s.UUID_EOM_ORDER)" > <mat-icon class="icon-size-5" color="warn"    [svgIcon]="'edit'"></mat-icon>   </button>                                        
                                                <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button                                        (click)="orderDetail(s.UUID_EOM_ORDER)">  <mat-icon class="icon-size-5" color="primary" [svgIcon]="'search'"></mat-icon> </button>                                            
                                            </div>
                                            <div *ngIf="s.COD_REASON_CODE =='AO'">
                                                <!--
                                                <button  mat-raised-button color="warn" disabled="true"   (click)="returnOrder(s.UUID_EOM_ORDER)" > <mat-icon  svgIcon="heroicons_solid:emoji-sad" ></mat-icon></button>
                                                <button mat-raised-button color="primary" (click)="orderDetail(s.UUID_EOM_ORDER)" > <mat-icon  svgIcon="heroicons_solid:clipboard-list" ></mat-icon></button>
                                                -->
                                                <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button  disabled="true"   (click)="returnOrder(s.UUID_EOM_ORDER)" > <mat-icon class="icon-size-5" color="warn" [svgIcon]="'edit'"></mat-icon> </button>                                        
                                                <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button                    (click)="orderDetail(s.UUID_EOM_ORDER)" > <mat-icon class="icon-size-5" color="primary" [svgIcon]="'search'"></mat-icon> </button>                                               
                                            </div>                                              
                                        <!-- ////////////////////////////////////////////////-->                               
                        </mat-cell>
                    </ng-container>                    
                    <!--//-->               
                    <mat-header-row *matHeaderRowDef="displayedOrder" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                    <mat-row *matRowDef="let row;columns: displayedOrder" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                    <!--//-->                    
                </mat-table>
                    <!--//-->      
                    <mat-spinner *ngIf="isLoadingOrder" style="margin:0 auto"></mat-spinner>         
                    <mat-paginator
                    #pagOrder
                    [pageSize]="pag"
                    showFirstLastButtons
                    [pageSizeOptions]="pageSizeOptions" 
                    aria-label="Select page of periodic elements">
                </mat-paginator>               
                    <!--//-->                
            </div> 
 <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->           
<!-- ================================================================== -->
 </mat-tab>
<!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->      
</mat-tab-group>
</div>
<!-- ///////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////////-->
</div>
