import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ooffTable } from '../model/ooff';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class RcService {


    appToken = localStorage.getItem('appToken');//environment.appTokenUser;

    constructor(
      private _http: HttpClient,
      private _toast: ToastrService
    ) { }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async setRc(json): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise(async (resolve, reject) => {
      try {
        const url = `${environment.endPoints.rc}`
  
        json.appToken = $this.appToken
        json.language = "es"
  
        const data = await $this._http.post(url,{
                                                  'data': JSON.stringify(json)
                                                })
        data.subscribe(
          (res: any) => {
              this._toast.success(res.dataSet.glsStatus, 'Éxito');
            resolve(res.dataSet);
          },
          (error) => {
            this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
            reject(error.error);
          }
        );
      }catch(err) {
        this._toast.error('No es posble crear Cargo', 'Error');
        console.log(err);
        reject(err);
      }
    });
  };
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async setRcDetail(json): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise(async (resolve, reject) => {
      try {
        const url = `${environment.endPoints.rcDetail}`
  
        json.appToken = $this.appToken
        json.language = "es"
  
        const data = await $this._http.post(url,{
                                                  'data': JSON.stringify(json)
                                                })
        data.subscribe(
          (res: any) => {
              this._toast.success(res.dataSet.glsStatus, 'Éxito');
            resolve(res.dataSet);
          },
          (error) => {
            this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
            reject(error.error);
          }
        );
      }catch(err) {
        this._toast.error('No es posble crear Cargo', 'Error');
        console.log(err);
        reject(err);
      }
    });
  };
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getRcAttributesType(): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.rcAttributesType}/${$this.appToken}`;
        const res = await $this._http.get<any>(url);
        res.subscribe(
          async (data) => {
            resolve(data);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async setRcAttributes(json): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise(async (resolve, reject) => {
      try {
        const url = `${environment.endPoints.rcAttributes}`
  
        json.appToken = $this.appToken
        json.language = "es"
  
        const data = await $this._http.post(url,{
                                                  'data': JSON.stringify(json)
                                                })
        data.subscribe(
          (res: any) => {
              this._toast.success(res.dataSet.glsStatus, 'Éxito');
            resolve(res.dataSet);
          },
          (error) => {
            this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
            reject(error.error);
          }
        );
      }catch(err) {
        this._toast.error('No es posble crear Atributo', 'Error');
        console.log(err);
        reject(err);
      }
    });
  };
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getRc(uuidCrmCustomer : string): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.rc}/${$this.appToken}/${uuidCrmCustomer}`;
        const res = await $this._http.get<any>(url);
        res.subscribe(
          async (data) => {
            resolve(data);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getRcDetail(uuidRc : string): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.rcDetail}/${$this.appToken}/${uuidRc}`;
        const res = await $this._http.get<any>(url);
        res.subscribe(
          async (data) => {         
            resolve(data);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////  
async setRcContact(json): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise(async (resolve, reject) => {
      try {
        const url = `${environment.endPoints.rcContact}`
  
        json.appToken = $this.appToken
        json.language = "es"
  
        const data = await $this._http.post(url,{
                                                  'data': JSON.stringify(json)
                                                })
        data.subscribe(
          (res: any) => {
              this._toast.success(res.dataSet.glsStatus, 'Éxito');
            resolve(res.dataSet);
          },
          (error) => {
            this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
            reject(error.error);
          }
        );
      }catch(err) {
        this._toast.error('No es posble crear Atributo', 'Error');
        console.log(err);
        reject(err);
      }
    });
  };
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////  
async getRcOrder(uuidRc  : string,uuidCrmCustomer : string,year : string,month : string): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.rcOrder}/${$this.appToken}/${uuidRc}/${uuidCrmCustomer}/${year}/${month}`;
        const res = await $this._http.get<any>(url);
        res.subscribe(
          async (data) => {
            resolve(data);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////  
public getRcPagination(
  uuidCustomer : string
 ,statusRC     : string
 ,year         : number 
 ,month        : number  

 ,page         : number
 ,perPage      : number
 ,from         : string 
 ,searchTerm   : string 
 ,orderBy      : string 
): Observable<ooffTable> {
 const $this = this;
 var url;
 this.appToken = localStorage.getItem('appToken');
 url =  $this.appToken 
 + '/' + uuidCustomer
 + '/' + statusRC
 + '/' + year  
 + '/' + month    
 + '/' + page	
 + '/' + perPage		
 + '/' + from											
 + '/' + searchTerm			
 + '/' + orderBy;
url = `${environment.endPoints.rcPagination}/${url}`;

 return this._http.get<ooffTable>(url);
}
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////  

}
