

<div class="w-full  sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- Header -->

<!-- ////////////////////////////////////////////////////////////////////////////////////////////////-->
<div class="w-full">
    <!-- ##########################################################  --> 
    <div class="grid grid-cols-10">   
        <div class="col-span-8">
            <!-- ## -->
                <h2 class="prose prose-xl">
                        OOFF: <b>{{data.ooff}} </b>
                        <mat-icon *ngIf="data.statusRc == 'I'" class="text-yellow-400 icon-size-25" [svgIcon]="'heroicons_solid:minus-circle'"></mat-icon>
                        <mat-icon *ngIf="data.statusRc == 'A'" class="text-green-400  icon-size-25" [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>   
                </h2>                       
                <h2 class="prose "> {{data.rut || '1-9' | rut:'DOTS_DASH'}} {{data.name}}</h2>   
                <h2 class="prose ">Agregar Producto</h2> 
            <!-- ## -->
        </div>
        <div class="col-span-1">
            <!-- ## -->
            <button class="close " mat-button (click)="btnClose()"> <mat-icon>close</mat-icon></button>    
            <!-- ## -->
        </div>
    </div>
    <!-- ##########################################################  -->    

</div>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<mat-divider class="py-2"></mat-divider>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->



<form [formGroup]="detalleForm" onsubmit="return false;">

<div class="grid grid-cols-2 w-full">

    <div>
        <div *ngIf="spWarehouse.length >1 " class="flex  w-1/2">
            <mat-form-field class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet form-gen">
                <mat-label> Bodega</mat-label>
                <mat-select (selectionChange)="setSpWarehouse($event.value)" formControlName="uuidWareHouse">
                    <ng-container *ngFor="let wm of spWarehouse">
                        <mat-option [value]="wm.UUID_WAREHOUSE"> {{wm.COD_WAREHOUSE}} - {{wm.NAME_WAREHOUSE}} </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="spWarehouse.length == 1 " class="flex col-span-2">
            Bodega<br />
            {{selectWarehouse.COD_WAREHOUSE}} - {{selectWarehouse.NAME_WAREHOUSE}}
        </div>
    </div>
    <div>
        <mat-form-field appearance="fill" class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet w-3/4">
            <mat-label>SKU</mat-label>
            <input matInput   formControlName="VlrCodigo" readonly>
            <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button (click)="showProducts()" > <mat-icon class="icon-size-5" color="primary" [svgIcon]="'search'"></mat-icon> </button>
            <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button (click)="resetProducts()"   > <mat-icon class="icon-size-5" color="warn" [svgIcon]="'delete'"></mat-icon> </button>
        </mat-form-field>
    </div>
</div>

                     <!-- ## -->
                     <div>
                        <mat-form-field appearance="fill" class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet form-gen w-3/4">
                            <mat-label>Producto</mat-label>
                            <input matInput  formControlName="NmbItemOri" readonly>
                            <mat-icon  matSuffix> abc </mat-icon>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet form-gen w-3/4">
                            <mat-label>Producto en Factura/Boleta</mat-label>
                            <input matInput  formControlName="NmbItem" readonly>
                            <mat-icon  matSuffix> abc </mat-icon>
                        </mat-form-field>  
                    </div>
                     <!-- ## -->        

    <div>
            <mat-form-field appearance="fill" class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet form-gen">
                <mat-label *ngIf="flagOverwrite == true" ><b style="color: rgb(0, 122, 61)">Precio Unitario</b></mat-label>
                <mat-label *ngIf="flagOverwrite == false">Precio Unitario</mat-label>

                <b>{{detalleForm.value.symbolCurrency}}</b>
                    <input matInput type="number" min="0" *ngIf="flagOverwrite == true"  formControlName="PrcItem" (input)="numericOnly($event)"  (keypress)="numericOnly($event)"  (paste)="onPaste($event)">
                    <input matInput type="number" min="0" *ngIf="flagOverwrite == false" formControlName="PrcItem" (input)="numericOnly($event)"  (keypress)="numericOnly($event)"  (paste)="onPaste($event)" readonly>
                <b>{{detalleForm.value.codCurrency}}</b>

                <mat-icon *ngIf="flagOverwrite == true"  matSuffix> create </mat-icon>
                <mat-icon *ngIf="flagOverwrite == false" matSuffix> payments </mat-icon>
            </mat-form-field>

        <mat-form-field appearance="fill" class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet form-gen">
            <mat-label>Cantidad</mat-label>
            <input type="number" min="1" [max]="maxDetalleItem" matInput  formControlName="QtyItem" (input)="numericOnly($event)"  (keypress)="numericOnly($event)"  (paste)="onPaste($event)">
            <mat-icon  matSuffix> inventory_2 </mat-icon>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field appearance="fill" *ngIf="detalleForm.get('CodImpAdic').value != null" class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet form-gen w-1/4">
            <mat-label>Impuesto adicional</mat-label>
            <input matInput formControlName="glsImpAdic" readonly>
        </mat-form-field>
                            <!-- //################################################################// -->
                             <!-- <div class="grid grid-rows-2 ">-->
                                <div>
                                    <mat-slide-toggle color="primary" formControlName="flagProductDiscount"  (change)="showProductDiscount($event)" > <b>Descuento</b> </mat-slide-toggle>

                                </div>                                  
                                <div class="grid grid-cols-3 gap-2" *ngIf="flagProductDiscountChecked == true">    
                                    <div class="discountAT" *ngIf="discountPS.amountDiscount >0  && discountPS.amountDiscountManual == 0" >
                                        <!--##-->
                                            <h1 *ngIf="discountPS.flagGlobalDiscount == 'Y'" class="prose"><b>Descuento Global (Automatico)</b></h1>
                                             <h1 *ngIf="discountPS.flagGlobalDiscount == 'N'" class="prose"><b>Descuento por Regla (Automatico)</b></h1>
                                                <!--//-->
                                                <h2> {{discountPS.nameDiscount}}</h2>
                                                <mat-form-field  appearance="fill" class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet">
                                                    <mat-label>Porcentaje Descuento</mat-label>
                                                    <input matInput type="number" min="0" max="100" [value]="discountPS.percentDiscount" readonly> %
                                                    <mat-icon  matSuffix> money_off </mat-icon>
                                                </mat-form-field>                                                
                                                <mat-form-field appearance="fill" class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet">
                                                    <mat-label>Descuento Monetario</mat-label>
                                                    <input matInput type="number"   [value]="discountPS.amountDiscount" readonly> 
                                                    <mat-icon  matSuffix> money_off </mat-icon>
                                                </mat-form-field>
                                                <!--//-->
                                        <!--##-->                             
                                    </div>
                                    <!-- //################################################################// -->
                                    <div class="discountManual ">
                                        <h1 class="prose"><b>Descuento Manual</b></h1>
                                        <h2> &nbsp; </h2>
                                        <mat-form-field appearance="fill" class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet">
                                            <mat-label>Porcentaje Descuento</mat-label>
                                            <input matInput type="number" min="0" max="99" [disabled]="detalleForm.value.VlrCodigo == null " formControlName="DescuentoPct" value="0"  #inputDSC (change)="setDiscountManual(inputDSC.value)"> %
                                            <mat-error *ngIf="detalleForm.get('DescuentoPct').hasError('max')"> El valor no puede ser mayor a 100%</mat-error>
                                            <mat-icon  matSuffix> money_off </mat-icon>
                                        </mat-form-field>
                                        <mat-form-field appearance="fill" class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet">
                                            <mat-label>Descuento Monetario</mat-label>
                                            <input matInput type="number" min="0" max="100" [value]="discountPS.amountDiscountManual" readonly> 
                                        
                                            <mat-icon  matSuffix> money_off </mat-icon> 
                                        </mat-form-field>                                
                                    </div>
                                    <!-- //################################################################// -->
                                     <div>
                                        <h1 class="prose"><b>Vigencia Descuento</b></h1>
                                        <div>
                                            <section >
                                                <mat-checkbox class="example-margin" (change)="setIndefinido($event.checked)">Indefinido</mat-checkbox>
                                            </section>
                                        </div>  
                                        <div >     
                                        <div>
                                            <mat-form-field class="w-1/2">
                                                    <mat-label>Fecha Inicio Descuento:</mat-label>   
                                                    <input matInput formControlName="dateStart" [matDatepicker]="emision" [min]="today" [value]="today" />
                                                    <mat-hint>MM/DD/YYYY</mat-hint>
                                                    <mat-datepicker-toggle matSuffix [for]="emision" ></mat-datepicker-toggle>
                                                    <mat-datepicker #emision ></mat-datepicker>
                                            </mat-form-field>   
                                        </div>      
                                        <div>
                                            <mat-form-field class="w-1/2">
                                                    <mat-label>Fecha Fin Descuento:</mat-label>   
                                                    <input matInput formControlName="dateEnd" [matDatepicker]="fin" [min]="today" [value]="today" />
                                                    <mat-hint>MM/DD/YYYY</mat-hint>
                                                    <mat-datepicker-toggle matSuffix [for]="fin" ></mat-datepicker-toggle>
                                                    <mat-datepicker #fin ></mat-datepicker>
                                            </mat-form-field>                                                 
                                        </div>
                                        </div>
                                     </div>
                                    <!-- //################################################################// -->
                            </div>
                            <!-- //################################################################// -->            
        <!-- ###############################################  -->
         <!-- 
        <div>
            <mat-form-field appearance="fill" class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet">
                <mat-label>Descuento</mat-label>
                <input matInput type="number" min="0" max="100" formControlName="DescuentoPct" value="0" (input)="numericOnly($event)"  (keypress)="numericOnly($event)"  (paste)="onPaste($event)"> %
                <mat-error *ngIf="detalleForm.get('DescuentoPct').hasError('max')"> El valor no puede ser mayor a 100%</mat-error>
                <mat-icon  matSuffix> money_off </mat-icon>
            </mat-form-field>
        </div>
    -->

        <!-- #################################################-->
    </div>
                     <!-- ## -->
                     <div>
                        <div class="w-3/4">
                            <mat-form-field class="fuse-mat-textarea desc">
                                <mat-label> Descripción</mat-label>
                                <textarea matInput formControlName="DscItem" maxlength="900" id="_DscItem"  (ngModelChange)="valueChange($event)" [readonly]="discountPS.subTotal == 0 "></textarea>
                            </mat-form-field>
                            <div class="count-div">
                                <span class="count-text" >Caracteres:<b>{{remainingText}}</b>/900</span>
                            </div>
                        </div>
                    </div>
                    <!-- ## -->    
    <!-- 
    <div>
        <mat-form-field class="fuse-mat-textarea desc">
            <mat-label> Descripción</mat-label>
            <textarea matInput formControlName="DscItem"></textarea>
        </mat-form-field>
    </div>
    -->
   <!--  <button mat-raised-button (click)="addProduct()" color="accent" class="addButton" [disabled]="detalleForm.invalid"> Añadir </button>-->
</form> 


                <!-- //################################################################// -->

                <br>
                 <br>
                    <div  class="grid grid-cols-2  ">
                        <!--//-->
                        <div>
                                <h1 class="prose prose-xl"> <b>SubTotal</b></h1> 
                                <h1 class="prose prose-xl" *ngIf="selectedPS.PRICE?.COD_CURRENCY == 'CLP'; else noClp"><b style="color: rgb(9, 9, 132)">{{discountPS.subTotal | currency:'CLP':'symbol-narrow'}}</b></h1>
                                <ng-template #noClp>
                                    <!-- ## -->
                                    <h1 class="prose prose-xl" ><b style="color: rgb(9, 9, 132)">{{selectedPS.PRICE?.SYMBOL_CURRENCY}}  {{discountPS.subTotal | number : '1.0-2'}}</b></h1>
                                    <!-- //-->                          
                                </ng-template>      
                        </div>
                        <!-- 
                        <mat-form-field appearance="fill" class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet">
                            <input matInput type="number"   [value]="discountPS.subTotal" readonly> 
                            <mat-icon  matSuffix> money_off </mat-icon>
                        </mat-form-field>
                        -->                                          
                        <!--//-->
                        <div>
                            <button mat-raised-button (click)="addProduct()" color="accent" class="addButton" [disabled]="detalleForm.invalid ||  discountPS.subTotal <= 0"> Añadir Producto </button>      
                        </div>
                    </div>                           
                <!-- //################################################################// -->  
                <br>
                 <br>                   
              
            <!-- ############################################ -->  