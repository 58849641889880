<form [formGroup]="storageLevelForm">
    <mat-form-field class="w-full">
        <mat-label> Bodega </mat-label>
        <mat-select formControlName="uuidWarehouse">
            <ng-container *ngFor="let warehouse of warehousesFiltered">
                <mat-option [value]="warehouse.UUID_WAREHOUSE">
                    {{warehouse.NAME_WAREHOUSE}}
                </mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="w-full">
        <mat-label>Nivel Minimo</mat-label>
        <input matInput formControlName="minLevel" type="number">
    </mat-form-field>
    <mat-form-field class="w-full">
        <mat-label>Nivel Recomendado</mat-label>
        <input matInput formControlName="okLevel" type="number">
    </mat-form-field>
    <mat-form-field class="w-full">
        <mat-label>Nivel Máximo</mat-label>
        <input matInput formControlName="maxLevel" type="number">
    </mat-form-field>
    <mat-form-field class="w-full">
        <mat-label>Flag Alert</mat-label>
        <mat-select formControlName="flagAlert">
            <mat-option value="Y">Si</mat-option>
            <mat-option value="N">No</mat-option>
        </mat-select>
    </mat-form-field>
    <div class="flex justify-end">
        <button mat-raised-button color="warn" mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" *ngIf="data.update == true" (click)="create()" [disabled]="storageLevelForm.invalid"> Actualizar </button>
        <button mat-raised-button color="primary" *ngIf="data.update == false" (click)="create()" [disabled]="storageLevelForm.invalid"> Crear </button>
    </div>
</form>