import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EmisorService } from 'app/services/emisor.service'; 
import { ChangeDetectorRef } from '@angular/core';
import { of } from 'rxjs';

@Component({
  selector: 'app-dialog-see-all-movements',  
  templateUrl: './dialog-see-all-movements.component.html',
  styleUrls: ['./dialog-see-all-movements.component.scss']
})
export class DialogSeeAllMovementsComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator') paginator: MatPaginator;

  reasonCodes: any[] = [];

  displayedColumns: string[] =  ['codMovimineto' ,'folio', 'nameReasonCode', 'typeDoc', 'dateUpdate','qty'];//['codMovimineto' ,'codBox', 'nameReasonCode', 'typeDoc', 'dateUpdate','qty'];

  detailPag         : number = 5;
  pageSizeOptions : any = [ 5 ,10, 50, 100];
  isLoading : boolean = false;

movementsData         : any ;
movementsTable    : any ;
reasonCodesSelected = 0;
txtSearch = null;
//############################################################
//############################################################
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<DialogSeeAllMovementsComponent>,
    private _fb: FormBuilder,
    private _emisorService: EmisorService,
    private ref: ChangeDetectorRef,
  ) { 

  }
//############################################################
//############################################################
async ngOnInit(): Promise<any>{


    await this.getMovements();
    await this. getData();
  }
//############################################################
//############################################################
  ngAfterViewInit(): void {

  }
//############################################################
//############################################################
applyFilter(event: Event,type:string) {
  if(type == 'txt'){
  const filterValue = (event.target as HTMLInputElement).value;
  this.movementsTable.filter = filterValue.trim().toLowerCase();
  }
  if(type == 'select'){
    //###############################
    console.log(event)
    this.isLoading=true; 
    let tmp = this.data.warehouseDetail.filter(item => item.COD_REASON_CODE == event)
    this.movementsTable = new MatTableDataSource(tmp);
    this.ref.detectChanges();
    this.movementsTable.paginator = this.paginator;
    //
    this.isLoading=false; 
    //###############################
  }
}

//############################################################
//############################################################
async getMovements (): Promise<any>{
  this.isLoading=true; 
  this.movementsTable = new MatTableDataSource(this.data.warehouseDetail);
  this.ref.detectChanges();
  this.movementsTable.paginator = this.paginator;
  //
  this.isLoading=false; 
};
//############################################################
//############################################################

async  resetFilters(): Promise<any>{
    this.reasonCodesSelected = null;
    this.txtSearch           = null;
    await this.getMovements();
  }
  
//############################################################
//############################################################
  async getData(): Promise<void> {

    let dataRS = {
                    appToken    : null 
                   ,uuid        : null
                   ,codDocType  : ['GD','GV']
                  }

    const { data } = await this._emisorService.getReasonCodesFilter(dataRS);   
    this.reasonCodes = data;
  }
//############################################################
//############################################################
async btnClose(): Promise<any> {
  this._dialogRef.close();
}
//############################################################
//############################################################
}
