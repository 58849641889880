import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CatalogService } from 'app/services/catalog.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { split } from 'lodash';
import { ChangeDetectorRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-esl-ps-services',
  templateUrl: './esl-ps-services.component.html',
  styleUrls: ['./esl-ps-services.component.scss']
})
export class EslPsServicesComponent implements OnInit {
  @ViewChild('pagCatalogo' )  pagCatalogo  : MatPaginator;


  displayedColumnCatalogo  : string[] = ['img', 'SKU', 'nombre','category','group','precio', 'stock', 'activo','tipo','tag','acc'];
  pag                     : number = 10;
  pageSizeOptions         : any    = [10, 50, 100];

  isLoadingCatalogo       : boolean = false;
  dataCatalogo            : any = [];
  tableCatalogo           : any = [];
  searchSelect            : any = '';
  urlImg                  : any =  environment.appImgURL + environment.appTokenImg
//////////////////////////////////////////////////
//////////////////////////////////////////////////
constructor(
  public dialog: MatDialog,
  public _dialogRef: MatDialogRef<EslPsServicesComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any,
  private _catalogService: CatalogService,
  private ref: ChangeDetectorRef,
  private _dialog: MatDialog,
) { }
//////////////////////////////////////////////////
//////////////////////////////////////////////////
async ngOnInit(): Promise<any> {
  await this.loadCatalogo()
  //////////////////////////////  
}
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
async loadCatalogo():Promise<any>{
  this.isLoadingCatalogo=true; 
  this.tableCatalogo =  new MatTableDataSource(this.data.eslpsServices);
  this.ref.detectChanges();
  this.tableCatalogo.paginator = this.pagCatalogo;
  this.isLoadingCatalogo=false; 
}  
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.tableCatalogo.filter  = filterValue.trim().toLowerCase();
  this.ref.detectChanges();
}
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
closeBtn(){
  // Unsuscribe to all observables
  this._dialogRef.close();
};
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////

selectProduct(o){
  this._dialogRef.close({eslpsServices:o});
}
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////

}
