<div class="w-full  sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <div  class="bg-white rounded-lg p-4">
    <!-- ##########################################################  -->
    <!-- ##########################################################  -->
    <div class="w-full">
        <!-- ##########################################################  --> 
        <div class="grid grid-cols-10">   
            <div class="col-span-8">
                <!-- ## -->
                    <h2 class="prose prose-xl">Crear link Externo <b> {{data.nameProduct}} </b></h2>                       
                    <h2 class="prose "><small>SKU <b>{{data.skuProduct}} </b></small>  </h2>   
                <!-- ## -->
            </div>
            <div class="col-span-1">
                <!-- ## -->
                <button class="close " mat-button (click)="closeBtn()"> <mat-icon>close</mat-icon></button>    
                <!-- ## -->
            </div>
        </div>
        <!-- ##########################################################  -->    

    </div>
    <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
    <mat-divider class="py-2"></mat-divider>
    <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
    <form [formGroup]="externalForm" class="w-full" >
        <div class="grid grid-cols-1">   
                    <mat-form-field class="w-1/3 pl-2">
                        <mat-label> Marca </mat-label>
                        <mat-select formControlName="uuidBrands" >
                            <ng-container *ngFor="let b of data.brands">
                                <mat-option [value]="b.UUID_PS_BRANDS">
                                {{ b.NAME_PS_BRANDS }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <!--##-->
        </div>
        <div class="grid grid-cols-1"> 
                    <mat-form-field class="w-3/4">
                        <mat-label> Link </mat-label>
                    <!-- <input matInput placeholder="Nombre del producto"  formControlName="link" />-->  
                        <mat-form-field >
                            <!--  <mat-label> Descripcion</mat-label>-->
                            <textarea matInput formControlName="link" rows="5" cols="100"></textarea>
                        </mat-form-field>  
                    </mat-form-field>
        </div>
        <button mat-flat-button [color]="'primary'" (click)="addExternalPS()" [disabled]="externalForm.invalid "> 
            Actualizar
        </button>
    </form>
    <!-- ##########################################################  -->
    <!-- ##########################################################  -->
    </div>
</div>        