import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CatalogService } from 'app/services/catalog.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { split } from 'lodash';
import { ChangeDetectorRef } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.scss']
})
export class DiscountsComponent implements OnInit {
//////////////////////////////////////////////////
//////////////////////////////////////////////////  
salesChannels: any[] = [];
discountForm: UntypedFormGroup;
minDateInfo = new Date(2000, 0, 1);
today = new Date();
maxDate = new Date(9999, 11, 31)
chargeCodeSelectDetail : any = {}
//
arrFlagFullFillment = []
//
uuidPsServices      = ''
uuidPsServicesPrice = ''
//
toltipAmount        : any = '';                  
toltipQuantity      : any = '';      
nameRangeAmountA    : any = '';        
typeRangeA          : any = 'N';  
flagRangeA          : any = 'N';  
nameRangeAmountB    : any = '';        
flagRangeB          : any = 'N';  
typeRangeB          : any = 'N';  
nameRangeQuantityA  : any = '';          
nameRangeQuantityB  : any = '';   
//
unitMeasurements    : any = {}
//////////////////////////////////////////////////
//////////////////////////////////////////////////  
constructor(
            public dialog: MatDialog,
            public _dialogRef: MatDialogRef<DiscountsComponent>,
            @Inject(MAT_DIALOG_DATA) public data: any,
            private _catalogService: CatalogService,
            private ref: ChangeDetectorRef,
            private _fb: FormBuilder,
            private _dialog: MatDialog,
            private _fuseConfirmationService: FuseConfirmationService,
            private _toast: ToastrService,  
) { 

  this.discountForm = this._fb.group({

     uuidPsServicesDiscount   :[null]                 
    ,uuidPsServicesPrice      :[null]                
    ,idEpcSalesChannel        :[null, Validators.required]                
    ,flagGlobalDiscount       :[true]                
    ,discount                 :[null, Validators.required]                    
    ,typePsServicesDiscount   :['P' ,[Validators.required]]           
    ,orderCategory            :[null]    
    //            
    ,flagAmountStart          :[false]               
    ,amountStart              :[null]         
    ,flagAmountEnd            :[false]        
    ,amountEnd                :[null]    
    ,flagAmountLimit          :[false]               
    ,amountLimit              :[null]    
    //
    ,flagQuantityStart        :[false]                 
    ,quantityStart            :[null]   
    ,flagQuantityEnd          :[false]        
    ,quantityEnd              :[null]    
    ,flagQuantityLimit        :[false]                 
    ,quantityLimit            :[null]           
    //
    ,salesDiscount            :[null]                  
    ,flagFullFillment         :[null]                  
    ,dateStart                :[''  ,[Validators.required]]             
    ,dateEnd                  :[''  ,[Validators.required]]          
    ,remove                   :[null]                  
    ,codChargeCode            :[null]       
    ,flagHour                 :[false]     
    ,hourStart                :[null]     
    ,hourEnd                  :[null]
    ,flagMonday               :[false]     
    ,flagTuesday              :[false]       
    ,flagWednesday            :[false]               
    ,flagThursday             :[false]            
    ,flagFriday               :[false]     
    ,flagSaturday             :[false]            
    ,flagSunday               :[false]   
    ,nameDiscount             :[null  ,[Validators.required]]        
    ,acction                  :[null]
    //
    ,setFlagAmountStart       :[false]     
    ,setFlagQuantityStart     :[false]     

  });
}
//////////////////////////////////////////////////
//////////////////////////////////////////////////  
async ngOnInit() {
  var tmp
  tmp = await this._catalogService.getAllSalesChannels()
  this.salesChannels = tmp.data;
  tmp = await this._catalogService.getpsDiscountFullfillment()
  this.arrFlagFullFillment = tmp.fullfillment
  this.setAmount('A',null);  
  this.discountForm.get('dateStart').setValue(this.today);
  this.discountForm.get('flagGlobalDiscount').setValue(true);  
  this.setFlagAmountEnd(false)
  this.setFlagQuantityEnd(false)
  this.chargeCodeSelectDetail = this.data.chargeCodeSelectDetail[0]
  this.uuidPsServices      = this.data.uuidPsServices     
  this.uuidPsServicesPrice = this.data.uuidPsServicesPrice
  this.unitMeasurements = this.data.unitMeasurements.filter(i => i.ID_UNIT_MEASUREMENT ==  this.data.idUnitMeasurement)[0]
  //
  this.discountForm.get('quantityLimit').setValue(0);
  this.discountForm.get('amountLimit').setValue(0);
  //

}
//////////////////////////////////////////////////
//////////////////////////////////////////////////  
async closeBtn(): Promise<any> {
  this._dialogRef.close({status : false});
}
//////////////////////////////////////////////////
//////////////////////////////////////////////////  
//###########################################################  
numericOnly(event): boolean {    
  let patt = /^([0-9])$/;
  let result = patt.test(event.key);
  return result;
}
//########################################################### 
alphaNumberOnly (e) {  // Accept only alpha numerics, not special characters 
   let patt = /^([a-zA-Z0-9])$/;
  let result = patt.test(e.key);
  return result;

}
//########################################################### 
onPaste(e) {
  e.preventDefault();
  return false;
}
//########################################################### 
setGlobal(val){
  this.discountForm.get('flagGlobalDiscount').setValue(val);  
  if(val == true){
  //
  this.discountForm.get('flagAmountStart').setValue(false);
  this.discountForm.get('flagAmountEnd').setValue(false);
  this.discountForm.get('amountStart').setValidators( []);
  this.discountForm.get('amountEnd').setValidators( []);
  this.discountForm.get('amountStart').setValue(0);
  this.discountForm.get('amountEnd').setValue(0);
  //this.discountForm.get('amountEnd').disable();
  //
  this.discountForm.get('flagQuantityStart').setValue(false);
  this.discountForm.get('flagQuantityEnd').setValue(false);
  this.discountForm.get('quantityStart').setValidators( []);
  this.discountForm.get('quantityEnd').setValidators( []);
  this.discountForm.get('quantityStart').setValue(0);
  this.discountForm.get('quantityEnd').setValue(0);    
  //his.discountForm.get('quantityEnd').disable();
  this.discountForm.updateValueAndValidity();
  }
  else{
    console.log("Desactiva")
    this.discountForm.get('flagAmountStart').setValue(null);
    this.discountForm.get('flagQuantityStart').setValue(null);
    this.discountForm.get('amountStart').setValue(null);
    this.discountForm.get('amountEnd').setValue(null);        
    this.discountForm.get('quantityStart').setValue(null);
    this.discountForm.get('quantityEnd').setValue(null);    
    this.discountForm.updateValueAndValidity();

  }


}
//###########################################################  
setAmount(type,val){
  var value 
  if(type == 'P'){
    if(val == null) value = null; else  value = val*100;
    this.discountForm.get('discount').setValue(value);
    this.discountForm.get('discount').setValidators( [Validators.required, Validators.min(1),Validators.max(99)]);
    this.discountForm.updateValueAndValidity();
  }
  else{
    this.discountForm.get('discount').setValue(val);
    this.discountForm.get('discount').setValidators( [Validators.required, Validators.min(1),Validators.max(999999)]);
    this.discountForm.updateValueAndValidity();
  }
  
  }
//###########################################################
setIndefinido(val){
  if(val == true){
    //#############
    this.discountForm.get('dateStart').setValue(this.today);
    this.discountForm.get('dateEnd').setValue(this.maxDate);
    //#############
  }
  else{
    //#############
    this.discountForm.get('dateStart').setValue(this.today);
    this.discountForm.get('dateEnd').setValue(null);
    //#############            
  }
}
//###########################################################  
setHour(val){
  if(val == true){
    //#############
    this.discountForm.get('flagHour').setValue(true);
    this.discountForm.get('hourStart').setValue(null);
    this.discountForm.get('hourEnd').setValue(null);
    this.discountForm.get('hourStart').setValidators( [Validators.required]);
    this.discountForm.get('hourEnd').setValidators( [Validators.required]);
    this.discountForm.updateValueAndValidity();
    //#############
  }
  else{
    //#############
    this.discountForm.get('flagHour').setValue(false);
    this.discountForm.get('hourStart').setValue('00:00');
    this.discountForm.get('hourEnd').setValue('00:00');    
    this.discountForm.get('hourStart').setValidators( []);
    this.discountForm.get('hourEnd').setValidators( []);
    this.discountForm.updateValueAndValidity();
    //#############            
  }  
}
//###########################################################  
setMaxFlagAmountLimit(val){
  if(val == true){
    //#############
    this.discountForm.get('flagAmountLimit').setValue(true);
    this.discountForm.get('amountLimit').setValue(null);
    this.discountForm.get('amountLimit').setValidators( [Validators.required]);
    this.discountForm.updateValueAndValidity();
    //#############
  }
  else{
    //#############
    this.discountForm.get('flagAmountLimit').setValue(false);
    this.discountForm.get('amountLimit').setValue(0);
    this.discountForm.get('amountLimit').setValidators( []);
    this.discountForm.updateValueAndValidity();
    //#############            
  } 
}
//###########################################################  
setMaxFlagQuantityLimit(val){
  if(val == true){
    //#############
    this.discountForm.get('flagQuantityLimit').setValue(true);
    this.discountForm.get('quantityLimit').setValue(null);
    this.discountForm.get('quantityLimit').setValidators( [Validators.required]);
    this.discountForm.updateValueAndValidity();
    //#############
  }
  else{
    //#############
    this.discountForm.get('flagQuantityLimit').setValue(false);
    this.discountForm.get('quantityLimit').setValue(0);
    this.discountForm.get('quantityLimit').setValidators( []);
    this.discountForm.updateValueAndValidity();
    //#############            
  } 
}
//###########################################################  
//###########################################################  
setFlagAmountStart(val){
  if(val == true){
    //#############
    this.discountForm.get('setFlagQuantityStart').setValue(false);
    this.setFlagQuantityStart(false)
    this.setFlagQuantityEnd(false)
    this.discountForm.get('flagAmountStart').setValue(true);
    //this.discountForm.get('flagAmountEnd').setValue(true);
    this.discountForm.get('amountStart').setValidators( [Validators.required]);
    //this.discountForm.get('amountEnd').setValidators( [Validators.required]);
    this.discountForm.updateValueAndValidity();
    //#############
  }
  else{
    //#############
    this.discountForm.get('flagAmountStart').setValue(false);
    this.discountForm.get('flagAmountEnd').setValue(false);    
    this.discountForm.get('amountEnd').disable();
    this.discountForm.get('amountStart').setValidators( []);
    //this.discountForm.get('amountEnd').setValidators( []);
    this.discountForm.updateValueAndValidity();
    //#############            
  } 
}
//###########################################################  
setFlagAmountEnd(val){
  if(val == true){
    //#############
    this.discountForm.get('flagAmountEnd').setValue(true);
    this.discountForm.get('amountEnd').setValidators( [Validators.required]);
    this.discountForm.get('amountEnd').enable();
    this.discountForm.updateValueAndValidity();
    //#############
  }
  else{
    //#############
    this.discountForm.get('flagAmountEnd').setValue(false);    
    this.discountForm.get('amountEnd').setValue(null)
    this.discountForm.get('amountEnd').setValidators( []);
    this.discountForm.get('amountEnd').disable();
    this.discountForm.updateValueAndValidity();
    //#############            
  } 
}
//###########################################################  
//########################################################### 
setFlagQuantityStart(val){
  if(val == true){
    //#############
    this.discountForm.get('setFlagAmountStart').setValue(false);
    this.setFlagAmountStart(false)
    this.setFlagAmountEnd(false)
    this.discountForm.get('flagQuantityStart').setValue(true);
    //this.discountForm.get('flagQuantityEnd').setValue(true);
    this.discountForm.get('quantityStart').setValidators( [Validators.required]);
    //this.discountForm.get('quantityEnd').setValidators( [Validators.required]);
    this.discountForm.updateValueAndValidity();
    //#############
  }
  else{
    //#############
    this.discountForm.get('flagQuantityStart').setValue(false);
    this.discountForm.get('flagQuantityEnd').setValue(false);
    this.discountForm.get('quantityEnd').disable();
    this.discountForm.get('quantityStart').setValidators( []);
    //this.discountForm.get('quantityEnd').setValidators( []);
    this.discountForm.updateValueAndValidity();
    //#############            
  } 
}
//###########################################################  
setFlagQuantityEnd(val){
  if(val == true){
    //#############
    this.discountForm.get('flagQuantityEnd').setValue(true);
    this.discountForm.get('quantityEnd').setValidators( [Validators.required]);
    this.discountForm.get('quantityEnd').enable();
    this.discountForm.updateValueAndValidity();
    //#############
  }
  else{
    //#############
    this.discountForm.get('flagQuantityEnd').setValue(false);    
    this.discountForm.get('quantityEnd').setValue(null)
    this.discountForm.get('quantityEnd').setValidators( []);
    this.discountForm.get('quantityEnd').disable();
    this.discountForm.updateValueAndValidity();
    //#############            
  } 
}
//###########################################################  
setFullFillment(val,t){
  var tmp;
  tmp = this.arrFlagFullFillment.filter(i=> i.FLAG_FULL_FILLMENT == val)[0]
  this.toltipAmount      = tmp.TOLTIP_AMOUNT                 
  this.toltipQuantity    = tmp.TOLTIP_QUANTITY     
  this.nameRangeAmountA   = tmp.NAME_RANGE_AMOUNT_A       
  this.typeRangeA         = tmp.TYPE_RANGE_A 
  this.flagRangeA         = tmp.FLAG_RANGE_A 
  this.nameRangeAmountB   = tmp.NAME_RANGE_AMOUNT_B       
  this.flagRangeB         = tmp.FLAG_RANGE_B 
  this.typeRangeB         = tmp.TYPE_RANGE_B 
  this.nameRangeQuantityA = tmp.NAME_RANGE_QUANTITY_A         
  this.nameRangeQuantityB = tmp.NAME_RANGE_QUANTITY_B    
  //
  if(this.discountForm.value.flagAmountStart == true){
    if(this.flagRangeB == 'Y')this.setFlagAmountEnd(true)
    if(this.flagRangeB == 'N')this.setFlagAmountEnd(false)

    this.setFlagQuantityEnd(false)
  }
  //
  if(this.discountForm.value.flagQuantityStart == true){
    if(this.flagRangeB == 'Y')this.setFlagQuantityEnd(true)
    if(this.flagRangeB == 'N')this.setFlagQuantityEnd(false)

    this.setFlagAmountEnd(false)
  }

}
//###########################################################  
async addDiscount(){
  //
  var flagHour
  var hourStart,hourEnd
  var flagFullFillment,orderCategory,salesDiscount
  var flagMonday,flagTuesday,flagWednesday,flagThursday,flagFriday,flagSaturday,flagSunday   
  var typePsServicesDiscount,discount;
  //
  flagMonday    = this.discountForm.value.flagMonday     ? 'Y' : 'N' 
  flagTuesday   = this.discountForm.value.flagTuesday    ? 'Y' : 'N'   
  flagWednesday = this.discountForm.value.flagWednesday  ? 'Y' : 'N'           
  flagThursday  = this.discountForm.value.flagThursday   ? 'Y' : 'N'        
  flagFriday    = this.discountForm.value.flagFriday     ? 'Y' : 'N' 
  flagSaturday  = this.discountForm.value.flagSaturday   ? 'Y' : 'N'        
  flagSunday    = this.discountForm.value.flagSunday     ? 'Y' : 'N' 
  //  
  if(flagMonday   == 'N' && flagTuesday  == 'N' && flagWednesday== 'N' && flagThursday == 'N' && flagFriday   == 'N' && flagSaturday == 'N' && flagSunday   == 'N'){
            //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
            const dialogRef = this._fuseConfirmationService.open({
              title: `Descuento Inactivo`,
              message: `¡¡Atención!!<br> Debe selecionar al menos un dia de aplicación<br><b>Dias Activo:Selecionar  Lunes - Domingo<b> `,
              icon: {
                  show: true,
                  name: 'assignment_turned_in',
                  color: 'primary'
              },
              actions: {
                  confirm: {
                      label: 'Continuar',
                      color: 'primary'
                  },
                  cancel: {
                      show: false,
                      label: 'Cancelar',
                  }
              }
            });
            return false;
            dialogRef.afterClosed().subscribe(async (result) => {
                  //"""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
                   
                  //"""""""""""""""""""""""""""""""""""""""""""""""""""""""""""  
            })        
            //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$                
  }
  //
  flagHour = this.discountForm.value.flagHour ? 'Y' : 'N'
  if(flagHour == 'N'){
    hourStart = '1900-01-01 00:00:00'
    hourEnd   = '1900-01-01 00:00:00'
  }else{
    hourStart  = '1900-01-01 '+this.discountForm.value.hourEnd   + ':00'
    hourEnd    = '1900-01-01 '+this.discountForm.value.hourStart + ':00'
  }
  //
  flagFullFillment = this.discountForm.value.flagFullFillment   || 'T0'         
  salesDiscount    = 'S'
  if(flagFullFillment == 'T'){
    orderCategory =1;
  }else{
    orderCategory =2;
  }
  //
  typePsServicesDiscount  = this.discountForm.value.typePsServicesDiscount    
  if(typePsServicesDiscount == 'P'){
    discount = (this.discountForm.value.discount/100)
  }else{
    discount = this.discountForm.value.discount       
  }
  var json = {
                   uuidPsServicesDiscount  : '0' 
                  ,uuidPsServices          : this.uuidPsServices                   
                  ,uuidPsServicesPrice     : this.uuidPsServicesPrice             
                  ,idEpcSalesChannel       : this.discountForm.value.idEpcSalesChannel             
                  ,flagGlobalDiscount      : this.discountForm.value.flagGlobalDiscount   ? 'Y' : 'N'                             
                  ,discount                : discount           
                  ,typePsServicesDiscount  : typePsServicesDiscount      
                  ,orderCategory           : 1        
                  ,flagAmountStart         : this.discountForm.value.flagAmountStart    ? 'Y' : 'N'   
                  ,amountStart             : this.discountForm.value.amountStart        | 0             
                  ,flagAmountEnd           : this.discountForm.value.flagAmountEnd      ? 'Y' : 'N'  
                  ,amountEnd               : this.discountForm.value.amountEnd          | 0  
                  ,flagAmountLimit         : this.discountForm.value.flagAmountLimit    ? 'Y' : 'N'           
                  ,amountLimit             : this.discountForm.value.amountLimit        | 0            
                  ,flagQuantityStart       : this.discountForm.value.flagQuantityStart  ? 'Y' : 'N'            
                  ,quantityStart           : this.discountForm.value.quantityStart      | 0       
                  ,flagQuantityEnd         : this.discountForm.value.flagQuantityEnd    ? 'Y' : 'N'   
                  ,quantityEnd             : this.discountForm.value.quantityEnd        | 0   
                  ,flagQuantityLimit       : this.discountForm.value.flagQuantityLimit  ? 'Y' : 'N'             
                  ,quantityLimit           : this.discountForm.value.quantityLimit      | 0  
                  ,salesDiscount           : salesDiscount         
                  ,flagFullFillment        : flagFullFillment       
                  ,dateStart               : this.formatDate(this.discountForm.value.dateStart)               
                  ,dateEnd                 : this.formatDate(this.discountForm.value.dateEnd)
                  ,remove                  : 'N'           
                  ,codChargeCode           : '0'
                  ,flagHour                : flagHour
                  ,hourStart               : hourEnd  
                  ,hourEnd                 : hourStart
                  ,flagMonday              : this.discountForm.value.flagMonday     ? 'Y' : 'N' 
                  ,flagTuesday             : this.discountForm.value.flagTuesday    ? 'Y' : 'N'   
                  ,flagWednesday           : this.discountForm.value.flagWednesday  ? 'Y' : 'N'           
                  ,flagThursday            : this.discountForm.value.flagThursday   ? 'Y' : 'N'        
                  ,flagFriday              : this.discountForm.value.flagFriday     ? 'Y' : 'N' 
                  ,flagSaturday            : this.discountForm.value.flagSaturday   ? 'Y' : 'N'        
                  ,flagSunday              : this.discountForm.value.flagSunday     ? 'Y' : 'N'   
                  ,nameDiscount            : this.discountForm.value.nameDiscount
                  ,acction                 : 'ADD'  
                }
  var add = await this._catalogService.setPsServicesDiscount(json)
  if(add.success == true){
    this._dialogRef.close({status : true});
  }
      
}
//###########################################################  
formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('/');
}
//###########################################################  
}
