import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CatalogService } from 'app/services/catalog.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { split } from 'lodash';
import { ChangeDetectorRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { environment } from 'environments/environment';
import {trigger, state, style, animate, transition} from '@angular/animations';
@Component({
  selector: 'app-modal-img',
  templateUrl: './modal-img.component.html',
  styleUrls: ['./modal-img.component.scss'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated-90', style({ transform: 'rotate(90deg)' })),
      state('rotated-180', style({ transform: 'rotate(180deg)' })),
      state('rotated-270', style({ transform: 'rotate(270eg)' })),
      state('rotated-360', style({ transform: 'rotate(360eg)' })),
      transition('rotated => default', animate('1500ms ease-out')),
      transition('default => rotated', animate('400ms ease-in'))
  ])
]
})
export class ModalImgComponent implements OnInit {
  type      = null
  urlImg    = null
  uuidImg   = null 
  imgB64    = null
  imgHeight = null
  imgWidth  = null
  style     = null
  title     = null
  rotate    = null
  appTokenImg = environment.appTokenImg;
  appImgURL   = environment.appImgURL;
  appImgSizeA = environment.appImgSizeA;
  appImgSizeB = environment.appImgSizeB;
  state: string = 'default';
//////////////////////////////////////////////////
//////////////////////////////////////////////////
constructor(
  public dialog: MatDialog,
  public _dialogRef: MatDialogRef<ModalImgComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any,
  private _catalogService: CatalogService,
  private ref: ChangeDetectorRef,
) { }
//////////////////////////////////////////////////
//////////////////////////////////////////////////
  async ngOnInit(): Promise<any> {
   
    //////////////////////////////
    this.type      = this.data.type     
    this.urlImg    = this.data.urlImg
    this.uuidImg   = this.data.uuidImg   
    this.imgB64    = this.data.imgB64   
    this.imgHeight = this.data.imgHeight
    this.imgWidth  = this.data.imgWidth 
    this.title     = this.data.title
    this.rotate    = this.data.rotate
    //this.style     = "max-width:"+this.imgWidth+";max-width:"+this.imgHeight+";cursor:pointer"
    this.style     = "height:"+this.imgHeight+";width:"+this.imgWidth+";cursor:pointer"
    ////////////////////////////// 
    if(this.type == 'APP'){
      this.urlImg=this.appImgURL+this.appTokenImg+'/'+this.uuidImg+this.appImgSizeB 
    }
    ////////////////////////////// 
    if(this.rotate ){
      console.log(this.rotate)
      switch(this.rotate) { 
        case '90': { 
          this.state =  'rotated-90' 
           break; 
        } 
        case'180': { 
          this.state =  'rotated-180' 
           break; 
        } 
        case'270': { 
          this.state =  'rotated-270' 
           break; 
        }    
        case'360': { 
          this.state =  'rotated-360' 
           break; 
        }                
        default: { 
           //statements; 
           break; 
        } 
     }
    }
  }
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
closeBtn(){
  // Unsuscribe to all observables
  this._dialogRef.close();
};
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
}
