import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import { Directive, inject } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'underscore'; /* http://underscorejs.org/# */
import { FunctionsService } from './functions.service' 
import { FuseConfirmationService } from '@fuse/services/confirmation';
//
import { OrderService } from './orders.service';
import { SalesPointService } from './salesPoint.service';
import { DocsService } from './docs.service';
import { EmisorService } from './emisor.service';
import { CatalogService } from './catalog.service';
@Injectable({
  providedIn: 'root'
})

//####################################################

//####################################################
export class DteService { 
//####################################################
_codDoc=0;
_typeDoc='';
_globalTypeDoc='';
_configDte : any ={}
_uuidDTE : string = '0'
///
_ivaF       :number = 0.19
_ivaP       :number = 19
///
_flagDegub  : boolean = false;
_IsConnect  : boolean = false;
_glsStatus  : string
_rutEmisor  : string
//==
authDTE  : any
authDTE2 : any
//==
_configDteAll = [
                    {codDoc:  33,glsDoc:'',maxDetail:35,reduceDetail:17,maxRef:7 ,maxDiscount:3 ,codDoc2: 33   ,glsDoc2:'Factura'}
                   ,{codDoc:  34,glsDoc:'',maxDetail:35,reduceDetail:17,maxRef:7 ,maxDiscount:3 ,codDoc2: 34   ,glsDoc2:'Factura Exenta'}
                   ,{codDoc:  52,glsDoc:'',maxDetail:35,reduceDetail:17,maxRef:7 ,maxDiscount:3 ,codDoc2: 52   ,glsDoc2:'Guía Despacho'}
                   ,{codDoc:  39,glsDoc:'',maxDetail:35,reduceDetail:17,maxRef:7 ,maxDiscount:3 ,codDoc2: 39   ,glsDoc2:'Boleta'}
                   ,{codDoc:  41,glsDoc:'',maxDetail:35,reduceDetail:17,maxRef:7 ,maxDiscount:3 ,codDoc2: 41   ,glsDoc2:'Boleta Exenta'}
                   ,{codDoc:5001,glsDoc:'',maxDetail:35,reduceDetail:17,maxRef:7 ,maxDiscount:3 ,codDoc2: 5001 ,glsDoc2:'Ticket'}
                   ,{codDoc:5100,glsDoc:'',maxDetail:35,reduceDetail:17,maxRef:7 ,maxDiscount:3 ,codDoc2: 33   ,glsDoc2:'Cotización'}
                   ,{codDoc: 110,glsDoc:'',maxDetail:35,reduceDetail:17,maxRef:7 ,maxDiscount:3 ,codDoc2:110   ,glsDoc2:'Factura Exportación'}
                ];
//
totales :any={
                 MntNeto       : 0     
                ,MntExe        : 0   
                ,IVA           : 0 
                ,MntTotal      : 0     
                ,MntoNF        : 0   
                ,impAdicional  : 0
                ,ImptoReten    : 0 
                ,TpoMoneda     : 0
                }
summary :any={ quantity:0,products:0}
mnt     :any={MntDsc:0,MntRcg:0,PcrDsc:0,PcrRcg:0,MntNetoOri: 0}
//== 
jsonDTE:any={}
DTE:any={}
//
_DscRcg : any= [];
//=================================================================================
//==
orderCtz : any = {
                     language      : "es"
                    ,appToken      : null
                    ,uuidSSO       : null
                    ,ctz           : {}
                    ,ctzDiscount   : []
                    ,ctzDetail     : []
}
order : any = {
                 language      :"es"
                ,appToken      : null
                ,uuidSSO       : null
                ,order         : {}
                ,orderDiscount : []
                ,orderDetail   : []
                ,orderShipping : null
}
//
TBOL:any = {
    "Encabezado" :
                {
                "IdDoc"      : {
                                   TipoDTE     : null 
                                  ,Folio       : null 
                                  ,FchEmis     : null 
                                  ,IndServicio : null
                                  ,FchVenc     : null 

                              },
                "Emisor"     : null,
                "Receptor"   : null,
                "Totales"    : null,
        }
    ,"Detalle"      : []
    ,"SubTotInfo"   : []
    ,"DscRcgGlobal" : []
    ,"Referencia"   : []
}
//==      
TDTE :any= {
  "Encabezado" :
              {
                 "IdDoc"      : {
                                     TipoDTE     : null 
                                    ,Folio       : null                   
                                    ,FchEmis     : null 
                                    ,TpoTranVenta :1
                                    //,IndServicio : null
                                    ,FmaPago      :1
                                    ,FchVenc     : null 
                                    
                                    
                                    
                                },
                 "Emisor"     : null,
                 "RUTMandante": null,
                 "Receptor"   : null,
                 "RUTSolicita": null,
                 "Transporte" : null,
                 "Totales"    : null,
                 "OtraMoneda" : null
        }
  ,"Detalle"      : []
  ,"SubTotInfo"   : []
  ,"DscRcgGlobal" : []
  ,"Referencia"   : []
  ,"Comisiones"   : []
} 
//== 
TEXP : any = {
    "Encabezado" :
    {
       "IdDoc"      : {
                           TipoDTE     : null 
                          ,Folio       : null                   
                          ,FchEmis     : null 
                          ,FmaPagExp :1
                      },
       "Emisor"     : null,
       "RUTMandante": null,
       "Receptor"   : null,
       "RUTSolicita": null,
       "Transporte" : null,
       "Totales"    : null,
       "OtraMoneda" : null
}
,"Detalle"      : []
,"SubTotInfo"   : []
,"DscRcgGlobal" : []
,"Referencia"   : []
,"Comisiones"   : []
}
//==
TTKT :any= {
"Encabezado" :
  {
     "IdDoc"      : {
                         TipoTKT     : null 
                        ,Folio       : null                   
                        ,FchEmis     : null 
                        ,FchVenc     : null 
                        ,FmaPago     : 2
                    },
      "Emisor":{
                       RUTEmisor   : null
                      ,RznSoc      : null
                      ,NomFantasia : null
                      ,Direccion   : null
                      ,Comuna      : null
                      ,Ciudad      : null
                                      
                  },
        "Receptor":{
                     RUTRecep     : null
                    ,RznSocRecep  : null
                    ,CiudadRecep  : null
                    ,Contacto     : null
                    ,DirRecep     : null
                    ,CmnaRecep    : null
                 },
     "Totales"   : {
                       MntNeto       : 0     
                      ,MntExe        : 0   
                      ,IVA           : 0 
                      ,MntTotal      : 0     
                      ,MntoNF        : 0   
                      ,MntDesc       : 0
                      ,MntRcrg       : 0
                      ,MntStotal     : 0
                    }
}
,Detalle      : []
,QR :{
         url    : null
        ,title  : null
        ,tail   : null
        }
,DscRcgGlobal : []        
        
} 
//==
TADUANA : any = {
    Aduana: {
               CodModVenta      : null 
              ,CodClauVenta     : null 
              ,TotClauVenta     : null 
              ,CodViaTransp     : null 
              ,CodPtoEmbarque   : null 
              ,CodPtoDesemb     : null 
              ,CodUnidMedTara   : null 
              ,CodUnidPesoBruto : null 
              ,CodUnidPesoNeto  : null 
              ,TipoBultos        : {
                                         CodTpoBultos : null 
                                        ,CantBultos   : null 
                                        ,Marcas       : null 
                                        ,IdContainer  : null 
                                        ,Sello        : null 
                                        ,EmisorSello  : null 
                                  }
              ,TotBultos     : null
              ,MntFlete      : null
              ,MntSeguro     : null
              ,CodPaisRecep  : null
              ,CodPaisDestin : null
          }
        }
//==
//=================================================================================
_emisorData : any = undefined;
_token     : any;
//
infoEmpresa = {
    rut        : '1-9'
   ,rznSocial  : null
   ,giro       : null
   ,direccion  : null
   ,comuna     : null
   ,ciudad     : null
   ,rutClean   : null
   ,logo       : null
}
//
userData : any =  {
                         login        : ''
                        ,firstName    : ''
                        ,lastName     : ''
                        ,rutEmpresa   : ''
                        ,logoEmpresa  : ''
                        ,pin          : ''
                        ,envirometDTE : ''
                        ,envirometBOL : ''
                        ,envirometTKT : ''             
                }
//
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$|
constructor(
     private _fuseConfirmationService: FuseConfirmationService
    ,private _salesPointService      : SalesPointService
    ,private _fn                     : FunctionsService
    ,private _docsServ               : DocsService
    ,private _toastr                 : ToastrService     
    ,private _emisorService          : EmisorService 
    ,private _catalogService         : CatalogService
)
{

    //
    this._token = localStorage.getItem('appToken')
    this._emisorData = JSON.parse(localStorage.getItem('emisorData'));
    this._rutEmisor = this._emisorData.emisorData[0].RUT
    var tmp;
    tmp =  (JSON.parse(localStorage.getItem('ssoData')))
    this.userData = {
                         login        : tmp.user.login
                        ,firstName    : tmp.user.firstName
                        ,lastName     : tmp.user.lastName
                        ,pin          : this._emisorData.usrData[0].PIN
                        ,envirometDTE : this._emisorData.emisorData[0].COD_ENVIROMENT_DTE
                        ,envirometBOL : this._emisorData.emisorData[0].COD_ENVIROMENT_BOL
                        ,envirometTKT : this._emisorData.emisorData[0].COD_ENVIROMENT_TKT
                    }
    //
    this.infoEmpresa = {
                             rut        : this._fn._rutFormat(this._emisorData.emisorData[0].RUT)
                            ,rznSocial  : this._emisorData.emisorData[0].RAZON_SOCIAL
                            ,giro       : this._emisorData.emisorData[0].GIRO_IMPRESO
                            ,direccion  : this._emisorData.usrData[0].ADDRESS
                            ,comuna     : this._emisorData.usrData[0].GLS_COMUNA
                            ,ciudad     : this._emisorData.usrData[0].GLS_CIUDAD
                            ,rutClean   : this._emisorData.emisorData[0].RUT
                            ,logo       : null
                        };
    //
       

}
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
 getCustomerData(){
    
    return (this.infoEmpresa)
}
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
async discountCouponAt(flagDscRcgAT:boolean=true,spDetail:any = {},codSalesChannel: string = '0',codPmPayMeans: string = '0',uuidCrmCustomer: string = '0',quantitySales,amountSales): Promise<any>{
  let $this = this;
  return await new Promise(
    async function (resolve, reject) {
      //////////////////////////////////////////// 
      if(flagDscRcgAT == false){
        resolve([])        
      }else{
          var tmp: any ={},tmp2: any ={}
          var  cuponDescuentoAT :any= []
          if(spDetail.FLAG_APPLY_DISCOUNT_AT == 'Y'){
              //////////////////////////////////////////////////    
              tmp = await $this._salesPointService.discountCouponAT(codSalesChannel,codPmPayMeans,uuidCrmCustomer,amountSales,quantitySales);
              //cuponDescuentoAT=tmp.AtDiscountCoupon
              if(tmp.AtDiscountCoupon.length > 0){
                  //await this.dte.restartDscRcg()
                  for(let i=0;i<tmp.AtDiscountCoupon.length;i++){
                      //##
                      //await this.dte.setDscRcg(this.cuponDescuentoAT[i])
                      //##
                      cuponDescuentoAT.push(tmp.AtDiscountCoupon[i])
                     
                  }
                  resolve(cuponDescuentoAT)
              }else{
                  //############################################# 
                    resolve([])        
                  //#############################################
              }
              //////////////////////////////////////////////////  
              }else{
                resolve([])     
              }
        }
      ////////////////////////////////////////////               
    }) 
}
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
getConfig(){
    return (this._configDte)
}
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
async getDteData(): Promise<any> {
    let $this = this;
    return await new Promise(
      async function (resolve, reject) {
        //////////////////////////////////////////// 
        (await $this._docsServ.getRulesFormatByType($this.authDTE2, 0)).subscribe(
            (res) => {
              res = res.dataSet.ruleDte.ruleAgpcn;
             // console.log(res)
             resolve({
                         referencias   : res.filter(item => item.codAgpcn === '14' && item.codCndcn !='SET')
                        ,descuentos    : res.filter(item => item.codAgpcn === '18' )
                        ,vlrDescuentos : res.filter(item => item.codAgpcn === '19' )
                        ,fmaPago       : res.filter(item => item.codAgpcn === '20' && item.codCndcn !='3')
                        //
                        ,IndTraslado   : res.filter(item => item.codAgpcn === '21' )
                        ,TipoDespacho  : res.filter(item => item.codAgpcn === '22' )
            })
            },
            (err) => {
              console.log(err);
            });
        //////////////////////////////////////////// 
      })                 
  };
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
async getDteDataEx(): Promise<any> {
    let $this = this;
    return await new Promise(
      async function (resolve, reject) {
        //////////////////////////////////////////// 
        (await $this._docsServ.getRulesFormatByType($this.authDTE2, 0)).subscribe(
            (res) => {
              res = res.dataSet.ruleDte.ruleAgpcn;
             // console.log(res)
             resolve({
                         referencias      : res.filter(item => item.codAgpcn === '14' && item.codCndcn !='SET')
                        ,descuentos       : res.filter(item => item.codAgpcn === '18' )
                        ,vlrDescuentos    : res.filter(item => item.codAgpcn === '19' )
                        ,fmaPago          : res.filter(item => item.codAgpcn === '20' )
                        ,FmaPagExp        : res.filter(item => item.codAgpcn === '04' )
                        ,Nacionalidad     : res.filter(item => item.codAgpcn === '13' )
                        ,CodModVenta      : res.filter(item => item.codAgpcn === '06' )
                        ,CodClauVenta     : res.filter(item => item.codAgpcn === '02' )
                        ,CodViaTransp     : res.filter(item => item.codAgpcn === '07' )
                        ,CodPtoEmbarque   : res.filter(item => item.codAgpcn === '05' )
                        ,CodPtoDesemb     : res.filter(item => item.codAgpcn === '05' )
                        ,CodUnidMedTara   : res.filter(item => item.codAgpcn === '03' )
                        ,CodUnidPesoBruto : res.filter(item => item.codAgpcn === '03' )
                        ,CodUnidPesoNeto  : res.filter(item => item.codAgpcn === '03' )
                        ,CodTpoBultos     : res.filter(item => item.codAgpcn === '01' )
                        ,CodPaisRecep     : res.filter(item => item.codAgpcn === '13' )
                        ,CodPaisDestin    : res.filter(item => item.codAgpcn === '13' )
                        ,TpoMonedaDest    : res.filter(item => item.codAgpcn === '17' )
                        ,TpoMonedaOri     : res.filter(item => item.codAgpcn === '17' )
                        ,TipoDocID        : res.filter(item => item.codAgpcn === '23' )
            })
            },
            (err) => {
              console.log(err);
            });
        //////////////////////////////////////////// 
      })                 
  };
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
async getTc(month,dateTC,currency): Promise<any> {
    let $this = this;
    return await new Promise(
      async function (resolve, reject) {
        //////////////////////////////////////////// 
        if(month == null){
            month = (new Date().getMonth())+1;
        }
        //==
        if(dateTC == null){
            dateTC = $this._fn._formatDate(new Date(),'-')
        }
        //==
        if(currency == null){
            currency = 'USD'
        }        
        ////////////////////////////////////////////    
        try{
            var res =  await $this._catalogService.getCurrency(month)   
            resolve({
                tc : res.data.filter(i => i.COD_CURRENCY == currency && i.DATE_GREG == dateTC)[0].CURRENCY_VALUE
             })
        }catch(e){
            resolve({tc : 1200})
        }
        //////////////////////////////////////////// 
      })                 
  };
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
async initDte(codDoc,flagDegub){
    this._codDoc=codDoc
    this._flagDegub = flagDegub
    //==
     this._configDte = await this._configDteAll.filter(item => item.codDoc == codDoc)[0]
     this._configDte.glsDoc = this._getGlsDoc()
    //==
    var tmp = await this._emisorService.getLogo()
    this.infoEmpresa.logo = tmp.logo
    //==
    if(codDoc == 39 || codDoc == 41){
        //=======
        this._typeDoc='BOL';
        this._globalTypeDoc='BOL'; 
        this.jsonDTE = JSON.parse(JSON.stringify(this.TBOL))
        this.authDTE  = await this.makeAuthInvoice(codDoc);
        this.authDTE2 = await this.makeAuthInvoice(this._configDte.codDoc2);
        //=======        
    }else if(codDoc == 5100){
        //=======        
        this._typeDoc='CTZ';
        this._globalTypeDoc='DTE';
        this.jsonDTE = JSON.parse(JSON.stringify(this.TDTE))
        this.authDTE  = await this.makeAuthInvoice(codDoc);
        this.authDTE2 = await this.makeAuthInvoice(this._configDte.codDoc2);
        //=======        
    }else if(codDoc == 5001){
        //=======        
        this._typeDoc='TKT';
        this._globalTypeDoc='BOL';
        this.jsonDTE = JSON.parse(JSON.stringify(this.TBOL))
        this.authDTE  = await this.makeAuthInvoice(codDoc);
        this.authDTE2 = await this.makeAuthInvoice(this._configDte.codDoc2);
        //=======        
    }else{
        //=======        
        this._typeDoc='DTE';
        this._globalTypeDoc='DTE';
        this.jsonDTE = JSON.parse(JSON.stringify(this.TDTE))
        this.authDTE  = await this.makeAuthInvoice(codDoc);
        this.authDTE2 = await this.makeAuthInvoice(this._configDte.codDoc2);
        //=======        
    }
    return true;
}
////////////////////////////////////////       
////////////////////////////////////////
async makeAuthInvoice(TipoDTE): Promise<any>{  
    let $this = this;
    return await new Promise(
      async function (resolve, reject) {
        var auth,token
          //////////////////////////////////////////// 
          if(TipoDTE == 39 || TipoDTE == 41){
                auth = {"appToken":$this._token,"language":"es","environment": $this.userData.envirometBOL,"user":$this.userData.login, "passwd":null, "pin":$this.userData.pin,"log":false};
          }
          else if(TipoDTE == 5001 || TipoDTE == 5100  ){ // hacer clase para tratar los tkt
                auth = {"appToken":$this._token,"language":"es","environment": $this.userData.envirometTKT,"user":$this.userData.login, "passwd":null, "pin":$this.userData.pin,"log":false};
         }
           else{
                auth = {"appToken":$this._token,"language":"es","environment": $this.userData.envirometDTE,"user":$this.userData.login, "passwd":null, "pin":$this.userData.pin,"log":false};
         }
                ////////////////////////////////////////////  
         resolve(auth)
        }); 
  }
////////////////////////////////////////       
////////////////////////////////////////
_consoleLog(txt){
    if( this._flagDegub == true)
        console.log(txt)
    return(true)
}
////////////////////////////////////////       
////////////////////////////////////////
_getGlsDoc(){
    switch (this._codDoc) {
      case 33: return('FACTURA ELECTRÓNICA')
        break;
      case 34: return('FACTURA NO AFECTA O EXENTA ELECTRÓNICA')
        break;
      case 46: return('FACTURA DE COMPRA ELECTRÓNICA')
        break;
      case 61: return('NOTA DE CRÉDITO ELECTRÓNICA')
        break;
      case 56: return('NOTA DE DÉBITO ELECTRÓNICA')
        break;
      case 52: return('GUÍA DE DESPACHO ELECTRÓNICA')
        break;
      case 43: return('LIQUIDACIÓN FACTURA ELECTRÓNICA')
        break;
      //
      case 39: return('BOLETA ELECTRÓNICA')
        break;
      case 41: return('BOLETA NO AFECTA O EXENTA ELECTRÓNICA')
        break;          
      //
      case 110: return('FACTURA DE EXPORTACIÓN ELECTRÓNICA')
        break;
      case 111: return('NOTA DE DÉBITO DE EXPORTACIÓN ELECTRÓNICA')
        break;
      case 112: return('NOTA DE CRÉDITO DE EXPORTACIÓN ELECTRÓNICA')
        break;
      //
      case 5001: return('TICKET')
        break;                      
      case 5100: return('COTIZACIÓN')
        break;                            
    }
  }
////////////////////////////////////////       
////////////////////////////////////////
async _calculateDiscount(detalle){
  let $this = this;
  return await new Promise(
    async function (resolve, reject) {
       ////////////////////////////////////////////
       var rule=0,global=0,amount=0,i=0,quantity=0,quantityFIX=0,nameDiscountGlobal='',nameDiscountRule='',uuidPsServicesDiscountGlobal='0',uuidPsServicesDiscountRule='0'
       var priceCLP = detalle.PrcItem//detalle.extraData.PRICE.PRICE_CLP
       var flagGlobalDiscount  = 'Y'  // global | rule
       var fullFillment        = 'T0' 
       var nameDiscount        = ''  // Glosa Descuento
       var typeDiscount        = 'P'  // Percent o Amount
       var percentDiscount     = 0  // Porcentaje Descuento
       var amountDiscount      = 0  // Monto Descuento
       var flagQuantityLimit   = 'N'                         
       var quantityLimit       = 0                 
       var flagAmountLimit     = 'N'                     
       var amountLimitClp      = 0
       var flagQuantityStart   = 'N'                     
       var quantityStart       = 0                 
       var flagQuantityEnd     = 'N'                 
       var quantityEnd         = 0             
       var flagAmountStart     = 'N'                 
       var amountStartClp      = 0                 
       var flagAmountEnd       = 'N'                 
       var amountEndClp        = 0    
       var uuidPsServicesDiscount =''
       try{
          //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
          if(detalle.extraData.PRICE.FLAG_QUANTITY_LIMIT == 'Y'){
            
            if(detalle.QtyItem > detalle.extraData.PRICE.QUANTITY_LIMIT){
                flagQuantityLimit = 'Y'
                quantityLimit     = detalle.extraData.PRICE.QUANTITY_LIMIT 
              quantityFIX = detalle.extraData.PRICE.QUANTITY_LIMIT
            }else{
              quantityFIX = detalle.QtyItem
            }
          }else{
            quantityFIX = detalle.QtyItem
          }
          //===========================
           //* $this.ivaF
           //$this.typeDoc == 'TKT'
              //=====================================
              if(detalle.extraData.PRICE.FLAG_GLOBAL_DISCOUNT == 'Y'){
                //==================
                  if(detalle.extraData.PRICE.TYPE_PS_SERVICES_DISCOUNT == 'P'){
                    //##############################################           
                    /////////////////// 
                    amount = priceCLP * quantityFIX
                    ///////////////////
                      if($this._globalTypeDoc == 'DTE')
                          global = Math.round( amount *  (detalle.extraData.PRICE.DISCOUNT_CLP));
                      else
                          global = Math.round( amount *  detalle.extraData.PRICE.DISCOUNT_CLP );
                    ///////////////////
                        typeDiscount            = detalle.extraData.PRICE.TYPE_PS_SERVICES_DISCOUNT
                        flagGlobalDiscount      = detalle.extraData.PRICE.FLAG_GLOBAL_DISCOUNT
                        percentDiscount         = detalle.extraData.PRICE.DISCOUNT_CLP    
                        nameDiscount            = detalle.extraData.PRICE.NAME_DISCOUNT    
                        uuidPsServicesDiscount  = detalle.extraData.PRICE.UUID_PS_SERVICES_DISCOUNT
                        fullFillment            = 'G'
                        //
                    nameDiscountGlobal           = detalle.extraData.PRICE.NAME_DISCOUNT   
                    uuidPsServicesDiscountGlobal = detalle.extraData.PRICE.UUID_PS_SERVICES_DISCOUNT                   
                    //##############################################                    
                  }
                  if(detalle.extraData.PRICE.TYPE_PS_SERVICES_DISCOUNT == 'A'){
                    //##############################################
                    ///////////////////
                      if($this._globalTypeDoc == 'DTE')
                         global = Math.round( (detalle.extraData.PRICE.DISCOUNT_CLP/ (1+$this._ivaF)) * quantityFIX)
                      else
                         global = Math.round( detalle.extraData.PRICE.DISCOUNT_CLP * quantityFIX)
                    ///////////////////  
                        typeDiscount            = detalle.extraData.PRICE.TYPE_PS_SERVICES_DISCOUNT
                        flagGlobalDiscount      = detalle.extraData.PRICE.FLAG_GLOBAL_DISCOUNT
                        percentDiscount         = detalle.extraData.PRICE.DISCOUNT_CLP    
                        nameDiscount            = detalle.extraData.PRICE.NAME_DISCOUNT    
                        uuidPsServicesDiscount  = detalle.extraData.PRICE.UUID_PS_SERVICES_DISCOUNT
                        fullFillment            = 'G'
                        //                                      
                    nameDiscountGlobal           = detalle.extraData.PRICE.NAME_DISCOUNT   
                    uuidPsServicesDiscountGlobal = detalle.extraData.PRICE.UUID_PS_SERVICES_DISCOUNT              
                    //##############################################
                  }
                //==================
              }
                //==================
                //T0
                //ET Descuento Escalonado sobre Total
                //EM Descuento Escalonado, a paritr del minimo
                //TB Descuento por Rango               
                //==================
                  if(detalle.extraData.PRICE.DISCOUNTS_RULE.length > 0){
                    //##############################################
                      //RULE
                      for(i=0;i<detalle.extraData.PRICE.DISCOUNTS_RULE.length;i++){
                        /////////////////////////////////////////////////////////
                         
                          //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
                          flagGlobalDiscount = 'N'
                          //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$                                                    
                          if(detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_FULL_FILLMENT == 'ET'){
                            amount = priceCLP * quantityFIX
                              if(detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_AMOUNT_START   =='Y'){
                              //
                                if(amount > detalle.extraData.PRICE.DISCOUNTS_RULE[i].AMOUNT_START_CLP ){
                                  //
                                  if(detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT == 'P'){
                                    ///////////////////
                                        if($this._globalTypeDoc == 'DTE')
                                          rule = Math.round( amount * (detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP/ (1+$this._ivaF)))
                                      else
                                          rule = Math.round( amount * detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP)
                                    ///////////////////                                    
                                    flagAmountStart            = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_AMOUNT_START
                                    flagAmountEnd              = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_AMOUNT_END
                                    typeDiscount               = detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT
                                    amountStartClp             = detalle.extraData.PRICE.DISCOUNTS_RULE[i].AMOUNT_START_CLP
                                    amountEndClp               = detalle.extraData.PRICE.DISCOUNTS_RULE[i].AMOUNT_END_CLP
                                    percentDiscount            = detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP
                                    amountDiscount             = 0
                                    nameDiscountRule           = detalle.extraData.PRICE.DISCOUNTS_RULE[i].NAME_DISCOUNT
                                    uuidPsServicesDiscountRule = detalle.extraData.PRICE.DISCOUNTS_RULE[i].UUID_PS_SERVICES_DISCOUNT                                       
                                    //
                                  }
                                  if(detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT == 'A'){
                                    ///////////////////
                                        if($this._globalTypeDoc == 'DTE')
                                          rule = Math.round( quantityFIX * (detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP/(1+$this._ivaF)))
                                      else
                                          rule = Math.round( quantityFIX * detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP)
                                    ///////////////////                                     
                                    flagAmountStart            = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_AMOUNT_START
                                    flagAmountEnd              = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_AMOUNT_END
                                    typeDiscount               = detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT
                                    amountStartClp             = detalle.extraData.PRICE.DISCOUNTS_RULE[i].AMOUNT_START_CLP
                                    amountEndClp               = detalle.extraData.PRICE.DISCOUNTS_RULE[i].AMOUNT_END_CLP
                                    percentDiscount            = 0
                                    amountDiscount             = detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP
                                    nameDiscountRule           = detalle.extraData.PRICE.DISCOUNTS_RULE[i].NAME_DISCOUNT
                                    uuidPsServicesDiscountRule = detalle.extraData.PRICE.DISCOUNTS_RULE[i].UUID_PS_SERVICES_DISCOUNT                                       
                                    //
                                  }
                                  //
                                }
                              //
                              }
                              if(detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_QUANTITY_START == 'Y'){
                              //
                                if(detalle.QtyItem > detalle.extraData.PRICE.DISCOUNTS_RULE[i].QUANTITY_START ){
                                 
                                  //
                                  if(detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT == 'P'){
                                    ///////////////////
                                        if($this._globalTypeDoc == 'DTE')
                                          rule = Math.round( amount * (detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP))
                                      else
                                          rule = Math.round( amount * detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP)
                                    ///////////////////                                       
                                    flagQuantityStart          = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_QUANTITY_START
                                    flagQuantityEnd            = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_QUANTITY_END 
                                    typeDiscount               = detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT
                                    quantityStart              = detalle.extraData.PRICE.DISCOUNTS_RULE[i].QUANTITY_START
                                    quantityEnd                = detalle.extraData.PRICE.DISCOUNTS_RULE[i].QUANTITY_END
                                    percentDiscount            = detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP
                                    amountDiscount             = 0
                                    nameDiscountRule           = detalle.extraData.PRICE.DISCOUNTS_RULE[i].NAME_DISCOUNT
                                    uuidPsServicesDiscountRule = detalle.extraData.PRICE.DISCOUNTS_RULE[i].UUID_PS_SERVICES_DISCOUNT                                       
                                    //
                                  }
                                  if(detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT == 'A'){
                                    ///////////////////
                                        if($this._globalTypeDoc == 'DTE')
                                          rule = Math.round( quantityFIX * (detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP/(1+$this._ivaF)))
                                      else
                                          rule = Math.round( quantityFIX * detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP)
                                    ///////////////////                                      
                                    flagQuantityStart          = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_QUANTITY_START
                                    flagQuantityEnd            = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_QUANTITY_END 
                                    typeDiscount               = detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT
                                    quantityStart              = detalle.extraData.PRICE.DISCOUNTS_RULE[i].QUANTITY_START
                                    quantityEnd                = detalle.extraData.PRICE.DISCOUNTS_RULE[i].QUANTITY_END
                                    percentDiscount            = 0
                                    amountDiscount             = detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP
                                    nameDiscountRule           = detalle.extraData.PRICE.DISCOUNTS_RULE[i].NAME_DISCOUNT
                                    uuidPsServicesDiscountRule = detalle.extraData.PRICE.DISCOUNTS_RULE[i].UUID_PS_SERVICES_DISCOUNT                                       
                                    //
                                  }
                                  //
                                }
                              //
                              }                     
                          }
                          //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
                          //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$                          
                          if(detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_FULL_FILLMENT == 'EM'){
                            fullFillment = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_FULL_FILLMENT
                            if(detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_AMOUNT_START   =='Y'){
                                flagAmountStart = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_AMOUNT_START
                              //
                              quantity = quantityFIX - detalle.extraData.PRICE.DISCOUNTS_RULE[i].QUANTITY_START
                              amount = (priceCLP * quantityFIX) -  detalle.extraData.PRICE.DISCOUNTS_RULE[i].AMOUNT_START_CLP
                                if(amount > 0 ){
                                  //
                                  if(detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT == 'P'){
                                    ///////////////////
                                        if($this._globalTypeDoc == 'DTE')
                                          rule = Math.round( amount * (detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP))
                                      else
                                          rule = Math.round( amount * detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP)
                                    ///////////////////                                       
                                    flagAmountStart            = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_AMOUNT_START
                                    flagAmountEnd              = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_AMOUNT_END
                                    typeDiscount               = detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT
                                    amountStartClp             = detalle.extraData.PRICE.DISCOUNTS_RULE[i].AMOUNT_START_CLP
                                    amountEndClp               = detalle.extraData.PRICE.DISCOUNTS_RULE[i].AMOUNT_END_CLP
                                    percentDiscount            = detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP
                                    amountDiscount             = 0
                                    nameDiscountRule           = detalle.extraData.PRICE.DISCOUNTS_RULE[i].NAME_DISCOUNT
                                    uuidPsServicesDiscountRule = detalle.extraData.PRICE.DISCOUNTS_RULE[i].UUID_PS_SERVICES_DISCOUNT                                       
                                    //
                                  }
                                  if(detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT == 'A'){
                                    ///////////////////
                                        if($this._globalTypeDoc == 'DTE')
                                          rule = Math.round( quantity * (detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP/(1+$this._ivaF)))
                                      else
                                          rule = Math.round( quantity * detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP)
                                    ///////////////////                                       
                                    flagAmountStart            = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_AMOUNT_START
                                    flagAmountEnd              = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_AMOUNT_END
                                    typeDiscount               = detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT
                                    amountStartClp             = detalle.extraData.PRICE.DISCOUNTS_RULE[i].AMOUNT_START_CLP
                                    amountEndClp               = detalle.extraData.PRICE.DISCOUNTS_RULE[i].AMOUNT_END_CLP
                                    percentDiscount            =  0
                                    amountDiscount             = detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP
                                    nameDiscountRule           = detalle.extraData.PRICE.DISCOUNTS_RULE[i].NAME_DISCOUNT
                                    uuidPsServicesDiscountRule = detalle.extraData.PRICE.DISCOUNTS_RULE[i].UUID_PS_SERVICES_DISCOUNT                                       
                                    //
                                  }
                                  //
                                }
                              //
                              }
                              if(detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_QUANTITY_START == 'Y'){
                              //
                              quantity = quantityFIX - detalle.extraData.PRICE.DISCOUNTS_RULE[i].QUANTITY_START
                              amount   = (priceCLP * quantity) 
                                if(quantityFIX > detalle.extraData.PRICE.DISCOUNTS_RULE[i].QUANTITY_START ){
                                  //
                                  if(detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT == 'P'){
                                    ///////////////////
                                        if($this._globalTypeDoc == 'DTE')
                                          rule = Math.round( amount * (detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP))
                                      else
                                          rule = Math.round( amount * detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP)
                                    ///////////////////                                      
                                    flagQuantityStart          = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_QUANTITY_START
                                    flagQuantityEnd            = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_QUANTITY_END 
                                    typeDiscount               = detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT
                                    quantityStart              = detalle.extraData.PRICE.DISCOUNTS_RULE[i].QUANTITY_START
                                    quantityEnd                = detalle.extraData.PRICE.DISCOUNTS_RULE[i].QUANTITY_END
                                    percentDiscount            = detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP
                                    amountDiscount             = 0
                                    nameDiscountRule           = detalle.extraData.PRICE.DISCOUNTS_RULE[i].NAME_DISCOUNT
                                    uuidPsServicesDiscountRule = detalle.extraData.PRICE.DISCOUNTS_RULE[i].UUID_PS_SERVICES_DISCOUNT                                       
                                    //
                                  }
                                  if(detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT == 'A'){
                                    ///////////////////
                                        if($this._globalTypeDoc == 'DTE')
                                          rule = Math.round( quantity * (detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP/(1+$this._ivaF)))
                                      else
                                          rule = Math.round( quantity * detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP)
                                    ///////////////////                                     
                                    flagQuantityStart          = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_QUANTITY_START
                                    flagQuantityEnd            = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_QUANTITY_END 
                                    typeDiscount               = detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT
                                    quantityStart              = detalle.extraData.PRICE.DISCOUNTS_RULE[i].QUANTITY_START
                                    quantityEnd                = detalle.extraData.PRICE.DISCOUNTS_RULE[i].QUANTITY_END
                                    percentDiscount            = 0
                                    amountDiscount             = detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP
                                    nameDiscountRule           = detalle.extraData.PRICE.DISCOUNTS_RULE[i].NAME_DISCOUNT
                                    uuidPsServicesDiscountRule = detalle.extraData.PRICE.DISCOUNTS_RULE[i].UUID_PS_SERVICES_DISCOUNT                                       
                                    //
                                  }
                                  //
                                }
                              //
                              }                                                                       
                          }  
                          //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
                          //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$                          
                          if(detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_FULL_FILLMENT == 'TB'){
                            fullFillment = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_FULL_FILLMENT
                            if(detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_AMOUNT_START == 'Y' && detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_AMOUNT_END == 'Y'){
                              //
                              amount = priceCLP * quantityFIX
                                if(amount > 0 ){
                                  //
                                  if(detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT == 'P'){
                                    if(amount >= detalle.extraData.PRICE.DISCOUNTS_RULE[i].AMOUNT_START_CLP && amount <= detalle.extraData.PRICE.DISCOUNTS_RULE[i].AMOUNT_END_CLP){
                                      //           
                                      rule = Math.round( amount * detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP)
                                      ///////////////////
                                          if($this._globalTypeDoc == 'DTE')
                                            rule = Math.round( amount * (detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP))
                                        else
                                            rule = Math.round( amount * detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP)
                                      ///////////////////                                        
                                      flagAmountStart            = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_AMOUNT_START
                                      flagAmountEnd              = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_AMOUNT_END
                                      typeDiscount               = detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT
                                      amountStartClp             = detalle.extraData.PRICE.DISCOUNTS_RULE[i].AMOUNT_START_CLP
                                      amountEndClp               = detalle.extraData.PRICE.DISCOUNTS_RULE[i].AMOUNT_END_CLP
                                      percentDiscount            = detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP
                                      amountDiscount             = 0
                                      nameDiscountRule           = detalle.extraData.PRICE.DISCOUNTS_RULE[i].NAME_DISCOUNT
                                      uuidPsServicesDiscountRule = detalle.extraData.PRICE.DISCOUNTS_RULE[i].UUID_PS_SERVICES_DISCOUNT                                       
                                      //
                                    }
                                  }
                                  if(detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT == 'A'){
                                    if(amount >= detalle.extraData.PRICE.DISCOUNTS_RULE[i].AMOUNT_START_CLP && amount <= detalle.extraData.PRICE.DISCOUNTS_RULE[i].AMOUNT_END_CLP){
                                      ///////////////////
                                          if($this._globalTypeDoc == 'DTE')
                                            rule = Math.round( quantityFIX * (detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP/(1+$this._ivaF)))
                                        else
                                            rule = Math.round( quantityFIX * detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP)
                                      ///////////////////                                       
                                      flagAmountStart            = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_AMOUNT_START
                                      flagAmountEnd              = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_AMOUNT_END
                                      typeDiscount               = detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT
                                      amountStartClp             = detalle.extraData.PRICE.DISCOUNTS_RULE[i].AMOUNT_START_CLP
                                      amountEndClp               = detalle.extraData.PRICE.DISCOUNTS_RULE[i].AMOUNT_END_CLP
                                      percentDiscount            = 0
                                      amountDiscount             = detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP
                                      nameDiscountRule           = detalle.extraData.PRICE.DISCOUNTS_RULE[i].NAME_DISCOUNT
                                      uuidPsServicesDiscountRule = detalle.extraData.PRICE.DISCOUNTS_RULE[i].UUID_PS_SERVICES_DISCOUNT                                       
                                      //
                                    }
                                  }
                                  //
                                }
                              //
                              }
                              if(detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_QUANTITY_START == 'Y' && detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_QUANTITY_END == 'Y'){
                              //
                                if(quantityFIX>0 ){
                                  //
                                  amount = priceCLP * quantityFIX
                                  if(detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT == 'P'){
                                    if(quantityFIX >= detalle.extraData.PRICE.DISCOUNTS_RULE[i].QUANTITY_START && detalle.QtyItem <= detalle.extraData.PRICE.DISCOUNTS_RULE[i].QUANTITY_END ){
                                      rule = Math.round( amount *detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP)
                                      ///////////////////
                                          if($this._globalTypeDoc == 'DTE')
                                            rule = Math.round( amount * (detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP))
                                        else
                                            rule = Math.round( amount * detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP)
                                      ///////////////////                                        
                                      flagQuantityStart          = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_QUANTITY_START
                                      flagQuantityEnd            = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_QUANTITY_END 
                                      typeDiscount               = detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT
                                      quantityStart              = detalle.extraData.PRICE.DISCOUNTS_RULE[i].QUANTITY_START
                                      quantityEnd                = detalle.extraData.PRICE.DISCOUNTS_RULE[i].QUANTITY_END
                                      percentDiscount            = detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP
                                      amountDiscount             = 0
                                      nameDiscountRule           = detalle.extraData.PRICE.DISCOUNTS_RULE[i].NAME_DISCOUNT
                                      uuidPsServicesDiscountRule = detalle.extraData.PRICE.DISCOUNTS_RULE[i].UUID_PS_SERVICES_DISCOUNT                                       
                                      //
                                    }
                                  }
                                  if(detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT == 'A'){
                                    if(detalle.QtyItem >= detalle.extraData.PRICE.DISCOUNTS_RULE[i].QUANTITY_START && detalle.QtyItem <= detalle.extraData.PRICE.DISCOUNTS_RULE[i].QUANTITY_END ){
                                      ///////////////////
                                          if($this._globalTypeDoc == 'DTE')
                                            rule = Math.round( quantityFIX * (detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP/(1+$this._ivaF)))
                                        else
                                            rule = Math.round( quantityFIX * detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP)
                                      ///////////////////                                         
                                      flagQuantityStart          = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_QUANTITY_START
                                      flagQuantityEnd            = detalle.extraData.PRICE.DISCOUNTS_RULE[i].FLAG_QUANTITY_END 
                                      typeDiscount               = detalle.extraData.PRICE.DISCOUNTS_RULE[i].TYPE_PS_SERVICES_DISCOUNT
                                      quantityStart              = detalle.extraData.PRICE.DISCOUNTS_RULE[i].QUANTITY_START
                                      quantityEnd                = detalle.extraData.PRICE.DISCOUNTS_RULE[i].QUANTITY_END
                                      percentDiscount            = 0
                                      amountDiscount             = detalle.extraData.PRICE.DISCOUNTS_RULE[i].DISCOUNT_CLP
                                      nameDiscountRule           = detalle.extraData.PRICE.DISCOUNTS_RULE[i].NAME_DISCOUNT
                                      uuidPsServicesDiscountRule = detalle.extraData.PRICE.DISCOUNTS_RULE[i].UUID_PS_SERVICES_DISCOUNT                                       
                                      //
                                    }
                                  }
                                  //
                                }
                              //
                              }                             
                                                                                
                          }  
                          //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
                          //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$                                                                                                   
                        /////////////////////////////////////////////////////////                        
                      }
                    //##############################################                                        
                  }
                //==================                                
          //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!   
            if(detalle.extraData.PRICE.FLAG_AMOUNT_LIMIT == 'Y'){
              if(global > detalle.extraData.PRICE.AMOUNT_LIMIT_CLP ){
                flagAmountLimit     = detalle.extraData.PRICE.FLAG_AMOUNT_LIMIT                     
                amountLimitClp      = detalle.extraData.PRICE.AMOUNT_LIMIT_CLP
                global = detalle.extraData.PRICE.AMOUNT_LIMIT_CLP
              }
              if(rule > detalle.extraData.PRICE.AMOUNT_LIMIT_CLP ){
                flagAmountLimit     = detalle.extraData.PRICE.FLAG_AMOUNT_LIMIT                     
                amountLimitClp      = detalle.extraData.PRICE.AMOUNT_LIMIT_CLP                
                rule = detalle.extraData.PRICE.AMOUNT_LIMIT_CLP
              }              
            }
            //===========================                    
          //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! 
          if(global > 0 || rule > 0){  
            //==
            amount = priceCLP * detalle.QtyItem
            if(global > rule){
                //#########################
                if(typeDiscount == 'A'){
                    percentDiscount = Math.round((global/amount)* 100 )  
                }else{
                    percentDiscount = Math.round(percentDiscount* 100 )  
                }
                //==
                resolve({ 
                             success                : true
                            ,flagGlobalDiscount     : 'Y'
                            ,fullFillment           : 'T0'
                            ,nameDiscount           : nameDiscountGlobal    
                            //    
                            ,typeDiscount           : detalle.extraData.PRICE.TYPE_PS_SERVICES_DISCOUNT   
                            ,percentDiscount        : percentDiscount
                            ,amountDiscount         : global      
                            ,subTotal               : amount - global
                            //
                            ,flagQuantityLimit      : flagQuantityLimit     
                            ,quantityLimit          : quantityLimit       
                            ,flagAmountLimit        : flagAmountLimit     
                            ,amountLimitClp         : amountLimitClp      
                            ,flagQuantityStart      : 'N'
                            ,quantityStart          : 0
                            ,flagQuantityEnd        : 'N'
                            ,quantityEnd            : 0
                            ,flagAmountStart        : 'N'
                            ,amountStartClp         : 0
                            ,flagAmountEnd          : 'N'
                            ,amountEndClp           : 0     
                            ,uuidPsServicesDiscount : uuidPsServicesDiscountGlobal
                        })
                //#########################
            }
            //==
            if(rule > global){
                //#########################
                if(typeDiscount == 'A'){
                    percentDiscount = Math.round((rule/amount)* 100 )  
                }else{
                    percentDiscount = Math.round(percentDiscount* 100 )  
                }
                //==
                resolve({ 
                             success                : true
                            ,flagGlobalDiscount     : flagGlobalDiscount  
                            ,fullFillment           : fullFillment        
                            ,nameDiscount           : nameDiscountRule    
                            //    
                            ,typeDiscount           : typeDiscount        
                            ,percentDiscount        : percentDiscount  
                            ,amountDiscount         : rule     
                            ,subTotal               : amount - rule
                            // 
                            ,flagQuantityLimit      : flagQuantityLimit     
                            ,quantityLimit          : quantityLimit       
                            ,flagAmountLimit        : flagAmountLimit     
                            ,amountLimitClp         : amountLimitClp      
                            ,flagQuantityStart      : flagQuantityStart   
                            ,quantityStart          : quantityStart       
                            ,flagQuantityEnd        : flagQuantityEnd     
                            ,quantityEnd            : quantityEnd         
                            ,flagAmountStart        : flagAmountStart     
                            ,amountStartClp         : amountStartClp      
                            ,flagAmountEnd          : flagAmountEnd       
                            ,amountEndClp           : amountEndClp        
                            ,uuidPsServicesDiscount : uuidPsServicesDiscountRule
                        })
                //#########################
            }            
            //==          
          }else{
            //==
            amount = priceCLP * detalle.QtyItem
            //==
                resolve({ 
                         success                : true
                        ,flagGlobalDiscount     : 'N'
                        ,fullFillment           : 'T0'
                        ,nameDiscount           : ''    
                        //    
                        ,typeDiscount           : ''
                        ,percentDiscount        : 0
                        ,amountDiscount         : 0
                        ,subTotal               : amount
                        //
                        ,flagQuantityLimit      : 'N'   
                        ,quantityLimit          : 0       
                        ,flagAmountLimit        : 'N'   
                        ,amountLimitClp         : 0      
                        ,flagQuantityStart      : 'N'
                        ,quantityStart          : 0
                        ,flagQuantityEnd        : 'N'
                        ,quantityEnd            : 0
                        ,flagAmountStart        : 'N'
                        ,amountStartClp         : 0
                        ,flagAmountEnd          : 'N'
                        ,amountEndClp           : 0     
                        ,uuidPsServicesDiscount : '0'
                    })
            //==
          }
          
       }
       catch(e)
       {
           console.log(e)
           reject({success:false,error:e})
     }
}); 
  }
////////////////////////////////////////       
////////////////////////////////////////
async chkDte(flagDscRcgAT:boolean=true,spDetail:any={},infoDocForm:any={},receptorForm:any={},customerRelatedContact:any=[],flagShowDetail:boolean=true,dataPS:any=[],dataREF:any=[],dataDsc:any=[],transporteForm:any={},customerRelatedAddress:any=[],aduanaForm:any={},monedaForm:any={},tipoBultosForm:any={}):Promise<any>{
    let $this = this;
    return await new Promise(
      async function (resolve, reject) { 
         ////////////////////////////////////////////
         try{
            ////////////////////////////////////////////   
                var i=0,j=0,index=0,quantity=0,glsErr='',tmp,tmp2,DscItem;
                var quantitySales: number =0,amountSales: number =0
                //
                    if($this._codDoc == 39   || $this._codDoc == 41) $this.jsonDTE = JSON.parse(JSON.stringify($this.TBOL))
                    if($this._codDoc == 33   || $this._codDoc == 34) $this.jsonDTE = JSON.parse(JSON.stringify($this.TDTE))
                    if($this._codDoc == 5001 )                       $this.jsonDTE = JSON.parse(JSON.stringify($this.TTKT))                        
                    if($this._codDoc == 5100 )                       $this.jsonDTE = JSON.parse(JSON.stringify($this.TDTE))                        
                    if($this._codDoc == 41   )                       $this.jsonDTE = JSON.parse(JSON.stringify($this.TDTE))
                    if($this._codDoc == 46   )                       $this.jsonDTE = JSON.parse(JSON.stringify($this.TDTE))
                    if($this._codDoc == 52   )                       $this.jsonDTE = JSON.parse(JSON.stringify($this.TDTE))              
                    if($this._codDoc == 110  )                       $this.jsonDTE = JSON.parse(JSON.stringify($this.TEXP))                         
                //$
                if($this._codDoc == 39 || $this._codDoc == 41 ){
                    //======================================================================================================
                    $this.jsonDTE.Encabezado.IdDoc.TipoDTE=$this._codDoc
                    $this.jsonDTE.Encabezado.IdDoc.FchEmis=$this._fn._formatDate(infoDocForm.fechaEmisionInfo,'-')   
                    $this.jsonDTE.Encabezado.IdDoc.FchVenc=$this._fn._formatDate(infoDocForm.fechaVencimientoInfo,'-')
                    $this.jsonDTE.Encabezado.IdDoc.IndServicio = 3;
                    //
                    $this.jsonDTE.Encabezado.Emisor   = { "RUTEmisor": $this._rutEmisor}
                    $this.jsonDTE.Encabezado.Receptor = { "RUTRecep" : receptorForm.rutReceptor }
                    ////////////////////////////////////////////
                    /*
                        if(receptorForm.uuidContactoReceptor.length > 0 ){
                            tmp = customerRelatedContact.filter(i => i.UUID_CRM_CUSTOMER_CONTACT == receptorForm.uuidContactoReceptor)[0]
                            $this.jsonDTE.Encabezado.Receptor.Contacto    = tmp.FIRST_NAME + ' ' +  tmp.LAST_NAME
                            $this.jsonDTE.Encabezado.Receptor.CorreoRecep = tmp.MAIL
                        }
                    */
                    ////////////////////////////////////////////                     
                    //======================================================================================================                    
            }else if($this._codDoc == 5001) {
                    //======================================================================================================                
                    $this.jsonDTE.Encabezado.IdDoc.TipoTKT=$this._configDte.codDoc2
                    $this.jsonDTE.Encabezado.IdDoc.FchEmis=$this._fn._formatDate(infoDocForm.fechaEmisionInfo,'-')   
                    $this.jsonDTE.Encabezado.IdDoc.FmaPago=infoDocForm.fmaPago
                    $this.jsonDTE.Encabezado.IdDoc.FchVenc=$this._fn._formatDate(infoDocForm.fechaVencimientoInfo,'-')
                    //
                    $this.jsonDTE.Encabezado.Emisor   = { "RUTEmisor": $this._rutEmisor}
                    $this.jsonDTE.Encabezado.Receptor = { "RUTRecep" : receptorForm.rutReceptor }    
                    ////////////////////////////////////////////
                    /*
                        if(receptorForm.uuidContactoReceptor.length > 0 ){
                            tmp = customerRelatedContact.filter(i => i.UUID_CRM_CUSTOMER_CONTACT == receptorForm.uuidContactoReceptor)[0]
                            $this.jsonDTE.Encabezado.Receptor.Contacto    = tmp.FIRST_NAME + ' ' +  tmp.LAST_NAME
                            $this.jsonDTE.Encabezado.Receptor.CorreoRecep = tmp.MAIL
                        }
                    */
                    ////////////////////////////////////////////  
                    $this.jsonDTE.QR.url   = '0'
                    $this.jsonDTE.QR.title = '0'
                    $this.jsonDTE.QR.tail  = '0'

                    ////////////////////////////////////////////                       
                    //======================================================================================================                    
            }else if($this._codDoc == 5100) {
                    //======================================================================================================                
                        $this.jsonDTE.Encabezado.IdDoc.TipoDTE=$this._configDte.codDoc2
                        $this.jsonDTE.Encabezado.IdDoc.FchEmis=$this._fn._formatDate(infoDocForm.fechaEmisionInfo,'-')   
                        $this.jsonDTE.Encabezado.IdDoc.FmaPago=infoDocForm.fmaPago
                        $this.jsonDTE.Encabezado.IdDoc.FchVenc=$this._fn._formatDate(infoDocForm.fechaVencimientoInfo,'-')
                        //
                        $this.jsonDTE.Encabezado.Emisor   = { "RUTEmisor": $this._rutEmisor}
                        $this.jsonDTE.Encabezado.Receptor = { "RUTRecep" : receptorForm.rutReceptor }    
                        ////////////////////////////////////////////
                            if(receptorForm.uuidContactoReceptor.length > 0 ){
                                tmp = customerRelatedContact.filter(i => i.UUID_CRM_CUSTOMER_CONTACT == receptorForm.uuidContactoReceptor)[0]
                                $this.jsonDTE.Encabezado.Receptor.Contacto    = tmp.FIRST_NAME + ' ' +  tmp.LAST_NAME
                                $this.jsonDTE.Encabezado.Receptor.CorreoRecep = tmp.MAIL
                            }
                        ////////////////////////////////////////////    
                    //======================================================================================================                        
            }else if($this._codDoc == 110) {
                    //======================================================================================================                                
                        $this.jsonDTE.Encabezado.IdDoc.TipoDTE=$this._configDte.codDoc2
                        $this.jsonDTE.Encabezado.IdDoc.FchEmis=$this._fn._formatDate(infoDocForm.fechaEmisionInfo,'-')   
                        $this.jsonDTE.Encabezado.IdDoc.FchVenc=$this._fn._formatDate(infoDocForm.fechaVencimientoInfo,'-')
                        $this.jsonDTE.Encabezado.IdDoc.FmaPago=infoDocForm.fmaPago
                        $this.jsonDTE.Encabezado.IdDoc.FmaPagExp=$this._fn._formatDate(infoDocForm.FmaPagExp,'-')
                        //
                        $this.jsonDTE.Encabezado.Emisor   = { "RUTEmisor": $this._rutEmisor}  
                        $this.jsonDTE.Encabezado.Receptor = { 
                                                               "RUTRecep"   : "55555555-5" 
                                                              ,"RznSocRecep": "Factura Exportación"
                                                              ,"Extranjero" : {"Nacionalidad": receptorForm.Nacionalidad}
                                                              ,"TipoDocID"  : receptorForm.TipoDocID
                                                              ,"GiroRecep"  : "Particular"
                                                              ,"CiudadRecep": receptorForm.CiudadRecep
                                                              ,"DirRecep"   : receptorForm.DirRecep
                                                           }    
                        ////////////////////////////////////////////
                            if(aduanaForm.CodModVenta      ) $this.TADUANA.Aduana.CodModVenta      = aduanaForm.CodModVenta     ;      
                            if(aduanaForm.CodClauVenta     ) $this.TADUANA.Aduana.CodClauVenta     = aduanaForm.CodClauVenta    ;     
                            if(aduanaForm.TotClauVenta     ) $this.TADUANA.Aduana.TotClauVenta     = aduanaForm.TotClauVenta    ;     
                            if(aduanaForm.CodViaTransp     ) $this.TADUANA.Aduana.CodViaTransp     = aduanaForm.CodViaTransp    ;     
                            if(aduanaForm.CodPtoEmbarque   ) $this.TADUANA.Aduana.CodPtoEmbarque   = aduanaForm.CodPtoEmbarque  ;     
                            if(aduanaForm.CodPtoDesemb     ) $this.TADUANA.Aduana.CodPtoDesemb     = aduanaForm.CodPtoDesemb    ;     
                            if(aduanaForm.CodUnidMedTara   ) $this.TADUANA.Aduana.CodUnidMedTara   = aduanaForm.CodUnidMedTara  ;     
                            if(aduanaForm.CodUnidPesoBruto ) $this.TADUANA.Aduana.CodUnidPesoBruto = aduanaForm.CodUnidPesoBruto;     
                            if(aduanaForm.CodUnidPesoNeto  ) $this.TADUANA.Aduana.CodUnidPesoNeto  = aduanaForm.CodUnidPesoNeto ;      
                            if(aduanaForm.TotBultos        ) $this.TADUANA.Aduana.TotBultos        = aduanaForm.TotBultos       ;      
                            if(aduanaForm.MntFlete         ) $this.TADUANA.Aduana.MntFlete         = aduanaForm.MntFlete        ;     
                            if(aduanaForm.MntSeguro        ) $this.TADUANA.Aduana.MntSeguro        = aduanaForm.MntSeguro       ;      
                            if(aduanaForm.CodPaisRecep     ) $this.TADUANA.Aduana.CodPaisRecep     = aduanaForm.CodPaisRecep    ;     
                            if(aduanaForm.CodPaisDestin    ) $this.TADUANA.Aduana.CodPaisDestin    = aduanaForm.CodPaisDestin   ;      
                            //TipoBultos
                            if(tipoBultosForm.CodTpoBultos ) $this.TADUANA.TipoBultos.CodTpoBultos = tipoBultosForm.CodTpoBultos;
                            if(tipoBultosForm.CantBultos   ) $this.TADUANA.TipoBultos.CantBultos   = tipoBultosForm.CantBultos  ;
                            if(tipoBultosForm.Marcas       ) $this.TADUANA.TipoBultos.Marcas       = tipoBultosForm.Marcas      ;
                            if(tipoBultosForm.IdContainer  ) $this.TADUANA.TipoBultos.IdContainer  = tipoBultosForm.IdContainer ;
                            if(tipoBultosForm.Sello        ) $this.TADUANA.TipoBultos.Sello        = tipoBultosForm.Sello       ;
                            if(tipoBultosForm.EmisorSello  ) $this.TADUANA.TipoBultos.EmisorSello  = tipoBultosForm.EmisorSello ;
                        //
                            $this.jsonDTE.Encabezado.Transporte = $this.TADUANA
                            //OtraMoneda
                            $this.jsonDTE.Encabezado.Totales    = {
                                                                    "TpoMoneda": monedaForm.TpoMonedaDest
                                                                    }
                            $this.jsonDTE.Encabezado.OtraMoneda =  {
                                                                    "TpoMoneda": monedaForm.TpoMonedaOri
                                                                ,"TpoCambio" : monedaForm.TpoCambioOri
                                                                }
                        ////////////////////////////////////////////    
                    //======================================================================================================                                
            }else if($this._codDoc == 52) {
                    //======================================================================================================   
                    try{             
                    $this.jsonDTE.Encabezado.IdDoc.TipoDTE=$this._codDoc
                    $this.jsonDTE.Encabezado.IdDoc.FchEmis=$this._fn._formatDate(infoDocForm.fechaEmisionInfo,'-')   
                    $this.jsonDTE.Encabezado.IdDoc.IndTraslado  = infoDocForm.IndTraslado
                    $this.jsonDTE.Encabezado.IdDoc.TipoDespacho = infoDocForm.TipoDespacho
                    //
                    $this.jsonDTE.Encabezado.Emisor   = { "RUTEmisor": $this._rutEmisor}
                    if(infoDocForm.IndTraslado == '5' && infoDocForm.TipoDespacho == '3' ){
                      $this.jsonDTE.Encabezado.Receptor = { "RUTRecep" : $this._rutEmisor }
                    }else{
                      $this.jsonDTE.Encabezado.Receptor = { "RUTRecep" : receptorForm.rutReceptor }
                    }
                    ////////////////////////////////////////////
                    $this.jsonDTE.Encabezado.Transporte = {}
                    if(transporteForm.RUTTrans != null) $this.jsonDTE.Encabezado.Transporte.RUTTrans = transporteForm.RUTTrans.slice(0,10) 
                        $this.jsonDTE.Encabezado.Transporte.Patente = transporteForm.Patente.slice(0,8) 
                    
                    $this.jsonDTE.Encabezado.Transporte.Chofer ={
                                                                "RUTChofer"   :  transporteForm.RUTChofer.slice(0,10) 
                                                                ,"NombreChofer": (transporteForm.NombreChofer +' '+ transporteForm.ApellidoChofer).slice(0,30) 
                                                            }
                    ////////////////////////////////////////////
                    tmp = customerRelatedAddress.filter(i => i.UUID_CRM_CUSTOMER_ADDRESS ==transporteForm.DirDest )[0]
                    tmp2=tmp.ADDRESS1 + ' ' + tmp.ADDRESS2
                    $this.jsonDTE.Encabezado.Transporte.DirDest    = tmp2.slice(0,70) 
                    $this.jsonDTE.Encabezado.Transporte.CmnaDest   = tmp.GLS_COMUNA.slice(0,20) 
                    $this.jsonDTE.Encabezado.Transporte.CiudadDest = tmp.GLS_CIUDAD.slice(0,20) 
                    }catch(e){
                      console.log(e)
                    }
                    ////////////////////////////////////////////   
                    //======================================================================================================                    
            }else{
                    //======================================================================================================                
                    $this.jsonDTE.Encabezado.IdDoc.TipoDTE=$this._codDoc
                    $this.jsonDTE.Encabezado.IdDoc.FchEmis=$this._fn._formatDate(infoDocForm.fechaEmisionInfo,'-')   
                    $this.jsonDTE.Encabezado.IdDoc.FmaPago=infoDocForm.fmaPago
                    $this.jsonDTE.Encabezado.IdDoc.FchVenc=$this._fn._formatDate(infoDocForm.fechaVencimientoInfo,'-')
                    //
                    $this.jsonDTE.Encabezado.Emisor   = { "RUTEmisor": $this._rutEmisor}
                    $this.jsonDTE.Encabezado.Receptor = { "RUTRecep" : receptorForm.rutReceptor }    
                    ////////////////////////////////////////////
                        if(receptorForm.uuidContactoReceptor.length > 0 ){
                            tmp = customerRelatedContact.filter(i => i.UUID_CRM_CUSTOMER_CONTACT == receptorForm.uuidContactoReceptor)[0]
                            $this.jsonDTE.Encabezado.Receptor.Contacto    = tmp.FIRST_NAME + ' ' +  tmp.LAST_NAME
                            $this.jsonDTE.Encabezado.Receptor.CorreoRecep = tmp.MAIL
                        }
                    ////////////////////////////////////////////    
                    //======================================================================================================                    
            
            }
                //##############################################
                index=0;
                quantity=0;
                $this.mnt.MntNetoOri= 0
                for(i=0;i<dataPS.length;i++){
                index = index +1;
                quantity=quantity + Number(dataPS[i].QtyItem)
                $this.mnt.MntNetoOri= Number($this.mnt.MntNetoOri) + Number(dataPS[i].MontoItem )
                //####
                if(flagShowDetail == true){
                    if(dataPS[i].DscItem == 'Sin Descripción')  DscItem=false; else DscItem=dataPS[i].DscItem;
                }else{
                    DscItem=false
                }
                //####
                $this.jsonDTE.Detalle.push(
                                            {
                                                NroLinDet       : index
                                                ,CdgItem        :[
                                                                    {
                                                                    TpoCodigo : dataPS[i].TpoCodigo
                                                                    ,VlrCodigo : dataPS[i].VlrCodigo
                                                                    },
                                                                    {
                                                                    TpoCodigo :'WRH'
                                                                    ,VlrCodigo : dataPS[i].extraData.COD_WAREHOUSE
                                                                    }                                          
                                                                    ]
                                                ,TpoDocLiq       : dataPS[i].TpoDocLiq    
                                                ,Retenedor       : dataPS[i].Retenedor
                                                ,NmbItem         : dataPS[i].NmbItem  
                                                ,DscItem         : DscItem
                                                ,QtyRef          : dataPS[i].QtyRef
                                                ,UnmRef          : dataPS[i].UnmRef
                                                ,PrcRef          : dataPS[i].PrcRef
                                                ,QtyItem         : dataPS[i].QtyItem  
                                                ,Subcantidad     : dataPS[i].Subcantidad   
                                                ,FchElabor       : dataPS[i].FchElabor    
                                                ,FchVencim       : dataPS[i].FchVencim    
                                                ,UnmdItem        : dataPS[i].UnmdItem  
                                                ,PrcItem         : dataPS[i].PrcItem  
                                                ,OtrMnda         : dataPS[i].OtrMnda      
                                            //   ,TipoDscto       : $this.dataPS[i].TipoDscto    
                                            //   ,SubDscto        : $this.dataPS[i].SubDscto  
                                            //   ,ValorDscto      : $this.dataPS[i].ValorDscto   
                                                ,DescuentoPct    : null
                                                ,DescuentoMonto  : null
                                                ,RecargoPct      : null
                                                ,RecargoMonto    : null    
                                            //  ,SubRecargo      : $this.dataPS[i].SubRecargo        
                                                ,MontoItem       : dataPS[i].MontoItem   
                                                ,extraData       : dataPS[i].extraData   
                                            }      
                                        )
                if(dataPS[i].IndExe         > 0 ) $this.jsonDTE.Detalle[i].IndExe         = dataPS[i].IndExe
                if(dataPS[i].DescuentoPct   > 0 ) $this.jsonDTE.Detalle[i].DescuentoPct   = dataPS[i].DescuentoPct
                if(dataPS[i].DescuentoMonto > 0 ) $this.jsonDTE.Detalle[i].DescuentoMonto = dataPS[i].DescuentoMonto
                if(dataPS[i].CodImpAdic     > 0 ) $this.jsonDTE.Detalle[i].CodImpAdic     = dataPS[i].CodImpAdic
                //==
                amountSales = amountSales +  dataPS[i].MontoItem  
                //==
                }
                $this.summary.quantity = quantity
                $this.summary.products = index
                //==
                quantitySales = quantity
                //==
                //##############################################  
                index=0;
                for(i=0;i<dataREF.length;i++){
                    index = index +1;
                    if(dataREF[i].tpoDocRef == 'GLS'){
                        $this.jsonDTE.Referencia.push({
                                                    NroLinRef  :  index
                                                    ,FchRef     :  $this._fn._formatDate(dataREF[i].fechaRef,'-')        
                                                    ,IndGlobal  :  1
                                                    ,TpoDocRef  :  dataREF[i].tpoDocRef       
                                                    ,FolioRef   :  0    
                                                    ,RazonRef   :  dataREF[i].rznRef   
                                                })
                    }
                    if(dataREF[i].tpoDocRef != 'GLS'){
                    $this.jsonDTE.Referencia.push({
                                                NroLinRef  :  index
                                                ,FchRef     :  $this._fn._formatDate(dataREF[i].fechaRef,'-')        
                                                ,IndGlobal  :  dataREF[i].IndGlobal       
                                                ,TpoDocRef  :  dataREF[i].tpoDocRef       
                                                ,FolioRef   :  dataREF[i].folioRef        
                                                ,RazonRef   :  dataREF[i].rznRef   
                                            })
                }      
                }
                //##############################################
                if($this._codDoc == 110) {
                    //===========================================
                    index=0;
                    for(i=0;i<dataDsc.length;i++){
                      index = index +1;
                      $this.jsonDTE.DscRcgGlobal.push({
                                                   NroLinDR       :  index
                                                  ,TpoMov         :  dataDsc[i].TpoMov          
                                                  ,GlosaDR        :  dataDsc[i].GlosaDR          
                                                  ,TpoValor       :  dataDsc[i].TpoValor          
                                                  ,ValorDR        :  dataDsc[i].ValorDR    
                                                  ,ValorDROtrMnda :  dataDsc[i].ValorDR  //*  this.monedaForm.value.TpoCambioOri           
                                                  })
                    }                    
                    //===========================================                                        
                }else{
                    //===========================================
                    index=0;
                    for(i=0;i<dataDsc.length;i++){
                    index = index +1;
                    $this.jsonDTE.DscRcgGlobal.push({
                                                 NroLinDR  :  index
                                                ,TpoMov    :  dataDsc[i].TpoMov          
                                                ,GlosaDR   :  dataDsc[i].GlosaDR          
                                                ,TpoValor  :  dataDsc[i].TpoValor          
                                                ,ValorDR   :  dataDsc[i].ValorDR                 
                                                })
                    }
                    //===========================================                    
                }
                //##############################################  
                //############################################## 
                  //agregar descuento en tkt
                  if ($this._codDoc == 5001){
                    //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
                    //agregar descuento
                    $this._DscRcg = []
                    let amountSalesTmp = Math.round(amountSales/ (1+$this._ivaF)) 
                    $this._DscRcg= await $this.discountCouponAt(flagDscRcgAT,spDetail,spDetail.COD_SALES_CHANNEL,'0',receptorForm.uuidCrmCustomer,quantitySales,amountSalesTmp)
                      for(i=0;i<$this._DscRcg.length;i++){

                        $this.mnt.MntDsc = $this.mnt.MntDsc + Math.round( $this._DscRcg[i].DISCOUNT_CALCULATE* (1+$this._ivaF)) 
                        $this.jsonDTE.DscRcgGlobal.push({
                          NroLinDR  :  index
                          ,TpoMov    :  'D'      
                          ,GlosaDR   :  $this._DscRcg[i].COD_EPC_DISCOUNT_COUPON       
                          ,TpoValor  : '$'     
                          ,ValorDR   :  Math.round( $this._DscRcg[i].DISCOUNT_CALCULATE* (1+$this._ivaF)) //$this._DscRcg[i].DISCOUNT_CALCULATE                
                          })                        
                      }
                    //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&                                        
                  }else{
                    //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
                    //agregar descuento global
                    $this._DscRcg = []
                    $this._DscRcg= await $this.discountCouponAt(flagDscRcgAT,spDetail,spDetail.COD_SALES_CHANNEL,'0',receptorForm.uuidCrmCustomer,quantitySales,amountSales)
                    index = $this.jsonDTE.DscRcgGlobal.length
                          
                          for(i=0;i<$this._DscRcg.length;i++){
                            //##############################################
                            index = index +1;
                              //===========================================
                              if($this._codDoc == 110) {
                                $this.jsonDTE.DscRcgGlobal.push({
                                                            NroLinDR       :  index
                                                            ,TpoMov         :  'D'
                                                            ,GlosaDR        :  $this._DscRcg[i].COD_EPC_DISCOUNT_COUPON                                                  
                                                            ,TpoValor       :  '$'
                                                            ,ValorDR        :  $this._DscRcg[i].DISCOUNT_CALCULATE
                                                            ,ValorDROtrMnda :  $this._DscRcg[i].DISCOUNT_CALCULATE           
                                                            })             
                              //===========================================                                                         
                            }else{
                              //===========================================                      
                              
                              $this.jsonDTE.DscRcgGlobal.push({
                                                          NroLinDR  :  index
                                                          ,TpoMov    :  'D'      
                                                          ,GlosaDR   :  $this._DscRcg[i].COD_EPC_DISCOUNT_COUPON       
                                                          ,TpoValor  : '$'     
                                                          ,ValorDR   :  $this._DscRcg[i].DISCOUNT_CALCULATE                
                                                          })
                              }
                              //=========================================== 
                            //##############################################                  
                          }
                    }
                    //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&                
                //##############################################
                //##############################################                
                const res = await $this._docsServ.chkDocs($this.authDTE2,[$this.jsonDTE]);
                if ($this._codDoc == 5001){
                  //======================================================
                      if(res.success == false){
                        //=======================================================================================
                            glsErr = 'Formato incorrectoTicket<br>'
                            const dialogRef = $this._fuseConfirmationService.open({
                                title: `Error al validar Documento`,
                                message: `${glsErr}`,
                                icon: {
                                show: true,
                                name: 'local_atm',
                                color: 'warning'
                                },    
                                actions: {
                                    confirm: {
                                        label: 'Aceptar',
                                        color: 'warn',
                                    },
                                    cancel: {
                                        show: false,
                                        label: 'Cancelar',
                                    },
                                },
                            });
                            dialogRef.afterClosed().subscribe(async (result) => {
                            //$this.flagPlace = false;
                                    reject({
                                            success   : false
                                            ,error     : ''
                                            ,totales   : null
                                            ,mnt       : null
                                            ,DTE       : null
                                            ,summary   : null
                                            ,flagPlace : false
                                            })
                                });                              
                        //=======================================================================================
                      }else{
                        //=======================================================================================                        
                            $this.DTE = res.dataSet[0].jsonTKT 
                            //$this.flagPlace = true;
                    
                            $this.totales.MntNeto      = 0
                            $this.totales.MntExe       = 0
                            $this.totales.IVA          = 0
                            $this.totales.MntTotal     = $this.DTE.Encabezado.Totales.MntTotal
                            $this.totales.MntoNF       = 0
                            $this.totales.ImptoReten   = 0
                            //==
                            //==
                            $this.mnt.MntDsc= $this.DTE.Encabezado.Totales.MntDesc
                            $this.mnt.MntRcg= $this.DTE.Encabezado.Totales.MntRcrg
                            $this.mnt.PcrRcg= 0
                            $this.mnt.PcrDsc= 0 
                        //=======================================================================================                        
                      }

                  //======================================================                                    
                }else{
                  //======================================================
                    if(res.success == false){
                        //=======================================================================================
                                try{
                                    if(res?.dataSet?.errDTE[0]?.status?.length > 0){
                                    //glsErr=
                                    for(let i=0;i<res.dataSet.errDTE[0].status[0].length;i++){
                                        //==
                                        glsErr = res.dataSet.errDTE[0].status[0][i].status.rule.NOMBRE+'<br>'
                                        glsErr = glsErr + res.dataSet.errDTE[0].status[0][i].status.rule.VALDIDACION+ '<br>'
                                        //==
                                    }
                                    
                                    }
                                }
                                catch(e){}
                                try{
                                    console.log(res.detail.error)
                                    if(res.detail.error.glsStatus){ 
                                    //==
                                        glsErr = res.detail.error.glsStatus[0].msg+'<br>'
                                        glsErr = glsErr + res.detail.error.glsStatus[0].status+ '<br>'
                                    //==                  
                                    }
                                }catch(e){}
                        //==
                                const dialogRef = $this._fuseConfirmationService.open({
                                    title: `Error al validar Documento`,
                                    message: `<b>${res.dataSet.glsStatus || ''  }</b>${glsErr}`,
                                    icon: {
                                    show: true,
                                    name: 'local_atm',
                                    color: 'warning'
                                    },    
                                    actions: {
                                        confirm: {
                                            label: 'Aceptar',
                                            color: 'warn',
                                        },
                                        cancel: {
                                            show: false,
                                            label: 'Cancelar',
                                        },
                                    },
                                });
                                dialogRef.afterClosed().subscribe(async (result) => {
                                //$this.flagPlace = false;
                                        reject({
                                                success   : false
                                                ,error     : res.dataSet.glsStatus
                                                ,totales   : null
                                                ,mnt       : null
                                                ,DTE       : null
                                                ,summary   : null
                                                ,flagPlace : false
                                                })
                                    });              
                        //=======================================================================================
                    }
                    else{
                        //=======================================================================================  
                            $this.DTE = res.dataSet[0].jsonDTE 
                            //$this.flagPlace = true;
                    
                            $this.totales.MntNeto      = $this.DTE.Encabezado.Totales.MntNeto
                            $this.totales.MntExe       = $this.DTE.Encabezado.Totales.MntExe
                            $this.totales.IVA          = $this.DTE.Encabezado.Totales.IVA
                            $this.totales.MntTotal     = $this.DTE.Encabezado.Totales.MntTotal
                            $this.totales.MntoNF       = $this.DTE.Encabezado.Totales.MontoNF
                            $this.totales.ImptoReten   = $this.DTE.Encabezado.Totales.ImptoReten
                            //==
                            if($this._codDoc == 110) {
                                $this.totales.TpoMoneda    = $this.DTE.Encabezado.Totales.TpoMoneda
                            }
                            //==
                            if($this.DTE.Encabezado.Totales.ImptoReten != undefined){
                                index=0;
                                $this.totales.impAdicional =0
                                for(i=0;i<$this.DTE.Encabezado.Totales.ImptoReten.length;i++){
                                index = index +1;
                                $this.totales.impAdicional = $this.totales.impAdicional + $this.DTE.Encabezado.Totales.ImptoReten[i].MontoImp
                                }
                            }
                            //==
                            $this.mnt.MntDsc=0
                            $this.mnt.MntRcg=0
                            $this.mnt.PcrRcg=0
                            $this.mnt.PcrDsc=0  
                            if($this.DTE.DscRcgGlobal != undefined){
                            index=0;      
                            for(i=0;i<$this.DTE.DscRcgGlobal.length;i++){
                                index = index +1;
                                if($this.DTE.DscRcgGlobal[i].TpoValor == '%'){
                                if($this.DTE.DscRcgGlobal[i].TpoMov == 'R' ) $this.mnt.PcrRcg = $this.mnt.PcrRcg + $this.DTE.DscRcgGlobal[i].ValorDR
                                if($this.DTE.DscRcgGlobal[i].TpoMov == 'D' ) $this.mnt.PcrDsc = $this.mnt.PcrDsc + $this.DTE.DscRcgGlobal[i].ValorDR
                                }
                                if($this.DTE.DscRcgGlobal[i].TpoValor == '$'){
                                if($this.DTE.DscRcgGlobal[i].TpoMov == 'R' ) $this.mnt.MntRcg = $this.mnt.MntRcg + $this.DTE.DscRcgGlobal[i].ValorDR
                                if($this.DTE.DscRcgGlobal[i].TpoMov == 'D' ) $this.mnt.MntDsc = $this.mnt.MntDsc + $this.DTE.DscRcgGlobal[i].ValorDR            
                                }          
                    
                            }
                            }
                        //==       
                        //======================================================================================= 
                    }
                  //======================================================                    
                }
                //##############################################
                //##############################################                 
            resolve({
                        success : true
                       ,error   : null
                       ,totales : $this.totales
                       ,mnt     : $this.mnt
                       ,DTE     : $this.DTE
                       ,summary : $this.summary
                       ,flagPlace : true
                    })
        ////////////////////////////////////////////  
         }
            catch(e)
            {
                console.log(e)
                reject({
                    success   : false
                   ,error     : e
                   ,totales   : $this.totales
                   ,mnt       : $this.mnt
                   ,DTE       : $this.DTE
                   ,summary   : $this.summary
                   ,flagPlace : false
                })
          }
     });            
  }
////////////////////////////////////////       
////////////////////////////////////////
getDscRcg(){
  return(this._DscRcg)
}
///////////////////////////////////////
///////////////////////////////////////
async placeOrderCtz(spDetail:any,virtualSP:any,receptorForm:any,dataPS:any):Promise<any>{
    let $this = this; 
    return await new Promise(
      async function (resolve, reject) { 
        //#########################################
         try{
            /////////////////////////////////////////////////////////////////////////////////////////////////   

            /////////////////////////////////////////////////////////////////////////////////////////////////   
                var i:number,index:number, mntDesc : number =0,placeOrder,dueDate;
                var ts_hms = new Date();
                let fechaRef = ts_hms.getFullYear() + '-' + ("0" + (ts_hms.getMonth() + 1)).slice(-2) + '-' + ("0" + (ts_hms.getDate())).slice(-2) 
                /////////////////////////////////////////////////////////////////////////////////////////////////  
                const dialogRef = $this._fuseConfirmationService.open({
                title: `Emitir ${$this._fn._ucFirstAllWords($this._getGlsDoc())}?`,
                message: `Cliente: <b>${$this._fn._ucFirstAllWords(receptorForm.rznSocReceptor)}</b><br> Monto Total: <b>${$this._fn._currencyPipe.transform($this.DTE.Encabezado.Totales.MntTotal, 'CLP', 'symbol-narrow')}</b>`,
                icon: {
                    show: true,
                    name: 'assignment_turned_in',
                    color: 'primary'
                },    
                actions: {
                    confirm: {
                        label: 'Aceptar',
                        color: 'primary',
                    },
                    cancel: {
                        show: true,
                        label: 'Cancelar',
                    },
                },
            });
            
            dialogRef.afterClosed().subscribe(async (result) => {
                if (result === 'confirmed') {
            //////////////////////////////////////////  
            $this._uuidDTE = '0'
                            try{ dueDate = $this.DTE.Encabezado.IdDoc.FchVenc.split('-').join('/'); } catch(e){ dueDate=null} 
                            $this.orderCtz = {
                                            language     :"es"
                                            ,appToken    : null
                                            ,uuidSSO     : null
                                            ,ctz         : {}
                                            ,ctzDiscount : []
                                            ,ctzDetail   : []
                                        }               
                        //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
                            mntDesc = $this.mnt.MntNetoOri - $this.DTE.Encabezado.Totales.MntNeto
                            $this.orderCtz.ctz = {
                                                    "totalEnvio"                   : 0
                                                    ,"totalDescuento"              : mntDesc                         |0
                                                    ,"totalNeto"                   : $this.DTE.Encabezado.Totales.MntNeto  |0
                                                    ,"totalIva"                    : $this.DTE.Encabezado.Totales.IVA      |0
                                                    ,"totalExento"                 : $this.DTE.Encabezado.Totales.MntExe   |0
                                                    ,"total"                       : $this.DTE.Encabezado.Totales.MntTotal |0
                                                    ,"totalAjusteSencillo"         : 0
                                                    ,"totalDonacion"               : 0
                                                    ,"totalTip"                    : 0                                      
                                                    ,"codDoc"                      : $this._codDoc
                                                    ,"uuidDte"                     : '0'
                                                    ,"codStatusDte"                : 'P'
                                                    ,"uuidEmisorReceptor"          : receptorForm.uuidEmisorReceptor    
                                                    ,"rutEmisorReceptor"           : receptorForm.rutReceptor    
                                                    ,"flagShiping"                 : 'N'
                                                    ,"dateShiping"                 : null
                                                    ,"flagDelivered"               : 'N'
                                                    ,"dateDelivered"               : null
                                                    ,"uuidSp"                      : virtualSP.UUID_SP
                                                    ,"uuidCrmCustomer"             : receptorForm.uuidCrmCustomer       
                                                    ,"uuidEomOrderStatus"          : null
                                                    ,"dueDate"                     : dueDate
                                                    ,"uuidCrmCustomerContact"      : receptorForm.uuidContactoReceptor
                                                }      
                                    //=======================
                                    for(i=0;i<dataPS.length;i++){
                                    $this.orderCtz.ctzDetail.push(
                                                                        {
                                                                         "uuidPsServices"          : dataPS[i].extraData.UUID_PS_SERVICES
                                                                        ,"uuidPsServicesPrice"     : dataPS[i].extraData.PRICE.UUID_PS_SERVICES_PRICE
                                                                        ,"uuidPsServicesDiscount"  : dataPS[i].uuidPsServicesDiscount
                                                                        ,"uuidPsServicesPromotion" : '0'
                                                                        ,"uuidWarehouse"           : dataPS[i].extraData.UUID_WAREHOUSE
                                                                        ,"uuidCurrencyValue"       : dataPS[i].extraData.PRICE.UUID_CURRENCY_VALUE
                                                                        ,"quantity"                : dataPS[i].QtyItem
                                                                        ,"neto"                    : dataPS[i].extraData.PRICE.PRICE_CLP
                                                                        ,"exento"                  : 0
                                                                        ,"iva"                     : 0
                                                                        ,"total"                   : dataPS[i].MontoItem
                                                                        ,"descuento"               : dataPS[i].DescuentoMontoTmp
                                                                    }
                                                                    )
                                    } 
                                    //=======================                       
                              //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""  
                              //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""  
                                //==
                                $this.orderCtz.ctzDiscount = [];
                                for(i=0;i<$this._DscRcg.length;i++){
                                  $this.orderCtz.ctzDiscount.push(
                                                                  { 
                                                                     uuidEpcDiscountCoupon : $this._DscRcg[i].UUID_EPC_DISCOUNT_COUPON
                                                                    ,amountDiscountCoupon  : $this._DscRcg[i].DISCOUNT_CALCULATE

                                                                  }
                                                                )
                                }
                                //==
                              //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""  
                              //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""  
                            placeOrder = await $this._salesPointService.ctzPlace($this.orderCtz);
                            if(placeOrder.status == true && placeOrder.codStatus == '200'){
                            ////////////////////////////////////////////////////////////////////////
                            // AGREGO REFERENCIAS 
                                //==================================================
                                if($this.DTE.Referencia != undefined){  
                                    index = $this.DTE.Referencia.length 
                                    index = index +1;
                                }
                                else{
                                    $this.DTE["Referencia"]=[]
                                    index = 1;
                                }
                                $this.DTE.Referencia.push({
                                                                 NroLinRef : index
                                                                ,TpoDocRef : 'GLS'
                                                                ,IndGlobal : 1
                                                                ,FolioRef  : 0
                                                                ,FchRef    : fechaRef
                                                                ,RazonRef  : "Cotización:" + placeOrder.ctz.FRIENLY_CTZ_ORDER
                                                            })
                                    index = index +1;
                                    ////////////////////////////////////////////////////////                           
                                    if(spDetail.FLAG_SHOW_EMPLOYEE == 'Y'){ 
                                    $this.DTE.Referencia.push({
                                                             NroLinRef : index
                                                            ,TpoDocRef : 'GLS'
                                                            ,IndGlobal : 1
                                                            ,FolioRef  : 0
                                                            ,FchRef    : fechaRef
                                                            ,RazonRef  : "Atendido por:" +$this.userData.firstName +' '+ $this.userData.lastName
                                                            })
                                    }
                                //==================================================            
                            ////////////////////////////////////////////////////////////////////////
                            try{
                                    let dte  : any = await $this.makeDte($this.DTE)    
                                    ////////////////////////////////////////////////////////////////////////
                                    let udpCtz={
                                                "language"      :"es"
                                                ,"appToken"     : null
                                                ,"uuidSSO"      : null
                                                ,"uuidEomCtz"   : placeOrder.ctz.UUID_CTZ_ORDER
                                                ,"codDoc"       : dte[0].jsonCTZ.Encabezado.IdDoc.TipoCTZ
                                                ,"uuidDte"      : dte[0].uuidCTZ
                                                ,"codStatusDte" : 'OK'
                                                ,"uuidEomOrder" : null
                                                ,"codCtzStatus" : null
                                            }
                                    $this._uuidDTE = dte[0].uuidCTZ
                                    let orderCtz = await $this._salesPointService.ctzUdp(udpCtz);
                                    $this._toastr.success('Cotización  Emitida', 'Exito');
                                    //####################################################
                                        const dialogRef = $this._fuseConfirmationService.open({
                                            title: `${$this._getGlsDoc()}, folio ${dte[0].jsonCTZ.Encabezado.IdDoc.Folio}`,
                                            message: `Monto Total: <b>${$this._fn._currencyPipe.transform($this.DTE.Encabezado.Totales.MntTotal, 'CLP', 'symbol-narrow')}</b>`,
                                            icon: {
                                                show: true,
                                                name: 'assignment_turned_in',
                                                color: 'primary'
                                            },    
                                            actions: {
                                                confirm: {
                                                    label: `Ver ${$this._configDte.glsDoc2}`,
                                                    color: 'primary',
                                                },
                                                cancel: {
                                                    show: true,
                                                    label: `Emitir Otra ${$this._configDte.glsDoc2}`,
                                                },
                                            },
                                        });
                                        
                                        dialogRef.afterClosed().subscribe(async (result) => {
                                            if (result === 'confirmed') {
                                                /////////////////////////////////////////
                                                await $this.downloadDte('a4','0')
                                                /////////////////////////////////////////  
                                            }
                                        })
                                    //####################################################
                                    //this.resetProducts();
                                    //this.resetReceptor();
                                    //this.dataPS=[];
                                    //this.dataREF=[];
                                    //this.dataDsc=[];
                                    //await this.loadTablePS();
                                    //await this.loadTableRef();
                                    //await this.loadTableDsc();
                                    $this.totales.MntNeto       = 0     
                                    $this.totales.MntExe        = 0   
                                    $this.totales.IVA           = 0 
                                    $this.totales.MntTotal      = 0     
                                    $this.totales.MntoNF        = 0   
                                    $this.totales.impAdicional  = 0
                                    $this.summary.quantity      = 0
                                    $this.summary.products      = 0
                                    $this.mnt.MntDsc            = 0
                                    $this.mnt.MntRcg            = 0
                                    $this.mnt.PcrDsc            = 0
                                    $this.mnt.PcrRcg            = 0
                                    $this.mnt.MntNetoOri        = 0
                                    $this.orderCtz = {
                                                         language    :"es"
                                                        ,appToken    : null
                                                        ,uuidSSO     : null
                                                        ,ctz         : {}
                                                        ,ctzDiscount : []
                                                        ,ctzDetail   : []
                                                    }   
                                    //
                                    resolve({
                                              success    : true
                                             ,error      : null
                                             ,uuidEomCtz : placeOrder.ctz.UUID_CTZ_ORDER
                                             ,uuidDTE    : $this._uuidDTE
                                            })                                                    
                                    ////////////////////////////////////////////////////////////////////////                
            
                            }catch(e){
                                console.log(e)
                                    $this._toastr.error('Cotización no emitido', 'Error');
                                    resolve({
                                        success : false
                                       ,error   : null
                                   })
                            }
                            ////////////////////////////////////////////////////////////////////////
                        }
                        //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""          
                    //////////////////////////////////////////                    
                        } else {
                            resolve({
                                success : false
                               ,error   : null
                           })
                    }
                });              
            /////////////////////////////////////////////////////////////////////////////////////////////////   
            /////////////////////////////////////////////////////////////////////////////////////////////////                                        

        //#########################################
        }
        catch(e)
        {
        console.log(e)
            reject({
                 success   : false
                ,error     : e
                })
        }
        //#########################################        
    });      
  
}
////////////////////////////////////////       
////////////////////////////////////////
getBruto(neto){
    return this._fn._roundAmount(neto * (Number(Number(1)+ Number(this._ivaF))),null,4)
}
////////////////////////////////////////       
////////////////////////////////////////
getIVA(){
  return this._ivaF
}
////////////////////////////////////////       
////////////////////////////////////////
async placeOrderDTE(spDetail:any,virtualSP:any,receptorForm:any,dataPS:any,carrierChecked:any,calculateShipping:any,selectShipment:any,selectAddress:any,selectWhareHouse:any,flagInsertWarehouseMovements:any):Promise<any>{    
        let $this = this; 
        return await new Promise(
          async function (resolve, reject) { 
            //#########################################
             try{
                /////////////////////////////////////////////////////////////////////////////////////////////////   
                /////////////////////////////////////////////////////////////////////////////////////////////////   
                  var i:number,index:number, mntDesc : number =0,placeOrder,dueDate,orderShipping={};
                  var ts_hms = new Date();
                  let fechaRef = ts_hms.getFullYear() + '-' + ("0" + (ts_hms.getMonth() + 1)).slice(-2) + '-' + ("0" + (ts_hms.getDate())).slice(-2) 
                  var descuento =0,total=0
                  /////////////////////////////////////////////////////////////////////////////////////////////////  
                  const dialogRef = $this._fuseConfirmationService.open({
                    title: `Emitir ${$this._fn._ucFirstAllWords($this._getGlsDoc())}?`,
                    message: `Cliente: <b>${$this._fn._ucFirstAllWords(receptorForm.rznSocReceptor)}</b><br> Monto Total: <b>${$this._fn._currencyPipe.transform($this.DTE.Encabezado.Totales.MntTotal, 'CLP', 'symbol-narrow')}</b>`,
                    icon: {
                      show: true,
                      name: 'assignment_turned_in',
                      color: 'primary'
                    },    
                    actions: {
                        confirm: {
                            label: 'Aceptar',
                            color: 'primary',
                        },
                        cancel: {
                            show: true,
                            label: 'Cancelar',
                        },
                    },
                });
    
                dialogRef.afterClosed().subscribe(async (result) => {
                    if (result === 'confirmed') {
                //////////////////////////////////////////  
                $this._uuidDTE = '0'
                              try{ dueDate = $this.DTE.Encabezado.IdDoc.FchVenc.split('-').join('/'); } catch(e){ dueDate=null} 
                              $this.order = {
                                              language      :"es"
                                              ,appToken      : null
                                              ,uuidSSO       : null
                                              ,order         : {}
                                              ,orderDiscount : []
                                              ,orderDetail   : []
                                              ,orderShipping  : null
                                            }               
                          //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
                          //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
                              if($this._codDoc == 39 || $this._codDoc == 41){
                                mntDesc = $this.mnt.MntNetoOri - $this.DTE.Encabezado.Totales.MntNeto
                              
                                $this.order.order = {
                                                        "totalEnvio"                  : 0
                                                        ,"totalDescuento"              : mntDesc                              |0
                                                        ,"totalNeto"                   : $this.DTE.Encabezado.Totales.MntNeto  |0
                                                        ,"totalIva"                    : $this.DTE.Encabezado.Totales.IVA      |0
                                                        ,"totalExento"                 : $this.DTE.Encabezado.Totales.MntExe   |0
                                                        ,"total"                       : $this.DTE.Encabezado.Totales.MntTotal |0
                                                        ,"totalAjusteSencillo"         : 0
                                                        ,"totalDonacion"               : 0
                                                        ,"totalTip"                    : 0                                      
                                                        ,"codDoc"                      : $this._codDoc
                                                        ,"uuidDte"                     : '0'
                                                        ,"codStatusDte"                : 'P'
                                                        ,"uuidEmisorReceptor"          : receptorForm.uuidEmisorReceptor    
                                                        ,"rutEmisorReceptor"           : receptorForm.rutReceptor    
                                                        ,"flagShiping"                 : 'N'
                                                        ,"dateShiping"                 : null
                                                        ,"flagDelivered"               : 'N'
                                                        ,"dateDelivered"               : null
                                                        ,"uuidSp"                      : virtualSP.UUID_SP
                                                        ,"uuidCrmCustomer"             : receptorForm.uuidCrmCustomer       
                                                        ,"uuidEomOrderStatus"          : null
                                                        ,"dueDate"                     : dueDate
                                                        ,"uuidCrmCustomerContact"      : receptorForm.uuidContactoReceptor
                                                  } 
                                         //orden sin movimiento de Stock
                                        if(flagInsertWarehouseMovements.flagDontInsertWarehouseMovements == 'Y'){
                                          $this.order.order.flagDontInsertWarehouseMovements = 'Y'
                                          $this.order.order.uuidWarehouseMovements           = flagInsertWarehouseMovements.uuidWarehouseMovements
                                        }    
                                      //=======================
                                      for(i=0;i<dataPS.length;i++){
                                        //==
                                          if($this._codDoc == 39) {descuento =  Math.round(dataPS[i].DescuentoMontoTmp * (1 + $this._ivaF));total=Math.round(dataPS[i].MontoItem * (1 + $this._ivaF))}
                                          if($this._codDoc == 41) {descuento = dataPS[i].DescuentoMontoTmp;total=dataPS[i].MontoItem} 
                                        //==
                                        $this.order.orderDetail.push(
                                                                          {
                                                                            "uuidPsServices"         : dataPS[i].extraData.UUID_PS_SERVICES
                                                                          ,"uuidPsServicesPrice"     : dataPS[i].extraData.PRICE.UUID_PS_SERVICES_PRICE
                                                                          ,"uuidPsServicesDiscount"  : dataPS[i].uuidPsServicesDiscount //dataPS[i].extraData.PRICE.UUID_PS_SERVICES_DISCOUNT
                                                                          ,"uuidPsServicesPromotion" : '0'
                                                                          ,"uuidWarehouse"           : dataPS[i].extraData.UUID_WAREHOUSE
                                                                          ,"uuidCurrencyValue"       : dataPS[i].extraData.PRICE.UUID_CURRENCY_VALUE
                                                                          ,"quantity"                : dataPS[i].QtyItem
                                                                          ,"neto"                    : dataPS[i].extraData.PRICE.PRICE_CLP
                                                                          ,"exento"                  : 0
                                                                          ,"iva"                     : 0
                                                                          ,"total"                   : total
                                                                          ,"descuento"               : descuento
                                                                      }
                                                                      )
                                      } 
                                      //=======================                       
                              }
                              //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
                              else if($this._codDoc == 5001){  
                                console.log( $this.DTE.Encabezado.Totales)
                                $this.order.order = {
                                                         "totalEnvio"                  : 0
                                                        ,"totalDescuento"              : Math.round($this.DTE.Encabezado.Totales.MntDesc / (1 + $this._ivaF)) 
                                                        ,"totalNeto"                   : Math.round($this.DTE.Encabezado.Totales.MntTotal / (1 + $this._ivaF)) 
                                                        ,"totalIva"                    : $this.DTE.Encabezado.Totales.MntTotal - Math.round($this.DTE.Encabezado.Totales.MntTotal / (1 + $this._ivaF)) 
                                                        ,"totalExento"                 : 0
                                                        ,"total"                       : $this.DTE.Encabezado.Totales.MntTotal 
                                                        ,"totalAjusteSencillo"         : 0
                                                        ,"totalDonacion"               : 0
                                                        ,"totalTip"                    : 0                                      
                                                        ,"codDoc"                      : $this._codDoc
                                                        ,"uuidDte"                     : '0'
                                                        ,"codStatusDte"                : 'P'
                                                        ,"uuidEmisorReceptor"          : receptorForm.uuidEmisorReceptor    
                                                        ,"rutEmisorReceptor"           : receptorForm.rutReceptor    
                                                        ,"flagShiping"                 : 'N'
                                                        ,"dateShiping"                 : null
                                                        ,"flagDelivered"               : 'N'
                                                        ,"dateDelivered"               : null
                                                        ,"uuidSp"                      : virtualSP.UUID_SP
                                                        ,"uuidCrmCustomer"             : receptorForm.uuidCrmCustomer       
                                                        ,"uuidEomOrderStatus"          : null
                                                        ,"dueDate"                     : dueDate
                                                        ,"uuidCrmCustomerContact"      : receptorForm.uuidContactoReceptor
                                                  } 
                                         //orden sin movimiento de Stock
                                         if(flagInsertWarehouseMovements.flagDontInsertWarehouseMovements == 'Y'){
                                          $this.order.order.flagDontInsertWarehouseMovements = 'Y'
                                          $this.order.order.uuidWarehouseMovements           = flagInsertWarehouseMovements.uuidWarehouseMovements
                                        }                                                          
                                      //=======================
                                      for(i=0;i<dataPS.length;i++){
                                        $this.order.orderDetail.push(
                                                                          {
                                                                            "uuidPsServices"         : dataPS[i].extraData.UUID_PS_SERVICES
                                                                          ,"uuidPsServicesPrice"     : dataPS[i].extraData.PRICE.UUID_PS_SERVICES_PRICE
                                                                          ,"uuidPsServicesDiscount"  : dataPS[i].uuidPsServicesDiscount //dataPS[i].extraData.PRICE.UUID_PS_SERVICES_DISCOUNT
                                                                          ,"uuidPsServicesPromotion" : '0'
                                                                          ,"uuidWarehouse"           : dataPS[i].extraData.UUID_WAREHOUSE
                                                                          ,"uuidCurrencyValue"       : dataPS[i].extraData.PRICE.UUID_CURRENCY_VALUE
                                                                          ,"quantity"                : dataPS[i].QtyItem
                                                                          ,"neto"                    : dataPS[i].extraData.PRICE.PRICE_CLP
                                                                          ,"exento"                  : 0
                                                                          ,"iva"                     : 0
                                                                          ,"total"                   : dataPS[i].MontoItem
                                                                          ,"descuento"               : dataPS[i].DescuentoMontoTmp
                                                                      }
                                                                      )
                                      } 
                                      //=======================                                            
                              } 
                              //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
                              else if($this._codDoc == 5100){  
                                mntDesc = $this.mnt.MntNetoOri - $this.DTE.Encabezado.Totales.MntNeto
                                $this.order.order = {
                                                        "totalEnvio"                  : 0
                                                        ,"totalDescuento"              : mntDesc                               |0
                                                        ,"totalNeto"                   : $this.DTE.Encabezado.Totales.MntNeto  |0
                                                        ,"totalIva"                    : $this.DTE.Encabezado.Totales.IVA      |0
                                                        ,"totalExento"                 : $this.DTE.Encabezado.Totales.MntExe   |0
                                                        ,"total"                       : $this.DTE.Encabezado.Totales.MntTotal |0
                                                        ,"totalAjusteSencillo"         : 0
                                                        ,"totalDonacion"               : 0
                                                        ,"totalTip"                    : 0                                      
                                                        ,"codDoc"                      : $this._codDoc
                                                        ,"uuidDte"                     : '0'
                                                        ,"codStatusDte"                : 'P'
                                                        ,"uuidEmisorReceptor"          : receptorForm.uuidEmisorReceptor    
                                                        ,"rutEmisorReceptor"           : receptorForm.rutReceptor    
                                                        ,"flagShiping"                 : 'N'
                                                        ,"dateShiping"                 : null
                                                        ,"flagDelivered"               : 'N'
                                                        ,"dateDelivered"               : null
                                                        ,"uuidSp"                      : virtualSP.UUID_SP
                                                        ,"uuidCrmCustomer"             : receptorForm.uuidCrmCustomer       
                                                        ,"uuidEomOrderStatus"          : null
                                                        ,"dueDate"                     : dueDate
                                                        ,"uuidCrmCustomerContact"      : receptorForm.uuidContactoReceptor
                                                  } 
                                         //orden sin movimiento de Stock
                                         if(flagInsertWarehouseMovements.flagDontInsertWarehouseMovements == 'Y'){
                                          $this.order.order.flagDontInsertWarehouseMovements = 'Y'
                                          $this.order.order.uuidWarehouseMovements           = flagInsertWarehouseMovements.uuidWarehouseMovements
                                        }                                                          
                                      //=======================
                                      for(i=0;i<dataPS.length;i++){
                                        $this.order.orderDetail.push(
                                                                          {
                                                                            "uuidPsServices"         : dataPS[i].extraData.UUID_PS_SERVICES
                                                                          ,"uuidPsServicesPrice"     : dataPS[i].extraData.PRICE.UUID_PS_SERVICES_PRICE
                                                                          ,"uuidPsServicesDiscount"  : dataPS[i].uuidPsServicesDiscount //dataPS[i].extraData.PRICE.UUID_PS_SERVICES_DISCOUNT
                                                                          ,"uuidPsServicesPromotion" : '0'
                                                                          ,"uuidWarehouse"           : dataPS[i].extraData.UUID_WAREHOUSE
                                                                          ,"uuidCurrencyValue"       : dataPS[i].extraData.PRICE.UUID_CURRENCY_VALUE
                                                                          ,"quantity"                : dataPS[i].QtyItem
                                                                          ,"neto"                    : dataPS[i].extraData.PRICE.PRICE_CLP
                                                                          ,"exento"                  : 0
                                                                          ,"iva"                     : 0
                                                                          ,"total"                   : dataPS[i].MontoItem
                                                                          ,"descuento"               : dataPS[i].DescuentoMontoTmp
                                                                      }
                                                                      )
                                      } 
                                      //=======================                                            
                              } 
                              //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
                              else if($this._codDoc == 110){  
                                mntDesc = $this.mnt.MntNetoOri - $this.DTE.Encabezado.OtraMoneda.MntTotOtrMnda
                                $this.order.order = {
                                                         "totalEnvio"                  : 0
                                                        ,"totalDescuento"              : mntDesc                              |0
                                                        ,"totalNeto"                   : 0
                                                        ,"totalIva"                    : 0
                                                        ,"totalExento"                 : $this.DTE.Encabezado.OtraMoneda.MntExeOtrMnda |0
                                                        ,"total"                       : $this.DTE.Encabezado.OtraMoneda.MntTotOtrMnda |0
                                                        ,"totalAjusteSencillo"         : 0
                                                        ,"totalDonacion"               : 0
                                                        ,"totalTip"                    : 0                                      
                                                        ,"codDoc"                      : $this._codDoc
                                                        ,"uuidDte"                     : '0'
                                                        ,"codStatusDte"                : 'P'
                                                        ,"uuidEmisorReceptor"          : receptorForm.uuidEmisorReceptor    
                                                        ,"rutEmisorReceptor"           : receptorForm.rutReceptor    
                                                        ,"flagShiping"                 : 'N'
                                                        ,"dateShiping"                 : null
                                                        ,"flagDelivered"               : 'N'
                                                        ,"dateDelivered"               : null
                                                        ,"uuidSp"                      : virtualSP.UUID_SP
                                                        ,"uuidCrmCustomer"             : receptorForm.uuidCrmCustomer       
                                                        ,"uuidEomOrderStatus"          : null
                                                        ,"dueDate"                     : dueDate
                                                        ,"uuidCrmCustomerContact"      : receptorForm.uuidContactoReceptor
                                                  } 
                                         //orden sin movimiento de Stock
                                         if(flagInsertWarehouseMovements.flagDontInsertWarehouseMovements == 'Y'){
                                          $this.order.order.flagDontInsertWarehouseMovements = 'Y'
                                          $this.order.order.uuidWarehouseMovements           = flagInsertWarehouseMovements.uuidWarehouseMovements
                                        }                                                          
                                      //=======================
                                      for(i=0;i<dataPS.length;i++){
                                        $this.order.orderDetail.push(
                                                                          {
                                                                            "uuidPsServices"         : dataPS[i].extraData.UUID_PS_SERVICES
                                                                          ,"uuidPsServicesPrice"     : dataPS[i].extraData.PRICE.UUID_PS_SERVICES_PRICE
                                                                          ,"uuidPsServicesDiscount"  : dataPS[i].uuidPsServicesDiscount //dataPS[i].extraData.PRICE.UUID_PS_SERVICES_DISCOUNT
                                                                          ,"uuidPsServicesPromotion" : '0'
                                                                          ,"uuidWarehouse"           : dataPS[i].extraData.UUID_WAREHOUSE
                                                                          ,"uuidCurrencyValue"       : dataPS[i].extraData.PRICE.UUID_CURRENCY_VALUE
                                                                          ,"quantity"                : dataPS[i].QtyItem
                                                                          ,"neto"                    : dataPS[i].extraData.PRICE.PRICE_CLP
                                                                          ,"exento"                  : 0
                                                                          ,"iva"                     : 0
                                                                          ,"total"                   : dataPS[i].MontoItemOMS
                                                                          ,"descuento"               : dataPS[i].newDiscountOMS
                                                                      }
                                                                      )
                                      } 
                                      //=======================                                            
                              } 
                              //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
                              else{ 
                                mntDesc = $this.mnt.MntNetoOri - $this.DTE.Encabezado.Totales.MntNeto
                                $this.order.order = {
                                                        "totalEnvio"                   : 0
                                                        ,"totalDescuento"              : mntDesc                              |0
                                                        ,"totalNeto"                   : $this.DTE.Encabezado.Totales.MntNeto  |0
                                                        ,"totalIva"                    : $this.DTE.Encabezado.Totales.IVA      |0
                                                        ,"totalExento"                 : $this.DTE.Encabezado.Totales.MntExe   |0
                                                        ,"total"                       : $this.DTE.Encabezado.Totales.MntTotal |0
                                                        ,"totalAjusteSencillo"         : 0
                                                        ,"totalDonacion"               : 0
                                                        ,"totalTip"                    : 0                                      
                                                        ,"codDoc"                      : $this._codDoc
                                                        ,"uuidDte"                     : '0'
                                                        ,"codStatusDte"                : 'P'
                                                        ,"uuidEmisorReceptor"          : receptorForm.uuidEmisorReceptor    
                                                        ,"rutEmisorReceptor"           : receptorForm.rutReceptor    
                                                        ,"flagShiping"                 : 'N'
                                                        ,"dateShiping"                 : null
                                                        ,"flagDelivered"               : 'N'
                                                        ,"dateDelivered"               : null
                                                        ,"uuidSp"                      : virtualSP.UUID_SP
                                                        ,"uuidCrmCustomer"             : receptorForm.uuidCrmCustomer       
                                                        ,"uuidEomOrderStatus"          : null
                                                        ,"dueDate"                     : dueDate
                                                        ,"uuidCrmCustomerContact"      : receptorForm.uuidContactoReceptor
                                                  }     
                                         //orden sin movimiento de Stock
                                         if(flagInsertWarehouseMovements.flagDontInsertWarehouseMovements == 'Y'){
                                          $this.order.order.flagDontInsertWarehouseMovements = 'Y'
                                          $this.order.order.uuidWarehouseMovements           = flagInsertWarehouseMovements.uuidWarehouseMovements
                                        }                                                      
                                      //=======================
                                      for(i=0;i<dataPS.length;i++){
                                        $this.order.orderDetail.push(
                                                                          {
                                                                            "uuidPsServices"         : dataPS[i].extraData.UUID_PS_SERVICES
                                                                          ,"uuidPsServicesPrice"     : dataPS[i].extraData.PRICE.UUID_PS_SERVICES_PRICE
                                                                          ,"uuidPsServicesDiscount"  : dataPS[i].uuidPsServicesDiscount //dataPS[i].extraData.PRICE.UUID_PS_SERVICES_DISCOUNT
                                                                          ,"uuidPsServicesPromotion" : '0'
                                                                          ,"uuidWarehouse"           : dataPS[i].extraData.UUID_WAREHOUSE
                                                                          ,"uuidCurrencyValue"       : dataPS[i].extraData.PRICE.UUID_CURRENCY_VALUE
                                                                          ,"quantity"                : dataPS[i].QtyItem
                                                                          ,"neto"                    : dataPS[i].extraData.PRICE.PRICE_CLP
                                                                          ,"exento"                  : 0
                                                                          ,"iva"                     : 0
                                                                          ,"total"                   : dataPS[i].MontoItem
                                                                          ,"descuento"               : dataPS[i].DescuentoMontoTmp
                                                                      }
                                                                      )
                                      } 
                                      //=======================                        
                              }  
                              //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""  
                              //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""  
                                //==
                                $this.order.orderDiscount = [];
                                for(i=0;i<$this._DscRcg.length;i++){
                                  $this.order.orderDiscount.push(
                                                                  { 
                                                                     uuidEpcDiscountCoupon : $this._DscRcg[i].UUID_EPC_DISCOUNT_COUPON
                                                                    ,amountDiscountCoupon  : $this._DscRcg[i].DISCOUNT_CALCULATE
                                                                  }
                                                                )
                                }
                                //==
                              //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""  
                              //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""                                        
                              if(carrierChecked == true){
    
                                $this.order.order.totalEnvio  = calculateShipping.amount | 0 
                                $this.order.order.flagShiping = 'P'
                                    if(selectShipment.COD_SHIPING_METHOD == 'C&C'){
                                      orderShipping =[{
                                                           uuidCsCarrier           : selectShipment.UUID_CS_CARRIER 
                                                          ,uuidWarehouse           : selectWhareHouse.UUID_WAREHOUSE
                                                          ,uuidCrmCustomerAddress  : '0'
                                                          ,netoEnvio               : 0  
                                                          ,ivaEnvio                : 0  
                                                          ,totalEnvio              : 0  
                                                          ,shippingMeasure         : {} 
                                                          ,trakingNumber           : '00000'
                                                          ,noteShiping             : 'Entrega en Tienda'
                                                        } ]
                                      $this.order.orderShipping =orderShipping
                                      }
                                      //##
                                      if(selectShipment.COD_SHIPING_METHOD == 'SHP'){
                                        //==
                                          if(selectShipment.COD_CS_CARRIER == 'ONW'){
                                            //==                            
                                              orderShipping =[{
                                                                 uuidCsCarrier           : selectShipment.UUID_CS_CARRIER
                                                                ,uuidWarehouse           : '0'   
                                                                ,uuidCrmCustomerAddress  : selectAddress.UUID_CRM_CUSTOMER_ADDRESS
                                                                ,netoEnvio               : 0  
                                                                ,ivaEnvio                : 0  
                                                                ,totalEnvio              : 0
                                                                ,shippingMeasure         : calculateShipping.measures   
                                                                ,trakingNumber           : '00000'
                                                                ,noteShiping             : 'Despacho Propio'
                                                              } ]
                                            //==
                                            }else{
                                            //==                              
                                              orderShipping =[{
                                                                 uuidCsCarrier           : selectShipment.UUID_CS_CARRIER
                                                                ,uuidWarehouse           : '0'   
                                                                ,uuidCrmCustomerAddress  : selectAddress.UUID_CRM_CUSTOMER_ADDRESS
                                                                ,netoEnvio               : calculateShipping.totalShipping  | 0  
                                                                ,ivaEnvio                : calculateShipping.ivaShipping    | 0  
                                                                ,totalEnvio              : calculateShipping.totalShipping  | 0
                                                                ,shippingMeasure         : calculateShipping.measures       
                                                                ,trakingNumber           : '00000'
                                                                ,noteShiping             : calculateShipping.noteShiping
                                                              } ]                            
                                            //==                                                          
                                            }
                                      $this.order.orderShipping =orderShipping                                          
                                      }
                                      //##
                                      if(selectShipment.COD_SHIPING_METHOD == 'FLC'){
                                        orderShipping =[{
                                                           uuidCsCarrier           : selectShipment.UUID_CS_CARRIER
                                                          ,uuidWarehouse           : '0'   
                                                          ,uuidCrmCustomerAddress  : '0'
                                                          ,netoEnvio               : calculateShipping.totalShipping  | 0  
                                                          ,ivaEnvio                : calculateShipping.ivaShipping    | 0  
                                                          ,totalEnvio              : calculateShipping.totalShipping  | 0
                                                          ,shippingMeasure         : {} 
                                                          ,trakingNumber           : '00000'
                                                          ,noteShiping             : receptorForm.contactoReceptorMail
                                                        } ]
                                    $this.order.orderShipping =orderShipping                                        
                                    }    
                              }            
                              //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""  
                              //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""   
                              placeOrder = await $this._salesPointService.orderPlace($this.order);
                              if(placeOrder.status == true && placeOrder.codStatus == '200'){
                                ////////////////////////////////////////////////////////////////////////
                                // AGREGO REFERENCIAS 
                                if($this._codDoc == 39 || $this._codDoc == 41){
                                  //==================================================      
                                      if($this.DTE.Referencia != undefined){  
                                        index= $this.DTE.Referencia.length 
                                        index = index +1;
                                      }
                                      else{
                                        $this.DTE["Referencia"]=[]
                                        index = 1;
                                      }
                                      ////////////////////////////////////////////////////////
                                      if(spDetail.FLAG_SHOW_ORDER == 'Y'){
                                        $this.DTE.Referencia.push({
                                                                  NroLinRef: index
                                                                  ,RazonRef : "Orden:" + placeOrder.order.FRIENLY_EOM_ORDER
                                                                  })
                                        index = index +1;
                                      }
                                      ////////////////////////////////////////////////////////
                                      if(spDetail.FLAG_SHOW_SP == 'Y'){
                                        $this.DTE.Referencia.push({
                                                                  NroLinRef: index
                                                                  ,RazonRef : "CodVndor:" + spDetail.COD_CM_EMPLOYEE + ' CodCaja:' + virtualSP.COD_SP
                                                                  })
                                        index = index +1;
                                      }
                                      //////////////////////////////////////////////////////
                                      if(spDetail.FLAG_SHOW_EMPLOYEE == 'Y'){
                                        $this.DTE.Referencia.push({
                                                                  NroLinRef: index
                                                                  ,RazonRef : "Atendido por:" +$this.userData.firstName +' '+ $this.userData.lastName
                                                                  })
                                      }
                                      /////////////////////////////////////////////////////
                                  //==================================================            
                                }else if($this._codDoc == 5001 ){  //|| $this._codDoc == 5100
                                  //================================================== 
                                   $this.DTE.QR.url   = placeOrder.order.FRIENLY_EOM_ORDER
                                   $this.DTE.QR.title = "Orden:" + placeOrder.order.FRIENLY_EOM_ORDER
                                   $this.DTE.QR.tail  = "CodVndor:" + spDetail.COD_CM_EMPLOYEE + ' CodCaja:' + virtualSP.COD_SP
                                    //==
                                    /*
                                      $this.DTE.DscRcgGlobal.forEach((e: any) => {
                                          e.ValorDR = Math.round( e.ValorDR / (1 + $this._ivaF))
                                      });
                                      */
                                    //==
                                  //==================================================            
                                }else{
                                  //==================================================
                                    if($this.DTE.Referencia != undefined){  
                                        index= $this.DTE.Referencia.length 
                                        index = index +1;
                                    }
                                    else{
                                      $this.DTE["Referencia"]=[]
                                      index = 1;
                                    }
                                    /////////////////////////////////////////////////////
                                    if(spDetail.FLAG_SHOW_ORDER == 'Y'){
                                        $this.DTE.Referencia.push({
                                                                   NroLinRef : index
                                                                  ,TpoDocRef : 'GLS'
                                                                  ,IndGlobal : 1
                                                                  ,FolioRef  : 0
                                                                  ,FchRef    : fechaRef
                                                                  ,RazonRef  : "Orden:" + placeOrder.order.FRIENLY_EOM_ORDER
                                                                })
                                        index = index +1;
                                    }
                                    /////////////////////////////////////////////////////  
                                    if(spDetail.FLAG_SHOW_SP == 'Y'){                      
                                        $this.DTE.Referencia.push({
                                                                 NroLinRef : index
                                                                ,TpoDocRef : 'GLS'
                                                                ,IndGlobal : 1
                                                                ,FolioRef  : 0
                                                                ,FchRef    : fechaRef
                                                                ,RazonRef : "CodVndor:" + spDetail.COD_CM_EMPLOYEE + ' CodCaja:' + virtualSP.COD_SP
                                                                })
                                        index = index +1;
                                    }
                                    /////////////////////////////////////////////////////  
                                    if(spDetail.FLAG_SHOW_EMPLOYEE == 'Y'){                      
                                        $this.DTE.Referencia.push({
                                                                 NroLinRef : index
                                                                ,TpoDocRef : 'GLS'
                                                                ,IndGlobal : 1
                                                                ,FolioRef  : 0
                                                                ,FchRef    : fechaRef
                                                                ,RazonRef : "Atendido por:" +$this.userData.firstName +' '+ $this.userData.lastName
                                                                })
                                    }
                                    /////////////////////////////////////////////////////                                                
                                  //==================================================            
                                }
                              ////////////////////////////////////////////////////////////////////////
                              try{
                                        let save : any = await $this.orderDteSave(placeOrder.order.UUID_EOM_ORDER,0,$this.DTE) 
                                        let dte  : any = await $this.makeDte($this.DTE)    
                                        ////////////////////////////////////////////////////////////////////////
                                        if(save.status == true && $this._codDoc == 5001){ 
                                          //????????????????????????????????????????????????????????????????????????????????????
                                          console.log( "##############")
                                          console.log( dte)
                                          console.log( "##############")
                                                      let udpDte={
                                                        "language"  :"es"
                                                        ,"appToken"  : null
                                                        ,"uuidSSO"   : null
                                                        ,"order":{
                                                        
                                                                    "uuidEomOrder"     : placeOrder.order.UUID_EOM_ORDER
                                                                    ,"codDoc"          : $this._codDoc
                                                                    ,"uuidDte"         : dte[0].uuidTKT
                                                                    ,"codStatusDte"    : 'OK'
                                                                    ,"uuidCrmCustomer" : ''     
                                                              }
                                                    }
                                            $this._uuidDTE =dte[0].uuidTKT
                                            let orderDte = await $this._salesPointService.orderDte(udpDte);
                                            $this._toastr.success('DTE Emitido', 'Exito');
                                            $this._toastr.success('Orden Pendiente de Pago ', 'Alerta');
                                            //####################################################
                                            if(flagInsertWarehouseMovements.flagDontInsertWarehouseMovements == 'Y'){
                                              //===========================================================
                                              const dialogRef = $this._fuseConfirmationService.open({
                                                    title: `${$this._getGlsDoc()}, folio ${dte[0].folio}`,
                                                    message: `Monto Total: <b>${$this._fn._currencyPipe.transform($this.DTE.Encabezado.Totales.MntTotal, 'CLP', 'symbol-narrow')}</b>`,
                                                    icon: {
                                                        show: true,
                                                        name: 'assignment_turned_in',
                                                        color: 'primary'
                                                    },    
                                                    actions: {
                                                        confirm: {
                                                            label:  `Ver ${$this._configDte.glsDoc2}`,
                                                            color: 'primary',
                                                        },
                                                        cancel: {
                                                            show: false,
                                                            label: `Emitir Otra ${$this._configDte.glsDoc2}`,
                                                        },
                                                    },
                                                });
                                                
                                                dialogRef.afterClosed().subscribe(async (result) => {
                                                    if (result === 'confirmed') {
                                                        /////////////////////////////////////////
                                                        await $this.downloadDte('a4','0')
                                                        /////////////////////////////////////////  
                                                    }
                                                })                                                   
                                              //===========================================================                                                                                                    
                                            }else{
                                              //===========================================================
                                                  const dialogRef = $this._fuseConfirmationService.open({
                                                    title: `${$this._getGlsDoc()}, folio ${dte[0].folio}`,
                                                    message: `Monto Total: <b>${$this._fn._currencyPipe.transform($this.DTE.Encabezado.Totales.MntTotal, 'CLP', 'symbol-narrow')}</b>`,
                                                    icon: {
                                                        show: true,
                                                        name: 'assignment_turned_in',
                                                        color: 'primary'
                                                    },    
                                                    actions: {
                                                        confirm: {
                                                            label:  `Ver ${$this._configDte.glsDoc2}`,
                                                            color: 'primary',
                                                        },
                                                        cancel: {
                                                            show: true,
                                                            label: `Emitir Otra ${$this._configDte.glsDoc2}`,
                                                        },
                                                    },
                                                });
                                                
                                                dialogRef.afterClosed().subscribe(async (result) => {
                                                    if (result === 'confirmed') {
                                                        /////////////////////////////////////////
                                                        await $this.downloadDte('a4','0')
                                                        /////////////////////////////////////////  
                                                    }
                                                })                                                  
                                              //===========================================================                                                  
                                            }
                                            //####################################################                                            
                                            //this.resetProducts();
                                            //this.resetReceptor();
                                            //dataPS=[];
                                            //this.dataREF=[];
                                            //this.dataDsc=[];
                                            //await this.loadTablePS();
                                            //await this.loadTableRef();
                                            //await this.loadTableDsc();
                                            $this.totales.MntNeto       = 0     
                                            $this.totales.MntExe        = 0   
                                            $this.totales.IVA           = 0 
                                            $this.totales.MntTotal      = 0     
                                            $this.totales.MntoNF        = 0   
                                            $this.totales.impAdicional  = 0
                                            $this.summary.quantity      = 0
                                            $this.summary.products      = 0
                                            $this.mnt.MntDsc            = 0
                                            $this.mnt.MntRcg            = 0
                                            $this.mnt.PcrDsc            = 0
                                            $this.mnt.PcrRcg            = 0
                                            $this.mnt.MntNetoOri        = 0
                                            $this.order = {
                                                            language      :"es"
                                                            ,appToken      : null
                                                            ,uuidSSO       : null
                                                            ,order         : {}
                                                            ,orderDiscount : []
                                                            ,orderDetail   : []
                                                          }    
                                            //this.carrierChecked = false                                              
                                            //this.carrierForm.get('uuidCsCarrier').setValue( null);
                                            //this.carrierForm.get('DirDest').setValue( null);
                                            //this.carrierForm.get('uuidwhareHouse').setValue( null);
                                            //this.selectShipment   = {}
                                            //this.selectAddress    = {}
                                            //this.selectWhareHouse = {}          
                                            resolve({
                                                    success      : true
                                                    ,error        : null
                                                    ,uuidEomOrder : placeOrder.order.UUID_EOM_ORDER
                                                    ,uuidDTE      : $this._uuidDTE
                                                    })   
                                          //????????????????????????????????????????????????????????????????????????????????????                                                    
                                        }else{
                                          //????????????????????????????????????????????????????????????????????????????????????
                                          if(save.status == true && dte.caratula.status == true){
                                                let udpDte={
                                                              "language"  :"es"
                                                              ,"appToken"  : null
                                                              ,"uuidSSO"   : null
                                                              ,"order":{
                                                              
                                                                          "uuidEomOrder"     : placeOrder.order.UUID_EOM_ORDER
                                                                          ,"codDoc"          : dte.dte[0].codDoc
                                                                          ,"uuidDte"         : dte.dte[0].uuidDTE
                                                                          ,"codStatusDte"    : 'OK'
                                                                          ,"uuidCrmCustomer" : ''     
                                                                    }
                                                          }
                                                  $this._uuidDTE =dte.dte[0].uuidDTE
                                                  let orderDte = await $this._salesPointService.orderDte(udpDte);
                                                  $this._toastr.success('DTE Emitido', 'Exito');
                                                  $this._toastr.success('Orden Pendiente de Pago ', 'Alerta');
                                                  //####################################################
                                                  if(flagInsertWarehouseMovements.flagDontInsertWarehouseMovements == 'Y'){
                                                    //===========================================================
                                                    const dialogRef = $this._fuseConfirmationService.open({
                                                          title: `${$this._getGlsDoc()}, folio ${dte.dte[0].folio}`,
                                                          message: `Monto Total: <b>${$this._fn._currencyPipe.transform($this.DTE.Encabezado.Totales.MntTotal, 'CLP', 'symbol-narrow')}</b>`,
                                                          icon: {
                                                              show: true,
                                                              name: 'assignment_turned_in',
                                                              color: 'primary'
                                                          },    
                                                          actions: {
                                                              confirm: {
                                                                  label:  `Ver ${$this._configDte.glsDoc2}`,
                                                                  color: 'primary',
                                                              },
                                                              cancel: {
                                                                  show: false,
                                                                  label: `Emitir Otra ${$this._configDte.glsDoc2}`,
                                                              },
                                                          },
                                                      });
                                                      
                                                      dialogRef.afterClosed().subscribe(async (result) => {
                                                          if (result === 'confirmed') {
                                                              /////////////////////////////////////////
                                                              await $this.downloadDte('a4','0')
                                                              /////////////////////////////////////////  
                                                          }
                                                      })                                                   
                                                    //===========================================================                                                                                                    
                                                  }else{
                                                    //===========================================================
                                                        const dialogRef = $this._fuseConfirmationService.open({
                                                          title: `${$this._getGlsDoc()}, folio ${dte.dte[0].folio}`,
                                                          message: `Monto Total: <b>${$this._fn._currencyPipe.transform($this.DTE.Encabezado.Totales.MntTotal, 'CLP', 'symbol-narrow')}</b>`,
                                                          icon: {
                                                              show: true,
                                                              name: 'assignment_turned_in',
                                                              color: 'primary'
                                                          },    
                                                          actions: {
                                                              confirm: {
                                                                  label:  `Ver ${$this._configDte.glsDoc2}`,
                                                                  color: 'primary',
                                                              },
                                                              cancel: {
                                                                  show: true,
                                                                  label: `Emitir Otra ${$this._configDte.glsDoc2}`,
                                                              },
                                                          },
                                                      });
                                                      
                                                      dialogRef.afterClosed().subscribe(async (result) => {
                                                          if (result === 'confirmed') {
                                                              /////////////////////////////////////////
                                                              await $this.downloadDte('a4','0')
                                                              /////////////////////////////////////////  
                                                          }
                                                      })                                                  
                                                    //===========================================================                                                  
                                                  }
                                                  //####################################################                                            
                                                  //this.resetProducts();
                                                  //this.resetReceptor();
                                                  //dataPS=[];
                                                  //this.dataREF=[];
                                                  //this.dataDsc=[];
                                                  //await this.loadTablePS();
                                                  //await this.loadTableRef();
                                                  //await this.loadTableDsc();
                                                  $this.totales.MntNeto       = 0     
                                                  $this.totales.MntExe        = 0   
                                                  $this.totales.IVA           = 0 
                                                  $this.totales.MntTotal      = 0     
                                                  $this.totales.MntoNF        = 0   
                                                  $this.totales.impAdicional  = 0
                                                  $this.summary.quantity      = 0
                                                  $this.summary.products      = 0
                                                  $this.mnt.MntDsc            = 0
                                                  $this.mnt.MntRcg            = 0
                                                  $this.mnt.PcrDsc            = 0
                                                  $this.mnt.PcrRcg            = 0
                                                  $this.mnt.MntNetoOri        = 0
                                                  $this.order = {
                                                                  language      :"es"
                                                                  ,appToken      : null
                                                                  ,uuidSSO       : null
                                                                  ,order         : {}
                                                                  ,orderDiscount : []
                                                                  ,orderDetail   : []
                                                                }    
                                                  //this.carrierChecked = false                                              
                                                  //this.carrierForm.get('uuidCsCarrier').setValue( null);
                                                  //this.carrierForm.get('DirDest').setValue( null);
                                                  //this.carrierForm.get('uuidwhareHouse').setValue( null);
                                                  //this.selectShipment   = {}
                                                  //this.selectAddress    = {}
                                                  //this.selectWhareHouse = {}          
                                                  resolve({
                                                          success      : true
                                                          ,error        : null
                                                          ,uuidEomOrder : placeOrder.order.UUID_EOM_ORDER
                                                          ,uuidDTE      : $this._uuidDTE
                                                          })                                                                    
      
                                            }
                                          //????????????????????????????????????????????????????????????????????????????????????
                                        }
                                        ////////////////////////////////////////////////////////////////////////                
    
                                }catch(e){
                                    console.log("#########################")
                                    console.log(e)
                                    console.log("#########################")
                                      $this._toastr.error('DTE no emitido', 'Error');
                                       resolve({
                                            success : false
                                           ,error   : null
                                       })
                              }
                              ////////////////////////////////////////////////////////////////////////
                            }
                          //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""          
                        //////////////////////////////////////////                    
                          } else {
                            resolve({
                                        success : false
                                       ,error   : null
                                    })
                        }
                    });            
                /////////////////////////////////////////////////////////////////////////////////////////////////   
                /////////////////////////////////////////////////////////////////////////////////////////////////                                        
    
            //#########################################
          }
          catch(e)
          {
          console.log(e)
              reject({
                   success   : false
                  ,error     : e
                  })
          }
          //#########################################        
      });      
    
}            
////////////////////////////////////////       
////////////////////////////////////////
////////////////////////////////////////       
////////////////////////////////////////
async placeOrderGD(spDetail:any,virtualSP:any,receptorForm:any,dataPS:any,carrierChecked:any,calculateShipping:any,selectShipment:any,selectAddress:any,selectWhareHouse:any):Promise<any>{    
    let $this = this; 
    return await new Promise(
      async function (resolve, reject) { 
        //#########################################
         try{
            /////////////////////////////////////////////////////////////////////////////////////////////////   
            /////////////////////////////////////////////////////////////////////////////////////////////////   
              var i:number,index:number, mntDesc : number =0,placeOrder,dueDate,orderShipping={};
              var ts_hms = new Date();
              let fechaRef = ts_hms.getFullYear() + '-' + ("0" + (ts_hms.getMonth() + 1)).slice(-2) + '-' + ("0" + (ts_hms.getDate())).slice(-2) 
              /////////////////////////////////////////////////////////////////////////////////////////////////  
              const dialogRef = $this._fuseConfirmationService.open({
                title: `Emitir ${$this._fn._ucFirstAllWords($this._getGlsDoc())}?`,
                message: `Cliente: <b>${$this._fn._ucFirstAllWords(receptorForm.rznSocReceptor)}</b><br> Monto Total: <b>${$this._fn._currencyPipe.transform($this.DTE.Encabezado.Totales.MntTotal, 'CLP', 'symbol-narrow')}</b>`,
                icon: {
                  show: true,
                  name: 'assignment_turned_in',
                  color: 'primary'
                },    
                actions: {
                    confirm: {
                        label: 'Aceptar',
                        color: 'primary',
                    },
                    cancel: {
                        show: true,
                        label: 'Cancelar',
                    },
                },
            });

            dialogRef.afterClosed().subscribe(async (result) => {
                if (result === 'confirmed') {
            //////////////////////////////////////////  
                            ////////////////////////////////////////////////////////////////////////
                            // AGREGO REFERENCIAS 
                              //==================================================
                                if($this.DTE.Referencia != undefined){  
                                    index= $this.DTE.Referencia.length 
                                    index = index +1;
                                }
                                else{
                                  $this.DTE["Referencia"]=[]
                                  index = 1;
                                }
                                /////////////////////////////////////////////////////
                                /////////////////////////////////////////////////////  
                                if(spDetail.FLAG_SHOW_SP == 'Y'){                      
                                    $this.DTE.Referencia.push({
                                                             NroLinRef : index
                                                            ,TpoDocRef : 'GLS'
                                                            ,IndGlobal : 1
                                                            ,FolioRef  : 0
                                                            ,FchRef    : fechaRef
                                                            ,RazonRef : "CodVndor:" + spDetail.COD_CM_EMPLOYEE + ' CodCaja:' + virtualSP.COD_SP
                                                            })
                                    index = index +1;
                                }
                                /////////////////////////////////////////////////////  
                                if(spDetail.FLAG_SHOW_EMPLOYEE == 'Y'){                      
                                    $this.DTE.Referencia.push({
                                                             NroLinRef : index
                                                            ,TpoDocRef : 'GLS'
                                                            ,IndGlobal : 1
                                                            ,FolioRef  : 0
                                                            ,FchRef    : fechaRef
                                                            ,RazonRef : "Atendido por:" +$this.userData.firstName +' '+ $this.userData.lastName
                                                            })
                                }
                                /////////////////////////////////////////////////////                                                
                              //==================================================            
                          ////////////////////////////////////////////////////////////////////////
                          try{
                                    let dte  : any = await $this.makeDte($this.DTE)    
                                    $this._uuidDTE =dte.dte[0].uuidDTE
                                    ////////////////////////////////////////////////////////////////////////
                                    if(dte.caratula.status == true){
                                            $this._toastr.success('DTE Emitido', 'Exito');
                                            //####################################################
                                                const dialogRef = $this._fuseConfirmationService.open({
                                                    title: `${$this._getGlsDoc()}, folio ${dte.dte[0].folio}`,
                                                    message: `Monto Total: <b>${$this._fn._currencyPipe.transform($this.DTE.Encabezado.Totales.MntTotal, 'CLP', 'symbol-narrow')}</b>`,
                                                    icon: {
                                                        show: true,
                                                        name: 'assignment_turned_in',
                                                        color: 'primary'
                                                    },    
                                                    actions: {
                                                        confirm: {
                                                            label:  `Ver ${$this._configDte.glsDoc2}`,
                                                            color: 'primary',
                                                        },
                                                        cancel: {
                                                            show: true,
                                                            label: `Emitir Otra ${$this._configDte.glsDoc2}`,
                                                        },
                                                    },
                                                });
                                                
                                                dialogRef.afterClosed().subscribe(async (result) => {
                                                    if (result === 'confirmed') {
                                                        /////////////////////////////////////////
                                                        await $this.downloadDte('a4','0')
                                                        /////////////////////////////////////////  
                                                    }
                                                })
                                            //####################################################                                            
                                            //this.resetProducts();
                                            //this.resetReceptor();
                                            //dataPS=[];
                                            //this.dataREF=[];
                                            //this.dataDsc=[];
                                            //await this.loadTablePS();
                                            //await this.loadTableRef();
                                            //await this.loadTableDsc();
                                            $this.totales.MntNeto       = 0     
                                            $this.totales.MntExe        = 0   
                                            $this.totales.IVA           = 0 
                                            $this.totales.MntTotal      = 0     
                                            $this.totales.MntoNF        = 0   
                                            $this.totales.impAdicional  = 0
                                            $this.summary.quantity      = 0
                                            $this.summary.products      = 0
                                            $this.mnt.MntDsc            = 0
                                            $this.mnt.MntRcg            = 0
                                            $this.mnt.PcrDsc            = 0
                                            $this.mnt.PcrRcg            = 0
                                            $this.mnt.MntNetoOri        = 0        
                                            resolve({
                                                     success      : true
                                                    ,error        : null
                                                    ,uuidDTE      : $this._uuidDTE
                                                    })                                                                    

                                      }
                                    ////////////////////////////////////////////////////////////////////////                

                            }catch(e){
                                console.log("#########################")
                                console.log(e)
                                console.log("#########################")
                                  $this._toastr.error('DTE no emitido', 'Error');
                                   resolve({
                                        success : false
                                       ,error   : null
                                   })
                          }
                          ////////////////////////////////////////////////////////////////////////
                        }
                      //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""          
                    //////////////////////////////////////////                    
                });            
            /////////////////////////////////////////////////////////////////////////////////////////////////   
            /////////////////////////////////////////////////////////////////////////////////////////////////                                        

        //#########################################
      }
      catch(e)
      {
      console.log(e)
          reject({
               success   : false
              ,error     : e
              })
      }
      //#########################################        
  });      

}    
////////////////////////////////////////       
////////////////////////////////////////

////////////////////////////////////////       
////////////////////////////////////////
/*
{
   "language":"es",
   "appToken":"5e99141d-a4ec-40b6-8f3d-3df23e018845",
   "uuidSSO":"42468202-2100-7477-4043-424682022100",
   "uuidEomOrder":"13884202-4250-4021-1745-16E4E7C4E591",
   "uuidEomOrderReturn":"0",
   "userInvoice":"14383206-6",
   "type":"USR",
   "format":"80mm",
   "dteB64":true
}
*/
/*
async makeDteAT(uuidEomOrder,uuidEomOrderReturn){
    let $this = this;
    return await new Promise(
      async function (resolve, reject) {
          var tmp,typeUser,user,makeDte,data;
          //////////////////////////////////////////// 
          try{
              tmp       = (JSON.parse(localStorage.getItem('emisorData')))
              typeUser  = tmp.usrData[0].TYPE_USER
              user      = tmp.usrData[0].USER
              data = {
                       language           :"es"
                      ,appToken           : null
                      ,uuidSSO            : null
                      ,uuidEomOrder       : uuidEomOrder
                      ,uuidEomOrderReturn : uuidEomOrderReturn
                      ,userInvoice        : user
                      ,type               : typeUser
                      ,format             :"80mm"
                      ,dteB64             :false
                        
                      }
              makeDte = await $this._salesPointService.makeOmsOrderDTE(data); 
              resolve(makeDte)
          }catch(e){
            resolve(makeDte)
          }
          ////////////////////////////////////////////  
  
        }); 
  }
*/
////////////////////////////////////////       
////////////////////////////////////////
async downloadDte(size: string,op: string){

    var urlDte = `${environment.endPoints.pdf}${this._uuidDTE}`;
    var urlTkt = `${environment.endPoints.pdfTkt}${this._uuidDTE}`;
    var urlCtz = `${environment.endPoints.pdfCtz}${this._uuidDTE}`;
  
    if( this._codDoc == 5001){
      urlTkt = urlTkt
      window.open(urlTkt, "_blank");
    }else if(this._codDoc == 5100){
        window.open(urlCtz, "_blank");
    }
    else{
      if(size == '80mm' && op == '0' ) urlDte = urlDte+'/'+size
      if(size == 'a4'   && op == '0' ) urlDte = urlDte+'/'+size
      if(size == 'a4'   && op != '0' ) urlDte = urlDte+'/a4/cedible'    
      window.open(urlDte, "_blank");
    }
    
  }
////////////////////////////////////////       
////////////////////////////////////////
async orderDteSave(uuidEomOrder,uuidEomOrderReturn,jsonDte): Promise<any>{  
    let $this = this;
    return await new Promise(
      async function (resolve, reject) {
        var auth,token
        token = localStorage.getItem('appToken')
          //////////////////////////////////////////// 
          let json = {
                             "language" :"es"
                            ,"appToken" : null
                            ,"uuidSSO"  : null
                            ,"orderDTE":{
                            
                                        "uuidEomOrder"         : uuidEomOrder
                                        ,"uuidEomOrderReturn"  : uuidEomOrderReturn
                                        ,"dteData"             : jsonDte
                                }
                                
                            }
          let save = await $this._salesPointService.orderDteSave(json);
          ////////////////////////////////////////////  
         resolve(save)
        }); 
  }
////////////////////////////////////////       
////////////////////////////////////////
async makeDte(dte){
    let $this = this;
    return await new Promise(
      async function (resolve, reject) {
          ////////////////////////////////////////////
          try{
              //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°    
                var make,data
                data = [dte]
                if( $this._codDoc == 5100){
                    dte.Encabezado.IdDoc.TipoDTE = 33
                    dte.Encabezado.IdDoc.TipoCTZ = $this._codDoc
                    make = await $this._salesPointService.makeCtz( $this.authDTE ,data)
                }else if( $this._codDoc == 5001){

                  dte.Encabezado.IdDoc.TipoTKT=$this._codDoc
                  make = await $this._salesPointService.makeTkt( $this.authDTE ,data)
                   
                }
                else{
                    make = await $this._salesPointService.makeDte( $this.authDTE ,data)
                }
                resolve(make)
              //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°  
            }
            catch(e)
            {
                 console.log(e)
                reject({success:false,error:e,jsonDte:null})
          }
     });             
  } 
////////////////////////////////////////       
////////////////////////////////////////

////////////////////////////////////////       
////////////////////////////////////////


////////////////////////////////////////       
////////////////////////////////////////
////////////////////////////////////////       
////////////////////////////////////////
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
}    