import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { psServicesCartTable } from '../model/psServicesCart';
import { psServicesCartGS2DTETable } from '../model/psServicesCartGS2DTE';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SalesPointService {


    appToken = localStorage.getItem('appToken');//environment.appTokenUser;



    constructor(
      private _http: HttpClient,
      private _toast: ToastrService
    ) { }
  
//////////////////////////////////////////////////////////////////  
//////////////////////////////////////////////////////////////////
async getSp(): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.sp}${$this.appToken}`;
        const res = await $this._http.get<any>(url);
        res.subscribe(
          async (data) => {
            // console.log(data);
            data = data.dataSet;
            console.log(data)
            data.data.forEach((element: any) => {
              element.DATA_PRINTER = JSON.parse(element.DATA_PRINTER);
            });
            resolve(data);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getSpDetail(uuidSp : string): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.spDetail}/${$this.appToken}/${uuidSp}`;
      const res = await $this._http.get<any>(url);
      res.subscribe(
        async (data) => {
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getSpListEmployee(): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.spEployeeList}/${$this.appToken}`;
      const res = await $this._http.get<any>(url);
      res.subscribe(
        async (data) => {
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async spEployee(uuidSp : string): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.spEployee}/${$this.appToken}/${uuidSp}`;
      const res = await $this._http.get<any>(url);
      res.subscribe(
        async (data) => {
        //   console.log(data);
          data = data.dataSet;
          /*
          data.data.forEach((element: any) => {
            element.DATA_PRINTER = JSON.parse(element.DATA_PRINTER);
          });*/
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
}; 
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async spPsServices(uuidSp : string,uuidWh : string,date : string): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.spPsServices}/${$this.appToken}/${uuidSp}/${uuidWh}/${date}`;
      const res = await $this._http.get<any>(url);
      res.subscribe(
        async (data) => {
          data = data.dataSet.psServices;
          ///////////////////////////////////

          //////////////////////////////////
          data.forEach((element: any) => {
            element.J_ANTICIPADO_TAX = JSON.parse(element.J_ANTICIPADO_TAX);
            element.J_RETENIDO_TAX = JSON.parse(element.J_RETENIDO_TAX);
            element.J_TAX = JSON.parse(element.J_TAX);
            element.PRICE = JSON.parse(element.PRICE);
     //       element.PS_ATTRIBUTES= JSON.parse(element.PS_ATTRIBUTES);
     //       element.PS_SHIPPING_MEASURE= JSON.parse(element.PS_SHIPPING_MEASURE);
          });
          
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async spPrinter(order): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.spPrinter}`

      order.appToken = $this.appToken
      order.language = "es"

      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(order)
                                              })
      data.subscribe(
        (res: any) => {
            this._toast.success('Impresora Actualizada', 'Éxito');
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error.error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble Actualizar Impresora', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async chkDocs(data: any, auth: any): Promise<any> {
    return await this._http.post<any>( environment.endPoints.chkDTE, {
      'auth': JSON.stringify(auth),
      'data': JSON.stringify(data)
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async spPsServicesStorageTotal(codPsServices : string): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.spPsServicesStorageTotal}/${$this.appToken}/${codPsServices}`;
      const res = await $this._http.get<any>(url);
      res.subscribe(
        async (data) => {
        //   console.log(data);
          data = data.dataSet;
          /*
          data.data.forEach((element: any) => {
            element.DATA_PRINTER = JSON.parse(element.DATA_PRINTER);
          });*/
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////  
async discountCouponChk(codEpcDiscountCoupon : string, uuidCustomer : string,rutreceptor : string, idEpcSalesChannel : number,codPmPayMeans : string): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.discountCouponChk}/${$this.appToken}/${codEpcDiscountCoupon}/${uuidCustomer}/${rutreceptor}/${idEpcSalesChannel}/${codPmPayMeans}`;
      const res = await $this._http.get<any>(url);
      res.subscribe(
        async (data) => {
        //   console.log(data);
          data = data.dataSet;
          /*
          data.data.forEach((element: any) => {
            element.DATA_PRINTER = JSON.parse(element.DATA_PRINTER);
          });*/
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};  
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////  
/*
async discountCouponAT(idEpcSalesChannel : string): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.discountCouponAT}/${$this.appToken}/${idEpcSalesChannel}`;
      const res = await $this._http.get<any>(url);
      res.subscribe(
        async (data) => {
          data = data.dataSet;
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};*/
async discountCouponAT(codSalesChannel: string, codPmPayMeans : string, uuidCrmCustomer : string, amountSales : number, quantitySales: number): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  var url;
  return await new Promise (async function(resolve, reject) {
    try {
        url = `${environment.endPoints.discountCouponAT}/${$this.appToken}/${codSalesChannel}/${codPmPayMeans}/${uuidCrmCustomer}/${amountSales}/${quantitySales}`;
      const res = await $this._http.get<any>(url);
      res.subscribe(
        async (data) => {
        //   console.log(data);
          data = data.dataSet;
          /*
          data.data.forEach((element: any) => {
            element.DATA_PRINTER = JSON.parse(element.DATA_PRINTER);
          });*/
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////  
async discountCouponDetail(codEpcDiscountCoupon : string): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.discountCouponDetail}/${$this.appToken}/${codEpcDiscountCoupon}`;
      const res = await $this._http.get<any>(url);
      res.subscribe(
        async (data) => {
        //   console.log(data);
          data = data.dataSet;
          /*
          data.data.forEach((element: any) => {
            element.DATA_PRINTER = JSON.parse(element.DATA_PRINTER);
          });*/
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////  
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////  
async orderPlace(order): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.orderPlace}`

      order.appToken = $this.appToken
      order.language = "es"

      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(order)
                                              })
      data.subscribe(
        (res: any) => {
            this._toast.success('Orden Creada', 'Éxito');
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error.error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear orden Orden', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async orderPayment(order): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.orderPatment}`

      order.appToken = $this.appToken
      order.language = "es"

      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(order)
                                              })
      data.subscribe(
        (res: any) => {
            this._toast.success('Orden Pagada', 'Éxito');
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error.error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble pagar orden ', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async orderClose(order): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.orderClose}`
      order.appToken = $this.appToken
      order.language = "es"

      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(order)
                                              })
      data.subscribe(
        (res: any) => {
            this._toast.success('Orden Finalizada', 'Éxito');
           // console.log(res)
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error.error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble finalizar orden ', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async makeDte(auth,dataDte): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.dteLight}`
      const data = await $this._http.post(url,{
                                                   'auth': JSON.stringify(auth)
                                                  ,'data': JSON.stringify(dataDte)
                                              })
      data.subscribe(
        (res: any) => {
            //console.log(res)
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.error('DTE no emitido\n\r '+ error.error.detail.error.glsStatus, 'Error');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble emimtir DTE', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async makeTkt(auth,dataDte): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.tkt}`
      const data = await $this._http.post(url,{
                                                   'auth': JSON.stringify(auth)
                                                  ,'data': JSON.stringify(dataDte)
                                              })
      data.subscribe(
        (res: any) => {
            //console.log(res)
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.error('DTE no emitido\n\r '+ error.error.detail.error.glsStatus, 'Error');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble emimtir DTE', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async makeDteFull(auth,dataDte): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.emitDTE}`
      const data = await $this._http.post(url,{
                                                   'auth': JSON.stringify(auth)
                                                  ,'data': JSON.stringify(dataDte)
                                              })
      data.subscribe(
        (res: any) => {
            //console.log(res)
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.error('DTE no emitido\n\r '+ error.error.detail.error.glsStatus, 'Error');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble emimtir DTE', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getPdfB64(urlPdf,size): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.pdfB64}/${urlPdf}/${size}`;
      const res = await $this._http.get<any>(url);
      res.subscribe(
        async (data) => {
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getPdfUrl(urlPdf,size): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.pdf}${urlPdf}/${size}`;
      resolve(url);
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getJson(uuidDte): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.JSON}${uuidDte}`;
      const res = await $this._http.get<any>(url);
      res.subscribe(
        async (data) => {
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
/*
ANULA
DEVOLUCION
*/
async getJsonNC(uuidDte,type): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.genNotaCredito}${uuidDte}/${type}`;
      const res = await $this._http.get<any>(url);
      res.subscribe(
        async (data) => {
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async orderDte(order): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.orderDte}`
      order.appToken = $this.appToken
      order.language = "es"
      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(order)
                                              })
      data.subscribe(
        (res: any) => {
           // console.log(res)
          resolve(res.dataSet);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble emimtir DTE', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async closeSP(order): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.spClose}`
      order.appToken = $this.appToken
      order.language = "es"
      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(order)
                                              })
      data.subscribe(
        (res: any) => {
            this._toast.success(res.dataSet.glsStatus, 'Correcto');
          resolve(res.dataSet);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble cerrar Punto de Venta', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async openSP(order): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.spOpen}`
      order.appToken = $this.appToken
      order.language = "es"
      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(order)
                                              })
      data.subscribe(
        (res: any) => {
            this._toast.success(res.dataSet.glsStatus, 'Correcto');
          resolve(res.dataSet);
        },
        (error) => {
          console.log(error);
          //this._toast.error('DTE no emitido\n\r ', 'Error');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble abrir Punto de Venta', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async certSP(order): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.spCert}`
      order.appToken = $this.appToken
      order.language = "es"
      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(order)
                                              })
      data.subscribe(
        (res: any) => {
            this._toast.success(res.dataSet.glsStatus, 'Correcto');
          resolve(res.dataSet);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble cert Punto de Venta', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
  async getReasonCode(): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.reasonCodes}/${$this.appToken}`;
        const res = await $this._http.get<any>(url);
        res.subscribe(
          async (data) => {
          //   console.log(data);
            data = data.dataSet;
            /*
            data.data.forEach((element: any) => {
              element.DATA_PRINTER = JSON.parse(element.DATA_PRINTER);
            });*/
            resolve(data);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async orderReturnPlace(order): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.orderReturnPlace}`

      order.appToken = $this.appToken
      order.language = "es"

      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(order)
                                              })
      data.subscribe(
        (res: any) => {
            this._toast.success('Orden Devolucion Creada', 'Éxito');
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error.error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Orden Devolucion Orden', 'Error');
      console.log(err);
      reject(err);
    }
  });
};

//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async orderReturnClose(order): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.orderReturnClose}`

      order.appToken = $this.appToken
      order.language = "es"

      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(order)
                                              })
      data.subscribe(
        (res: any) => {
            this._toast.success('Emite Nota de Credito', 'Éxito');
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error.error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Nota de Credito', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async orderDteSave(order): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.orderDteSave}`

      order.appToken = $this.appToken
      order.language = "es"

      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(order)
                                              })
      data.subscribe(
        (res: any) => {
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.warning('No es posble guardar DTE json guardado', 'Alerta');
          reject(error.error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble guardar DTE json guardado', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async orderCancel(order): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.orderCancel}`

      order.appToken = $this.appToken
      order.language = "es"

      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(order)
                                              })
      data.subscribe(
        (res: any) => {
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.warning('No es posble anular Orden', 'Alerta');
          reject(error.error);
        }
      );
    }catch(err) {
      this._toast.error('Error al anular Orden', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async addorderMessage(order): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.orderMessage}`

      order.appToken = $this.appToken
      order.language = "es"

      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(order)
                                              })
      data.subscribe(
        (res: any) => {
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.warning('Completar formulario', 'Alerta');
          reject(error.error);
        }
      );
    }catch(err) {
      this._toast.error('Error al agregadar Nota', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async delorderMessage(uuidOrder,uuidOrderMessage): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.orderMessage}/${$this.appToken}/${uuidOrder}/${uuidOrderMessage}`;
      const data = await $this._http.delete(url)
      data.subscribe(
        (res: any) => {
          this._toast.success('Nota Eliminada', 'Ok');
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.warning('No es posble eliminar Nota', 'Alerta');
          reject(error.error);
        }
      );
    }catch(err) {
      this._toast.error('Error al eliminar Nota', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getSpPayMeans(): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.spPayMeans}/${$this.appToken}`;
      const res = await $this._http.get<any>(url);
      res.subscribe(
        async (data) => {
        //   console.log(data);
          data = data.dataSet;
          /*
          data.data.forEach((element: any) => {
            element.DATA_PRINTER = JSON.parse(element.DATA_PRINTER);
          });*/
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////  
async ctzPlace(order): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.ctzPlace}`

      order.appToken = $this.appToken
      order.language = "es"

      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(order)
                                              })
      data.subscribe(
        (res: any) => {
            this._toast.success('Cotización Creada', 'Éxito');
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error.error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Cotización', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async makeCtz(auth,dataDte): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.ctz}`
      const data = await $this._http.post(url,{
                                                   'auth': JSON.stringify(auth)
                                                  ,'data': JSON.stringify(dataDte)
                                              })
      data.subscribe(
        (res: any) => {
            //console.log(res)
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.error('DTE no emitido\n\r '+ error.error.detail.error.glsStatus, 'Error');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble emimtir DTE', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getCtz(uuidCustomer: string ,year: number, month: number): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.ctzList}/${$this.appToken}/${uuidCustomer}/${year}/${month}`;
      const res = await $this._http.get<any>(url);
      res.subscribe(
        async (data) => {
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////  
async ctzUdp(order): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.ctzDetail}`

      order.appToken = $this.appToken
      order.language = "es"

      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(order)
                                              })
      data.subscribe(
        (res: any) => {
           // this._toast.success('Cotización Creada', 'Éxito');
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error.error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble Actualizar Cotización', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getCtzDetail(uuidEomCtz: string): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.ctzDetail}/${$this.appToken}/${uuidEomCtz}`;
      const res = await $this._http.get<any>(url);
      res.subscribe(
        async (data) => {
          ///////////////////////////////////////////////////
          data.dataSet.ctzMessage.forEach((element: any) => {
            element.EMPLOYEE_DATA= JSON.parse(element.EMPLOYEE_DATA);
          });
          ///////////////////////////////////////////////////
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async ctzPlaceOrder(order): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.ctzPlaceOrder}`

      order.appToken = $this.appToken
      order.language = "es"

      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(order)
                                              })
      data.subscribe(
        (res: any) => {
            this._toast.success('Orden Creada', 'Éxito');
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error.error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Orden', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getCtz2Dte(uuidEomCtz: string,codDoc: number): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.ctz2dte}/${uuidEomCtz}/${codDoc}`;
      const res = await $this._http.get<any>(url);
      res.subscribe(
        async (data) => {
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async setOmsOrderForwardCarrier(order): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.orderForwardCarrier}`

      order.appToken = $this.appToken
      order.language = "es"

      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(order)
                                              })
      data.subscribe(
        (res: any) => {
            this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          resolve(error.error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Orden', 'Error');
      console.log(err);
      resolve(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async setOmsOrderSetTrackID(order): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.orderSetTrackID}`

      order.appToken = $this.appToken
      order.language = "es"

      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(order)
                                              })
      data.subscribe(
        (res: any) => {
            this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error.error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Orden', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async makeOmsOrderDTE(order): Promise<any>{  
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.makeOmsOrderDTE}`

      order.appToken = $this.appToken
      order.language = "es"
      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(order)
                                              })
      data.subscribe(
        (res: any) => {
            this._toast.success(res.dataSet.gls, 'Éxito');
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.gls, 'Alerta');
          reject(error.error.dataSet);
        }
      );
    }catch(err) {
      this._toast.error('No es posble emitir DTE', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 

/*
async getSpDetail(idEpcSalesChannel : string): Promise<any>{
  const $this = this;
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.discountCouponAT}/${$this.appToken}/${idEpcSalesChannel}`;
      const res = await $this._http.get<any>(url);
      res.subscribe(
        async (data) => {
        //   console.log(data);
          data = data.dataSet;

          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
*/
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
//////////////////////////////////////////////////////////////////
public spPsServicesPagination(
  uuidSp             : string
 ,uuidWh             : string
 ,date               : string  
 ,codCategory        : string  
 ,codGroup           : string  
 ,page               : number
 ,perPage            : number
 ,from               : string 
 ,searchTerm         : string 
 ,orderBy            : string 
  ,uuidBrands         : string
): Observable<psServicesCartTable> {
 const $this = this;
 var url;
 this.appToken = localStorage.getItem('appToken');
 url =  $this.appToken 
 + '/' + uuidSp
 + '/' + uuidWh
 + '/' + date  
 + '/' + codCategory  
 + '/' + codGroup    
 + '/' + page	
 + '/' + perPage		
 + '/' + from											
 + '/' + searchTerm			
 + '/' + orderBy
+ '/' + uuidBrands
url = `${environment.endPoints.spPsServicesPagination}/${url}`;

 return this._http.get<psServicesCartTable>(url);
}
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async spCarrier(): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.spCarrier}/${$this.appToken}`;
      const res = await $this._http.get<any>(url);
      res.subscribe(
        async (data) => {
          ///////////////////////////////////////////////////
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async spCalculateShipmentCost(order): Promise<any>{   
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.calculateShipmentCost}`

      order.appToken = $this.appToken
      order.language = "es"
      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(order)
                                              })
      data.subscribe(
        (res: any) => {
            this._toast.success(res.dataSet.gls, 'Despacho Calculado');
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.error(error.error.dataSet.gls, 'Alerta');
          reject(error.error.dataSet);
        }
      );
    }catch(err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async spPsServicesSingle(uuidSp : string,uuidWh : string,date : string,uuidPsServices:string,barcode:string): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.spPsServiceSingle}/${$this.appToken}/${uuidSp}/${uuidWh}/${date}/${uuidPsServices}/${barcode}`;
      const res = await $this._http.get<any>(url);
      res.subscribe(
        async (data) => {
          data = data.dataSet.psService;       
          resolve(data);
        },
        (error) => {
          resolve(null);
        }
      );
    }
    catch (err) {
      resolve(null);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
public spPsServicesSG2DTE(
  uuidSp             : string
 ,uuidWh             : string
 ,date               : string  
 ,codCategory        : string  
 ,codGroup           : string 
 ,uuidWarehouseMovements          : string 
 ,page               : number
 ,perPage            : number
 ,from               : string 
 ,searchTerm         : string 
 ,orderBy            : string 
): Observable<psServicesCartGS2DTETable> {
 const $this = this;
 var url;
 this.appToken = localStorage.getItem('appToken');
 url =  $this.appToken 
 + '/' + uuidSp
 + '/' + uuidWh
 + '/' + date  
 + '/' + codCategory  
 + '/' + codGroup  
 + '/' + uuidWarehouseMovements  
 + '/' + page	
 + '/' + perPage		
 + '/' + from											
 + '/' + searchTerm			
 + '/' + orderBy;
url = `${environment.endPoints.spPsServicesSG2DTE}/${url}`;

 return this._http.get<psServicesCartGS2DTETable>(url);
}
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
}
