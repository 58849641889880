import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import * as _ from 'underscore'; /* http://underscorejs.org/# */
/*
npm i underscore --save
npm i @types/underscore --save-dev
*/
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, NumberFormatStyle } from '@angular/common';
import { DatePipe,DecimalPipe } from '@angular/common';
import * as rut from '@fdograph/rut-utilities';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

  _currencyPipe  : CurrencyPipe
  _datePipe      : DatePipe
  _decimalPipe   : DecimalPipe

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  constructor() { 
    this._currencyPipe = new CurrencyPipe('es-CL')
    this._datePipe     = new  DatePipe('es-CL')
    this._decimalPipe  = new DecimalPipe('es-CL')

  }
//====================================================
//====================================================
/**
 * 
 * @param {*} master 
 * @param {*} child 
 * @returns 
 * 
 * master : [ '33', '34', '46', '52', '56', '61' ]
 * child  : [ '33', '56', '61' ]
 * return true
 */
async  _arrIn(master,child) {
    return new Promise((resolve, reject) =>{
        // ========================================================
          try{
            //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""    
             var value;
             for( let i in child){ 
                 value = master.find(element => element ==child[i])
                 if(value == undefined){
                    resolve(false);
                 }
             }
             resolve(true);
            //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
            }
            catch(e)
            {
              reject(false)
            }
        // ========================================================
        })
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!  
    }
//====================================================
//====================================================
_formatDate(date,sep) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join(sep);
}
//====================================================
//====================================================
_amountFormat(amount){

  return this._currencyPipe.transform(amount, 'CLP', 'symbol-narrow') 
}
//====================================================
//====================================================
_numberFormat(n){

  return this._decimalPipe.transform(n) 
}
//====================================================
//====================================================
_rutFormat(rutUnformat){
return rut.formatRut(rutUnformat, rut.RutFormat.DOTS_DASH)
}
//====================================================
//====================================================
_dateFormat(date){  
  return this._datePipe.transform(date, 'dd-MM-yyyy')
}
//====================================================
//====================================================
//capitalizeFirstLetter
_capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}
//====================================================
//====================================================
_ucFirstAllWords( str )
{   var pieces 
    str = str.toLowerCase()
    pieces = str.split(" ");
    for ( var i = 0; i < pieces.length; i++ )
    {
        var j = pieces[i].charAt(0).toUpperCase();
        pieces[i] = j + pieces[i].substr(1);
    }
    return pieces.join(" ");
}
//====================================================
//====================================================
_numericOnly(event): boolean {    
  let patt = /^([0-9])$/;
  let result = patt.test(event.key);
  return result;
}
//====================================================
//====================================================
_alphaNumberOnly (e) {  // Accept only alpha numerics, not special characters 
   let patt = /^([a-zA-Z0-9])$/;
  let result = patt.test(e.key);
  return result;
}
//====================================================
//====================================================
_onPaste(e) {
  e.preventDefault();
  return false;
}
//====================================================
//====================================================
_removeSpecialCharacters(event) {
  let newVal = event.target.value.replace('[^A-Za-z,]', '');
  return newVal;
}
//====================================================
//====================================================
_openExternal(url){
  window.open(url, "_blank");
  }
//====================================================
//====================================================
_isNumber(number){
  try{
      return /^-?[\d.]+(?:e-?\d+)?$/.test(number); 
      //  return(parseInt(number,10))
  } catch (e) {
      console.log(e);
      return false;
  }        
}
//====================================================
//====================================================
_roundAmount(amount,currency,float){
  try{
          var a = Number(amount)
          var b = Number(float)
          if(currency == null || currency == undefined){
              return(Math.round(a))
          }
          else
          {
              return(Math.round(a))
          }
      }
      catch(e)
      {
        console.log(e);
        return null;
  
      }  
  }
//====================================================
//====================================================  
_chkRut(strRut){
  if(strRut){
    let tmpRut = strRut.split("-")
    if(tmpRut[1] == 'k' || tmpRut[1] == 'K') strRut =  tmpRut[0] +'-K'
    ///////////////
        if(rut.validateRut(strRut) == true)
          return {status:true,srtRut:rut.formatRut(strRut, rut.RutFormat.DOTS_DASH),srtRutClean:rut.formatRut(strRut, rut.RutFormat.DASH)}
        else
          return {status:false,srtRut:null}
}
return {status:false,srtRut:null,srtRutClean:null}
}
//====================================================
//====================================================
_longDate(date,comuna){
  var tmp = date.split('-')
  var str = comuna.trim() + ', '+ tmp[2] +' de '+ this._month2txt(tmp[1]) +' de '+ tmp[0]
  return(str)
}
//====================================================
//====================================================  
_date2DTE(date,sep='/'){
  var tmp = date.split('-')
  return (tmp[2]+sep+tmp[1]+sep+tmp[0])
}
//====================================================
//====================================================  
_month2txt(month){

  var tmp = Number(month)
  switch (tmp) {
    case  1: return 'Enero';
    case  2: return 'Febrero';
    case  3: return 'Marzo';
    case  4: return 'Abril';
    case  5: return 'Mayo';
    case  6: return 'Junio';
    case  7: return 'Julio';
    case  8: return 'Agosto';
    case  9: return 'Septiembre';
    case 10: return 'Octubre';
    case 11: return 'Noviembre';
    case 12: return 'Diciembre';                        
    default: return '';
  }
}
//====================================================
//====================================================
async  _sumArrayJson(json,key){
    return new Promise(async function (resolve, reject) {
        // ========================================================
          try{
            //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""    
            var sum=0;
                for(let i=0;i<json.length;i++){
                    sum = Number(sum)+ (Number(json[i][key]) || 0 )
                }
              resolve(sum);
            //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
            }
            catch(e)
            {
                console.log(e)
                resolve(false)
      
            }
        // ========================================================
        })
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!     
}
//====================================================
//====================================================
//######################################################
//######################################################
/**
 * 
 * @param {*} json 
 * @param {*} field 
 * @param {*} type 
 * @returns 
 *  json: [
 *          { TipoDTE: 33 },
 *          { TipoDTE: 33 },
 *          { TipoDTE: 33 },
 *          { TipoDTE: 33 },
 *          { TipoDTE: 56 },
 *          { TipoDTE: 56 }
 *       ]
 *
 * field  : TipoDTE
 * return key   : [ '33', '56' ]
 * return count : { '33': 4, '56': 2 }
 *
 */
async  _jsonCountBy(json,field,type='key'){
  return new Promise((resolve, reject) =>{
      // ========================================================
        try{
          //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""      
          if(type=='key')
          resolve ( _.keys( _.countBy(json, field)));
          if(type=='count'){
              resolve  ( _.countBy(json,field));
          }

          //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
          }
          catch(e)
          {
            
            reject(null)
    
          }
      // ========================================================
      })
      // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!        
}
//######################################################
//######################################################
/**
* 
* @param {*} json 
* @param {*} field 
* @returns 
* [
*  { RUT: '77071142-8', ...}
* ,{ RUT: '77071142-8', ...}
* ,{ RUT: '77071142-8', ...}
* ]
* return [ '77071142-8' ]
*/
async  _jsonCountByValue(json,field){

return new Promise((resolve, reject) =>{
  // ========================================================
    try{
      //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""      
      var aa =field 
      var values = json.map(function (arr) { return arr[aa]; });
      var sorted = values.sort();
      
      var unique = sorted.filter(function (value, index) {
          return value !== sorted[index + 1];
      });
     resolve(unique)
      //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
      }
      catch(e)
      {
        reject(null)
      }
  // ========================================================
  })
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!        
}
//######################################################
//######################################################
/**
 * 
 * @param {*} json 
 * @param {*} field 
 * @param {*} type 
 * @returns 
 *  json: [
 *          { TipoDTE: 33 },
 *          { TipoDTE: 33 },
 *          { TipoDTE: 33 },
 *          { TipoDTE: 33 },
 *          { TipoDTE: 56 },
 *          { TipoDTE: 56 }
 *       ]
 *
 * field  : TipoDTE
 * return key   : [ '33', '56' ]
 * return count : { '33': 4, '56': 2 }
 *
 */
async  _jsonGroupBy(json,field,sumField,operationGroup){
  return new Promise(async (resolve, reject) =>{
      // ========================================================
      var groupBy : any =[]
      var fil           =[]
      var filedFilter : string =''
      var suma        = 0
      var cuenta      = 0
      var operation   = 0
        try{
          //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""    
          var uniqueField: any =  await this._jsonCountByValue(json,field)
          if(uniqueField.length == 0 ){
            resolve([])
          }else{
            for(let i=0;i<uniqueField.length;i++){
                  filedFilter = uniqueField[i]
                  fil = await json.filter(i => i[field] ==  filedFilter)
                  cuenta = fil.length
                  suma = 0
                  operation = 0 
                  for(let j=0;j<fil.length;j++){
                        suma = suma + fil[j][sumField]
                  }
                  //==========================================================================
                      switch (operationGroup) {
                        case 'sum': 
                        //#################################################################
                          operation = suma
                        break;
                        //#################################################################
                        case 'avg': 
                          operation = suma / fil.length
                        break;
                        //#################################################################
                        case 'count': 
                          operation =  fil.length
                        break;
                        //#################################################################   
                        default: 
                          operation = suma
                        break;
                        //#################################################################   
                        }
                  //==========================================================================
                  fil = []
                  groupBy.push({
                                   field  : uniqueField[i]
                                  ,sum    : suma
                                  ,count  : cuenta
                                  ,group  : operation 

                                })
            }
          }
          resolve(groupBy)
          //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
          }
          catch(e)
          {
            
            resolve(null)
    
          }
      // ========================================================
      })
      // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!        
}
//######################################################
//######################################################
/*
checkRut(rut,field) {
  var valor,cuerpo,dv,rutTmp
  
  var suma,multiplo,index,dvEsperado


  if(field == 'RUTTrans'){
      this.errRUTTrans     = ''
      this.errFlagRUTTrans = false
  }
  //
  if(field == 'RUTChofer'){
      this.errRUTChofer     = ''
      this.errFlagRUTChofer = false
  }
  //
  if(rut == undefined || rut == null || rut == ''  )
  {    
      console.log("Rut Nulo")
  }
  else{
      
      //Quito espacios en blanco
      valor = rut.trim();        
      // Despejar Puntos
      valor = valor.replaceAll('.','');
      // Despejar Guión
      valor = valor.replaceAll('-',''); 
      //
      cuerpo = valor.substring(0,valor.length -1)
      dv     = valor.substring(valor.length -1,valor.length )
     
      //console.log(cuerpo)
      //console.log(dv)
      // Formatear RUN
      rut = cuerpo + '-'+ dv
      //////////////////////////////////////////////////
      // Si no cumple con el mínimo ej. (n.nnn.nnn)
      
      if(cuerpo.length < 7) { 
          if(field == 'RUTTrans'){
              this.errRUTTrans     = "RUT Empresa Incompleto"
              this.errFlagRUTTrans = true
          }
          //
          if(field == 'RUTChofer'){
              this.errRUTChofer     = "RUT Chofer Incompleto"
              this.errFlagRUTChofer = true
          }
          return false;
      }
        
      //////////////////////////////////////////////////        
      let rutTmp = this._fn._chkRut(rut)
      console.log(rutTmp)
      //////////////////////////////////////////////////  
      if(rutTmp.status == false){
          if(field == 'RUTTrans'){
              this.errRUTTrans     = "RUT Empresa Inválido"
              this.errFlagRUTTrans = true
              //this.transporteForm.get('RUTTrans').setValue(null);
          }
          //
          if(field == 'RUTChofer'){
              this.errRUTChofer     = "RUT Chofer Inválido"
              this.errFlagRUTChofer = true
              //this.transporteForm.get('RUTChofer').setValue(null);
          }
      }  
      else{
          if(field == 'RUTTrans'){
              this.transporteForm.get('RUTTrans').setValue(rutTmp.srtRutClean);
              this.errRUTTrans     = ""
              this.errFlagRUTTrans = false
          }
          //
          if(field == 'RUTChofer'){
              this.transporteForm.get('RUTChofer').setValue(rutTmp.srtRutClean);
              this.errRUTChofer     =""
              this.errFlagRUTChofer = false
          }            
      }
      //////////////////////////////////////////////////          
      return true; 
  }   
  }
*/
//====================================================
//====================================================
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
}