import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { Router } from '@angular/router';

import { CatalogService } from 'app/services/catalog.service';
import { DocsService } from 'app/services/docs.service';
import { EmisorService } from 'app/services/emisor.service';
import { WarehouseService } from 'app/services/warehouse.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { jsPDF } from "jspdf";
import { FunctionsService } from 'app/services/functions.service';

@Component({
  selector: 'app-dialog-update-movimientos',
  templateUrl: './dialog-update-movimientos.component.html',
  styleUrls: ['./dialog-update-movimientos.component.scss']
})
export class DialogUpdateMovimientosComponent implements OnInit {

  @ViewChild('table') table: MatTable<any> | undefined;

  emisorData: any = (JSON.parse(localStorage.getItem('emisorData'))).emisorData[0];

  guiaEntradaForm: UntypedFormGroup;
  detalleGuiaForm: UntypedFormGroup;


  reasonCodes            : any[] = [];
  selectedReasonCodes    : any = {}
  reasonCodesDte         : any[] = [];
  selectedreasonCodesDte : any = {}
  //
  showDocument       : boolean = false;
  showObsMovements   : boolean = false;

  showCodBoxStorage  : boolean = false;
  showBasePrice      : boolean = false;
  showDataBoxStorage : boolean = false;
  showEntryPsServices: boolean = false;
  showDueDate        : boolean = false;
  //
  detalleDelete : any =[];
  //
  basePriceGls       : string  = null
  //
  dateTmp = new Date().toISOString().split('T')[0]
  idCodDocDefault :any= {}
  //
  configBoxStorage : any =[]
  dataBoxStorage   : any =[]
  //
  bodegas: any[] = [];
  estados: any[] = [];
  tiposDTEs: any[] = [];
  selectedEstado : any ={}
  update: boolean = false;

  emisorLogo: any = '';


  detalle: any[] = [];
  index : number = 0;

  displayedColumnsDetalle: string[] = ['code','lote','namePS', 'stock', 'price', 'dscto','exent', 'rm'];
  detailWarehouse: any  ={
                             ADDRESS_WAREHOUSE    : ''
                            ,COD_SII_WAREHOUSE    : ''
                            ,COD_WAREHOUSE        : ''
                            ,NAME_WAREHOUSE_TYPE  : ''
                          }
  nameDoc : any = ''
  codDoc  : any = ''
  psServiceSelected : any = {}
  //
  flagGeneraGuiaDespacho : boolean = false;

  //MAX
  // displayedColumnsDetailMovement = [];
  //totalItems = 0;
  //
  //
  tipoGuia = this.data.movement.COD_DOC_TYPE;
  isDisabled = true;
  //
  namePdf ='';
  //
  summary : any = []
  //
 //////////////////////////////////////////////////
  constructor(
    private _fb: FormBuilder,
    private _emisorService: EmisorService,
    private _catalogService: CatalogService,
    private _docService: DocsService,
    private _dialog: MatDialog,
    private _toastr: ToastrService,
    private _fuseConfirmationService: FuseConfirmationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _warehouseService: WarehouseService,
    private _dialogRef: MatDialogRef<DialogUpdateMovimientosComponent>,
    private _fn : FunctionsService
  ) {

  }
 //////////////////////////////////////////////////
 async ngOnInit(): Promise<any> {

    const { logo } = await this._emisorService.getLogo();
    this.emisorLogo = logo;
    // console.log(typeof(this.data.detailMovement[0].DATA_BOX_STORAGE))
    if(typeof(this.data.detailMovement[0].DATA_BOX_STORAGE) === 'string'){
      this.data.detailMovement.forEach((element) => {
        element.DATA_BOX_STORAGE = JSON.parse(element.DATA_BOX_STORAGE);
      }); 
    };
    //==


this.summary = await this._fn. _jsonGroupBy(this.data.detailMovement,'NAME_UNIT_MEASUREMENT','QUANTITY','sum')

console.log(this.data.movement)
console.log(this.data.detailMovement)

  };


 //////////////////////////////////////////////////

async generatePDF() {
  const dialogContent = await document.getElementById('dialogContent');
  var  nameDoc //= this.data.movement.COD_WAREHOUSE_MOVEMENTS;
    //
    if(this.data.movement.COD_DOC_TYPE == 'GV'){
      nameDoc = this.data.movement.FRIENLY_EOM_ORDER + '_' + this.data.movement.COD_DOC_TYPE+'.pdf'
  }
  else{
    nameDoc = this.data.movement.COD_WAREHOUSE_MOVEMENTS + '.pdf'
  }
  //
  const doc = new jsPDF({
    format: 'a4',
    unit: 'px',
  });
  doc.html(dialogContent, {
    filename: nameDoc,
    html2canvas: {
      scale: 0.47, 
    },
    margin: [10, 10, 10, 10],
    callback: function (doc) {
      doc.save(nameDoc);
    },
  });
}
}
