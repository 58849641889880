import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl,UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Observable, of, ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { RcService } from  'app/services/rc.service';
import { ClienteService } from 'app/services/cliente.service';
import { environment } from 'environments/environment';
//
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ChangeDetectorRef } from '@angular/core';
import { SalesPointService } from 'app/services/salesPoint.service';
import { DialogSearchProductsComponent } from '../../docs/dialog-search-products/dialog-search-products.component'
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';
//
import { DteService } from 'app/services/dte.service';
//

@Component({
  selector: 'app-select-rc-products',
  templateUrl: './select-rc-products.component.html',
  styleUrls: ['./select-rc-products.component.scss']
})
export class SelectRcProductsComponent implements OnInit {
//######################################################
detalleForm   : UntypedFormGroup;
 //
 virtualSP    : any={}
 spDetail     : any={}
 spPayMeans   : any = []
 spWarehouse  : any = []
 tipDoc       : any = []
 selectPayMeans   : any={}
 selectWarehouse  : any={}
 flagOverwrite    : boolean = false;
 flagProductDiscountChecked : boolean = false;
 maxDetalleItem   : number = 9999999
 psItem           : number = 0
 dataPS           : any = {};
 selectedPS       : any = {};

 minDateInfo = new Date(2000, 0, 1);
 today = new Date();
 maxDate = new Date(9999, 11, 31)

 remainingText : number = 0;
///
discountPS = {
  success                : true
 ,flagGlobalDiscount     : 'N'
 ,fullFillment           : '0'
 ,nameDiscount           : ''
 //    
 ,typeDiscount           : ''
 ,percentDiscount        : 0
 ,amountDiscount         : 0
 ,subTotal               : 0
 //
 ,flagQuantityLimit      : 'N'
 ,quantityLimit          : 0
 ,flagAmountLimit        : 'N'
 ,amountLimitClp         : 0
 ,flagQuantityStart      : 'N'
 ,quantityStart          : 0
 ,flagQuantityEnd        : 'N'
 ,quantityEnd            : 0
 ,flagAmountStart        : 'N'
 ,amountStartClp         : 0
 ,flagAmountEnd          : 'N'
 ,amountEndClp           : 0     
 ,uuidPsServicesDiscount : '0'
 //
 ,discountManual         : 'N'
 ,percentDiscountManual  : 0
 ,amountDiscountManual   : 0
 //
 ,flagActiveDiscount     : true
}            
///
//######################################################
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: UntypedFormBuilder,
    public _dialogRef: MatDialogRef<SelectRcProductsComponent>,
    private _fb: FormBuilder,
    private _fuseConfirmationService: FuseConfirmationService,
    private _rc:RcService,
    private ref: ChangeDetectorRef,
    private _clienteService:ClienteService,
    private _dialog: MatDialog,    
    private _salesPointService : SalesPointService,
    private _currencyPipe:CurrencyPipe,
    private _dteService : DteService,
  ) { 
    this.detalleForm = this._formBuilder.group({
      NroLinDet         : [null, [Validators.required]]
     ,TpoCodigo         : ['SKU']  
     ,VlrCodigo         : [null, [Validators.required]]
     ,IndExe            : [null]   
     //,ItemEspectaculo : null              
     //,RUTMandante     : null          
     ,NmbItem           : [null, [Validators.required]]
     ,NmbItemOri        : [null, [Validators.required]]
     ,DscItem           : [null, [Validators.required]] 
     //,InfoTicket      : null            
     ,QtyItem           : [null, [Validators.required]]
     ,UnmdItem          : [null, [Validators.required]]        
     ,PrcItem           : [null, [Validators.required]]
     ,DescuentoPct      : [null, ]
     ,DescuentoMonto    : [null, ]
     ,RecargoPct        : [null, ]
     ,RecargoMonto      : [null, ]
     ,MontoItem         : [null, [Validators.required]]
     //
     ,extraData         : [null, [Validators.required]]
     ,uuidWareHouse     : [null, [Validators.required]]
     ,CodImpAdic        : [null, ]
     ,glsImpAdic        : [null, ]
     //
     ,codCurrency       : [null]
     ,symbolCurrency    : [null]
     ,dateStart         : [null, [Validators.required]]
     ,dateEnd           : [null, [Validators.required]]
    //
    ,uuidPsServicesDiscount : [null, ]
    //
    ,flagProductDiscount:[false]

    ,DscItemTable : ['']
    //

});

  }
//######################################################
async ngOnInit(){

  await this.getSpListEmployee()
  }
//######################################################
async getSpListEmployee(): Promise<any>{
  var tmp,tmp2,tmp3,tmp4;
  tmp = await this._salesPointService.getSpListEmployee();
  tmp2 = tmp.dataSet.sp.filter(item => item.TYPE_SP == 'V')
  if(tmp2.length == 0){
    //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
    alert("Error, sin punto de venta virtual")
    //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$    
  } 
  else{
    //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$    
      this.virtualSP=tmp2[0]
      tmp3 = await this._salesPointService.spEployee(this.virtualSP.UUID_SP);
      this.spDetail            = tmp3.sp
      this.spPayMeans          = tmp3.pmPaysMeans
      this.spWarehouse         = tmp3.wmWarehouse
      this.tipDoc              = tmp3.doc
      /////////////////////////////////////////////////
      //##Medio de pago por defecto
      if(this.spPayMeans.length > 0){
        tmp4 = this.spPayMeans.filter(item => item.FLAG_DEFAULT_PAY_MEANS == 'Y')
        if(tmp4.length > 0) this.selectPayMeans = tmp4[0]; else this.selectPayMeans = this.spPayMeans[0];
      }
      else{
        //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
         alert("Punto de venta sin medios de pago configurados")
        //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  
      }
      //##Bodega por defecto
      if(this.spWarehouse.length > 0){
        tmp4 = this.spWarehouse.filter(item => item.FLAG_DEFAULT_WAREHOUSE == 'Y')
        if(tmp4.length > 0) this.selectWarehouse = tmp4[0]; else this.selectWarehouse = this.spWarehouse[0];
        this.detalleForm.get('uuidWareHouse').setValue( this.selectWarehouse.UUID_WAREHOUSE);
      }
      else{
        //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
         alert("Punto de venta sin bodegas configuradas")
        //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  
      }
      /////////////////////////////////////////////////      
    //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$      
  }
}
//########################################################### 
async setSpWarehouse(v): Promise<any>{
  this.selectWarehouse = this.spWarehouse.filter(item => item.UUID_WAREHOUSE == v)[0]
}
//########################################################### 
async setSpPayMeans(v): Promise<any>{
  this.selectPayMeans = this.spPayMeans.filter(item => item.COD_PM_PAY_MEANS == v)[0]
}
//########################################################### 
//########################################################### 
showProducts(): void {
  const dialogRef = this._dialog.open(DialogSearchProductsComponent, {
      width: '100%'
     ,data: {
           uuidSp: this.virtualSP.UUID_SP
          ,uuidWh:this.selectWarehouse.UUID_WAREHOUSE
          ,date  : 'now'
        }
  });
  dialogRef.afterClosed().subscribe(async result => {
      var taxA,taxR, tasa, glosa
      this.selectedPS = {}
      //####################################
        tasa = null
        glosa= null
        if(result.FLAG_ANTICIPADO_TAX == 'Y'){
          tasa = result.J_ANTICIPADO_TAX.COD_TAX
          glosa= result.J_ANTICIPADO_TAX.TASA+'% ' + result.J_ANTICIPADO_TAX.GLOSA
        }
      //####################################      
      if(!!result){
        this.selectedPS = result
        console.log(result)
          this.detalleForm.patchValue({      
                                           NroLinDet         : 0
                                          ,TpoCodigo         : 'SKU'  
                                          ,VlrCodigo         : result.COD_PS_SERVICES
                                          ,IndExe            : result.COD_DTE_TAX        
                                          ,NmbItem           : result.NAME_PS_SERVICES
                                          ,NmbItemOri        : result.NAME_PS_SERVICES_INVOICE
                                          ,DscItem           : result.PS_SERVICES_DESCRIPTION_INVOICE
                                          ,QtyItem           : 1
                                          ,UnmdItem          : result.COD_UNIT_MEASUREMENT       
                                          ,PrcItem           : result.PRICE.PRICE
                                          ,DescuentoPct      : 0
                                          ,DescuentoMonto    : result.PRICE.DISCOUNT
                                          ,RecargoPct        : null
                                          ,RecargoMonto      : null
                                          ,MontoItem         : result.PRICE.PRICE_CLP 
                                          ,extraData         : result
                                          ,uuidWareHouse     : result.UUID_WAREHOUSE  
                                          ,CodImpAdic        : tasa
                                          ,glsImpAdic        : glosa
                                          ,codCurrency       : result.PRICE.COD_CURRENCY
                                          ,symbolCurrency    : result.PRICE.SYMBOL_CURRENCY
                                          //
                                      })    
          // set max aviable
          if( result.COD_WAREHOUSE_TYPE == 'W' ){
            if(result.FLAG_OUT_STOCK == 'Y') this.maxDetalleItem = 99999;
            if(result.FLAG_OUT_STOCK == 'N') this.maxDetalleItem = 99999;//result.QUANTITY_AVAILABLE; son ordenes futuras
          }
          else{
          this.maxDetalleItem = 99999
          }
          // set overWrite
          if( result.FLAG_OVERWRITE == 'Y' ){
                this.flagOverwrite = true;
          }
          else{
                this.flagOverwrite = false;
          }
          //
          this.setIndefinido(true)
          await this.changeQuantity(this.detalleForm.value.QtyItem)
          // 

    }
  });
};
//########################################################### 
resetProducts(){
  this.detalleForm.reset();
}
//########################################################### 
addProduct(){
  var newAmount   = 0
  var newDiscount = 0
  var flagOverwritePrice;
    /////////////////////////////////////////////////////////////////////////////////////////////////
    //calcula total
    const dialogRef = this._fuseConfirmationService.open({
      title: `Agregar Producto?`,
      message: `${this.detalleForm.value.NmbItem}<b>${this._currencyPipe.transform(this.detalleForm.value.PrcItem  , 'CLP', 'symbol-narrow')}</b>, cantidad:<b>${this.detalleForm.value.QtyItem}</b>`,
      icon: {
        show: true,
        name: 'assignment_turned_in',
        color: 'primary'
      },    
      actions: {
          confirm: {
              label: 'Agregar',
              color: 'primary',
          },
          cancel: {
              label: 'Cancelar',
          },
      },
  });

  dialogRef.afterClosed().subscribe(async (result) => {
      if (result === 'confirmed') {
          //////////////////////////////////////////
          if(this.detalleForm.value.PrcItem == this.selectedPS.PRICE.PRICE ) flagOverwritePrice = 'N'; else flagOverwritePrice='Y';
          //===============================
           var newDiscount = 0
           if(this.discountPS.amountDiscount > 0){
            newDiscount = this.discountPS.percentDiscount/100
           }
           if(this.discountPS.amountDiscountManual > 0){
            newDiscount = this.discountPS.percentDiscountManual/100
           }
          //===============================
          this.dataPS = {
                             appToken                : null
                            ,language                : 'es'
                            ,uuidSSO                 : null
                            ,uuidRc                  : this.data.uuidRc      
                            ,detailRc                : [
                                                          {
                                                           uuidPsServices          : this.selectedPS.UUID_PS_SERVICES                 
                                                          ,uuidPsServicesPrice     : this.selectedPS.PRICE.UUID_PS_SERVICES_PRICE                         
                                                          ,uuidPsServicesDiscount  : this.discountPS.uuidPsServicesDiscount                       
                                                          ,uuidPsServicesPromotion : '0'                       
                                                          ,uuidWarehouse           : this.selectedPS.UUID_WAREHOUSE        
                                                          ,uuidCurrencyValue       : this.selectedPS.PRICE.UUID_CURRENCY_VALUE                     
                                                          ,quantityPsServices      : this.detalleForm.value.QtyItem  
                                                          ,flagOverwritePrice      : flagOverwritePrice
                                                          ,overwritePrice          : this.detalleForm.value.PrcItem    
                                                          ,overwriteDescripction   : this.detalleForm.value.DscItem      
                                                          ,overwriteDiscount       : newDiscount
                                                          ,dateStartDiscount       : this.formatDate(this.detalleForm.value.dateStart)                               
                                                          ,dateEndDiscount         : this.formatDate(this.detalleForm.value.dateEnd)                                                            
                                                          ,action                  : 'ADD'   
                                                          }
                                                        ]
                          }
          let j = await await this._rc.setRcDetail(this.dataPS)
          if(j.codStatus == 200 ){
          //===========================================
          this._dialogRef.close({reload:true});
          //===========================================
          }

          //////////////////////////////////////////                    
      } else {
          return;
      }
  });
/////////////////////////////////////////////////////////////////////////////////////////////////            
}
//########################################################### 
btnClose(){
  this._dialogRef.close();
}
//########################################################### 
showProductDiscount(e){
  console.log(e)
    if(e.checked ==  true)
      this.flagProductDiscountChecked = true
    else 
      this.flagProductDiscountChecked = false
  }
//########################################################### 

setIndefinido(val){
  if(val == true){
    //#############
    this.detalleForm.get('dateStart').setValue(this.today);
    this.detalleForm.get('dateEnd').setValue(this.maxDate);
    //#############
  }
  else{
    //#############
    this.detalleForm.get('dateStart').setValue(this.today);
    this.detalleForm.get('dateEnd').setValue(null);
    //#############            
  }
}
//########################################################### 
//###########################################################
formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('/');
}
//###########################################################


//###########################################################
//###########################################################
numericOnly(event): boolean {  
  var floatLen = this.selectedPS.DECIMAL_PLACES_UNIT_MEASUREMENT
  if(floatLen>0){
    //============
    /*
    controlar cantidad de decimales
    console.log("Dentro!!")
      var valid = parseFloat(event.key);
      console.log(event.key)
      console.log(valid)
      if( !valid )return false; else return true ;
      */
     return true
    //============    
  }else{
    //============
      let patt = /^([0-9])$/;
      let result = patt.test(event.key);
      return result;
    //============      
  }

}

alphaNumberOnly (e) {  // Accept only alpha numerics, not special characters 
   let patt = /^([a-zA-Z0-9])$/;
  let result = patt.test(e.key);
  return result;

}

onPaste(e) {
  e.preventDefault();
  return false;
}
//############################################################
//############################################################
 //###########################################################
  //###########################################################
  setStepper(acc){
    var minVal   = 0.001
    var maxval   = this.maxDetalleItem
    var step     = this.selectedPS.STEP_UNIT_MEASUREMENT
    var quantity = this.detalleForm.get('quantity').value

    if(acc == 'PLUS'){
      if(quantity < maxval)
      quantity = Number(quantity) + step; 
    }
    if(acc == 'MINUS'){

      if(quantity > minVal)
        if(quantity - step < 0){
          quantity=0
        }else{
        quantity = Number(quantity) - step; 
        }
    }
    this.changeQuantity(quantity)
   // this.setQuantityLabel(json,quantity.value)
  // this.updatePS(json,'AVAILABLE',quantity.value)
  }
  //###########################################################
  async changeQuantity(q){

    if(q> this.maxDetalleItem){
      //========================================================
            const dialogRef = this._fuseConfirmationService.open({
              title: `Error se ha superado el maximo de items para ${this.selectedPS.NAME_PS_SERVICES}<br>Cod:${this.selectedPS.COD_PS_SERVICES}`,
              message: ` Máximo Items :<b>${this.maxDetalleItem}</b>`,
              icon: {
                  show: true,
                  name: 'delete',
                  color: 'warn'
              },
              actions: {
                  confirm: {
                      label: 'Aceptar', 
                      color: 'warn'
                  },
                  cancel: {
                      show : false,
                      label : "Cancel"
                  }
              },
          });
          dialogRef.afterClosed().subscribe(async (result) => {
              return false
          })        
      //========================================================  
      }else{
      //======================================================== 
            
    //======================================================== 
    if(this.discountPS.amountDiscountManual  == 0){
      this.detalleForm.get('QtyItem').setValue(q)
      var tmp         = (this.detalleForm.value.QtyItem  * this.detalleForm.value.PrcItem)
      var  discount : any =await this._dteService._calculateDiscount(this.detalleForm.value)
      //////////////////////////////////////////
      this.discountPS.success                = discount.success
      this.discountPS.flagGlobalDiscount     = discount.flagGlobalDiscount
      this.discountPS.fullFillment           = discount.fullFillment
      this.discountPS.nameDiscount           = discount.nameDiscount
      this.discountPS.typeDiscount           = discount.typeDiscount
      this.discountPS.percentDiscount        = discount.percentDiscount
      this.discountPS.amountDiscount         = discount.amountDiscount
      this.discountPS.subTotal               = discount.subTotal
      this.discountPS.flagQuantityLimit      = discount.flagQuantityLimit
      this.discountPS.quantityLimit          = discount.quantityLimit
      this.discountPS.flagAmountLimit        = discount.flagAmountLimit
      this.discountPS.amountLimitClp         = discount.amountLimitClp
      this.discountPS.flagQuantityStart      = discount.flagQuantityStart
      this.discountPS.quantityStart          = discount.quantityStart
      this.discountPS.flagQuantityEnd        = discount.flagQuantityEnd
      this.discountPS.quantityEnd            = discount.quantityEnd
      this.discountPS.flagAmountStart        = discount.flagAmountStart
      this.discountPS.amountStartClp         = discount.amountStartClp
      this.discountPS.flagAmountEnd          = discount.flagAmountEnd
      this.discountPS.amountEndClp           = discount.amountEndClp
      this.discountPS.uuidPsServicesDiscount = discount.uuidPsServicesDiscount
      this.discountPS.discountManual         = 'N'
      this.discountPS.percentDiscountManual  = 0
      this.discountPS.amountDiscountManual   = 0
      this.discountPS.flagActiveDiscount     = true                        
      //////////////////////////////////////////
      if(this.discountPS.amountDiscount > 0 ){ 
        this.flagProductDiscountChecked = true
        this.detalleForm.get('flagProductDiscount').setValue(true)
      }
      //////////////////////////////////////////
      this.detalleForm.get('uuidPsServicesDiscount').setValue(this.discountPS.uuidPsServicesDiscount)     
      this.detalleForm.get('DescuentoPct').setValue(0)
      this.detalleForm.get('DescuentoMonto').setValue(0)
  }    
//========================================================  
  
      //========================================================           
      }
  
  
    }
//////////////////////////////////////////////////
////////////////////////////////////////////////// 
  //###########################################################
  async setDiscountManual(percent){
    var newAmount   = 0
    var newDiscount = 0
    var tmp         = (this.detalleForm.value.QtyItem  * this.detalleForm.value.PrcItem)
   //Calculo descuento
   if(this.detalleForm.value.DescuentoPct > 0 )
    {
      if(this.selectedPS.PRICE.COD_CURRENCY == 'CLP' ){
        //==
          if(this.data.codDoc == 110 || this.data.codDoc == 111 || this.data.codDoc == 112 ){
              
            newDiscount = tmp * (Number(this.detalleForm.value.DescuentoPct/100)); 
          }
          else{
            newDiscount = Math.round( tmp *  (Number(this.detalleForm.value.DescuentoPct/100)))
          }
        //==  
      }else{
        //==
          newDiscount = ( tmp *  (Number(this.detalleForm.value.DescuentoPct/100)))
        //==        
      }    
    }else{
      this.discountPS.amountDiscountManual  =0;
      this.discountPS.percentDiscountManual =0;
      await this.changeQuantity(this.detalleForm.value.QtyItem)
      return true
    } 
    //Aplico descuento informado como monto o calculado por %
    if(newDiscount > 0)  {
          //newDiscount    = Number(newDiscount) + Number(this.detalleForm.value.DescuentoMonto)
          if(this.selectedPS.PRICE.COD_CURRENCY == 'CLP' )
            newAmount      = Math.round(tmp - ( newDiscount))
          else
            newAmount      = tmp - ( newDiscount)
    }
    else{
      newDiscount    = 0
      if(this.selectedPS.PRICE.COD_CURRENCY == 'CLP' )
        newAmount      = Math.round( tmp)
      else
        newAmount      =  tmp
    }
    ///////////////////////////////////////////
        this.discountPS.discountManual         = 'Y'
        this.discountPS.percentDiscountManual  = this.detalleForm.value.DescuentoPct
        this.discountPS.amountDiscountManual   = newDiscount
        this.discountPS.subTotal               = newAmount
        this.detalleForm.get('DescuentoMonto').setValue(newDiscount)
    ///////////////////////////////////////////
  }
  //###########################################################
  //###########################################################
//########################################################### 
//########################################################### 
valueChange(value) {
  this.remainingText =  value.length
 }
//########################################################### 
//###########################################################  
}
