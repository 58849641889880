import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CatalogService } from 'app/services/catalog.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { split } from 'lodash';
import { ChangeDetectorRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {ModalImgComponent} from '../modal-img/modal-img.component'
import {EslPsServicesComponent} from '../esl-ps-services/esl-ps-services.component'


@Component({
  selector: 'app-esl-detail',
  templateUrl: './esl-detail.component.html',
  styleUrls: ['./esl-detail.component.scss']
})
export class EslDetailComponent implements OnInit {
  @ViewChild('pagEslPsDetail'   ) pagEslPsDetail   : MatPaginator;

  @ViewChild(MatSort) private _sort: MatSort;
                                      //['ACTION_QUEUE','COD_EPC_ESL_VENDOR_STATE','MESSAGE','COUNT_SEND','DATE_PROCESS','DATE_REQUEST','DATE_SCHEDULE','ESTATUS_QUEUE','GLS_STATE','SAMPLE_B64','STATUS']; 
  displayedColumnEslPsDetail   : string[] = ['ACTION_QUEUE','MESSAGE','COUNT_SEND','DATE_PROCESS','DATE_REQUEST','DATE_SCHEDULE','B64PRICE','RESULT_EPC_ESL_VENDOR']; 
  pag                    : number = 10;
  pageSizeOptions        : any    = [ 10,50, 100];
  isLoadingEslPsDetail   : boolean = false;
  dataEslPsDetail        : any     = [];
  tableEslPsDetail       : any     = [];
  dataEsl                : any     = {}
  type                   : string  = null
  eslAccessories         : any     = [];
  eslBasestation         : any     = [];
  eslTemplate            : any     = [];
  eslpsServices          : any     = [];
  flagModalPS            : boolean = false;
  eslPSForm              : UntypedFormGroup;
  showB64                : boolean = false
  imgB64                 : string  = null
  eslTemplateSize        : any     = [];
  //
  showSearchPs           : boolean = false
  dataPS                 : any     = {}



//////////////////////////////////////////////////
//////////////////////////////////////////////////
constructor(
  public dialog: MatDialog,
  public _dialogRef: MatDialogRef<EslDetailComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any,
  private _catalogService: CatalogService,
  private ref: ChangeDetectorRef,
  private _dialog: MatDialog,
  private _formBuilder: UntypedFormBuilder,
) {
  this.eslPSForm = this._formBuilder.group({
                                              uuidPsServices:       ['', Validators.required],
                                              uuidEpcEslTag:        ['', Validators.required],
                                              idEpcEslAccessories:  ['', Validators.required], 
                                              idEpcEslTemplate:     ['', Validators.required],                                                 
                                              //PS
                                              NAME_PS_SERVICES:     [''],     
                                              COD_PS_SERVICES:      [''],     
                                              BARCODE:              [''], 
                                              NAME_PS_CATEGORY:     [''], 
                                              NAME_PS_GROUP:        [''], 
                                              NAME_PS_TYPE:         [''], 
                                              //TAG
                                              COD_ESL_TAG:          [''],     
                                              COLOR_ESL:            [''],     
                                              SIZE_ESL:             ['']
                                          });

}
//////////////////////////////////////////////////
//////////////////////////////////////////////////
async ngOnInit(): Promise<any> {
  await this.getEslData()
  this.type = this.data.type;
  this.eslTemplateSize = this.eslTemplate.filter(item => item.SIZE_ESL == this.data.dataTag.SIZE_ESL   )
  //////////////////////////////
  if( this.type =='NEW' ){
    /////////////////////////////////////////
      await this.getEpcEslpsServices()
      //
      if(this.data.uuidPsServices){
          //################################################################
          this.flagModalPS = false;
          this.dataPS      = this.eslpsServices.filter(item => item.UUID_PS_SERVICES ==  this.data.uuidPsServices )  
          this.eslPSForm.patchValue({
                                        uuidPsServices:      this.dataPS.UUID_PS_SERVICES     
                                      ,uuidEpcEslTag:        this.data.dataTag.UUID_EPC_ESL_TAG
                                      ,idEpcEslAccessories:  null 
                                      ,idEpcEslTemplate:     null                                                 
                                      //PS
                                      ,NAME_PS_SERVICES:     this.dataPS.NAME_PS_SERVICES     
                                      ,COD_PS_SERVICES:      this.dataPS.COD_PS_SERVICES     
                                      ,BARCODE:              this.dataPS.BARCODE 
                                      ,NAME_PS_CATEGORY:     this.dataPS.NAME_PS_CATEGORY 
                                      ,NAME_PS_GROUP:        this.dataPS.NAME_PS_GROUP 
                                      ,NAME_PS_TYPE:         this.dataPS.NAME_PS_TYPE 
                                      //TAG
                                      ,COD_ESL_TAG:          this.data.dataTag.COD_ESL_TAG     
                                      ,COLOR_ESL:            this.data.dataTag.COLOR_ESL     
                                      ,SIZE_ESL:             this.data.dataTag.SIZE_ESL    
                                      ,STATUS:               this.data.dataTag.STATUS
                                    });          
          //################################################################          
      }
      else{
          //################################################################        
            //muestro modal
            this.flagModalPS = true;
           
          //################################################################        
      }
    /////////////////////////////////////////    
  }  
  if( this.type =='DETAIL' ){
    /////////////////////////////////////////    
      await this.getEslDetail()
      await this.loadEslTag()
    /////////////////////////////////////////    
  }
  //////////////////////////////  
}
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
async showPS(): Promise<void> {
  const dialogRef = this._dialog.open(EslPsServicesComponent, {
    
    height:'100%',
    width: '100%',
    data: {
       eslpsServices:this.eslpsServices 
    }
});
dialogRef.afterClosed().subscribe(async (result) => {
  if(!!result){
  this.dataPS = result.eslpsServices
  this.eslPSForm.patchValue({
                                 uuidPsServices:       this.dataPS.UUID_PS_SERVICES     
                                ,uuidEpcEslTag:        this.data.dataTag.UUID_EPC_ESL_TAG
                                ,idEpcEslAccessories:  null 
                                ,idEpcEslTemplate:     null                                                 
                                //PS
                                ,NAME_PS_SERVICES:     this.dataPS.NAME_PS_SERVICES     
                                ,COD_PS_SERVICES:      this.dataPS.COD_PS_SERVICES     
                                ,BARCODE:              this.dataPS.BARCODE 
                                ,NAME_PS_CATEGORY:     this.dataPS.NAME_PS_CATEGORY 
                                ,NAME_PS_GROUP:        this.dataPS.NAME_PS_GROUP 
                                ,NAME_PS_TYPE:         this.dataPS.NAME_PS_TYPE 
                                //TAG
                                ,COD_ESL_TAG:          this.data.dataTag.COD_ESL_TAG     
                                ,COLOR_ESL:            this.data.dataTag.COLOR_ESL     
                                ,SIZE_ESL:             this.data.dataTag.SIZE_ESL    
                                ,STATUS:               this.data.dataTag.STATUS
                              });
  }

});

}

///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
async getEpcEslpsServices(): Promise<void> {
  let tmp = await this._catalogService.getEpcEslpsServices();
  this.eslpsServices = tmp.eslPsServices 
}
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
async getEslData(): Promise<void> {
  let tmp = await this._catalogService.getEpcEslData();
  this.eslAccessories = tmp.eslAccessories 
  this.eslBasestation = tmp.eslBasestation 
  this.eslTemplate    = tmp.eslTemplate    
}
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
async getEslDetail(): Promise<void> {
  let tmp = await this._catalogService.getEpcEslDetail(this.data.uuidPsServicesEslTag);
  this.dataEsl         = tmp.tag
  this.dataEslPsDetail = tmp.tagQueue
}
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
async loadEslTag():Promise<any>{
  this.isLoadingEslPsDetail=true; 
  this.tableEslPsDetail =  new MatTableDataSource(this.dataEslPsDetail);
  this.ref.detectChanges();
  this.tableEslPsDetail.paginator = this.pagEslPsDetail;
  this.isLoadingEslPsDetail=false; 
}
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
closeBtn(){
  // Unsuscribe to all observables
  this._dialogRef.close();
};
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
async openImg(type:string,urlImg:string, uuidImg: string,imgB64 : string): Promise<any>{
  const dialogRef = this._dialog.open(ModalImgComponent, {
           
             width: '50%'
            ,data: {
                       type      : type
                      ,urlImg    : urlImg
                      ,uuidImg   : uuidImg
                      ,imgB64    : imgB64
                      ,imgHeight : '400px'
                      ,imgWidth  : '200px'
                      ,rotate    : '90'
                      ,title     : this.dataEsl.NAME_PS_SERVICES + " "+ this.dataEsl.COD_ESL_TAG
            }
        });
        dialogRef.afterClosed().subscribe(async (result) => {
            //this.ngOnInit();
        });
} 
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
showTemplate(B64){
  this.showB64  = true;
  this.imgB64   = B64
}
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
async addPsTag(){
    var arr = {
      appToken              : null
     ,language              : 'es'
     ,uuidSSO               : null
     ,uuidPsServices        : this.eslPSForm.value.uuidPsServices
     ,uuidEpcEslTag         : this.eslPSForm.value.uuidEpcEslTag
     ,idEpcEslAccessories   : this.eslPSForm.value.idEpcEslAccessories
     ,idEpcEslTemplate      : this.eslPSForm.value.idEpcEslTemplate
     
     }
    let setTag = await this._catalogService.epcSetTag(arr)
    this._dialogRef.close();

    }
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
}