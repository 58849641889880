<div class="close">
    <button mat-button (click)="closeBtn()"> <mat-icon>close</mat-icon></button>
    <!-- <button mat-button (click)="test()"> TEST </button> -->
  </div>
    <!-- Selec date -->
    <div class="mat-elevation-z8">
    <!--====================================================================--> 
    <!--====================================================================--> 
    </div>
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
        <div class="py-2">
          <div class="text-4xl font-extrabold tracking-tight">{{title}}</div>
        </div>
        <div   class="container">
                <!-- // -->
                <div></div>
                <!-- // -->     
                <div  class="center">
                    <div  class="modal-body" *ngIf="type == 'B64'">
                        <img  [@rotatedState]='state' [src]="imgB64"  [style]="style" class="modal-hover-opacity" (click)="closeBtn()"/> 
                    </div>
                <!-- // -->
                    <div class="modal-body" *ngIf="type == 'URL'">
                        <img  [@rotatedState]='state' [src]="urlImg"  [style]="style" class="modal-hover-opacity" (click)="closeBtn()"/> 
                    </div>
                <!-- // -->
                    <div class="modal-body" *ngIf="type == 'APP'">
                        <img   [@rotatedState]='state' [src]="urlImg"  [style]="style" class="modal-hover-opacity" (click)="closeBtn()"/>
                    </div>
                <!-- // "max-width:imgWidth;max-width:imgHeight; -->
            </div>           
        </div>
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->