

<!-- ################################################################################################################################################################################
     ################################################################################################################################################################################
     ################################################################################################################################################################################ -->

     <div
     *ngIf="data.movement.COD_ESTATUS != 'M'"
     class="absolute inset-0 min-w-0 text-center overflow-auto print:overflow-visible"
     cdkScrollable>
 
     <!-- Main -->
     <br>
     <div class="w-3/4">
         <!-- ##########################################################  --> 
         <div class="grid grid-cols-10">   
             <div class="col-span-7">
                 <!-- ## -->
                   <button mat-raised-button color="primary" (click)="generatePDF()">
                     <!-- <mat-icon svgIcon="picture_as_pdf"></mat-icon> -->
                     <mat-icon svgIcon="feather:arrow-down-circle"></mat-icon>
                     <p>PDF</p>
                 </button>              
                 <!-- ## -->
             </div>
             <div class="col-span-1">
                 <!-- ## -->
                 <!-- ## -->
             </div>
             <div class="col-span-1">
                 <!-- ## -->
                 <!-- ## -->
             </div> 
             <div class="col-span-1">
                 <!-- ## -->
                 <button class="close " mat-button mat-dialog-close> <mat-icon>close</mat-icon></button>
                 <!-- ## -->
             </div>                    
         </div>
         <!-- ##########################################################  -->   
     </div>
 
 
 
     <div class="inline-block p-6 sm:p-10 text-left print:p-3">
         <!-- Invoice -->
         <div class="w-240 p-16 rounded-2xl shadow bg-card print:w-auto print:rounded-none print:shadow-none print:bg-transparent">
             <div id="dialogContent">
             <!-- ##########################################################################  -->    
             <div class="w-full">
             <!-- ##########################################################  --> 
             <!-- ##########################################################################  -->                   
                 <div class="grid grid-cols-10">   
                     <div class="col-span-3">
                         <!-- ## -->
                          <div *ngIf="data.movement.COD_DOC_TYPE == 'GV'">
                             <h2 class="text-3xl tracking-tight text-primary"><b> {{data.movement.FRIENLY_EOM_ORDER}} </b></h2>                       
                             <h2 class="text-3xl col-span-2"><b> {{data.movement.NAME_DOC_TYPE }} </b></h2>  
                             <h2 ><b> {{data.movement.COD_WAREHOUSE_MOVEMENTS }} </b></h2>  
                          </div>
                           
                          <div *ngIf="data.movement.COD_DOC_TYPE != 'GV'">
                             <h2 class="text-3xl tracking-tight text-secondary"><b> {{data.movement.COD_WAREHOUSE_MOVEMENTS}} </b></h2>                       
                             <h2 class="text-3xl col-span-2"><b> {{data.movement.NAME_DOC_TYPE }} </b></h2>   
                          </div>   
                          
                         <!-- ## -->
                     </div>
                     <div class="col-span-7">
                         <!-- ## -->
                         <div class="grid auto-cols-max grid-flow-col gap-x-8 -mr-16 py-6 px-8 rounded-l-2xl">
                             <div class="place-self-center">
                                 <img
                                     class="w-18"
                                     [src]="emisorLogo">
                             </div>
                             <div class="pl-10 border-l text-md">
                                 <div class="font-medium"><b> {{emisorData.RAZON_SOCIAL}}</b> </div>
                                 <div> {{ emisorData.GIRO_IMPRESO }}</div>
                                 <div> {{emisorData.RUT | rut:'DOTS_DASH'}} </div>
                                 <!-- <div>hello@fuseinc.com</div> -->
                                 <!-- <div>www.fuseinc.com</div> -->
     
     
                             </div>
                         </div>   
                         <!-- ## -->
                     </div>
                 </div>
                 <!-- ##########################################################  -->    
         
             </div>       
             <div class="col-span-12 my-3 border-b"></div>     
             <!-- ##########################################################################  -->       
             <!-- ##########################################################################  --> 
             <div class="grid grid-cols-3">   
                 <div class="col-span-3">
                     <!-- ## -->
                         <div *ngIf="data.movement.COD_DOC_TYPE == 'GE'" class="grid">
                             <div class="font-medium tracking-tight text-secondary"> Proveedor </div>
                             <div class="prose prose-xl"> {{data.movement.DATA_SUPPLIER.RUT | rut:'DOTS_DASH'}} &nbsp;<b>{{data.movement.DATA_SUPPLIER.RAZON_SOCIAL | titlecase}}</b> </div>
                         </div>
                         <div *ngIf="data.movement.COD_DOC_TYPE == 'GS'" class="grid">
                                <div *ngIf="data.movement.COD_REASON_CODE == '801' || data.movement.COD_REASON_CODE == '802' || data.movement.COD_REASON_CODE == '825' ; else  noCustomer">
                                    <div class="font-medium tracking-tight text-secondary"> Cliente </div>
                                    <div class="prose prose-xl"> {{data.movement.DATA_CUSTOMER.RUT | rut:'DOTS_DASH'}} &nbsp;<b>{{data.movement.DATA_CUSTOMER.RAZON_SOCIAL | titlecase}}</b> </div>
                                </div>
                                <ng-template #noCustomer>
                                    <div >
                                        <div class="font-medium tracking-tight text-secondary">  </div>
                                        <div class="prose prose-xl"> <b>{{data.movement.NAME_TYPE_MOVEMENTS}}</b> </div>
                                    </div>                           
                                </ng-template>
                             
                         </div>
                         <div *ngIf="data.movement.COD_DOC_TYPE == 'GT'" class="grid">
                             <div class="font-medium tracking-tight text-secondary"> Tipo Traslado </div>
                             <div class="prose prose-xl"> <b>{{data.movement.NAME_TYPE_MOVEMENTS | titlecase}}</b> </div>
                         </div>
                         <div *ngIf="data.movement.COD_DOC_TYPE == 'GV'" class="grid">
                             <div *ngIf="data.movement.DATA_CUSTOMER.RUT  != ''">
                                 <div class="font-medium tracking-tight text-secondary"> Cliente </div>
                                 <div class="prose prose-xl"> {{data.movement.DATA_CUSTOMER.RUT | rut:'DOTS_DASH'}} &nbsp;<b>{{data.movement.DATA_CUSTOMER.RAZON_SOCIAL | titlecase}}</b> </div>
                             </div>
                             <div *ngIf="data.movement.DATA_CUSTOMER.RUT  == ''">
                                 <div class="font-medium tracking-tight text-secondary">  </div>
                                 <div class="prose prose-xl"> <b>{{data.movement.NAME_TYPE_MOVEMENTS}}</b> </div>
                             </div>                            
                         </div>                                                                        
                     <!-- ## -->
                 </div>              
             </div>                  
             <!-- ##########################################################################  -->       
             <!-- ##########################################################################  -->   
             <div class="grid grid-cols-2">   
                 <div class="col-span-1">
                     <!-- ## -->
                     <div class="flex items-start justify-between">
                         <div class="grid grid-cols-2 gap-x-4 gap-y-1">
                             <div class="font-medium tracking-tight text-secondary"> Creado </div>
                             <div class="font-medium"> {{data.movement.CM_EMPLOYEE_CREATION.FIRST_NAME}} {{data.movement.CM_EMPLOYEE_CREATION.LAST_NAME}} </div>
                         </div>
                     </div>     
                     <!-- ## -->
                 </div>
                 <div class="col-span-1">
                     <!-- ## -->
                     <div class="flex items-start justify-between" *ngIf="data.movement.CM_EMPLOYEE_MODIFICACION.LOGIN != '00000000-0'">
                         <div class="grid grid-cols-2 gap-x-4 gap-y-1">
                             <div class="font-medium tracking-tight text-secondary"> Modificado:</div>
                             <div class="font-medium"> {{data.movement.CM_EMPLOYEE_MODIFICACION.FIRST_NAME}} {{data.movement.CM_EMPLOYEE_MODIFICACION.LAST_NAME}} </div>
                         </div>
                     </div>   
                     <!-- ## -->
                 </div>
                
             </div>               
             <!-- ##########################################################################  -->       
             <!-- ##########################################################################  -->                                          
             <div class="grid grid-cols-2">   
                 <div class="col-span-1">
                     <!-- ## -->
                     <div class="flex items-start justify-between">
                         <div class="grid grid-cols-2 gap-x-4 gap-y-1">
                             <div class="font-medium tracking-tight text-secondary">  Fecha de Creación </div>
                             <div class="font-medium">  {{data.movement.DATE_CREATION | date:'dd/MM/yyyy'}}</div>
                         </div>
                     </div> 
                     <!-- ## -->
                 </div>
                 <div class="col-span-1">
                     <!-- ## -->
                     <div class="flex items-start justify-between">
                         <div class="grid grid-cols-2 gap-x-4 gap-y-1">
                             <div class="font-medium tracking-tight text-secondary"> Fecha Contable </div>
                             <div class="font-medium"> {{data.movement.DATE_ACCOUNTING | date:'dd/MM/yyyy'}}</div>
                         </div>
                     </div> 
                     <!-- ## -->
                 </div>                
             </div>
             <!-- ##########################################################################  -->    
             <!-- ##########################################################################  -->  
             <div class="grid grid-cols-2">   
                 <div class="col-span-1">
                     <!-- ## -->
                     <div class="flex items-start justify-between">
                         <div class="">
                             <div class="font-medium tracking-tight text-secondary">  Bodega Origen </div>
                             <div class="font-medium"> {{data.movement.COD_WAREHOUSE}} - {{data.movement.NAME_WAREHOUSE}}</div>
                             <div *ngIf="data.movement.ID_TYPE_TRANSFER_ORI > 0" class="font-medium">Estado Origen: &nbsp;<b style="color: rgb(17, 3, 121)">{{data.movement.COD_TYPE_TRANSFER_ORI}}</b></div>
                         </div>
                     </div> 
                     <!-- ## -->
                 </div>
                 <div class="col-span-1">
                     <!-- ## -->
                     <div class="flex items-start justify-between" *ngIf="data.movement.COD_DOC_TYPE == 'GT'">
                         <div class="">
                             <div class="font-medium tracking-tight text-secondary"> Bodega Destino </div>
                             <div class="font-medium">{{data.movement.COD_WAREHOUSE_DESTINATION}} - {{data.movement.NAME_WAREHOUSE_DESTINATION}}</div>
                             <div *ngIf="data.movement.ID_TYPE_TRANSFER_DES > 0" class="font-medium">Estado Destino: &nbsp;<b style="color: rgb(17, 3, 121)">{{data.movement.COD_TYPE_TRANSFER_DES}}</b></div>
                         </div>
                     </div> 
                     <!-- ## -->
                 </div>                
             </div>
             <!-- ##########################################################################  -->    
             <!-- ##########################################################################  -->  
             <div class="grid grid-cols-2">   
                 <div class="col-span-1">
                     <!-- ## -->
                     <div class="flex items-start justify-between">
                         <div class="">
                             <div class="font-medium tracking-tight text-secondary"> Motivo </div>
                             <div class="font-medium"><b>{{data.movement.COD_REASON_CODE}} - {{data.movement.NAME_COD_REASON_CODE}}</b></div>
                         </div>
                     </div> 
                     <!-- ## -->
                 </div>
                 <div class="col-span-1">
                     <!-- ## -->
                     <div class="flex items-start justify-between" >
                         <!-- ## -->
                         <!-- ## -->                         
                            <div *ngIf="data.movement.COD_DOC_TYPE != 'GT'">
                                <!-- // -->
                                    <div *ngIf="data.movement.COD_REASON_CODE == '426' || data.movement.COD_REASON_CODE == '428' || data.movement.COD_REASON_CODE == '433' || data.movement.COD_REASON_CODE == '826' || data.movement.COD_REASON_CODE == '828' || data.movement.COD_REASON_CODE == '833'; else codDocALL"> 

                                    </div>
                                    <ng-template #codDocALL>
                                        <div class="" *ngIf="data.movement.FOLIO_DTE > 0">
                                            <div class="font-medium tracking-tight text-secondary"> Folio </div>
                                            <div class="font-medium"><b>{{data.movement.FOLIO_DTE | number }}</b>&nbsp;
                                                    (Cod.Doc:{{data.movement.COD_DOC}})                     
                                            </div>
                                        </div>    
                                    </ng-template>                         
                                <!-- // -->
                            </div>
                         <!-- ## -->
                         <!-- ## -->   
                            <div *ngIf="data.movement.COD_DOC_TYPE == 'GT'">
                                <!-- // -->
                                    <span *ngIf="data.movement.COD_REASON_CODE == '909'; else codDocGT">
                                        <div class="" *ngIf="data.movement.FOLIO_DTE > 0">
                                            <div class="font-medium tracking-tight text-secondary"> Folio </div>
                                            <div class="font-medium"><b>{{data.movement.FOLIO_DTE | number }}</b>&nbsp;(Cod.Doc:52)</div>
                                        </div> 
                                    </span>  
                                    <ng-template #codDocGT>
                                                      
                                    </ng-template>                          
                                <!-- // -->
                            </div>   
                         <!-- ## -->
                         <!-- ## -->                                                                             
                     </div> 
                     <!-- ## -->
                 </div>                
             </div>
             <!-- ##########################################################################  -->    
             <!-- ##########################################################################  -->   
             <div class="grid grid-cols-2">   
                 <div class="col-span-2">
                     <!-- ## -->
                     <div class="flex items-start justify-between">
                         <div class="">
                             <div class="font-medium tracking-tight text-secondary"> Descripción </div>
                             <div class="font-medium">  {{data.movement.DESCRIPTION}}</div>
                         </div>
                     </div> 
                     <!-- ## -->
                     <div class="flex items-start justify-between" *ngIf="data.movement.OBS_MOVEMENTS != '*'">
                         <div class="">
                             <div class="font-medium tracking-tight text-secondary"> Obsevaciones </div>
                             <div class="font-medium">  
                                                        <span *ngIf="data.movement.OBS_MOVEMENTS != 'at'">{{data.movement.OBS_MOVEMENTS}}</span>
                                                        <span *ngIf="data.movement.OBS_MOVEMENTS == 'at'">Generado Automatico </span>
                             </div>
                         </div>
                     </div> 
                     <!-- ## -->
                 </div>               
             </div>
             <!-- ##########################################################################  -->    
             <!-- ##########################################################################  -->                                                      
 <!-- 
                 <div class="flex items-start justify-between">
                     <div class="grid grid-cols-2 gap-x-4 gap-y-1">
                        
                         <div class="font-medium tracking-tight text-secondary"> Fecha de creación</div>
                         <div class="font-medium"> {{data.movement.DATE_CREATION | date:'dd/MM/yyyy'}} </div>
                         <div class="font-medium tracking-tight text-secondary">Fecha Contable</div>
                         <div class="font-medium"> {{data.movement.DATE_ACCOUNTING | date:'dd/MM/yyyy'}} </div>
                         <div class="font-medium tracking-tight text-secondary"> Proveedor </div>
                         <div class="font-medium"> {{data.movement.DATA_SUPPLIER.RAZON_SOCIAL}} </div>
                         <div class="font-medium tracking-tight text-secondary"> Creado:</div>
                         <div class="font-medium"> {{data.movement.CM_EMPLOYEE_CREATION.FIRST_NAME}} {{data.movement.CM_EMPLOYEE_CREATION.LAST_NAME}} </div>
                         <div class="font-medium tracking-tight text-secondary"> Modificado:</div>
                         <div class="font-medium"> {{data.movement.CM_EMPLOYEE_MODIFICACION.FIRST_NAME}} {{data.movement.CM_EMPLOYEE_MODIFICACION.LAST_NAME}} </div>
 
                     </div>
 
                 </div>
     -->
 
                 <!-- <div class="grid grid-cols-12 gap-x-1 mt-12">
                     <table mat-table [dataSource]="data.detailMovement" class="w-full">
                         AGRegar bodega!!!
                         si es traslado origen y destino
                         mejorar la cabecera
                     </table>
                 </div> -->
                 <div class="col-span-12 my-3 border-b"></div>
                 <!-- ##################################################### -->
                 <div class="table-responsive">
                     <table class="table table-wishlist table-mobile">
                         <thead>
                             <tr>
                                 <th>#</th>
                                 <th>SKU/BAR</th>
                                 <!-- <th>BAR</th> -->
                                 <th>Producto</th>
                                 <th>Cantidad</th>
                                 <th>Ubicación</th>
                                 <th *ngIf="data.movement.COD_DOC_TYPE == 'GE'">Costo</th>
                                 <th *ngIf="data.movement.COD_DOC_TYPE == 'GE'">Sub Total Costo</th>
                                 <th>Precio</th>
                                 <th>Sub Total Precio</th>
                                 
                             </tr>
                         </thead>
 
                         <tbody>
                             <tr *ngFor="let o of data.detailMovement; let i = index;">
                                 <td><b>{{ (i+1) }}</b></td>
                                 <td>{{ o.COD_PS_SERVICES}}<br>{{ o.BARCODE }}</td>
                                 <!-- <td>{{ o.BARCODE }}</td> -->
                                 <td><b>{{ o.NAME_PS_SERVICES  }}</b></td>
                                 <td>
                                     <!-- &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
                                     <!-- <b>{{ o.QUANTITY | number}}</b></td> -->
                                     <span *ngIf="o.DATA_TYPE_UNIT_MEASUREMENT == 'NUMBER'; else floatUnit ">&nbsp; <b>{{o.QUANTITY |  number  }}</b>&nbsp;<small>{{o.DTE_UNIT_MEASUREMENT}}</small></span>
                                     <ng-template #floatUnit>
                                             <!-- ## -->
                                             <span *ngIf="o.DECIMAL_PLACES_UNIT_MEASUREMENT == 1 ">&nbsp; <b>{{o.QUANTITY |  number : '1.0-1' }}</b><small>{{o.DTE_UNIT_MEASUREMENT}}</small></span>
                                             <span *ngIf="o.DECIMAL_PLACES_UNIT_MEASUREMENT == 2 ">&nbsp; <b>{{o.QUANTITY |  number : '1.0-2' }}</b><small>{{o.DTE_UNIT_MEASUREMENT}}</small></span>
                                             <span *ngIf="o.DECIMAL_PLACES_UNIT_MEASUREMENT == 3 ">&nbsp; <b>{{o.QUANTITY |  number : '1.0-3' }}</b><small>{{o.DTE_UNIT_MEASUREMENT}}</small></span>
                                             <span *ngIf="o.DECIMAL_PLACES_UNIT_MEASUREMENT == 4 ">&nbsp; <b>{{o.QUANTITY |  number : '1.0-4' }}</b><small>{{o.DTE_UNIT_MEASUREMENT}}</small></span>
                                             <!-- ## -->                            
                                     </ng-template>  
                                     <!-- &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
                                 </td>
                                 <!-- ## --> 
                                 <td> 
                                    <span *ngIf="o.DATA_BOX_STORAGE.NAME_LOCATION_PS != 'n/a'; else noLocation">
                                      {{o.DATA_BOX_STORAGE.NAME_LOCATION_PS}}<br>
                                        <small>
                                            <span *ngFor="let s of o.DATA_BOX_STORAGE.LOCATION_PS">
                                                {{s.name}}:{{s.value}}<br>
                                            </span>
                                        </small>
                                    </span>
                                    <ng-template #noLocation>
                                        <small>Sin Ubicación Bodega</small>
                                    </ng-template>

                                 </td>
                                 <!-- ## -->
                                  <!-- &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
                                        <!-- ## --> 
                                        <td *ngIf="data.movement.COD_DOC_TYPE == 'GE'">
                                            <!-- ############################################### -->
                                            <span *ngIf="o.COD_CURRENCY == 'CLP'; else currencyCost ">
                                                <b>{{o.BASE_PRICE   | currency:'CLP':'symbol-narrow'}}</b>
                                            </span>
                                            <ng-template #currencyCost>
                                                <!-- ## -->
                                                <span>
                                                    <b>{{o.SYMBOL_CURRENCY}} {{o.BASE_PRICE   | number : '1.0-2'}}</b>
                                                </span>
                                                <!-- //-->                          
                                            </ng-template> 
                                            <!-- ############################################### -->                                         
                                        </td>
                                        <!-- ## -->
                                        <td *ngIf="data.movement.COD_DOC_TYPE == 'GE'">
                                            <!-- ############################################### -->
                                            <span *ngIf="o.COD_CURRENCY == 'CLP'; else currencyTotalCost ">
                                                <!-- $$ -->  
                                                <b>{{o.BASE_PRICE * o.QUANTITY  | currency:'CLP':'symbol-narrow'}}</b>  
                                            </span>
                                            <ng-template #currencyTotalCost>
                                                <!-- ## -->
                                                <span>
                                                <b> {{o.SYMBOL_CURRENCY}} {{ o.BASE_PRICE * o.QUANTITY | number : '1.0-2'}}</b> 
                                                </span>
                                                <!-- //-->                          
                                            </ng-template> 
                                            <!-- ############################################### -->     
                                        </td>
                                        <!-- ## -->
                                  <!-- &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
                                 <!-- ## --> 
                                 <td>
                                     <!-- ############################################### -->
                                      <div *ngIf="data.movement.COD_DOC_TYPE == 'GE'">
                                        <span *ngIf="o.COD_CURRENCY == 'CLP'; else currency1 ">
                                            <b>{{o.SALES_PRICE  | currency:'CLP':'symbol-narrow'}}</b>
                                        </span>
                                        <ng-template #currency1>
                                            <!-- ## -->
                                            <span>
                                                <b>{{o.SYMBOL_CURRENCY}} {{o.SALES_PRICE  | number : '1.0-2'}}</b>
                                            </span>
                                            <!-- //-->  
                                        </ng-template> 
                                      </div>
                                      <!--&& -->
                                      <div *ngIf="data.movement.COD_DOC_TYPE != 'GE'">
                                            <span *ngIf="o.PRICE.COD_CURRENCY == 'CLP'; else currency2 ">
                                                <b>{{o.PRICE.PRICE  | currency:'CLP':'symbol-narrow'}}</b>
                                            </span>
                                            <ng-template #currency2>
                                                <!-- ## -->
                                                <span>
                                                    <b>{{o.PRICE.SYMBOL_CURRENCY}} {{o.PRICE.PRICE  | number : '1.0-2'}}</b>
                                                </span>
                                                <!-- //-->                          
                                            </ng-template> 
                                      </div>
                                     <!-- ############################################### -->                                         
                                 </td>
                                 <!-- ## -->
                                 <td>
                                     <!-- ############################################### -->
                                     <div *ngIf="data.movement.COD_DOC_TYPE == 'GE'">
                                        <span *ngIf="o.COD_CURRENCY == 'CLP'; else currencyTotal1 ">
                                            <!-- $$ -->  
                                            <b>{{o.SALES_PRICE * o.QUANTITY  | currency:'CLP':'symbol-narrow'}}</b>  
                                        </span>
                                        <ng-template #currencyTotal1>
                                            <!-- ## -->
                                            <span>
                                             <b> {{o.SYMBOL_CURRENCY}} {{ o.SALES_PRICE * o.QUANTITY | number : '1.0-2'}}</b> 
                                            </span>
                                            <!-- //-->                          
                                        </ng-template> 
                                     </div>
                                      <!--&& -->
                                     <div *ngIf="data.movement.COD_DOC_TYPE != 'GE'">
                                        <span *ngIf="o.PRICE.COD_CURRENCY == 'CLP'; else currencyTotal2 ">
                                            <!-- $$ -->  
                                            <b>{{o.PRICE.PRICE * o.QUANTITY  | currency:'CLP':'symbol-narrow'}}</b>  
                                        </span>
                                        <ng-template #currencyTotal2>
                                            <!-- ## -->
                                            <span>
                                             <b> {{o.PRICE.SYMBOL_CURRENCY}} {{ o.PRICE.PRICE * o.QUANTITY | number : '1.0-2'}}</b> 
                                            </span>
                                            <!-- //-->                          
                                        </ng-template> 
                                     </div>
                                     <!-- ############################################### -->     
                                 </td>
                                
                             </tr>
                         </tbody>
                     </table>
                 </div> 
                 <!-- ## -->              
                 <div ><small><b>-Montos sin impuestos</b></small> </div>
                 <!-- ## -->
                 <div *ngIf="data.movement.COD_DOC_TYPE == 'GV'">
                     <small><b>(*)Precios con valor base, no consideran descuentos</b></small>
                 </div>
                 <!-- ##################################################### -->                
 <!-- 
                 <ng-container *ngFor="let movement of data.detailMovement">
                     <div class="my-4">
                         <div class="bg-white shadow-md rounded-lg p-4 flex items-center justify-between">
                             <h1 class="font-bold">{{ movement.NAME_PS_SERVICES }}</h1>
                             <div class="flex">
                                 <div class="mr-8">
                                     <p class="font-semibold">SKU:</p>
                                     <p>{{ movement.COD_PS_SERVICES }}</p>
                                 </div>
                                 <div class="mr-8">
                                     <p class="font-semibold">Cantidad:</p>
                                     <p>{{ movement.QUANTITY }}</p>
                                 </div>
                                 <div class="mr-8">
                                     <p class="font-semibold">Precio de venta:</p>
                                     <p>{{ movement.SALES_PRICE }}</p>
                                 </div>
                             </div>
                         </div>
 
                     </div>
                     <div class="col-span-12 my-4 border-b"></div>
                   </ng-container>
 -->                  
                 <!-- <button mat-button (click)="getColumnsDetailMovement()" >getColumnsDetailMovement()</button> -->
 
                 <div class="grid grid-cols-12 gap-x-1 mt-12">      
                     <div class="col-span-12 my-3 border-b"></div>            
                     <!-- Discount  -->
 
                     <div *ngIf="data.movement.COD_DOC_TYPE == 'GE'" class="col-span-5 self-center  ">
                         <h2 class="text-3xl  text-primary">Total&nbsp;:&nbsp;<b>{{data.movement.TOTAL  | currency:'CLP':'symbol-narrow'}}</b> </h2>
                     </div>

                     <div *ngIf="data.movement.COD_DOC_TYPE != 'GE'" class="col-span-5 self-center  ">
                        <h2 class="text-3xl  text-primary">Total&nbsp;:&nbsp;<b>{{data.movement.TOTAL_NOW  | currency:'CLP':'symbol-narrow'}}</b> </h2>
                    </div>                     

                     <div *ngIf="data.movement.COD_DOC_TYPE == 'GE'" class="col-span-5 self-center  ">
                        <h2 class="text-3xl  text-primary">Costo&nbsp;:&nbsp;<b>{{data.movement.TOTAL_BASE_PRICE  | currency:'CLP':'symbol-narrow'}}</b> </h2>
                    </div>
 
                     
                     <div class="col-span-7 text-right text-lg"><!--  <b>{{ totalItems }}</b> -->
                                 <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->
                                 <div class="table-responsive">
                                     <table class="table table-wishlist table-mobile">
                                         <thead>
                                             <tr>
                                                 <th> &nbsp;</th>
                                                 <th>Total Cantidad</th>
                                                 <th>Total Productos</th>
                                             </tr>
                                         </thead>
                 
                                         <tbody>
                                             <tr *ngFor="let o of summary; let i = index;">
                                                 
                                                 <td>{{ o.field}}</td>
                                                 <td>
                                                      <!-- &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
                                                     <b>{{ o.sum  }}</b>
                                                      <!-- &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
                                                     </td>
                                                 <td><b>{{ o.count  }}</b></td>
                                             </tr>
                                         </tbody>
                                     </table>                                 
                                  
                                 <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->                                 
                     </div>
                     <!-- Divider -->
                     <div class="col-span-12 my-3 border-b"></div>
                   
                     <!-- Total -->
                     <ng-container *ngIf="basePrice">
                         <div class="col-span-10 self-center text-2xl font-medium tracking-tight text-secondary">TOTAL</div>
                         <div class="col-span-2 text-right text-2xl font-medium"> 0 </div>
                     </ng-container>
                   </div>
             </div>
         </div>
 
     </div>
 
 </div>
 
 