/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DocsService {

  appToken =  localStorage.getItem('appToken');//environment.appTokenUser;


  constructor(
    private http: HttpClient,
  ) { 
    // console.log(this.appToken)
  }

  async getDataDteMake(): Promise<any> {
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this.http.get<any>( environment.endPoints.infoDTE);
        res.subscribe(
          (data) => {
            // console.log(data.dataSet);
            data = data.dataSet;
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };

  // Reglas de documentos
  async getRulesFormatByType(auth: any, type: number): Promise<any> {
    return this.http.post<any>( environment.endPoints.format, {
      'auth': JSON.stringify(auth),
      'data': JSON.stringify({ TipoDTE: type })
    });

  }

  // BOLETAS
  async emit(data:any,  typeDoc? :any): Promise<any> { 
    const $this = this; 
    this.appToken = localStorage.getItem('appToken');
    const emisorData = JSON.parse(localStorage.getItem('emisorData'));
    var auth = null;
    //////////////////////////////////////////////////////
    if(typeDoc == 'BOL'){
      auth = {"appToken":this.appToken,"language":"es","environment": emisorData.emisorData[0].COD_ENVIROMENT_BOL,"user":emisorData.usrData[0].USER, "passwd":null, "pin":emisorData.usrData[0].PIN,"log":false};
        }
        else if(typeDoc == 'TKT' ){ // hacer clase para tratar los tkt
              auth = {"appToken":this.appToken,"language":"es","environment": emisorData.emisorData[0].COD_ENVIROMENT_TKT,"user":emisorData.usrData[0].USER, "passwd":null, "pin":emisorData.usrData[0].PIN,"log":false};
        }
        else{
              auth = {"appToken":this.appToken,"language":"es","environment": emisorData.emisorData[0].COD_ENVIROMENT_DTE,"user":emisorData.usrData[0].USER, "passwd":null, "pin":emisorData.usrData[0].PIN,"log":false};
        }
    //////////////////////////////////////////////////////
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this.http.post<any>( environment.endPoints.emitDTE, {
          'auth': JSON.stringify(auth),
          'data': JSON.stringify(data)
        });
        res.subscribe(
          (dataRes) => {
            console.log(dataRes);
            // data = data.dataSet;
            resolve(dataRes);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }

  // Verificar documentos
  async chkDocs(auth,data: any): Promise<any> {
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    this.appToken = localStorage.getItem('appToken');
    const emisorData = JSON.parse(localStorage.getItem('emisorData'));
    var endPoint : string
    if(auth.environment == 'TEST-TKT' || auth.environment == 'PROD-TKT' ){   
      endPoint=environment.endPoints.chkTKT
    }else{
      endPoint=environment.endPoints.chkDTE
    }
    //////////////////////////////////////////////////////
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this.http.post<any>( endPoint, {
          'auth': JSON.stringify(auth),
          'data': JSON.stringify(data)
        });
        res.subscribe(
          (dataRes) => {
            // console.log(dataRes);
            // data = data.dataSet;
            resolve(dataRes);
          },
          (error) => {
            resolve(error.error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
    // return await this.http.post<any>( environment.endPoints.chkDTE, {
    //   'auth': JSON.stringify(auth),
    //   'data': JSON.stringify(data)
    // });
  }


  // Resumen de todos los documentos emitidos
  async getSummary(rut: string, month: number, year: number): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
        //
        let emisorData = (JSON.parse(localStorage.getItem('emisorData')))
        let env        = emisorData.emisorData[0].GROUP_ENVIROMENT_DTE
        let endPoint   = environment.endPoints.summary.replace('_$ENV$_',env) 
        //
    return await new Promise (async function(resolve, reject) {
      // const summary = {};
      try {
        const res = await $this.http.get<any>(endPoint + rut + '/' + month + '/' + year);
        res.subscribe(
          (data) => {
            // console.log(data.dataSet);
            data = data.dataSet;
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
        // resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }
//===========================================================================================================
  // Obtener todos los documentos recibidos
  async getReceivedDocs(rutEmisor: string,rutReceptor: string, month: number, year: number): Promise<any> {
    const $this = this;
    var url;
    this.appToken = localStorage.getItem('appToken');
        //
        let emisorData = (JSON.parse(localStorage.getItem('emisorData')))
        let env        = emisorData.emisorData[0].GROUP_ENVIROMENT_DTE
        let endPoint   = environment.endPoints.summaryRecibos.replace('_$ENV$_',env) 
        //
          if(rutReceptor == null) url=`${endPoint}${rutEmisor}/${month}/${year}`
                            else url=`${endPoint}${rutEmisor}/${rutReceptor}/${month}/${year}`
        //
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this.http.get<any>(url);
        res.subscribe(
          (data) => {
            // console.log(data);
            data = data.dataSet; 
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };
//===========================================================================================================
//:environment/:rutEmisor/:rutReceptor/:month/:year
  async listDocsByType(rutEmisor: string,rutReceptor: string, month: number, year: number, type: string): Promise<any>{
    const $this = this;
    var url;
    this.appToken = localStorage.getItem('appToken');
    //
    let emisorData = (JSON.parse(localStorage.getItem('emisorData')))
    var  env=null,endPoint  = null
    //

    return await new Promise (async function(resolve, reject) {
      try {
        if(type === 'BOL'){
          //
          env = emisorData.emisorData[0].GROUP_ENVIROMENT_BOL
          endPoint = environment.endPoints.listBOL.replace('_$ENV$_',env)
          //
          if(rutReceptor == null) url=`${endPoint}${rutEmisor}/${month}/${year}`
                             else url=`${endPoint}${rutEmisor}/${rutReceptor}/${month}/${year}`
          //
          const res = await $this.http.get<any>(url);
          res.subscribe(
            (data) => {
              // console.log(data);
              data = data.dataSet;
              resolve(data);
            },
            (error) => {
              reject(error);
            }
          );
        }else if(type === 'DTE'){
          //
          env = emisorData.emisorData[0].GROUP_ENVIROMENT_DTE
          endPoint = environment.endPoints.listDTE.replace('_$ENV$_',env)
          //
          if(rutReceptor == null) url=`${endPoint}${rutEmisor}/${month}/${year}`
                             else url=`${endPoint}${rutEmisor}/${rutReceptor}/${month}/${year}`
          //         
          const res = await $this.http.get<any>(url);
          res.subscribe(
            (data) => {
              // console.log(data);
              data = data.dataSet;
              resolve(data);
            },
            (error) => {
              reject(error);
            }
          );
        }else if(type === 'TKT'){
          //
          env = emisorData.emisorData[0].GROUP_ENVIROMENT_TKT
          endPoint = environment.endPoints.listTKT.replace('_$ENV$_',env)
          //
          if(rutReceptor == null) url=`${endPoint}${rutEmisor}/${month}/${year}`
                             else url=`${endPoint}${rutEmisor}/${rutReceptor}/${month}/${year}`
          //           
          const res = await $this.http.get<any>(url);
          res.subscribe(
            (data) => {
              // console.log(data);
              data = data.dataSet;
              resolve(data);
            },
            (error) => {
              reject(error);
            }
          );
        } else if(type === 'CTZ'){
          //
          env = emisorData.emisorData[0].GROUP_ENVIROMENT_TKT
          endPoint =  environment.endPoints.listCTZ.replace('_$ENV$_',env)
          //
          if(rutReceptor == null) url=`${endPoint}${rutEmisor}/${month}/${year}`
                             else url=`${endPoint}${rutEmisor}/${rutReceptor}/${month}/${year}`
          //           
          const res = await $this.http.get<any>(url);
          res.subscribe(
            (data) => {
              // console.log(data);
              data = data.dataSet;
              resolve(data);
            },
            (error) => {
              reject(error);
            }
          );
        }

      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };
//===================================================================================================================================
  async getJSONByUUID(uuid: string): Promise<any> {
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this.http.get<any>( environment.endPoints.JSON + uuid);
        res.subscribe(
          (data) => {
            // console.log(data);
            data = data.dataSet;
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//===========================================================================================================
  async genNotaCredito(uuid: string, typeNC: string): Promise<any> {
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this.http.get<any>( environment.endPoints.genNotaCredito + uuid + '/' + typeNC);
        res.subscribe(
          (data) => {
            // console.log(data);
            data = data.dataSet;
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//===========================================================================================================
  async genNotaDebito(uuid: string, typeND: string): Promise<any> {
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this.http.get<any>( environment.endPoints.genNotaDebito + uuid + '/' + typeND);
        res.subscribe(
          (data) => {
            // console.log(data);
            data = data.dataSet;
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//===========================================================================================================
  // Resumen por tipo de documento
  // Resumen por tipo de documento
  async getSummaryByType(rutEmpresa: string, month: number, year: number, type: string): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    //
    let emisorData = (JSON.parse(localStorage.getItem('emisorData')))
    var  env=null,endPoint  = null
    //
    return await new Promise (async function(resolve, reject) {
      try {
        if(type === 'BOL'){
          //
          env = emisorData.emisorData[0].GROUP_ENVIROMENT_BOL
          endPoint = environment.endPoints.summaryBOL.replace('_$ENV$_',env)
          //            
          const res = await $this.http.get<any>( endPoint + rutEmpresa + '/' + month + '/' + year);
          res.subscribe(
            (data) => {
              // console.log(data);
              data = data.dataSet;
              resolve(data);
            },
            (error) => {
              reject(error);
            }
          );
        }else if(type === 'DTE'){
          //
          env = emisorData.emisorData[0].GROUP_ENVIROMENT_DTE
          endPoint = environment.endPoints.summaryDTE.replace('_$ENV$_',env)
          //            
          const res = await $this.http.get<any>(endPoint + rutEmpresa + '/' + month + '/' + year);
          res.subscribe(
            (data) => {
              // console.log(data);
              data = data.dataSet;
              resolve(data);
            },
            (error) => {
              reject(error);
            }
          );
        }else if(type === 'TKT'){
          //
          env = emisorData.emisorData[0].GROUP_ENVIROMENT_TKT
          endPoint = environment.endPoints.summaryTKT.replace('_$ENV$_',env)
          //            
          const res = await $this.http.get<any>( endPoint + rutEmpresa + '/' + month + '/' + year);
          res.subscribe(
            (data) => {
              // console.log(data);
              data = data.dataSet;
              resolve(data);
            },
            (error) => {
              reject(error);
            }
          );
        }else{
          reject('Tipo de documento no válido');
        }
      }catch (err) {
        reject(err);
      }
    });
  };
//===========================================================================================================
  async genCtz(ctz: any, auth: any): Promise<any> {
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this.http.post<any>( environment.endPoints.ctz, {
          'auth': JSON.stringify(auth),
          'data': JSON.stringify(ctz)
        });
        res.subscribe(
          (data) => {
            // console.log(data);
            // data = data.dataSet;
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//===========================================================================================================
  async makeDTEFromCTZ(uuid: any, auth: any,TipoDTE:string): Promise<any> {
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    // let DTE = {};
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this.http.post<any>( environment.endPoints.makeDTEFromCTZ, {
          'auth': JSON.stringify(auth),
          'data': JSON.stringify({
            'uuidCTZ': uuid,
            'TipoDTE': TipoDTE
          })
        });
        res.subscribe(
          (data) => {
            data = data;
            console.log(data);
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };
//===========================================================================================================
  async getDocTypes(): Promise<any> {
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    this.appToken = localStorage.getItem('appToken');
    const url = `${environment.endPoints.documentTypes}${this.appToken}`
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this.http.get<any>(url);
        res.subscribe(
          (data) => {
            // console.log(data);
            data = data.dataSet;
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };
//===========================================================================================================
  async createGuiaEntrada(guia: any): Promise<any> { 
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    const url = `${environment.endPoints.guiaDeEntrada}`
    return await new Promise (async function(resolve, reject) {
      try {
        guia.appToken = $this.appToken
        const res = await $this.http.post<any>(url, { 
          "data": JSON.stringify(guia)
        });
        res.subscribe(
          (data) => {
            // console.log(data);
            data = data.dataSet;
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };
//===========================================================================================================
//===========================================================================================================
async recepcionDTE(json: any): Promise<any> { 
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  const url = `${environment.endPoints.recepcionDTE}`
  return await new Promise (async function(resolve, reject) {
    try {
      json.appToken = $this.appToken
      const res = await $this.http.post<any>(url, { 
        "data": JSON.stringify(json)
      });
      res.subscribe(
        (data) => {
          // console.log(data);
          data = data.dataSet;
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//===========================================================================================================
async aceptacionReclamoDTE(json: any): Promise<any> { 
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  const url = `${environment.endPoints.aceptacionReclamoDTE}`
  return await new Promise (async function(resolve, reject) {
    try {
      json.appToken = $this.appToken
      const res = await $this.http.post<any>(url, { 
        "data": JSON.stringify(json)
      });
      res.subscribe(
        (data) => {
          // console.log(data);
          data = data.dataSet;
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//===========================================================================================================
}
