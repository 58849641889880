import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CatalogService } from 'app/services/catalog.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { split } from 'lodash';
import { ChangeDetectorRef } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectClientesComponent } from '../../../clientes/select-clientes/select-clientes.component'
import { FuseConfirmationService } from '@fuse/services/confirmation';

@Component({
  selector: 'app-dialog-cost',
  templateUrl: './dialog-cost.component.html',
  styleUrls: ['./dialog-cost.component.scss']
})
export class DialogCostComponent implements OnInit {

  costosForm: UntypedFormGroup;
  currentCosts : any =[]

  supplierCost : any ={}

//////////////////////////////////////////////////
//////////////////////////////////////////////////
constructor(
  public dialog: MatDialog,
  public _dialogRef: MatDialogRef<DialogCostComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any,
  private _catalogService: CatalogService,
  private ref: ChangeDetectorRef,
  private _fb: FormBuilder,
  private _dialog: MatDialog,
  private _fuseConfirmationService: FuseConfirmationService,
) {

  this.costosForm = this._fb.group({
    name: ['', [Validators.required, Validators.minLength(1)]],
    price: [0, [Validators.required, Validators.minLength(1), Validators.min(0.01)]],
    active: [true, [Validators.required]],
    provider: ['', [Validators.required]],
    idPsServicesCostItem:[0, [Validators.required]],
    nameServicesCostItem:[0, [Validators.required]],
    uuidCrmCustomer         : [''],
    rutCustomer             : [null,[Validators.required]],//'66666666-6'
    nameCustores            : [null],//'CLIENTE GENERICO'
  });

 }
//////////////////////////////////////////////////
//////////////////////////////////////////////////
async ngOnInit(): Promise<any> {
  //////////////////////////////
  this.currentCosts = this.data.currentCosts
  this.supplierCost = this.currentCosts.filter(i => i.FLAG_COST_SUPPLIER == 'Y')
  //////////////////////////////
}
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
setNameCost(o){
  var flagsupplierCost
  if(o.FLAG_COST_SUPPLIER =='Y' &&  this.supplierCost.length > 0 ){
    //===============================================================
    this.costosForm.get('idPsServicesCostItem').setValue(null);
        const dialogRef = this._fuseConfirmationService.open({
          title: `No es posible agregar, solo puede existir un costo <b>${o.NAME_SERVICES_COST_ITEM}</b>`,
          message: `¡¡Atención!!<br><b>Debe Eliminar el costo antes de agregar uno nuevo</b> `,
          icon: {
              show: true,
              name: 'delete',
              color: 'warn'
          },
          actions: {
              confirm: {
                  label: 'Continuar',
                  color: 'warn'
              },
              cancel: {
                  show: false,
                  label: 'Cancelar',
              }
          }
        });
        return false;
        dialogRef.afterClosed().subscribe(async (result) => {
              //"""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
                 return false
              //"""""""""""""""""""""""""""""""""""""""""""""""""""""""""""  
        })     
    //===============================================================    
  }else{
    //===============================================================    
    this.costosForm.get('nameServicesCostItem').setValue(o.NAME_SERVICES_COST_ITEM);
    //===============================================================    
  }
  
}
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
closeBtn(){
  // Unsuscribe to all observables
  this._dialogRef.close();
};
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
addCost(){
  this._dialogRef.close(
    {
      costosForm : this.costosForm.value
    }
  );
}
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
showCustomer(){
  const dialogRef = this._dialog.open(SelectClientesComponent, {
      
    height: '100%',
    width: '100%',
    data: {
       flagCustomer     : false
      ,flagSupplier     : true
      ,flagEmployee     : false
      ,flagAssociate    : false
      ,flagDistributor  : false

    }
});
dialogRef.afterClosed().subscribe(async (result) => {
    this.costosForm.get('uuidCrmCustomer').setValue(result.UUID_CRM_CUSTOMER      );
    this.costosForm.get('rutCustomer').setValue(result.RUT     );
    this.costosForm.get('nameCustores').setValue(result.CUSTOMER_FANCY_NAME     );

});
}
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
}
