<div class="p-10 sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- Header -->
    <div class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
        <!-- Title -->

        <div > Movimientos Bodega: <b>{{data.codWarehouse}}-{{data.nameWareHouse}}  </b></div>
        <div >  Producto:<b>{{this.data.codProduct}}-{{data.nameProduct}}  </b></div>
        <div >
            <button mat-raised-button color="primary" (click)="btnClose()"> <mat-icon>close</mat-icon>Cerrar Ventana</button>
        </div>  
    </div>
    <div class="flex flex-auto overflow-hidden">

        <!-- Products list -->
        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">

    <mat-card>
        <h2 class="text-2xl pb-2">Filtros</h2>
        <mat-card-content class="flex">
<!-- ////////////////////////////////////////////////////////////////-->         
                <mat-form-field class="w-1/4 pr-5">
                    <input matInput placeholder="Codigo de Movimiento"  (keyup)="applyFilter($event,'txt')" placeholder="Buscar..." [(ngModel)]="txtSearch">
                </mat-form-field>

                <mat-form-field class="w-1/4 pr-5">
                    <mat-select placeholder="Reason Code" (selectionChange)="applyFilter($event.value,'select')" [(ngModel)]="reasonCodesSelected">
                        <ng-container *ngFor="let rc of reasonCodes">
                            <mat-option [value]="rc.COD_REASON_CODE">
                                {{ rc.NAME_COD_REASON_CODE }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

<!--                 
                <mat-form-field class="w-1/4 pr-5">
                    <mat-select
                        placeholder="Reason Code"
                        formControlName="reasonCode"
                    >
                        <ng-container *ngFor="let rc of reasonCodes">
                            <mat-option [value]="rc.NAME_COD_REASON_CODE">
                                {{ rc.NAME_COD_REASON_CODE }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>


                <mat-form-field class="w-1/3">
                    <mat-date-range-input [rangePicker]="picker" >
                        <input matStartDate placeholder="Start date" formControlName="startDate">
                        <input matEndDate placeholder="End date" formControlName="endDate">
                    </mat-date-range-input>
                    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
-->
<!-- ////////////////////////////////////////////////////////////////-->
            <button
                mat-raised-button
                color="warn"
                class="ml-2"
                (click)="resetFilters()"
            >
                <mat-icon>refresh</mat-icon>
            </button>
        </mat-card-content>
        <!-- <button mat-raised-button (click)="test()"> test </button> -->
    </mat-card>

    <div class="py-2"></div>

    <!-- Contenido -->
    <mat-card class="w-full">
        <mat-card-content class="w-full">
            <div class="flex justify-between">
                <h2 class="text-2xl pb-2">Egresos/Ingresos por ventas</h2>
                <!-- <button
                    mat-raised-button
                    color="primary"
                    (click)="
                        createOrUpdateComponent('casos', 'create')
                    "
                >
                    Nuevo
                </button> -->
            </div>
            <table mat-table [dataSource]="movementsTable" class="w-full"> 
                <ng-container matColumnDef="codMovimineto">
                    <th mat-header-cell *matHeaderCellDef> Codigo de movimiento </th>
                    <td mat-cell *matCellDef="let element"> {{element.COD_WAREHOUSE_MOVEMENTS}} </td>
                </ng-container>

                <ng-container matColumnDef="folio">
                    <th mat-header-cell *matHeaderCellDef>Folio</th>
                    <td mat-cell *matCellDef="let element"> {{element.FOLIO_DTE}}</td>
                </ng-container>

                <ng-container matColumnDef="nameReasonCode">
                    <th mat-header-cell *matHeaderCellDef> Tipo de Movimiento</th>
                    <td mat-cell *matCellDef="let element"> {{element.NAME_COD_REASON_CODE}} </td>
                </ng-container>

                <ng-container matColumnDef="typeDoc">
                    <th mat-header-cell *matHeaderCellDef> Tipo de documento </th>
                    <td mat-cell *matCellDef="let element"> {{element.COD_DOC}}-{{element.GLS_COD_DOC}} </td>
                </ng-container>

                
                <ng-container matColumnDef="dateUpdate">
                    <th mat-header-cell *matHeaderCellDef> Fecha Actualizacion </th>
                    <td mat-cell *matCellDef="let element"> {{element.DATE_UPDATE | date:'dd/MM/yyyy'}} </td>
                </ng-container>

                <ng-container matColumnDef="qty">
                    <th mat-header-cell *matHeaderCellDef> Cantidad </th>
                    <td mat-cell *matCellDef="let element"> <b> {{element.QUANTITY | number }} </b> </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
                        <!--//-->      
                        <mat-spinner *ngIf="isLoading" style="margin:0 auto"></mat-spinner>         
                        <mat-paginator
                        #paginator
                        [pageSize]="detailPag"
                        showFirstLastButtons
                        [pageSizeOptions]="pageSizeOptions" 
                        aria-label="Select page of periodic elements">
                    </mat-paginator>               
                        <!--//--> 
<!--
            <mat-paginator
                #paginator
                pageSize="5"
                showFirstLastButtons>
            </mat-paginator>
-->



        </mat-card-content>
    </mat-card>
</div>
</div>