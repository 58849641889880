import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { psServicesWhareHouseTable,psServicesWhareHouseTransferTable } from '../model/psServicesWhareHouse';
import { wareHouseMovementsTable } from '../model/wareHouseMovements';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

    // appToken = 'CCA067D69814CADAE0536B01A8C00150';
    // appToken = '804ff3684dd74764b048aa8d7e129ff4';
    appToken = localStorage.getItem('appToken');//environment.appTokenUser;



  constructor(
    private _http: HttpClient,
    private _toast: ToastrService
  ) { }
//////////////////////////////////////////////////////////////////  
////////////////////////////////////////////////////////////////// 
  async getWarehouse(): Promise<any> {};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getWarehouseMovement(type:string, year: number, month: number): Promise<any> { 
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const res = await this._http.get(`${environment.endPoints.getAllMovimientosWH}/${this.appToken}/${type}/${year}/${month}/`);
      res.subscribe(
        (data: any) => {
          resolve(data.dataSet);
        },
        (err) => {
          reject(err);
        }
      );
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
public getAllMovimientosWHPagination(
  type         : string
 ,uuidCustomer : string
 ,year         : number 
 ,month        : number  

 ,page         : number
 ,perPage      : number
 ,from         : string 
 ,searchTerm   : string 
 ,orderBy      : string 
): Observable<wareHouseMovementsTable> {
 const $this = this;
 var url;
 this.appToken = localStorage.getItem('appToken');
 url =  $this.appToken 
 + '/' + type
 + '/' + uuidCustomer
 + '/' + year  
 + '/' + month    
 + '/' + page	
 + '/' + perPage		
 + '/' + from											
 + '/' + searchTerm			
 + '/' + orderBy;
url = `${environment.endPoints.getAllMovimientosWHPagination}/${url}`;

 return this._http.get<wareHouseMovementsTable>(url);
}
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getWarehouseMovementDetail(uuidWarehouseMovement: string): Promise<any> {
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const res = await this._http.get(`${environment.endPoints.warehouseMovementsDetail}/${this.appToken}/${uuidWarehouseMovement}`);
      res.subscribe(
        (data: any) => {
          resolve(data.dataSet);
        },
        (err) => {
          reject(err);
        }
      );
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
  async updateWarehouseMovement(json): Promise<any> {
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise(async (resolve, reject) => {
      try {
        json.appToken = $this.appToken
        const res = await this._http.patch(`${environment.endPoints.guiaDeEntrada}`,{
          "data": JSON.stringify(json)
        });
        res.subscribe(
          (data: any) => {
            // console.log(data);
            resolve(data.dataSet);
          },
          (err) => {
            reject(err);
          }
        );
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
  async warehouseMovementsDelete(uuidWarehouseMovement: string): Promise<any> {
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise(async (resolve, reject) => {
      try {
        const res = await this._http.delete(`${environment.endPoints.warehouseMovementsDelete}/${this.appToken}/${uuidWarehouseMovement}`);
        res.subscribe(
          (data: any) => {
            resolve(data.dataSet);
          },
          (err) => {
            reject(err);
          }
        );
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
  async createStorageLevel(json, update): Promise<any> {
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    json = {
      "appToken": this.appToken,
      "language": "es",
      ...json
    }
    return await new Promise(async (resolve, reject) => {
      try {
        const res = await $this._http.post(`${environment.endPoints.storageLevel}`,{
          "data": JSON.stringify(json)
        });

        res.subscribe(
          (data: any) => {
            // console.log(data);
            resolve(data.dataSet);
            if(update){
              this._toast.success('Nivel de almacenamiento actualizado correctamente', 'Éxito');
            }else{
              this._toast.success('Nivel de almacenamiento creado correctamente', 'Éxito');
            }
          },
          (err) => {
            reject(err);
          }
        );
      } catch (err) {
        
      }
    });
  };
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
  // async updateWarehouseStorageLevel(json): Promise<any>{
  //   const $this = this;
  //   return await new Promise(async (resolve, reject) => {
  //     try {
  //       const res = await $this._http.patch(`${environment.endPoints.storageLevel}`,{
  //         "data": JSON.stringify(json)
  //       });

  //       res.subscribe(
  //         (data: any) => {
  //           // console.log(data);
  //           resolve(data.dataSet);
  //         },
  //         (err) => {
  //           reject(err);
  //         }
  //       );
  //     } catch (err) {
        
  //     }
  //   });
  // }
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
  async deleteWarehouseStorageLevel(uuidPsServices: string, uuidWarehouse:string): Promise<any>{
    const $this = this; 
    this.appToken = localStorage.getItem('appToken'); 
    return await new Promise(async (resolve, reject) => {
      try {
        const res = await $this._http.delete(`${environment.endPoints.storageLevel}${$this.appToken}/${uuidPsServices}/${uuidWarehouse}`);

        res.subscribe(
          (data: any) => {
            // console.log(data);
            resolve(data.dataSet);
            this._toast.success('Nivel de almacenamiento eliminado correctamente', 'Éxito');
          },
          (err) => {
            reject(err);
          }
        );
      } catch (err) {
        
      }
    });
  };
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getWarehouseSummary(): Promise<any> {
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const res = await this._http.get(`${environment.endPoints.warehouseStorageSummary}/${this.appToken}`);
      res.subscribe(
        (data: any) => {
          resolve(data.dataSet);
        },
        (err) => {
          reject(err);
        }
      );
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getWarehouseSummaryDetail(uuidWarehouse : string): Promise<any> {
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const res = await this._http.get(`${environment.endPoints.warehouseStorageSummaryDetail}/${this.appToken}/${uuidWarehouse}`);
      res.subscribe(
        (data: any) => {
          resolve(data.dataSet);
        },
        (err) => {
          reject(err);
        }
      );
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getWareHouseinventory(year: number, month: number): Promise<any> {
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const res = await this._http.get(`${environment.endPoints.wareHouseinventory}/${this.appToken}/${year}/${month}`);
      res.subscribe(
        (data: any) => {
                    ////////////////////////////////////////////////////////////////////
                    data.dataSet.mantencion.forEach(e => {
                          e.EMPLOYEE_CREATION = JSON.parse(e.EMPLOYEE_CREATION)
                          e.EMPLOYEE_UPDATE   = JSON.parse(e.EMPLOYEE_UPDATE)
                          e.TOTAL_INVENTORY   = JSON.parse(e.TOTAL_INVENTORY)
                    });
                    data.dataSet.disponible.forEach(e => {
                          e.EMPLOYEE_CREATION = JSON.parse(e.EMPLOYEE_CREATION)
                          e.EMPLOYEE_UPDATE   = JSON.parse(e.EMPLOYEE_UPDATE)
                          e.TOTAL_INVENTORY   = JSON.parse(e.TOTAL_INVENTORY)
                    });
                    ///////////////////////////////////////////////////////////////////
          resolve(data.dataSet);
        },
        (err) => {
          reject(err);
        }
      );
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async delWareHouseinventory(uuidWmNewInventory: string,chkWord: string): Promise<any>{
const $this = this;
this.appToken = localStorage.getItem('appToken');
return await new Promise (async function(resolve, reject) {
  try {
    const url =`${environment.endPoints.wareHouseinventory}/${$this.appToken}/${uuidWmNewInventory}/${chkWord}`
    const res = await $this._http.delete<any>(url).subscribe({
                                                  next: data => {
                                                    resolve(data.dataSet);
                                                  },
                                                  error: error => {
                                                      
                                                      console.log(error.error.dataSet, error);
                                                      reject(error.error.dataSet);
                                                  },
                                                  complete: () => console.info('complete') 
                                              })

  }
  catch (err) {
    console.log(err);
    reject(err);
  }
});

};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async newWareHouseinventory(json): Promise<any> {
const $this = this;
this.appToken = localStorage.getItem('appToken');
json.appToken = this.appToken
return await new Promise (async function(resolve, reject) {
  try {
    const url = `${environment.endPoints.wareHouseinventory}`;
    const res = await $this._http.post<any>(url,{
                                                  "data": JSON.stringify(json)
                                                }).subscribe({
                                                  next: data => {
                                                    resolve(data.dataSet);
                                                  },
                                                  error: error => {
                                                      
                                                      console.log(error.error.dataSet, error);
                                                      reject(error.error.dataSet);
                                                  },
                                                  complete: () => console.info('complete') 
                                              })

  }
  catch (err) {
    console.log(err);
    reject(err);
  }
});

};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getWareHouseinventoryDetail(uuidWmNewInventory: string): Promise<any> {
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const res = await this._http.get(`${environment.endPoints.wareHouseinventoryDetail}/${this.appToken}/${uuidWmNewInventory}`);
      res.subscribe(
        (data: any) => {
          ////////////////////////////////////////////////////////////////////
          data.dataSet.detail.forEach(e => {
                e.QUANTITY_NOW_WAREHOUSE  = JSON.parse(e.QUANTITY_NOW_WAREHOUSE)
          });
          ///////////////////////////////////////////////////////////////////
          resolve(data.dataSet);
        },
        (err) => {
          reject(err.dataSet);
        }
      );
    } catch (err) {
      console.log(err);
      reject(err.dataSet);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async delWareHouseinventoryDetail(uuidWmNewInventory: string,uuidWmNewInventoryDetail: string): Promise<any>{
  const $this = this; 
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const res = await $this._http.delete(`${environment.endPoints.wareHouseinventoryDetail}/${$this.appToken}/${uuidWmNewInventory}/${uuidWmNewInventoryDetail}`);

      res.subscribe(
        (data: any) => {

          resolve(data.dataSet);
          /*
          this._toastr.success('Nivel de almacenamiento eliminado correctamente', 'Éxito');
          */
        },
        (err) => {
          reject(err);
        }
      );
    } catch (err) {
      
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getWareHouseinventoryPs(uuidWmNewInventory: string): Promise<any> {
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const res = await this._http.get(`${environment.endPoints.wareHouseinventoryPs}/${this.appToken}/${uuidWmNewInventory}`);
      res.subscribe(
        (data: any) => {
          resolve(data.dataSet);
        },
        (err) => {
          reject(err);
        }
      );
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async processWareHouseinventory(uuidWmNewInventory): Promise<any> {
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  
  let json = {
           appToken              : this.appToken
          ,language              : 'es'       
          ,uuidSSO               : null
          ,uuidWmNewInventory    : uuidWmNewInventory   
        }
  return await new Promise(async (resolve, reject) => {
    try {
      const res = await $this._http.post(`${environment.endPoints.processWareHouseinventory}`,{
        "data": JSON.stringify(json)
      });

      res.subscribe(
        (data: any) => {
           console.log(data);
          resolve(data.dataSet);
          /*
          if(update){
            this._toastr.success('Nivel de almacenamiento actualizado correctamente', 'Éxito');
          }else{
            this._toastr.success('Nivel de almacenamiento creado correctamente', 'Éxito');
          }*/
        },
        (err) => {
          reject(err);
        }
      );
    } catch (err) {
      
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getWareHouseMovementsPsServices(uuidWarehouse: string): Promise<any> {
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const res = await this._http.get(`${environment.endPoints.wareHouseMovementsPsServices}/${this.appToken}/${uuidWarehouse}`);
      res.subscribe(
        (data: any) => {
          ////////////////////////////////////////////////////////////////////
          data.dataSet.psServices.forEach(e => {
                    e.QUANTITY_NOW_WAREHOUSE  = JSON.parse(e.QUANTITY_NOW_WAREHOUSE)
                  });
          ///////////////////////////////////////////////////////////////////          
          resolve(data.dataSet);
        },
        (err) => {
          reject(err);
        }
      );
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async newWarehouseTransfer(json): Promise<any> {
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  json.appToken = this.appToken
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.wareHouseMovementsTransfer}`;
      const res = await $this._http.post<any>(url,{
                                                    "data": JSON.stringify(json)
                                                  }).subscribe({
                                                    next: data => {
                                                      resolve(data.dataSet);
                                                    },
                                                    error: error => {
                                                        
                                                        console.log(error.error.dataSet, error);
                                                        reject(error.error.dataSet);
                                                    },
                                                    complete: () => console.info('complete') 
                                                })
  
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
  
  };
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async udpWarehouseTransfer(json): Promise<any> {
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  json.appToken = this.appToken
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.wareHouseMovementsTransfer}`;
      const res = await $this._http.patch<any>(url,{
                                                    "data": JSON.stringify(json)
                                                  }).subscribe({
                                                    next: data => {
                                                      resolve(data.dataSet);
                                                    },
                                                    error: error => {
                                                        
                                                        console.log(error.error.dataSet, error);
                                                        reject(error.error.dataSet);
                                                    },
                                                    complete: () => console.info('complete') 
                                                })
  
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
  
  }
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
  public getWareHouseMovementsPsServicesPagination(
    flagConsignado     : string
   ,uuidWh             : string 
   ,codCategory        : string  
   ,codGroup           : string  

   ,page               : number
   ,perPage            : number
   ,from               : string 
   ,searchTerm         : string 
   ,orderBy            : string 
   ,uuidBrands         : string
  ): Observable<psServicesWhareHouseTable> {
   const $this = this;
   var url;
   this.appToken = localStorage.getItem('appToken');
   url =  $this.appToken 
   + '/' + flagConsignado
   + '/' + uuidWh
   + '/' + codCategory  
   + '/' + codGroup    
   + '/' + page	
   + '/' + perPage		
   + '/' + from											
   + '/' + searchTerm			
   + '/' + orderBy
   + '/' + uuidBrands;
  url = `${environment.endPoints.wareHouseMovementsPsServicesPagination}/${url}`;
  
   return this._http.get<psServicesWhareHouseTable>(url);
  }
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
public getWareHouseTransferPsServicesPagination(
  flagConsignado     : string
 ,uuidWh             : string 
 ,codCategory        : string  
 ,codGroup           : string  

 ,page               : number
 ,perPage            : number
 ,from               : string 
 ,searchTerm         : string 
 ,orderBy            : string 
 ,uuidBrands         : string
 ,storageType         : string
): Observable<psServicesWhareHouseTransferTable> {
 const $this = this;
 var url;
 this.appToken = localStorage.getItem('appToken');
 url =  $this.appToken 
 + '/' + flagConsignado
 + '/' + uuidWh
 + '/' + codCategory  
 + '/' + codGroup    
 + '/' + page	
 + '/' + perPage		
 + '/' + from											
 + '/' + searchTerm			
 + '/' + orderBy
 + '/' + uuidBrands
 + '/' + storageType
url = `${environment.endPoints.wareHouseTransferPsServicesPagination}/${url}`;

 return this._http.get<psServicesWhareHouseTransferTable>(url);
}
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async warehousePsDataLocation(json): Promise<any> {
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  json.appToken = this.appToken
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.warehousePsDataLocation}`;
      const res = await $this._http.post<any>(url,{
                                                    "data": JSON.stringify(json)
                                                  }).subscribe({
                                                    next: data => {
                                                      $this._toast.success('Ubicación Creada', 'Éxito');
                                                      resolve(data.dataSet);
                                                    },
                                                    error: error => {
                                                      $this._toast.error('No es posble crear Ubicación', 'Error');
                                                       // console.log(error.error.dataSet, error);
                                                        reject(error.error.dataSet);
                                                    },
                                                   // complete: () => console.info('complete') 
                                                })
  
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
  
  };
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getWareHouseUniquePsServices(uuidWarehouse:string,storageType:string): Promise<any> {
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const res = await this._http.get(`${environment.endPoints.wareHouseUniquePsServices}/${this.appToken}/${uuidWarehouse}/${storageType}`);
      res.subscribe(
        (data: any) => {       
          resolve(data.dataSet); 
        },
        (err) => {
          reject(err);
        }
      );
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getWareHouseSinglePsServices(uuidWarehouse: string,uuidPsServices: string,barcode: string,storageType:string): Promise<any> {
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const res = await this._http.get(`${environment.endPoints.wareHouseSinglePsServices}/${this.appToken}/${uuidWarehouse}/${uuidPsServices}/${barcode}/${storageType}`);
      res.subscribe(
        (data: any) => {
          ////////////////////////////////////////////////////////////////////
          ///////////////////////////////////////////////////////////////////          
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
}
